import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { FetchedAccountInfo, TOSTermsVerified } from '../currentUser/actions'
import { requiredSubscription } from '../resume/resumeSlice'

/**
 * Declare list of modals managed by the placement
 */
export enum ManagedModal {
    Subscription = 'Subscription',
    TermsOfService = 'TermsOfService',
    SunsetDeletion = 'SunsetDeletion',
}

export type ModalManagerState = {
    visibleModal: ManagedModal | null
}

const initialState: ModalManagerState = {
    visibleModal: null,
}

const modalManagerSlice = createSlice({
    name: 'modalManager',
    initialState: initialState,
    reducers: {
        showModal: (state, action: PayloadAction<ManagedModal>) => {
            state.visibleModal = action.payload
        },
        hideModal: (state) => {
            state.visibleModal = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchedAccountInfo, (state, action) => {
                const accountInfo = action.payload
                if (!accountInfo.logged_in_as) {
                    return
                }

                if (accountInfo.tos_to_approve !== null) {
                    state.visibleModal = ManagedModal.TermsOfService
                }
            })
            .addCase(requiredSubscription, (state, _action) => {
                if (state.visibleModal === null) {
                    state.visibleModal = ManagedModal.Subscription
                }
            })
            .addCase(TOSTermsVerified, (state, _action) => {
                if (state.visibleModal === ManagedModal.TermsOfService) {
                    state.visibleModal = null
                }
            })
    },
})

export const { showModal, hideModal } = modalManagerSlice.actions

export const modalManagerReducerMapObj = {
    [modalManagerSlice.name]: modalManagerSlice.reducer,
}

export type StateWithModalManager = StateOfReducerMapObj<
    typeof modalManagerReducerMapObj
>
