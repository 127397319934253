import { memo, useCallback, useEffect, useRef, useState } from 'react'
import type { Token } from '@stripe/stripe-js'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import { getUserReadOnlyStatus } from '~/state/currentUser/selectors'
import { PRODUCT_NAME } from '~/config/constants'
import { BuyFromAbroad } from '~/components/Common/BuyFromAbroad'
import { _ } from '~/assets/localization/util'
import {
    fetchCreditCardInfo,
    fetchStoragePlanInfo,
    updateCreditCardInfo,
} from '~/API/storagePlan'
import { colors, fontSize, layout, mediaQueries } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import type { StripeStoragePlan } from '~/state/storagePlan/selectors'
import {
    getCreditCardInfo,
    getCurrentStripeStoragePlan,
    getStoragePlanGroups,
} from '~/state/storagePlan/selectors'
import { IconTextButton } from '../../Common/IconTextButton'
import { PageWrapper } from '../../Common/PageWrapper'
import { RippleLoaderPage } from '../../Common/RippleLoader'
import { TwoAreasTopNavBar } from '../../Navigation/TwoAreasTopNavBar'
import { BillingInfo } from './BillingInfo'
import { BuyMoreStorage } from './BuyMoreStorage'
import { PaidPerksTable } from './PaidPerksTable'
import { PaidPlanDetails } from './PaidPlanDetails'
import { PaymentSetting } from './PaymentSetting'

const ContentWrapper = styled.div`
    width: 640px;
    max-width: 640px;
    margin-top: 32px;

    ${mediaQueries.mobile} {
        padding: 0 12px;
    }
`
const SectionWrapper = styled.div`
    padding-bottom: 40px;
`
const SectionHeader = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.captureGrey300};
    padding: 16px 0;
    margin-bottom: 16px;
    font-size: ${fontSize.large_24};
    font-weight: bold;
    color: ${colors.captureGrey800};
`
const PerksWrapper = styled.div`
    padding: 0px 28px;
`
const PerksTable = () => {
    const isReadOnlyUser = useSelector(getUserReadOnlyStatus)
    if (!isReadOnlyUser) {
        return null
    }
    return (
        <SectionWrapper>
            <SectionHeader>
                {_('subscribe_to_product').replace(
                    '%product_name%',
                    PRODUCT_NAME,
                )}
            </SectionHeader>
            <PerksWrapper>
                <PaidPerksTable />
            </PerksWrapper>
        </SectionWrapper>
    )
}

type PlanDetailsProps = {
    handleCreditCardUpdate: (token: Token) => Promise<void>
    currentPlan?: StripeStoragePlan
    scrollToBuyStorageSection: () => void
}
const PlanDetails = (props: PlanDetailsProps) => {
    const { currentPlan, scrollToBuyStorageSection, handleCreditCardUpdate } =
        props
    const isReadOnlyUser = useSelector(getUserReadOnlyStatus)
    if (isReadOnlyUser) {
        return null
    }
    return (
        <SectionWrapper>
            <SectionHeader>{_('your_storage_plan')}</SectionHeader>
            <PaidPlanDetails />
            {currentPlan && (
                <BillingInfo
                    currentPlan={currentPlan}
                    handleBuyPlan={scrollToBuyStorageSection}
                    handleUpdateCreditCard={handleCreditCardUpdate}
                />
            )}
        </SectionWrapper>
    )
}

type PaymentInfoProps = {
    handleCreditCardUpdate: (token: Token) => Promise<void>
    validToDate?: string
    isCanceled?: boolean
}
const _PaymentInfo = ({
    handleCreditCardUpdate,
    validToDate,
    isCanceled,
}: PaymentInfoProps) => {
    const paymentInfo = useSelector(getCreditCardInfo)

    if (!paymentInfo) {
        return null
    }

    const hasActiveSubscription = !!validToDate && !isCanceled

    return (
        <SectionWrapper>
            <SectionHeader>{_('payment_settings')}</SectionHeader>
            <PaymentSetting
                doUpdateCreditCardInfo={handleCreditCardUpdate}
                currentCardInfo={paymentInfo}
                hasActiveSubscription={hasActiveSubscription}
            />
        </SectionWrapper>
    )
}

const PaymentInfo = memo(_PaymentInfo)

type UpgradePlanProps = { buyMoreRef: React.RefObject<HTMLDivElement> }
const UpgradePlan = (props: UpgradePlanProps) => {
    const storagePlanGroups = useSelector(getStoragePlanGroups)
    const isReadOnlyUser = useSelector(getUserReadOnlyStatus)
    return (
        <SectionWrapper>
            <SectionHeader ref={props.buyMoreRef}>
                {isReadOnlyUser
                    ? _('choose_subscription')
                    : _('upgrade_storage_plan')}
            </SectionHeader>
            {storagePlanGroups.length > 0 ? (
                <BuyMoreStorage key="more" />
            ) : (
                <BuyFromAbroad key="abroad" />
            )}
        </SectionWrapper>
    )
}

export const StoragePage = () => {
    const [isLoading, setIsLoading] = useState(true)

    const buyMoreRef = useRef<HTMLDivElement | null>(null)
    const navigate = useNavigate()
    const currentPlan = useSelector(getCurrentStripeStoragePlan)
    const dispatch = useDispatch()
    const fetchCurrentStoragePlan = () => fetchStoragePlanInfo(dispatch)
    const fetchPaymentInfo = useCallback(
        () => fetchCreditCardInfo(dispatch),
        [dispatch],
    )

    useEffect(() => {
        fetchStoragePlan()
    }, [])

    const handleCreditCardUpdate = useCallback(
        async (elementsToken: Token) => {
            if (elementsToken.card) {
                const { card, id: tokenID } = elementsToken
                await updateCreditCardInfo(dispatch, tokenID, card.id)
                await fetchCreditCardInfo(dispatch)
            }
        },
        [dispatch],
    )

    const scrollToBuyStorageSection = () => {
        if (buyMoreRef.current) {
            const startY = window.scrollY
            const diff =
                buyMoreRef.current.offsetTop - layout.topNavBarHeight - startY
            if (diff === 0) {
                return
            }
            const easing = (t: number) =>
                t < 0.5 ? 4 * Math.pow(t, 3) : 1 - 4 * Math.pow(1 - t, 3) // easeInOutCubic
            let start: number
            window.requestAnimationFrame(function step(timestamp) {
                start = start || timestamp
                const duration = 300
                const time = timestamp - start
                const percent = easing(Math.min(time / duration, 1))
                window.scrollTo(0, startY + diff * percent)
                if (time < duration) {
                    window.requestAnimationFrame(step)
                }
            })
        }
    }

    const fetchStoragePlan = async () => {
        setIsLoading(true)

        await fetchPaymentInfo()
        if (!currentPlan) {
            await fetchCurrentStoragePlan()
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return <RippleLoaderPage />
    }

    return (
        <PageWrapper
            navBar={
                <TwoAreasTopNavBar
                    left={
                        <IconTextButton
                            onClick={() => navigate(Pages.Settings.url)}
                            text={_('settings')}
                            icon={BackArrowIcon}
                            fontSize={fontSize.small_14}
                        />
                    }
                />
            }>
            <ContentWrapper>
                <PerksTable />
                <PlanDetails
                    handleCreditCardUpdate={handleCreditCardUpdate}
                    currentPlan={currentPlan}
                    scrollToBuyStorageSection={scrollToBuyStorageSection}
                />
                <PaymentInfo
                    handleCreditCardUpdate={handleCreditCardUpdate}
                    isCanceled={currentPlan?.isCanceled}
                    validToDate={currentPlan?.validToDate}
                />
                <UpgradePlan buyMoreRef={buyMoreRef} />
            </ContentWrapper>
        </PageWrapper>
    )
}
