export const strings = {
    // General
    of: 'av',
    ok: 'OK',
    yes: 'Ja',
    got_it: 'Jag förstår',
    see_more: 'Se mer',
    see_album: 'Se album',
    read_more: 'Läs mer',
    skip: 'Hoppa över',
    no: 'Nej',
    done: 'Klar',
    reactivate: 'Återaktivera',
    subscribe: 'Prenumerera',
    unsubscribe: 'Avsluta prenumerationen',
    delete_: 'Radera',
    download: 'Ladda ner',
    export: 'Exportera',
    edit: 'Redigera',
    copy: 'Kopiera',
    update: 'Uppdatera',
    cancel: 'Avbryt',
    share: 'Dela',
    undo: 'Ångra',
    back: 'Tillbaka',
    go_back: 'Gå tillbaka',
    select_all: 'Markera alla',
    restore: 'Återställ',
    filename: 'filnamn',
    file_type: 'filtyp',
    deleted: 'raderad',
    unknown: 'okänd',
    retry: 'Försök igen',
    create_account: 'Skapa ett konto',
    sign_in: 'Logga in',
    only_accessible_to_logged_in_users:
        'Den här funktionen är endast tillgänglig för inloggade användare',
    login_again_for_security:
        'Av säkerhetsskäl, vänligen logga in för att komma åt dina sekretessinställningar',
    logged_out_prompt: 'Ojdå, du loggades ut. Logga in för att fortsätta',
    capture_save_see_share: 'Spara, se och dela alla dina bilder och videor.',
    feature_only_available_in_app:
        'Denna funktion är för tillfället endast tillgänglig i appen Capture.',
    open_capture: 'Öppna Capture',
    open_in_app: 'Öppna i appen',
    storage: 'Lagring',
    active: 'Aktiv',
    save: 'Spara',
    // for buttons
    details: 'Detaljer',
    accept: 'Acceptera',
    deny: 'Blockera',
    num_of_total__format: '%num% av %total%',
    autoplay: 'automatisk uppspelning',
    something_went_wrong: 'Ojdå! Nånting gick fel.',
    cookies: 'Cookies',
    invalid_field: 'Värdet är ogiltigt',
    mandatory_field: 'Fältet är obligatoriskt',
    movies: 'Videor',
    screenshots: 'Skärmdumpar',
    recents: 'Senaste',
    here: 'här',

    // media types
    image: 'Bild',
    livephoto: 'Live photo',
    movie: 'Video',

    // Time
    january: 'Januari',
    february: 'Februari',
    march: 'Mars',
    april: 'April',
    may: 'Maj',
    june: 'Juni',
    july: 'Juli',
    august: 'Augusti',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
    day: 'Dag',
    month: 'Månad',
    year: 'År',
    monthly: 'månatlig',
    yearly: 'årsvis',
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dec',
    num_days__format: '%d dagar',
    one_day: '1 dag',
    // User Action Prompts & Alerts
    delete_files_prompt__format: `%d filer kommer att flyttas till Raderade objekt. Filerna kommer att raderas permanent efter 30 dagar.`,
    delete_single_file_prompt: `Filen kommer att flyttas till Raderade objekt. Filen kommer att raderas permanent efter 30 dagar.`,
    delete_file_failed_message__format:
        'Ett fel uppstod vid försöket att radera "%s". Vänligen försök igen.',
    // --- album prompts ---
    delete_album_file_prompt_text:
        'Filen kommer att tas bort från album. Alla kommentarer och älskar relaterade till denna fil kommer också att raderas.',
    delete_last_file_prompt_text:
        'Filen kommer att tas bort från album. Borttag av den sista filen i ett album kommer också radera albumet.',
    unsubscribe_album_prompt_text:
        'Du kommer inte längre kunna se detta album i din albumlista eller få uppdateringar om detta album.',
    delete_album_prompt_text:
        'Albumet kommer att raderas och tas bort från din albumlista.',
    delete_comment_prompt_text: 'Kommentaren kommer att raderas.',
    copy_files_to_timeline:
        'Albumets filer kommer att kopieras till dina foton.',
    // --- settings page prompts ---
    remove_device_prompt__format:
        'Du kommer att loggas ut från %s på denna enhet.',
    cancel_storage_plan_prompt__format:
        'Prenumeration kommer löpa ut %expired_date%. Totala lagringen kommer reduceras till %storage_amount% när prenumerationen löper ut.',
    reactivate_storage_plan_prompt__format:
        'Prenumerationen kommer löpa vidare i aktuell %period% fakturaperiod.',
    cancel_plan: 'Avbryt prenumeration',
    keep_plan: 'Behåll prenumerationen',
    change_storage_plan_period_prompt:
        'Bekräfta ändring av aktuell prenumerations faktureringsperiod. Den nya faktureringsperioden kommer börja omedelbart',
    // replace with plan_upgrade when charge amount is available
    upgrade_storage_plan_size_prompt__format:
        'Bekräfta ändring av aktuell prenumeration från %current_size% till %new_size%. Förändringar kommer att ske omedelbart.',
    // remove once getting charge amount is possible
    upgrade_storage_plan_prompt__format:
        'Prenumerationen kommer uppgraderas till %plan_price%/%period% (%size%). Du kommer debiteras %amount% och fakturaperioden kommer påbörjas omedelbart.',
    insufficient_plan_selected: 'Otillräcklig prenumeration vald',
    insufficient_plan_explanation__format:
        'Ditt totala lagringsutrymme efter köp av denna prenumeration (%max_storage%) är mindre än ditt nuvarande använda utrymme (%used_space%). Vänligen välj en större prenumeration eller minska på ditt använda utrymme.',
    downgrade_not_available:
        'Nedgradering av din aktuella prenumeration är inte tillgängligt just nu.',
    downgrade_active_plan_alert:
        'Du måste avbryta din aktuella prenumeration och vänta på att den ska löpa ut innan du kan välja denna prenumeration.',
    downgrade_canceled_plan_alert:
        'Du måste vänta på att din aktuella prenumeration ska löpa ut innan du kan välja denna prenumeration.',
    // --- terms of service prompts ---
    accept_tos__header: 'Acceptera tjänstevillkoren',
    accept_tos__we_have_made_changes__format:
        'Vi har gjort ändringar i våra %link_start%tjänstevillkor%link_end%.',
    accept_tos__please_read_and_accept:
        'Genom att använda tjänsten accepterar du %terms_of_service%. Vänligen läs dem noggrant.',
    decline_tos: 'Avböja och logga ut',
    // --- trash and document prompts ---
    delete_single_document_file: 'Filen kommer att raderas permanent.',
    delete_document_files__format: '%d filer kommer att raderas permanent.',
    restore_single_file_prompt:
        'Filen kommer att återställas till dina bilder.',
    restore_files_prompt__format:
        '%d filer kommer att återställas till dina bilder.',
    permanently_delete_single_file_prompt:
        'Filen kommer att raderas permanent. Denna åtgärd kan inte ångras.',
    permanently_delete_files_prompt__format:
        '%d filer kommer att raderas permanent. Denna åtgärd kan inte ångras.',
    // Navigation
    timeline: 'Bilder',
    albums: 'Album',
    add_files: 'Lägg till filer',
    documents: 'Dokument',
    settings: 'Inställningar',
    telenor_ID: 'Telenor ID',
    account: 'Konto',
    help: 'Hjälp',
    help_support: 'Hjälp och Support',
    deleted_items: 'Raderade objekt',
    login: 'Inloggning',
    log_out: 'Logga ut',
    close: 'Stäng',
    timeline_desc: 'Alla dina bilder och videor, ordnade efter datum.',
    albums_desc: 'Lägg till bilder i ett album och dela med dina vänner!',
    search_albums: 'Sök efter album',
    no_album_results:
        'Inga resultat för "%link_start%%search_string%%link_end%". Försök att söka efter ett annat album.',
    // Drag and drop
    drag_drop: 'Dra & släpp',
    drag_drop_files__format: 'Dra & släpp dina filer %split%or',
    drop_your_files__format:
        'Släpp dina filer för att ladda upp dem direkt till %product_name%!',
    dropZone_text__timeline:
        'filer för att ladda upp dem, eller en mapp för att skapa ett nytt album',
    dropZone_text__albums: 'filer eller mappar för att skapa nya album',
    dropZone_text__singleAlbum:
        'filer varsomhelst för att ladda upp dem till %album_name%',
    // click_here_to_select: 'or click here to select from you computer',

    // UploaderBox
    files: 'fil(er)',
    uploaded: 'uppladdade',
    did_not_upload: 'kunde inte laddas upp',
    upload_folder_reject: 'Kan inte ladda upp mappar',
    upload_folder_safari_reject: 'Safari stöder inte uppladdningsmappar',
    unsupported_file_reject: 'Filen stöds inte',
    was_not_uploaded: 'Laddades inte upp',
    see_which: 'Se vilken',
    // show_files: 'Show files',
    // show_photos: 'Show photos',
    offline: 'Ingen internetanslutning',
    upload: 'Ladda upp',
    uploading: 'Laddar upp',
    // pause: 'Pause',
    // resume: 'Resume',
    retrying_in: 'Försöker igen om',
    sec: 'sek',
    retrying_now: 'Försöker igen nu...',
    try_again: 'Försök igen',
    stop: 'Stopp',
    processing: 'Bearbetning',
    complete: 'Slutförd',
    cancelled: 'Avbruten',
    // unknown_format: 'Unknown format',
    preparing: 'Förbereder...',
    waiting_for_internet_connection: 'Väntar på internetanslutning...',
    out_of_storage: 'Slut på lagringsutrymme',
    free_up_space: 'Radera filer för att återuppta uppladdning',
    cancelling_upload_are_you_sure:
        'Är du säker på att du vill avbryta uppladdningen?',
    processing_video: 'Bearbetar video',
    // upload options modal
    select_from_device: 'Ladda upp foton från enheten',
    add_from_capture__format: 'Lägg till foton från %product_name%',
    // Popup
    reached_given_storage_ratio: 'Lagringsutrymmet är nästan fullt',
    reached_given_storage_ratio_subText:
        'Du har använt %used_storage%GB av %max_storage%GB. Frigör utrymme genom att radera foton eller videor, eller köp mer lagringsutrymme i Inställningar.',
    not_enough_storage_for_add: 'Otillräckligt lagringsutrymme',
    not_enough_storage_for_add_subText:
        'Du har inte tillräckligt med lagringsutrymme för alla de filer du försöker lägga till. Frigör utrymme genom att radera bilder eller videor, eller köp mer lagringsutrymme under Inställningar.',
    not_enough_storage_for_restore: 'Otillräckligt lagringsutrymme',
    not_enough_storage_for_restore_subText:
        'Du har inte tillräckligt utrymme för alla filer du försöker återställa. Frigör utrymme genom att radera bilder eller videor, eller köp mer utrymme under Inställningar.',
    // Popover
    popover__profile_picture_heading: 'Ändra din profilbild?',
    popover__profile_picture_body:
        'Du kan ändra din profilbild i inställningar, så att alla vet att du är du!',
    // T I M E L I N E
    // subscription required
    timeline_subscription_required_header:
        'Prenumeration krävs för att säkerhetskopiera foton.',
    timeline_subscription_required_sub:
        'Se våra prenumerationer och starta din kostnadsfria provperiod idag!',
    timeline_subscription_required_cta: 'Se prenumerationer',
    // emtpy state
    timeline_is_empty: 'Du har inga foton säkerhetskopierade här.',
    timeline_filtered_photos_empty: 'Du har inga foton.',
    timeline_filtered_recents_empty: 'Du har inga senaste.',
    start_uploading_photos_now: 'Varför börjar du inte ladda upp foton nu?',
    start_uploading_now: 'Varför börjar du inte ladda upp lite nu?',
    drag_drop_photos_to_upload:
        'Du kan dra och släppa dina foton här eller trycka på knappen nedan.',
    download_app_to_upload_directly_from_device:
        'Installera Capture-appen på din mobila enhet för att ladda upp foton och videor direkt från din enhets kamera och fotogalleri',
    timeline_empty_screenshot_filter: 'Du har inga skärmbilder. Bra för dig!',
    // TODO: verify text
    timeline_filtered_videos_empty: 'Du har inga videor.',
    drag_drop_files_to_upload:
        'Du kan dra och släppa dina filer här eller trycka på knappen nedan.',
    add_videos: 'Lägg till videor',
    // select from timeline empty
    upload_photos_add_album:
        'Ladda upp foton för att lägga till dem i ditt album.',
    // timeline page
    selected__format: '%d vald',
    none_selected: 'Inga objekt valda',
    select_files: 'Välj filer',
    file_count_exceeds_download_limit:
        'Du kan inte ladda ned mer än 500 objekt vid ett tillfälle. Vänligen välj färre objekt.',
    size_exceeds_download_limit:
        'Du kan inte ladda ned mer än 3,5 GB vid ett tillfälle. Vänligen ändra ditt urval.',
    fetching_file_selection_info: 'Hämtar filinformation för att utföra urval',
    shift_select_hint_header: 'Vill du snabbt välja flera foton samtidigt?',
    shift_select_hint_body:
        'Håll shift (⇧) och klicka för att välja flera objekt samtidigt.',
    fastscroller_hint_body:
        'Gå direkt till valfri månad genom att klicka på den',
    fastscroller_select_hint_body:
        'Markera en hel månad eller ett helt år genom att klicka här',
    filter_by_videos: 'Videor',
    filter_by_images: 'Bilder',
    filter_by_recents: 'Nyligen tilagda',
    filtered_by_videos: 'Filtrerat efter videor',
    filtered_by_images: 'Filtrerat efter bilder',
    filtered_by_recents: 'Filtrerat efter senaste',
    show_all: 'Visa alla',
    download_heic: 'Ladda ned HEIC',
    download_heic_desc_single: 'Original fil. Kompatibelt med iOS och macOS',
    download_heic_desc_multi:
        'filer som laddats upp. Kompatibel med iOS och macOS',
    download_jpeg: 'Ladda ned JPEG',
    download_jpeg_desc: 'Kompatibelt med de flesta enheter. Rekommenderad',
    download_original: 'Ladda ner original',
    download_original_desc: 'filer som uppladdad',
    download_converted: 'Konvertera och ladda ner',
    download_converted_desc:
        'till ett format kompatibelt med de flesta enheter',
    show: 'Visa',
    added_today: 'Tillagd idag',
    added_days__format: 'Lade till senaste %d dagarna',
    // paginated download
    file__format: '%d fil',
    files__format: '%d filer',
    about_to_download_files__format:
        'Du håller på att ladda ner %file_count_size%',
    about_to_download_paginated_description:
        'Att ladda ner många filer samtidigt kan ta lite tid. Se till att din nätverksanslutning är stabil och att din enhet är ansluten till en strömkälla.',
    download_all_at_once: 'Ladda ner allt på en gång',
    download_your_photos_and_videos: 'Ladda ner dina foton och videor',
    download_in_smaller_parts: 'Ladda ner i mindre delar',
    download_page__format: 'Ladda ner del %part% (%file_count%)',
    download_paginated_parts_below:
        'Vänligen ladda ner var och en av de mindre delarna nedan.',
    download_paginated__format:
        'Ditt val av %file_count% %file_count_size% har delats upp i mindre delar för bästa upplevelse. Klicka på varje zip-fil nedan för att ladda ner den.',
    download_pagination_convenience:
        'Du kan utan problem minimera fönstret och fortsätta använda %product_name% medan filerna laddas ner.',
    download_files: 'Ladde ned %file_count% (%file_count_size%)',
    close_window: 'Stäng fönstret',
    // close to quota - text ribbon
    close_to_quota__format: '%used_storage% GB används av %max_storage% GB',
    // A L B U M S
    album_name: 'Albumnamn',
    album: 'album',
    unnamed_album_placeholder: 'Namnlöst album',
    album_settings: 'Albuminställningar',
    add_to_albums: 'Lägg till i Mina album',
    subscribe_to_album: 'Gå med i album',
    item__format: '%d objekt',
    items__format: '%d objekt',
    participant__format: '%d deltagare',
    participants__format: '%d deltagare',
    participants: 'Deltagare',
    owner: 'Ägare',
    you_are_participating: 'Du deltar',
    by: 'av',
    create_album: 'Skapa album',
    auto_created_album_title: 'Namnlöst album',
    new_album: 'Nytt album',
    filter_by: 'Filtrera efter',
    sort_by: 'Sortera efter',
    filter_albums: 'Filtrera album',
    filtered_by_private: 'Filtrerat efter privata',
    filtered_by_shared: 'Filtrerat efter delade',
    sort_recent_activity: 'Senaste aktivitet',
    sort_newest_created: 'Senaste skapade',
    sort_oldest_created: 'Äldsta skapade',
    sort_name: 'Albumnamn',
    all_albums: 'Alla album',
    shared_albums: 'Delade album',
    private_albums: 'Privata album',
    download_album: 'Ladda ned album',
    add_photos: 'Lägg till bilder',
    add_to_album: 'Lägg till i album',
    you_and_others_love_message_format: 'Du och %d andra älskar detta',
    you_and_one_love_message: 'Du och en annan älskar detta',
    you_love_message: 'Du älskar detta',
    one_person_love_message: 'En älskar detta',
    people_love_message_format: '%d älskar detta',
    leave_album: 'Lämna album',
    delete_album: 'Radera album',
    delete_file: 'Radera fil',
    download_file: 'Ladda ned fil',
    download_files_failed_message:
        'Ett fel uppstod vid försöket att ladda ned. Vänligen försök igen senare.',
    set_as_cover_photo: 'Använd som omslagsfoto',
    add_file_to_timeline: `Lägg till i "Bilder"`,
    add_album_to_timeline: `Lägg till album i "Bilder"`,
    change_album_viewMode: 'Ändra visningsläge',
    sort_album_files: 'Sortera album',
    sort_album_files_title: 'Sortera album efter',
    sort_album_files_by_added: 'Nyligen tillagda',
    sort_album_files_by_newest_first: 'Nyast först',
    sort_album_files_by_oldest_first: 'Äldst först',
    album_file_count_exceeds_download_limit:
        'Du kan inte ladda ner ett album med fler än 500 objekt.',
    album_size_exceeds_download_limit:
        'Du kan inte ladda ner mer än 3,5 GB vid ett tillfälle.',
    // empty album list state
    album_list_is_empty: 'Du har inga album ännu.',
    empty_albumList_secondTitle: 'Varför skapar du inte ett nu?',
    empty_filtered_private_albumList_title: 'Du har inga privata album.',
    empty_filtered_shared_albumList_title: 'Du har inga delade album.',
    empty_filtered_albumList_subtext:
        'Det är enkelt att göra ett album! Tryck på knappen nedan för att starta.',
    empty_album_list_read_only_header:
        'Prenumeration krävs för att skapa album.',
    // empty album state
    empty_album_photos: 'Det finns inga foton i detta album än',
    // add to album
    album_select: 'Välj ett album',
    // create album
    create_new: 'Skapa nytt',
    private_album: 'Privat album',
    shared_album: 'Delat album',
    share_created_album_prompt_header: 'Du har skapat ett album!',
    share_created_album_prompt_text:
        'Dela det med vänner och familj, så att de kan lägga till bilder och kommentarer i ditt album.',
    share_later: 'Dela senare',
    share_now: 'Dela nu',
    name_your_album: 'Namnge ditt album',
    add_photos_to_album: 'Lägg till foton i album',
    create_empty_album: 'Skapa album och lägg till foton senare',
    save_create_album: 'Spara och skapa album',
    set_name_for_shared_album:
        'Användarnamn krävs för delade album så att dina vänner vet vem du är.',
    album_name_is_required_for_shared_albums:
        'Albumnamn krävs för delade album',
    // Uploading to album
    also_upload_to_timeline: 'Ladda upp till din foton?',
    uploading_to_album_name__format: 'Du laddar upp till "%album_name%"',
    uploading_to_album_also_to_timeline__format:
        'Ska dessa filer även läggas till i dina %product_name%-foton?',
    uploading_files_to_new_album: 'Du laddar upp filer till ett nytt album.',
    uploading_files_to_multiple_albums__format:
        'Du laddar upp filer till %album_count% nya album.',
    // Uploading folder to albums
    create_albums_prompt: 'Skapa album från mappar?',
    create_album_prompt: 'Skapa ett album?',
    also_create_albums__format:
        'Du laddar upp en mapp med namnet “%album_name%”. Ska vi skapa ett album med detta innehåll också?',
    also_create_albums_multiple__format:
        'Du laddar upp %d mappar. Ska vi skapa album för dessa?',
    // misc album
    fetching_albums: 'Hämtar album',
    // Comments
    comment: 'kommentar',
    comments: 'Kommentarer',
    write_your_comment: 'Skriv en kommentar',
    comment_error_message: 'Fel uppstod vid kommentering. Var god försök igen.',
    more: 'Mer',
    edited: 'Redigerad',
    edit_comment_error: 'Kunde inte redigera kommentar',
    delete_comment_error: 'Kunde inte radera kommentar',
    view_all_comments: 'Visa alla kommentarer',
    what_is_your_name: 'Vad heter du?',
    how_would_you_continue: 'Hur skulle du föredra att fortsätta?',
    hey: 'Hallå',
    tap_here_to_enter_name: 'Tryck här för att ange namn',
    click_here_to_enter_name: 'Klicka här för att ange namn',
    enter: 'skicka',
    register_or_sign_in: 'Registrera dig / Logga in',
    continue_as_guest: 'Fortsätt som gäst',
    provide_name_description__format:
        'Låt andra i albumet få veta vem du är genom att antingen fortsätta med ett %product_name%-konto eller som en gäst.',
    year_ago__format: '%d år sedan',
    years_ago__format: '%d år sedan',
    month_ago__format: '%d månad sedan',
    months_ago__format: '%d månader sedan',
    day_ago__format: '%d dag sedan',
    days_ago__format: '%d dagar sedan',
    hour_ago__format: '%d timme sedan',
    hours_ago__format: '%d timmar sedan',
    minute_ago__format: '%d minut sedan',
    minutes_ago__format: '%d minuter sedan',
    just_now: 'just nu',
    post: 'Posta',

    // C A R O U S E L   V I E W
    slideshow: 'Bildspel',
    play: 'Spela',
    pause: 'Pausa',
    previous: 'Föregående',
    next: 'Nästa',
    exit_fullscreen: 'Avsluta',
    info: 'Information',

    // F I L E  I N F O
    file_info: 'Filinformation',

    // S E T T I N G S
    // profile
    profile_info: 'Profilinfo',
    enter_your_name: 'Ange ditt namn',
    set_your_name: 'Ange ditt namn',
    update_profile_picture: 'Uppdatera profilfoto',
    // storage
    other_sources: 'annat ursprung',
    current_plan: 'aktuella prenumeration',
    storage_management: 'Lagringshantering',
    manage_storage: 'Hantera lagringsutrymme',
    total_storage: 'Totalt utrymme',
    remaining_storage: 'Återstående',
    used_storage: 'Använt',
    buy_more_storage: 'Köp mer lagringsutrymme',
    buy_more_storage_install_app:
        'På din plats är det endast möjligt att köpa lagring i Capture-appen. Ladda ner appen, gå till inställningar och tryck på "Köp mer lagringsutrymme".',
    buy_more_storage_open_app:
        'På din plats är det endast möjligt att köpa lagring i Capture-appen.',
    connected_devices: 'Inloggade enheter',
    unlimited: 'Obegränsat',
    your_storage_plan: 'Din prenumeration',
    next_billing_date__format: 'Nästa fakturadatum: %date%',
    expires_date__format:
        'Prenumerationen är avbruten. Lagringen löper ut %date%',
    plan_is_canceled: 'Du har avbrutit denna prenumeration',
    has_credit:
        'Du har %credit_amount% kredit i %product_name%. Kredit kommer automatiskt att användas för framtida fakturering.',
    manage_plan: 'Hantera',
    storage_used_out_of__format: '%storage_used% använt av %storage_total%',
    upgrade_storage_plan: 'Uppgradera prenumeration',
    cancel_storage_plan: 'Avbryt prenumeration',
    renew_status__format: 'Prenumerationen kommer att förnyas den %s',
    expired_status__format: 'Prenumerationen löper ut den %s',
    reactivate_storage_plan: 'Återaktivera prenumeration',
    choose_subscription: 'Välj en prenumeration',
    subscribe_to_product: 'Prenumerera på %product_name%',
    // storage sources
    storage_source_capture: 'Beviljat av %product_name%',
    storage_source_b2b_customer__format: 'Endast för %BU_name%-kunder',
    storage_source_web: 'Inköpt på webben',
    storage_source_apple_store: 'Inköp i app i iTunes',
    storage_source_googlePlay_store: 'Inköp i app i Google Play',
    storage_source_connect_store: 'Inköpt i gammal webb-butik',
    storage_source_other: 'Annat ursprung',
    storage_source_customer_service: 'Beviljat av Kundtjänst',
    storage_source_b2b__format: 'Beviljat av %BU_name%',
    free__as_in_no_cost: 'Gratis',
    details_unlimited_storage: 'Du har obegränsad lagring!',
    details_text_unlimited_storage_1__format:
        'Som %BU_name%-kund är du berättigad till %product_name%s obegränsade lagring utan extra kostnad, så att du aldrig behöver oroa dig för att få slut på utrymme!',
    details_text_unlimited_storage_2__format:
        'Fortfarande nyfiken på lagringspriser för icke-%BU_name%-kunder? Se nedan:',
    // payment settings
    payment_settings: 'Betalningsinställningar',
    payment_settings_info_text: 'Din registrerade kortinformation:',
    change_card_info: 'Ändra kortinfo',
    delete_card: 'Radera kort',
    update_card_info: 'Uppdatera kortinfo',
    card_number: 'Kortnummer',
    card_exp_date: 'Utgångsdatum',
    card_cvc: 'CVC/CVV',
    confirm_payment: 'Bekräfta betalning',
    // Buy more storage
    save__format: 'Spara %d',
    get_started: 'Kom igång!',
    telenor_business_discount_hook_header: 'Telenor-företagskund?',
    telenor_business_discount_hook:
        'Om du är Telenor-företagskund är du berättigad till rabatterade priser.',
    telenor_business_discount_hook_link: 'Gå till %telenor_mine_sider%.',
    telenor_mine_sider: 'Telenor Mina sidor',
    mine_sider: 'Mina sidor',
    telenor_unlimited_hook__format:
        'Som Telenor mobilkund har du tillgång till obegränsad lagring för %product_name%',
    telenor_unlimited_hook_subtext:
        'Berättigade prenumerationer: Fleksi/Medium/Trygg/Yng/U18/Frihet/Bedrift Fri+.',
    monthly_sub: 'Betala månadsvis',
    yearly_sub: 'Betala årsvis',
    email_request_explanation:
        'E-postadressen kommer att användas för uppdateringar gällande din prenumeration',
    fetch_storage_plan_failed:
        'Vi kunde inte på ett tillförlitligt sätt hämta din prenumeration',
    with_free_trial_detail:
        '%num_free_trial% månaders gratis provperiod, därefter %price_string% per %period%',
    downgrade_refund_detail:
        'Du får återbetalning i kredit i %product_name% som kommer att användas automatiskt vid framtida fakturering',
    without_free_trial_detail:
        'Du kommer att betala %price_string% per %period%',
    choose_subscription_plan_first: 'Välj en prenumeration först',
    choose_subscription_plan_to_see_details:
        'Välj ett av alternativen för att se detaljerna',
    choose_subscription_plan_to_start_free_trial:
        'Välj en av betalningsplanerna ovan för att starta din kostnadsfria provperiod',
    change_subscription_plan: 'Ändra din prenumeration',
    subscribe_with_free_trial: 'Få %num_free_trial% månader gratis',
    proceed_to_pay: 'Gå vidare till betalning',
    select_plan_to_see_details:
        'Välj en prenumeration nedan för att se detaljer.',
    subscribe_to_receive: 'Välj en prenumeration för att komma igång och få',
    months_free_trial: '%num_free_trial% månader gratis provperiod',
    learn_more: 'Läs mer',
    subscription_notes1:
        'Prenumerationer mindre än nuvarande lagringsanvänding är inte tillgängliga',
    // Update popup
    update_pop_header:
        'Vi har genomfört en säkerhetsuppdatering på %product_name% appen.',
    update_pop_body:
        'Se till att du har uppdaterat till den senaste app versionen (%version_number%) i App Store eller Play Store på din telefon',
    // select language
    language: 'Språk',
    select_language: 'Välj språk',
    // privacy
    privacy_info:
        'Granska din data och ta kontroll över dina inställningar på en och samma plats.',
    privacy: 'Sekretessinställningar',
    privacy_statement: 'Sekretesspolicy',
    open_privacy_pages: 'Öppna sekretess-sidor',
    consents: 'Samtycke',
    facial_recognition: 'Ansiktsigenkänning',
    privacy_desc__can_process_images_categorisation__format:
        '%company_name% kan bearbeta mina bilder för att kategorisera deras innehåll. Detta inkluderar gruppering av liknande ansikten.',
    face_grouping: 'Ansiktsgruppering',
    privacy_desc__can_process_face_details__format:
        '%company_name% kan behandla ansiktsdetaljer i bilder för att ge innehållskategorisering. Detta inkluderar att gruppera liknande ansikten tillsammans.',
    advanced_filtering_and_categorization:
        'Avancerad filtrering och kategorisering',
    privacy_desc__can_process_images_advanced_filtering__format:
        '%company_name% kan bearbeta innehållet i bilder för att tillhandahålla funktioner som avancerad filtrering och presentation av bilder som minnen.',
    help_us_improve: 'Hjälp oss att bli bättre',
    privacy_desc__can_process_data_for_improvement__format:
        '%company_name% kan behandla data om Hur jag använder deras tjänster för att förbättra deras produkter t.ex. Hur ofta jag använder appen.',
    stay_up_to_date: 'Hålla dig uppdaterad',
    privacy_desc__let_you_know_about_updates:
        'Vi vill informera dig om uppdateringar, nya funktioner och fantastiska nya produkter och tjänster vi erbjuder. Vi skickar också användbara tips för att förbättra din upplevelse och andra relevanta förslag.',
    can_contact_me_via__format: '%company_name% kan kontakta mig via:',
    push_notification: 'Push Notiser',
    access_your_data: 'Få tillgång till din data',
    privacy_desc__processes_with_privacy_policy__format:
        '%company_name% behandlar vissa kategorier av användardata i linje med syftena som beskrivs i vår %link_start%Sekretesspolicy%link_end%.',
    privacy_desc__download_personal_information__format:
        'Du kan ladda ner en kopia av de personuppgifter vi har om dig här.',
    privacy_request_a_copy_of_data: 'Begära en kopia av dina personuppgifter',
    privacy_download_all:
        'Du kan ladda ner alla dina foton, videor och album från Capture till din Mac/PC. Detta är användbart när du vill ha alla dina foton på din dator, överföra dem till en extern hårddisk eller säkerhetskopiera dem i en annan molntjänst.',
    privacy_download_all_button: 'Ladda ner allt från Capture',
    per_gdpr_right_to_request:
        'Enligt GDPR har du rätt att begära en kopia av all information vi lagrar om dig.',
    if_you_continue_metadata_download__format:
        'Om du fortsätter paketerar vi all din metadata och tillhandahåller den för nedladdning. Du kan bara göra en begäran var %d timme.',
    metadata_recently_downloaded_please_wait__format:
        'Du har nyligen laddat ner din metadata. Du kan bara göra en begäran var %d timme.',
    you_can_request_delete_account__format:
        'Du kan begära att %company_name% tar bort ditt konto, inklusive allt innehåll och all data vi har om dig.',
    delete_account_includes_irreversible_deletion:
        'Detta kommer att inkludera oåterkallelig radering av allt foto- och videoinnehåll på ditt Capture-konto, så se till att du tar en säkerhetskopia innan du gör denna begäran.',
    it_may_take_up_to_days__format:
        'Det kan ta upp till %d dagar för oss att slutföra denna begäran.',
    request_deletion: 'Begär radering',
    delete_account: 'Radera Konto',
    delete_your_account: 'Radera ditt Capture-konto',
    delete_your_account__info1:
        'Du kan begära att Telenor Software Lab AS tar bort ditt konto, inklusive allt innehåll och all data vi har om dig.',
    delete_your_account__info2:
        'Detta kommer att inkludera oåterkallelig radering av allt foto- och videoinnehåll på ditt Capture-konto, så se till att du tar en säkerhetskopia innan du gör denna begäran.',
    delete_your_account__info3:
        'Det kan ta upp till 30 dagar för oss att slutföra denna begäran.',
    delete_your_account__info4:
        'Om du raderar ditt Capture-konto raderas alla dina foton och videor permanent och all annan information på ditt Capture-konto.',
    delete_your_account__info5:
        'Detta är oåterkalleligt så se till att du har ytterligare en säkerhetskopia av dina foton och videor innan du fortsätter.',
    delete_your_account__info6:
        'När du klickar på "Radera konto" kommer du att loggas ut från alla enheter.',
    delete_your_account__success1: 'Din begäran om radering har mottagits.',
    delete_your_account__success2__format:
        'Du har loggats ut från alla enheter och vi kommer att behandla din begäran om radering inom %d dagar. Du kommer att få ett e-postmeddelande när din förfrågan är klar.',
    delete_your_account__failed1:
        'Något gick fel när vi behandlade din begäran om radering.',
    generic_explanation_for_why_it_may_failed:
        'Din anslutning till Internet kan ha blivit avbruten, eller så kan vi ha ett tillfälligt problem på våra servrar.',
    // TAKEOUT PAGE
    takeout_file: 'fil',
    takeout_files: 'filer',
    capture_settings: 'Capture inställningar',
    takeout_mobile_text:
        'Denna sida är inte tillgänglig på mobila enheter, vänligen använd en Mac/PC.',
    takeout_download_all_title: 'Ladda ner alla foton, videor och album',
    takeout_download_all_body: `Ladda ner alla dina foton, videor och album från
                        Capture till din Mac/PC. Detta är användbart när du vill
                        ha alla dina filer på din dator, överför dem till
                        en extern hårddisk, eller säkerhetskopiera dem på en annan
                        molntjänst. För frågor om nedladdning, besök
                        sidan %support_url%. För information om våra
                        prenumerationer, gå till %capture_settings%.`,
    takeout_subheader_photos: 'Capture Bilder',
    takeout_body_photos: `Ladda ner alla foton och videor som säkerhetskopieras
                        på fliken Bilder. Detta kommer att inkludera eventuella
                        dokument du kan ha, men inkluderar inte album. Allt
                        foton och videor kommer att laddas ner i original
                        formatera. För mer information om format, besök vår %support_url%-sida.`,
    takeout_subheader_albums: 'Capture Album',
    takeout_body_albums: `Nedan är en lista över alla privata och delade album i
                        fliken Album. Klicka på de du vill ha
                        att ladda ned.`,
    takeout_stats:
        'Du har %num_files filer (%total_size) i dina Capture Bilder.',
    takeout_download_button: 'Ladda ner allt från Capture',
    // SUBSCRIBE MODAL
    subscribe_title: 'Prenumerera på %product_name%',
    subscribe_welcome_text: 'Prenumerera på %product_name% idag och få ',
    subscribe_welcome_text2: 'Du kan läsa mer om %product_name% %faq_pages%.',
    subscribe_free: 'Gratis',
    subscribe_sub: 'Abo',
    subscribe_text1: 'Säkerhetskopiera foton och videor',
    subscribe_text2: 'Organisera foton i album',
    subscribe_text3: 'Dela foton och album',
    subscribe_text4: 'Redigera foton och videor',
    subscribe_text5: 'Gå med i delade album',
    subscribe_accept_tos: 'Jag accepterar Capture %terms_of_service%',
    subscribe_confirm: 'Välj en prenumeration',
    subscribe_skip: 'Fortsätt utan prenumeration',
    subscribe_reconsider: 'Se prenumerationsplaner',
    subscribe_error: 'Du måste acceptera användarvillkoren',
    no_subscription_title: 'Ingen prenumeration',
    no_subscription_limit:
        'Genom att använda Capture utan prenumeration kan du bara delta i album som andra har delat med dig.',
    no_subscription_reconsider:
        'Du kan köpa en prenumeration senare eller hantera ditt konto från Inställningar.',
    enter_payment_information: 'Ange betalningsinformation',
    business_users:
        'Telenor Företagskunder kan få rabatterade priser. Se %telenor_mine_sider%.',
    // UNSUBSCRIBE PAGE
    unsubscribe_from_email_form_title:
        'Avsluta prenumerationen på e-postmeddelanden från Capture',
    unsubscribe_from_email_form_text1:
        'Vi skickar ett e-postmeddelande i månaden med användbara förslag, tips och nya funktioner.',
    unsubscribe_from_email_form_text2:
        'Är du säker på att du vill avregistrera dig från vår e-postlista?',
    unsubscribe_from_email_form_button_stay: 'Stanna på listan!',
    unsubscribe_from_email_form_button_unsubscribe: 'Avregistrera mig',
    unsubscribe_from_email_success_title: 'Prenumeration avslutad!',
    unsubscribe_from_email_success_text:
        'Du kan ändra dina valg när som helst i "Sekretessinställningar" i Capture.',
    unsubscribe_from_email_stay_title:
        'Dina inställningar för e-post har sparats!',
    unsubscribe_from_email_stay_text:
        'Du kan ändra dina valg när som helst i "Sekretessinställningar" i Capture.',
    // I N F O
    // Download app page
    thanks_for_signing_in: 'Tack för att du loggade in!',
    get_capture_to_create_album:
        'Skaffa Capture på mobilen för att skapa dina egna album.',
    return_to_album: 'Gå tillbaka till albumet',
    // Footer
    telenor_capture_text:
        'Capture har utvecklats av Telenors företag Telenor Digital, som ger dig innovativa digitala lösningar. För att logga in på tjänster från Telenor Digital använder du Connect-kontot.',
    terms_of_service: 'Tjänstevillkor',
    customer_service: 'Kundservice',
    capture_footer_text_mobile: 'Visa i Capture-appen för bästa upplevelse',
    open: 'öppna',
    legal: 'Juridisk',
    // Album Not Found
    album_not_found: 'Album hittades inte',
    album_not_found_description:
        'Vi kunde tyvärr inte hitta albumet! Det kan bero på att länken är utgången eller felstavad. Det är också möjligt att albumet har raderats.',
    capture_create_user_description:
        'Capture är en säker och användarvänlig lagringslösning för bilder och andra filer som lagras online. Om du inte är en Capture-användare är du välkommen att skapa ett användarkonto.',
    capture_more_link: 'Läs mer om Capture',
    // Share Not Found
    share_not_found: 'Delning hittades inte',
    share_not_found_description:
        'Du kan ha klickat på en utgången, raderad eller felstavad länk.',
    // Page Not Found
    page_not_found: 'Vi verkar inte kunna hitta sidan du letar efter!',
    oops_page_not_found: 'Sidan kunde inte hittas.',
    helpful_links_description: 'Här är några användbara länkar istället',
    helpful_links_about_capture: 'Om Capture',
    // Provide Password
    password_required: 'Lösenord krävs',
    password_input_placeholder: 'Klicka här för att ange lösenord',
    password_required_submit: 'skicka',
    password_incorrect: 'Felaktigt lösenord',
    album_password_required_description:
        'Det här albumet är skyddat med ett lösenord. Var god ange lösenordet i rutan ovan för att låsa upp albumet.',
    // Require login to perform action
    needs_login_subscribe_to_album:
        'Du behöver ett %product_name%-konto för att få uppdateringar från detta album',
    needs_connect_to_create_album:
        'För att skapa album krävs ett %product_name%-konto med betald prenumeration',
    needs_login_to_copy_files_to_timeline:
        'Du behöver ett %product_name%-konto med en prenumeration för att spara dessa filer',
    needs_login_to_copy_album_to_timeline:
        'För att spara filer från detta album krävs ett %product_name%-konto med betald prenumeration',
    needs_login_to_view_share:
        'Du måste vara inloggad på %product_name% för att se detta',
    // Edit/Create album page
    no_title_yet: 'Ingen titel ännu',
    add_album_title: 'Ange albumets titel',
    // Suggest Modern Browser
    upgrade_your_browser: 'Vänligen uppdatera din webbläsare',
    upgrade_your_browser_des:
        'Tyvärr! Detta innehåll kan inte ses i din aktuella webbläsare. Uppdatera eller använd en annan webbläsare som Chrome, Firefox eller Microsoft Edge.',
    // Album settings
    share_album: 'Dela album',
    edit_album_settings: 'Redigera albuminställningar',
    enabled: 'Aktiverat',
    disabled: 'Inaktiverat',
    privacy_private: 'Privat',
    privacy_shared: 'Delat',
    edit_album_title_text: 'Albumets titel (Tryck för att ändra):',
    edit_allow_comments_text: 'Alla kan se och lägga till kommentarer',
    edit_allow_upload_text: 'Alla kan lägga till foton',
    edit_privacy_mode_text: 'Sekretessnivå',
    // Toasts
    toast__coverPhoto_was_set: 'Omslagsfotot ändrat',
    set_cover_photo_failed_message: 'Kunde inte byta omslagsfoto.',
    toast__delete_album_failed_message: 'Kunde inte radera albumet.',
    toast__unsubscribe_album_failed_message: 'Kunde inte lämna albumet.',
    toast__multiple_files_deleted__format: '%d filer raderade',
    toast__single_file_deleted: 'Filen raderad',
    delete_file_failed_message: 'Kunde inte radera fil',
    toast__delete_multiple_files_failed__format: 'Kunde inte radera %d filer',
    toast__multiple_files_perm_deleted__format: '%d filer permanent raderade',
    toast__single_file_perm_deleted: 'Filen permanent raderad',
    toast__multiple_files_perm_deleted_failed__format:
        'Fel - %d filer raderades inte permanent',
    toast__single_file_perm_deleted_failed:
        'Fel - filen raderades inte permanent',
    toast__single_file_restored: 'Filen återställd',
    toast__multiple_files_restored__format: '%d filer återställda',
    toast__single_file_restored_failed: 'Fel - filen återställdes inte',
    toast__multiple_files_restored_failed__format:
        'Fel - %d filer återställdes inte',
    toast__single_file_added_to_album__format:
        'Filen tillagd till album %album_name%',
    toast__multiple_files_added_to_album__format:
        '%d filer tillagda till album %album_name%',
    toast__single_file_added_to_album_failed__format:
        'Fel - filen inte tillagd',
    toast__multiple_file_added_to_album_failed__format:
        'Fel - %d filer inte tillagda',
    toast__album_auto_crated__format: 'Album %album_name% skapades till dig',
    toast__see_album: 'Se album',
    toast__album_added_to_timeline: 'Album tillagt till Bilder',
    toast__album_added_to_timeline_failed:
        'Det gick inte att lägga till albumet - försök igen',
    toast__album_changed_to_private: 'Albumet har ändrats till Privat',
    toast__file_added_to_timeline: 'Fil tillagd till Bilder',
    toast__multiple_files_added_to_timeline: 'Filer tillagda till Bilder',
    toast__file_added_to_timeline_failed:
        'Det gick inte att lägga till filen - försök igen',
    toast__files_were_shared: 'Dina filer delades!',
    toast__files_are_deleting: 'Raderar filer...',
    toast__files_are_restoring: 'Återställer filer...',
    toast__files_are_copying: 'Kopierar filer...',
    toast__preparing_to_download: 'Förbereder nedladdning...',
    toast__credit_card_updated: 'Information om betalkort har uppdaterats',
    toast__credit_card_update_failed: 'Kunde inte uppdatera info',
    toast__purchase_successful: 'Prenumeration köpt',
    toast__purchase_failed: 'Misslyckades med att köpa prenumeration',
    toast__plan_canceled: 'Prenumeration avslutat',
    toast__plan_cancel_failed: 'Misslyckades med att avsluta prenumeration',
    toast__plan_change_succeeded: 'Prenumeration har uppdaterats',
    toast__plan_change_failed: 'Misslyckades med att uppdatera prenumeration',
    toast__plan_reactivated: 'Prenumeration återaktiverad',
    toast__plan_reactivation_failed:
        'Misslyckades med att återaktivera prenumeration',
    toast__delete_user_card_success: 'Betalkort raderat',
    toast__delete_user_card_failed: 'Det gick inte att ta bort kortinformation',
    toast__change_profile_picture_failed: 'Kunde inte uppdatera profilbild',
    toast__selection_count_limit_reached: 'Du har valt för många foton',
    toast__file_copied_to_clipboard: 'Kopiera till urklipp!',
    toast__file_copy_to_clipboard_failed: 'Det gick inte att kopiera!',
    toast__change_user_name_failed: 'Det gick inte att uppdatera profilnamnet',

    // Trash
    photos_and_videos: 'Bilder och videor',
    trash_delete_info:
        'Raderade filer kommer raderades permanent efter 30 dagar.',
    trash_no_files: 'Du har inga raderade filer.',
    trash_restore_info: 'Du kan återställa dem närsomhelst innan det!',
    deleted_today: 'Raderingar idag',
    permanently_deleted_soon: 'Permanent raderade snart',
    recently_deleted: 'Nyligen raderade',
    // Documents
    documents_no_files: 'Du har inga dokument.',
    // Share files popup
    share_as_album: 'Dela som album',
    share_album_explanation:
        'Skapa ett album med alla foton du vill dela. Alla du delar det med kan gilla, kommentera och lägga till sina bilder också.',
    share_get_link_explanation:
        'Dela foton med en tillfällig länk. Länken upphör att gälla efter 30 dagar.',
    share_select_how_to: 'Välj hur du vill dela',
    share_album_created_link:
        'Album skapat! Dela album med ett av alternativen nedan:',
    share_disclaimer:
        'När du delar ett album kommer alla som har länken att kunna se det, även om de inte är personligt inbjudna av dig.',
    my_new_shared_album: 'Mitt nya delade album',
    share_to: 'Dela via',
    email: 'E-post',
    SMS: 'SMS',
    get_link: 'Hämta länk',
    hold_to_copy_link: 'Tryck och håll ner för att kopiera länken',
    copy_link: 'Kopiera länk',
    link_copied: 'Länken kopierades!',
    share_files_success_header: 'Du har delat ett album!',
    can_not_share_video_to_fb_album:
        'Oj då! Videor kan inte delas till Facebook-album',
    share_stop_sharing: 'Vill du sluta dela dessa filer?',
    share_login_to_stop_sharing:
        'Om du har delat dessa filer och vill sluta dela dem måste du först %link_start%login%link_end%.',
    // share album form
    allow_others_comment: 'Tillåt andra kommentera foton',
    allow_others_upload: 'Tillåt andra att ladda upp foton',
    // email sharing form
    send_email_to: 'Skicka email till:',
    enter_receivers_address: 'Ange mottagarens adress här',
    email_subject: 'Ämne:',
    enter_subject: 'Ange ämne här',
    email_message: 'Meddelande (frivilligt):',
    enter_message: 'Ange ditt meddelande här',
    invalid_input: 'Ogiltigt',
    // S H A R E   R E C E I V E R
    share__header_SingleFile__format:
        '%user_name% har delat en fil med dig genom %product_name%',
    share__header_MultipleFiles__format:
        '%user_name% har delat lite filer med dig genom %product_name%',
    share__header_SingleFile_owner__format:
        'Du har delat en fil genom %product_name%',
    share__header_MultipleFiles_owner__format:
        'Du har delat några filer genom %product_name%',
    share__add_singleFile_to_capture__format: 'Lägg till foto i %s',
    share__add_multipleFiles_to_capture__format: 'Lägg till foton i %s',
    download_all: 'Ladda ner allt',
    share_added_to_timeline: 'Filer lades till',
    share_added_to_timeline_failed:
        'Det gick inte att lägga till filer - försök igen',
    share__stop: 'Stoppa delning',
    share__confirm_stop: 'Är du säker på att du vill stoppa delningen?',
    share__deletion_failed: 'Misslyckades att stoppa delning av filer',
    share__deleted_SingleFile: 'Filen delas inte längre',
    share__deleted_MultipleFiles: 'Filerna delas inte längre',
    share_failed: 'Kunde inte dela filer - vänligen försök igen',
    share__not_capture_user: 'Inte en Capture-användare?',
    share__try_capture: 'Klicka här för att ge det ett försök!',
    share__service_info_part1__format:
        '%product_name% är till för att hålla dina foton och videor säkra! Det är det enklaste sättet att automatiskt säkerhetskopiera foton och videor från din telefon, så att du kan komma åt dem närsomhelst och varsomhelst, och dela dem med dina vänner och familj.',
    share__service_info_part2__format:
        'Teckna en prenumeration hos %product_name% och få 2 månaders gratis användning. Om du är mobilkund hos Telenor Norge ingår sannolikt obegränsat lagringsutrymme i %product_name% i din prenumeration.',
    share__service_info_part2__minSky__format:
        'Teckna en prenumeration hos %product_name% och få 2 månaders gratis användning. Om du har ett privat mobilabonnemang eller ett Frihet/Flyt/Fri+ företagsabonnemang hos Telenor Norge ingår obegränsat lagringsutrymme i %product_name% i ditt abonnemang. Skicka ett SMS till 1999 om ditt gratis lagringsutrymme ännu inte har aktiverats.',
    share__service_info_footer__format:
        'Ingår i alla privata mobilabonnemang och företagsabonnemang Frihet, Flyt och Fri+.',
    // Downloading share content on iOS
    share__download_to_ios_image_gallery: 'Ladda ner till bildgalleri',
    share__download_to_ios_image_gallery_desc1:
        'För att ladda ner i full upplösning till ditt bildgalleri, tryck och håll bilden nedan och välj "Spara bild" i menyn som visas.',
    share__download_to_ios_image_gallery_desc2:
        'Repetera för var och en av de delade bilderna.',
    share__download_to_ios_files: 'Ladda ner till filer',
    share__download_to_ios_files_desc:
        'Knappen nedan laddar ner en zip-fil innehållandes alla delade bilder. I iOS blir denna fil tillgänglig i appen Filer efter nedladdning.',
    // Password required
    share_password_required_description:
        'Den här Delningen är skyddad med ett lösenord. Var god ange lösenordet i rutan ovan för att visa Delningen.',
    // Select from external page
    choose_photo_selection_source: 'Välj foton från ',
    all_photos: 'Alla foton',
    // user hints
    hint_longpress_show_full_image:
        'Tryck och håll bild för att visa hela bilden',
    editor_hint_body: 'Lägg till filter, beskär bilder och mer.',
    // Tooltip
    readonly_tooltip: 'Prenumeration krävs för full tillgång.',
    // Sunset
    deletion_prompt__title: 'Ändring av Capture-villkor',
    deletion_prompt__body:
        'Från %delete_date% kommer Capture endast erbjudas som betaltjänst. Vill du fortsätta använda tjänsten och behålla dina uppladdade bilder, se våra betalprenumerationer på lagring. Vi bjuder på de 6 första månaderna.',
    deletion_prompt__more_info: 'Mer information',
    deletion_prompt__plans: 'Prenumerationer',
    // casting
    cast: 'Casta',
    casting_to: 'Casta till %s',
    // consent
    cookie_explanation__format:
        '%product_name% använder cookies för att analysera webbplatsanvändning och förbättra användarupplevelsen. En del av den information som samlas in används av tredje part.',
    cookie_explanaion_link__format:
        'Läs vår %link_start%sekretesspolicy%link_end% för att lära dig mer om hur vi använder cookies.',
    account_terminated_error: 'Kontot har avslutats',
    account_terminated_desc__format:
        'Kontot har avslutats av dess användare och är därför inte längre tillgängligt. Kontakta gärna vår kundtjänst för frågor eller hjälp.',
    // PhotoEditorSDK
    common_error: 'Fel',
    common_warning: 'Varning',
    common_color_colorPicker_hex: 'Hex',
    common_color_colorPicker_r: 'R',
    common_color_colorPicker_g: 'G',
    common_color_colorPicker_b: 'B',
    common_color_colorPicker_sliderHue: 'Färg',
    common_color_colorPicker_sliderOpacity: 'Färgopacitet',
    mainCanvasActions_buttonExport: 'Spara',
    mainCanvasActions_buttonUndo: 'Ångra',
    mainCanvasActions_buttonRedo: 'Göra om',
    mainCanvasActions_buttonClose: 'Stäng',
    infoModals_exporting_heading: 'Exporterar...',
    infoModals_exporting_body: 'Bara några sekunder...',
    infoModals_saving_heading: 'Sparar...',
    infoModals_saving_body: 'Bara några sekunder...',
    infoModals_loading_heading: 'Laddar...',
    infoModals_loading_body: 'Bara några sekunder...',
    infoModals_resizing_heading: 'Ändra storlek...',
    infoModals_resizing_body: 'Bara några sekunder...',
    infoModals_loadingFonts_heading: 'Laddar teckensnitt...',
    infoModals_loadingFonts_body: 'Bara några sekunder...',
    infoModals_stickerLoading_heading: 'Laddar klistermärke...',
    infoModals_stickerLoading_body: 'Bara några sekunder...',
    errorModals_imageLoading_headingDelimiter: ':',
    errorModals_imageLoading_body:
        'Det gick inte att ladda bilden. Detta kan ha flera anledningar, t.ex. filen är skadad eller filtypen stöds inte',
    errorModals_imageLoading_buttonYes: 'Försök igen',
    errorModals_imageLoading_buttonNo: 'Stäng',
    errorModals_rendering_headingDelimiter: ':',
    errorModals_rendering_body: 'Ett fel uppstod när bilden renderades',
    errorModals_rendering_buttonYes: 'Försök igen',
    errorModals_rendering_buttonNo: 'Stäng',
    errorModals_fontLoading_headingDelimiter: ':',
    errorModals_fontLoading_heading: 'Det gick inte att läsa in teckensnitt',
    errorModals_fontLoading_body:
        'Följande teckensnitt kunde inte laddas: ${error}',
    errorModals_fontLoading_buttonNo: 'Stäng',
    errorModals_webcamUnavailable_headingDelimiter: ':',
    errorModals_webcamUnavailable_body:
        'Det går inte att visa webbkamerabild (fel: ${error})',
    errorModals_webcamUnavailable_buttonYes: 'Stäng',
    errorModals_stickerLoading_headingDelimiter: ':',
    errorModals_stickerLoading_body: 'Det gick inte att ladda klistermärket',
    errorModals_stickerLoading_buttonNo: 'Stäng',
    errorModals_unexpectedError_headingDelimiter: ':',
    errorModals_unexpectedError_body: 'Ett oväntat fel har inträffat ${error}',
    errorModals_unexpectedError_buttonYes: 'Försök igen',
    errorModals_unexpectedError_buttonNo: 'Stäng',
    warningModals_imageResized_headingDelimiter: ':',
    warningModals_imageResized_heading: 'Bildens storlek har ändrats',
    warningModals_imageResized_body:
        'Din bild överskrider den maximala storleken på ${megapixels} megapixlar och har ändrats till ${width}x${height} pixlar',
    warningModals_imageResized_buttonYes: 'Fortsätt',
    warningModals_unsupportedSerializationVersion_headingDelimiter: ':',
    warningModals_unsupportedSerializationVersion_body:
        'Din data återställdes från ett äldre dataformat och kan se annorlunda ut',
    warningModals_unsupportedSerializationVersion_buttonYes:
        'Applicera förändringar',
    warningModals_unsupportedSerializationVersion_buttonNo: 'Avbryt',
    warningModals_discardChanges_headingDelimiter: ':',
    warningModals_discardChanges_body:
        'Du har osparade ändringar. Är du säker på att du vill ignorera ändringarna?',
    warningModals_discardChanges_buttonYes: 'Ignorera ändringar',
    warningModals_discardChanges_buttonNo: 'Behåll ändringar',
    warningModals_unsavedChanges_headingDelimiter: ':',
    warningModals_unsavedChanges_body:
        'Du har osparade ändringar. Är du säker på att du vill avsluta?',
    warningModals_unsavedChanges_buttonYes: 'Avsluta utan att spara',
    warningModals_unsavedChanges_buttonNo: 'Avbryt',
    warningModals_unsupportedWebGLRenderer_body:
        'Ett problem har upptäckts i webbläsaren som kan orsaka långa laddningstider. Prova en annan webbläsare.',
    warningModals_unsupportedWebGLRenderer_buttonYes: 'Fortsätt',
    library_title: 'Bibliotek',
    library_controls_buttonUpload: 'Ladda upp bild',
    library_controls_buttonWebcamOpen: 'Öppna webbkamera',
    library_controls_buttonWebcamClose: 'Stäng webbkameran',
    library_controls_placeholderSearch: 'Sök i biblioteket',
    library_controls_noResults: 'Inga resultat',
    filter_title: 'Filter',
    filter_controls_buttonReset: 'Ta bort filter',
    filter_controls_sliderIntensity: 'Filterintensitet',
    filter_categories_imgly_filter_category_duotone: 'Duotone',
    filter_categories_imgly_filter_category_bw: 'B & W',
    filter_categories_imgly_filter_category_vintage: 'Vintage',
    filter_categories_imgly_filter_category_smooth: 'Smooth',
    filter_categories_imgly_filter_category_cold: 'Kall',
    filter_categories_imgly_filter_category_warm: 'Varm',
    filter_categories_imgly_filter_category_legacy: 'Legacy',
    filter_items_imgly_lut_celsius: 'Inferno',
    filter_items_imgly_lut_chest: 'Chestnut',
    filter_items_imgly_lut_fixie: 'Fixie',
    filter_items_imgly_lut_fridge: 'Fridge',
    filter_items_imgly_lut_front: 'Sunny 70s',
    filter_items_imgly_lut_k2: 'Flat Black',
    filter_items_imgly_lut_mellow: 'Mellow',
    filter_items_imgly_lut_sin: 'Hard Stuff',
    filter_items_imgly_lut_texas: 'Oldtimer',
    filter_items_imgly_lut_ad1920: '1920 A.D.',
    filter_items_imgly_lut_ancient: 'Ancient',
    filter_items_imgly_lut_bleached: 'Kalmen',
    filter_items_imgly_lut_bleachedblue: 'Joran',
    filter_items_imgly_lut_blues: 'Polaroid',
    filter_items_imgly_lut_blueshadows: 'Zephyr',
    filter_items_imgly_lut_breeze: 'Levante',
    filter_items_imgly_lut_bw: 'Greyed',
    filter_items_imgly_lut_classic: 'Classic',
    filter_items_imgly_lut_colorful: 'Colorful',
    filter_items_imgly_lut_cool: 'Snappy',
    filter_items_imgly_lut_cottoncandy: 'Candy',
    filter_items_imgly_lut_creamy: 'Creamy',
    filter_items_imgly_lut_eighties: 'Low Fire',
    filter_items_imgly_lut_elder: 'Colla',
    filter_items_imgly_lut_evening: 'Sunrise',
    filter_items_imgly_lut_fall: 'Moss',
    filter_items_imgly_lut_food: 'Food',
    filter_items_imgly_lut_glam: 'Glam',
    filter_items_imgly_lut_gobblin: 'Gobblin',
    filter_items_imgly_lut_highcarb: 'High Carb',
    filter_items_imgly_lut_highcontrast: 'Hicon',
    filter_items_imgly_lut_k1: 'K1',
    filter_items_imgly_lut_k6: 'K6',
    filter_items_imgly_lut_kdynamic: 'Pebble',
    filter_items_imgly_lut_keen: 'Keen',
    filter_items_imgly_lut_lenin: 'Lemon',
    filter_items_imgly_lut_litho: 'Litho',
    filter_items_imgly_lut_lomo: 'Lomo',
    filter_items_imgly_lut_lomo100: 'Lomo 100',
    filter_items_imgly_lut_lucid: 'Lucid',
    filter_items_imgly_lut_neat: 'Neat',
    filter_items_imgly_lut_nogreen: 'Pumpkin',
    filter_items_imgly_lut_orchid: 'Solanus',
    filter_items_imgly_lut_pale: 'Pale',
    filter_items_imgly_lut_pitched: 'Pitched',
    filter_items_imgly_lut_plate: 'Weathered',
    filter_items_imgly_lut_pola669: 'Green Gap',
    filter_items_imgly_lut_polasx: 'Pola SX',
    filter_items_imgly_lut_pro400: 'Pro 400',
    filter_items_imgly_lut_quozi: 'Quozi',
    filter_items_imgly_lut_sepiahigh: 'Sepia',
    filter_items_imgly_lut_settled: 'Settled',
    filter_items_imgly_lut_seventies: 'Seventies',
    filter_items_imgly_lut_soft: 'Soft',
    filter_items_imgly_lut_steel: 'Steel',
    filter_items_imgly_lut_summer: 'Summer',
    filter_items_imgly_lut_sunset: 'Golden',
    filter_items_imgly_lut_tender: 'Tender',
    filter_items_imgly_lut_twilight: 'Twilight',
    filter_items_imgly_lut_winter: 'Softy',
    filter_items_imgly_lut_x400: 'Dusty',
    filter_items_imgly_duotone_desert: 'Desert',
    filter_items_imgly_duotone_peach: 'Peach',
    filter_items_imgly_duotone_clash: 'Clash',
    filter_items_imgly_duotone_plum: 'Plum',
    filter_items_imgly_duotone_breezy: 'Breezy',
    filter_items_imgly_duotone_deepblue: 'Deep Blue',
    filter_items_imgly_duotone_frog: 'Frog',
    filter_items_imgly_duotone_sunset: 'Sunset',
    adjustment_title: 'Ändra',
    adjustment_controls_buttonReset: 'Återställ justering',
    adjustment_categories_basics: 'Basic',
    adjustment_categories_refinements: 'Finesser',
    adjustment_items_brightness: 'Ljusstyrka',
    adjustment_items_saturation: 'Mättnad',
    adjustment_items_contrast: 'Kontrast',
    adjustment_items_gamma: 'Gamma',
    adjustment_items_sharpness: 'Skärpa',
    adjustment_items_clarity: 'Klarhet',
    adjustment_items_exposure: 'Exponering',
    adjustment_items_shadows: 'Skuggor',
    adjustment_items_highlights: 'Ljusa områden',
    adjustment_items_whites: 'Vitpunkt',
    adjustment_items_blacks: 'Svartpunkt',
    adjustment_items_temperature: 'Temperatur',
    focus_title: 'Fokus',
    focus_controls_buttonReset: 'Ta bort fokus',
    focus_controls_sliderIntensity: 'Fokus intensitet',
    focus_items_radial: 'Cirkulär',
    focus_items_mirrored: 'Speglad',
    focus_items_linear: 'Linjär',
    focus_items_gaussian: 'Gaussisk',
    focus_history_focusPosition: 'Fokusposition',
    focus_history_focusSize: 'Fokusstorlek',
    overlay_title: 'Överlägg',
    overlay_controls_buttonReset: 'Ta bort överlägg',
    overlay_controls_sliderOpacity: 'Överlägg opacitet',
    overlay_controls_carouselBlendMode: 'Överläggsblandningsläge',
    overlay_controls_blendModeNormal: 'Normal',
    overlay_controls_blendModeOverlay: 'Överlägg',
    overlay_controls_blendModeHardLight: 'Hårt ljus',
    overlay_controls_blendModeSoftLight: 'Mjukt ljus',
    overlay_controls_blendModeMultiply: 'Multiplicera',
    overlay_controls_blendModeDarken: 'Mörkna',
    overlay_controls_blendModeLighten: 'Lätta',
    overlay_controls_blendModeScreen: 'Skärm',
    overlay_controls_blendModeColorBurn: 'Color Burn',
    overlay_controls_tabOpacity: 'Transparens',
    overlay_controls_tabBlendMode: 'Blandningsläge',
    overlay_items_imgly_overlay_bokeh: 'Bokeh',
    overlay_items_imgly_overlay_chop: 'Hacka',
    overlay_items_imgly_overlay_clouds: 'Moln',
    overlay_items_imgly_overlay_golden: 'Golden',
    overlay_items_imgly_overlay_grain: 'Grain',
    overlay_items_imgly_overlay_hearts: 'Hearts',
    overlay_items_imgly_overlay_lightleak1: 'Light Leak 1',
    overlay_items_imgly_overlay_lightleak2: 'Light Leak 2',
    overlay_items_imgly_overlay_metal: 'Metal',
    overlay_items_imgly_overlay_mosaic: 'Mosaic',
    overlay_items_imgly_overlay_painting: 'Painting',
    overlay_items_imgly_overlay_paper: 'Paper',
    overlay_items_imgly_overlay_rain: 'Rain',
    overlay_items_imgly_overlay_vintage: 'Vintage',
    overlay_items_imgly_overlay_wall1: 'Vägg',
    overlay_items_imgly_overlay_wall2: 'Vägg 2',
    overlay_items_imgly_overlay_wood: 'Wood',
    sticker_title: 'Klistermärken',
    sticker_controls_buttonUpload: 'Ladda upp klistermärke',
    sticker_controls_sliderOpacity: 'Klistermärke Opacitet',
    sticker_controls_selectColor: 'Färg på klistermärke',
    sticker_controls_tabColor: 'Färg',
    sticker_controls_tabOpacity: 'Transparens',
    sticker_categories_imgly_sticker_emoticons: 'Emoticons',
    sticker_categories_imgly_sticker_shapes: 'Former',
    sticker_categories_imgly_sticker_custom: 'Anpassat',
    sticker_items_imgly_sticker_emoticons_alien: 'Alien',
    sticker_items_imgly_sticker_emoticons_angel: 'Angel',
    sticker_items_imgly_sticker_emoticons_angry: 'Angry',
    sticker_items_imgly_sticker_emoticons_anxious: 'Anxious',
    sticker_items_imgly_sticker_emoticons_asleep: 'Asleep',
    sticker_items_imgly_sticker_emoticons_attention: 'Attention',
    sticker_items_imgly_sticker_emoticons_baby_chicken: 'Baby Chicken',
    sticker_items_imgly_sticker_emoticons_batman: 'Batman',
    sticker_items_imgly_sticker_emoticons_beer: 'Beer',
    sticker_items_imgly_sticker_emoticons_black: 'Svart',
    sticker_items_imgly_sticker_emoticons_blue: 'Blå',
    sticker_items_imgly_sticker_emoticons_blush: 'Blush',
    sticker_items_imgly_sticker_emoticons_boxer: 'Boxer',
    sticker_items_imgly_sticker_emoticons_business: 'Business',
    sticker_items_imgly_sticker_emoticons_chicken: 'Chicken',
    sticker_items_imgly_sticker_emoticons_cool: 'Cool',
    sticker_items_imgly_sticker_emoticons_cry: 'Cry',
    sticker_items_imgly_sticker_emoticons_deceased: 'Deceased',
    sticker_items_imgly_sticker_emoticons_devil: 'Devil',
    sticker_items_imgly_sticker_emoticons_duckface: 'Duckface',
    sticker_items_imgly_sticker_emoticons_furious: 'Furious',
    sticker_items_imgly_sticker_emoticons_grin: 'Grin',
    sticker_items_imgly_sticker_emoticons_guitar: 'Guitar',
    sticker_items_imgly_sticker_emoticons_harry_potter: 'Harry Potter',
    sticker_items_imgly_sticker_emoticons_hippie: 'Hippie',
    sticker_items_imgly_sticker_emoticons_hitman: 'Hitman',
    sticker_items_imgly_sticker_emoticons_humourous: 'Humourous',
    sticker_items_imgly_sticker_emoticons_idea: 'Idea',
    sticker_items_imgly_sticker_emoticons_impatient: 'Impatient',
    sticker_items_imgly_sticker_emoticons_kiss: 'Kiss',
    sticker_items_imgly_sticker_emoticons_kisses: 'Kisses',
    sticker_items_imgly_sticker_emoticons_laugh: 'Laugh',
    sticker_items_imgly_sticker_emoticons_loud_cry: 'Loud Cry',
    sticker_items_imgly_sticker_emoticons_loving: 'Loving',
    sticker_items_imgly_sticker_emoticons_masked: 'Masked',
    sticker_items_imgly_sticker_emoticons_music: 'Music',
    sticker_items_imgly_sticker_emoticons_nerd: 'Nerd',
    sticker_items_imgly_sticker_emoticons_ninja: 'Ninja',
    sticker_items_imgly_sticker_emoticons_not_speaking_to_you:
        'Not speaking to you',
    sticker_items_imgly_sticker_emoticons_pig: 'Pig',
    sticker_items_imgly_sticker_emoticons_pumpkin: 'Pumpkin',
    sticker_items_imgly_sticker_emoticons_question: 'Question',
    sticker_items_imgly_sticker_emoticons_rabbit: 'Rabbit',
    sticker_items_imgly_sticker_emoticons_sad: 'Sad',
    sticker_items_imgly_sticker_emoticons_sick: 'Sick',
    sticker_items_imgly_sticker_emoticons_skateboard: 'Skateboard',
    sticker_items_imgly_sticker_emoticons_skull: 'Skull',
    sticker_items_imgly_sticker_emoticons_sleepy: 'Sleepy',
    sticker_items_imgly_sticker_emoticons_smile: 'Smile',
    sticker_items_imgly_sticker_emoticons_smoking: 'Smoking',
    sticker_items_imgly_sticker_emoticons_sobbing: 'Sobbing',
    sticker_items_imgly_sticker_emoticons_star: 'Star',
    sticker_items_imgly_sticker_emoticons_steaming_furious: 'Steaming Furious',
    sticker_items_imgly_sticker_emoticons_sunbathing: 'Sunbathing',
    sticker_items_imgly_sticker_emoticons_tired: 'Trött',
    sticker_items_imgly_sticker_emoticons_tongue_out_wink: 'Tongue out wink',
    sticker_items_imgly_sticker_emoticons_wave: 'Wave',
    sticker_items_imgly_sticker_emoticons_wide_grin: 'Wide Grin',
    sticker_items_imgly_sticker_emoticons_wink: 'Wink',
    sticker_items_imgly_sticker_emoticons_wrestler: 'Wrestler',
    sticker_items_imgly_sticker_shapes_arrow_02: 'Arrow 1',
    sticker_items_imgly_sticker_shapes_arrow_03: 'Arrow 2',
    sticker_items_imgly_sticker_shapes_badge_01: 'Badge 1',
    sticker_items_imgly_sticker_shapes_badge_11: 'Badge 5',
    sticker_items_imgly_sticker_shapes_badge_12: 'Badge 6',
    sticker_items_imgly_sticker_shapes_badge_13: 'Badge 7',
    sticker_items_imgly_sticker_shapes_badge_15: 'Badge 8',
    sticker_items_imgly_sticker_shapes_badge_18: 'Badge 9',
    sticker_items_imgly_sticker_shapes_badge_19: 'Badge 10',
    sticker_items_imgly_sticker_shapes_badge_20: 'Badge 11',
    sticker_items_imgly_sticker_shapes_badge_28: 'Badge 12',
    sticker_items_imgly_sticker_shapes_badge_32: 'Badge 13',
    sticker_items_imgly_sticker_shapes_badge_35: 'Badge 14',
    sticker_items_imgly_sticker_shapes_badge_36: 'Badge 15',
    sticker_items_imgly_sticker_shapes_badge_04: 'Badge 2',
    sticker_items_imgly_sticker_shapes_badge_06: 'Badge 3',
    sticker_items_imgly_sticker_shapes_badge_08: 'Badge 4',
    sticker_items_imgly_sticker_shapes_spray_01: 'Spray 1',
    sticker_items_imgly_sticker_shapes_spray_03: 'Spray 2',
    sticker_items_imgly_sticker_shapes_spray_04: 'Spray 3',
    sticker_canvasActions_buttonDelete: 'Radera',
    sticker_canvasActions_buttonBringToFront: 'Flytta fram',
    sticker_canvasActions_buttonDuplicate: 'Duplicera',
    sticker_canvasActions_buttonFlipHorizontal: 'Vända',
    sticker_canvasActions_buttonFlipVertical: 'Vända',
    sticker_history_add: 'Klistermärken',
    sticker_history_resize: 'Ändra storlek på klistermärken',
    sticker_history_position: 'Klistermärkesposition',
    sticker_history_color: 'Färg på klistermärke',
    sticker_history_delete: 'Radera klistermärke',
    sticker_history_order: 'Klistermärkeordning',
    sticker_history_opacity: 'Klistermärke Opacitet',
    sticker_history_flip: 'Rotera',
    text_title: 'Text',
    text_controls_buttonNew: 'Ny text',
    text_controls_dropdownFontFamily: 'Typsnittsfamilj',
    text_controls_textFontSize: 'Textstorlek',
    text_controls_selectAlignment: 'Justering',
    text_controls_selectFontColor: 'Textfärg',
    text_controls_selectBackgroundColor: 'Bakgrund',
    text_controls_sliderLineSpacing: 'Radavstånd',
    text_controls_tabColor: 'Färg',
    text_controls_tabBgColor: 'Bakgrund',
    text_controls_tabAlignment: 'Justering',
    text_controls_tabLineHeight: 'Radavstånd',
    text_controls_tabFontSize: 'Textstorlek',
    text_canvasControls_placeholderText: 'Skriva något',
    text_canvasControls_buttonSave: 'Klar',
    text_canvasControls_buttonClose: 'Avbryt',
    text_canvasControls_inputText: 'Textinmatning',
    text_canvasActions_buttonEdit: 'Redigera',
    text_canvasActions_buttonDelete: 'Radera',
    text_canvasActions_buttonBringToFront: 'Flytta fram',
    text_canvasActions_buttonDuplicate: 'Duplicera',
    text_history_add: 'Text',
    text_history_edit: 'Textredigering',
    text_history_resize: 'Ändra storlek på text',
    text_history_position: 'Textposition',
    text_history_alignment: 'Textjustering',
    text_history_textColor: 'Textfärg',
    text_history_backgroundColor: 'Textbakgrundsfärg',
    text_history_fontFamily: 'Typsnittsfamilj',
    text_history_fontStyle: 'Typsnitt',
    text_history_lineSpacing: 'Radavstånd',
    text_history_width: 'Textbredd',
    text_history_delete: 'Radera text',
    text_history_order: 'Textordning',
    textdesign_title: 'Textdesign',
    textdesign_controls_buttonNew: 'Ny textdesign',
    textdesign_controls_buttonShuffle: 'Blanda Layout',
    textdesign_controls_selectColor: 'Textfärg',
    textdesign_controls_tabColor: 'Färg',
    textdesign_controls_tabShuffle: 'Blanda',
    textdesign_canvasControls_placeholderText: 'Skriv något med stil',
    textdesign_canvasControls_buttonSave: 'Klar',
    textdesign_canvasControls_buttonClose: 'Stäng',
    textdesign_canvasControls_inputText: 'Textinmatning',
    textdesign_canvasActions_buttonEdit: 'Redigera',
    textdesign_canvasActions_buttonInvert: 'Invertera',
    textdesign_canvasActions_buttonDelete: 'Radera',
    textdesign_canvasActions_buttonBringToFront: 'Flytta fram',
    textdesign_canvasActions_buttonDuplicate: 'Duplicera',
    textdesign_history_add: 'Textdesign',
    textdesign_history_edit: 'Redigera textdesign',
    textdesign_history_resize: 'Ändra storlek på textdesign',
    textdesign_history_position: 'Textdesignposition',
    textdesign_history_color: 'Textdesignfärg',
    textdesign_history_shuffle: 'Blandning av textdesign',
    textdesign_history_invert: 'Invertera textdesign',
    textdesign_history_padding: 'Utfyllnad av textdesign',
    textdesign_history_order: 'Ordning för textdesign',
    textdesign_history_delete: 'Ta bort textdesign',
    frame_title: 'Ram',
    frame_controls_buttonReset: 'Ta bort ram',
    frame_controls_sliderOpacity: 'Ramopacitet',
    frame_controls_sliderSize: 'Ram storlek',
    frame_controls_selectColor: 'Ram färg',
    frame_controls_tabColor: 'Färg',
    frame_controls_tabOpacity: 'Transparens',
    frame_controls_tabSize: 'Storlek',
    frame_items_imgly_frame_dia: 'Dia',
    frame_items_imgly_frame_art_decor: 'Art Decor',
    frame_items_imgly_frame_black_passepartout: 'Svart',
    frame_items_imgly_frame_lowpoly_shadow: 'Low Poly',
    frame_items_imgly_frame_wood_passepartout: 'Wood',
    brush_title: 'Pensel',
    brush_controls_buttonReset: 'Ta bort pensel',
    brush_controls_sliderSize: 'Penselstorlek',
    brush_controls_sliderHardness: 'Penselskärpa',
    brush_controls_selectColor: 'Penselfärg',
    brush_controls_tabSize: 'Storlek',
    brush_controls_tabHardness: 'Skärpa',
    brush_controls_tabColor: 'Färg',
    brush_history_brushStroke: 'Penseldrag',
    transform_title: 'Beskär',
    transform_controls_buttonReset: 'Återställ',
    transform_controls_checkboxKeepResolution: 'Behåll upplösning',
    transform_controls_inputCropSize: 'Beskärningsstorlek',
    transform_controls_inputHeight: 'h',
    transform_controls_inputWidth: 'w',
    transform_controls_tabFlipAndRotate: 'Vänd och rotera',
    transform_controls_tabResolution: 'Upplösning',
    transform_controls_tabCropSize: 'Beskärningsstorlek',
    transform_categories_imgly_transforms_common: 'Allmänning',
    transform_categories_imgly_transforms_facebook: 'Facebook',
    transform_categories_imgly_transforms_twitter: 'Twitter',
    transform_categories_imgly_transforms_instagram: 'Instagram',
    transform_items_imgly_transform_common_custom: 'Anpassat',
    transform_items_imgly_transform_common_square: 'Kvadrat',
    transform_items_imgly_transform_common_4: '4:3',
    transform_items_imgly_transform_common_16: '16:9',
    transform_items_imgly_transform_common_3: '3:4',
    transform_items_imgly_transform_common_9: '9:16',
    transform_items_imgly_transform_facebook_profile: 'Profil',
    transform_items_imgly_transform_facebook_title: 'Titel',
    transform_items_imgly_transform_facebook_post: 'Posta',
    transform_items_imgly_transform_instagram_story: 'Story',
    transform_items_imgly_transform_instagram_landscape: 'Landskap',
    transform_items_imgly_transform_instagram_portrait: 'Porträtt',
    transform_items_imgly_transform_instagram_square: 'Kvadrat',
    transform_items_imgly_transform_twitter_profile: 'Profil',
    transform_items_imgly_transform_twitter_title: 'Titel',
    transform_items_imgly_transform_twitter_post: 'Posta',
    transform_transformActions_buttonFlipHorizontal: 'Vänd horisontell',
    transform_transformActions_buttonFlipVertical: 'Vänd vertikalt',
    transform_transformActions_buttonRotateClockwise: 'Rotera klockvis',
    transform_transformActions_buttonRotateAntiClockwise: 'Rotera moturs',
}
