import styled from 'styled-components'
import { colors, layout } from '~/assets/styleConstants'

export const TopNavPlaceholder = styled.div`
    height: ${layout.topNavBarHeight}px;
    width: 100%;
    flex: 0 0 auto;
`

const FixedTopNavWrapper = styled.div`
    height: ${layout.topNavBarHeight}px;
    padding: 8px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${colors.mainBackground};
    border-bottom: 1px solid ${colors.captureGrey300};
`

export const TopNavBar = (props: React.PropsWithChildren) => (
    <FixedTopNavWrapper>{props.children}</FixedTopNavWrapper>
)
