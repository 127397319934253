/**
 * This component is used to indicate to users the expected deletion date of their files
 * It belongs to the sunset flow and should be deleted once this is through
 */

import {
    Prompt,
    PromptActionButton,
    PromptContent,
    Typography,
} from '@capture/capture-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Pages } from '~/routing'
import { SUNSET_FAQ } from '~/config/constants'
import { _ } from '~/assets/localization/util'
import { getSunsetDeletionDate } from '~/state/currentUser/selectors'

interface SunsetDeletionPromptProps {
    onClose: () => void
}

export const SunsetDeletionPrompt = ({
    onClose,
}: SunsetDeletionPromptProps) => {
    const deletionDate = useSelector(getSunsetDeletionDate)
    const navigate = useNavigate()

    const openSunsetInNewTab = () => {
        onClose()
        window.open(SUNSET_FAQ, '_blank')
    }

    const navigateToSubscriptionPlans = () => {
        onClose()
        navigate(Pages.SettingsStorage.url)
    }

    const handleOpenChanged = (open: boolean) => {
        if (!open) {
            onClose()
        }
    }

    if (!deletionDate) {
        return null
    }

    const [before, after] = _('deletion_prompt__body').split('%delete_date%')

    return (
        <Prompt defaultOpen onOpenChange={handleOpenChanged}>
            <PromptContent
                title={_('deletion_prompt__title')}
                actions={[
                    <PromptActionButton
                        variant="secondary"
                        key="secondary"
                        onPress={openSunsetInNewTab}>
                        {_('deletion_prompt__more_info')}
                    </PromptActionButton>,
                    <PromptActionButton
                        variant="primary"
                        key="primary"
                        onPress={navigateToSubscriptionPlans}>
                        {_('deletion_prompt__plans')}
                    </PromptActionButton>,
                ]}>
                <Typography.Paragraph>
                    {before}
                    <Typography.Bold>{deletionDate}</Typography.Bold>
                    {after}
                </Typography.Paragraph>
            </PromptContent>
        </Prompt>
    )
}
