import { createRef, useEffect } from 'react'
import AddToAlbumIcon from '@capture/capture-components/src/icons/add-to-album.svg?react'
import AddToPhotosIcon from '@capture/capture-components/src/icons/add-to-photos.svg?react'
import CopyIcon from '@capture/capture-components/src/icons/copy.svg?react'
import DeleteIcon from '@capture/capture-components/src/icons/delete-file.svg?react'
import DownloadIcon from '@capture/capture-components/src/icons/download.svg?react'
import ExportIcon from '@capture/capture-components/src/icons/export.svg?react'
import InfoIcon from '@capture/capture-components/src/icons/info.svg?react'
import AlbumCoverIcon from '@capture/capture-components/src/icons/set-as-cover-photo.svg?react'
import ShareIcon from '@capture/capture-components/src/icons/share.svg?react'
import { _ } from '~/assets/localization/util'
import type { AlbumFile } from '~/state/album/selectors'
import { withoutFalsy } from '~/utilities/arrayUtils'
import { isBrowserClipboardCompatible } from '~/utilities/device'
import { useClickOutside } from '~/utilities/useClickOutside'
import type { ButtonProps } from '../Common/Button'
import { Button } from '../Common/Button'
import type { Point } from '../Common/ContextMenu'
import { MakeMenuOptions } from '../Common/OverflowMenu'
import { Wrapper } from './FileContextMenu.styled'
import useFileContextMenu from './useFileContextMenu'

interface ContextMenuProps {
    onClickOutside: () => void
    isAlbum?: boolean
    isSharedAlbum?: boolean
    position: Point
    showContextMenu: boolean
    showIcons?: boolean
}

// CONTEXT MENU LOGIC - RightClick method on the thumbnail for custom menu
// Sets a local state for the show contextMenu and a Redux state for the target that is required in TimelinePageView to pass on to the FilesOptionsMenu
// Setting the contextMenu options for the right-clicked file

const FileContextMenu = (props: ContextMenuProps) => {
    const ref = createRef<HTMLDivElement>()
    useClickOutside(ref, props.onClickOutside, { handleRightClick: true })
    const {
        windowWidth,
        windowHeight,
        file,
        fileExtension,
        isLoggedIn,
        isAlbumCover,
        downloadFile,
        shareFile,
        copyFileToClipboard,
        addFileToAlbum,
        copyToTimeline,
        setAsCoverPhoto,
        deleteFile,
        showSideFileInfo,
        hideSideFileInfo,
        emitMenuLoaded,
    } = useFileContextMenu(props.isAlbum)

    const handleDownload = () => {
        downloadFile('download')
    }

    const handleExport = () => downloadFile('export')

    const getMenuItems = () =>
        withoutFalsy<ButtonProps>([
            Button(_('share'), shareFile, {
                icon: ShareIcon,
                isDisabledForReadonly: true,
                cyKey: 'fileContextMenu__share',
            }),
            file &&
                !file.duration &&
                isBrowserClipboardCompatible() &&
                Button(_('copy'), copyFileToClipboard, {
                    icon: CopyIcon,
                    cyKey: 'fileContextMenu__copy',
                }),
            Button(_('download'), handleDownload, {
                icon: DownloadIcon,
                cyKey: 'fileContextMenu__download',
            }),
            // TODO: CAPWEB-2989 export should account for group in case of eg: live photos
            fileExtension === 'HEIC' &&
                Button(_('export'), handleExport, {
                    icon: ExportIcon,
                    cyKey: 'fileContextMenu__export',
                }),
            isLoggedIn &&
                Button(_('add_to_album'), addFileToAlbum, {
                    icon: AddToAlbumIcon,
                    cyKey: 'fileContextMenu__addToAlbum',
                    isDisabledForReadonly: true,
                }),
            isLoggedIn &&
                props.isAlbum &&
                Button(_('add_file_to_timeline'), copyToTimeline, {
                    icon: AddToPhotosIcon,
                    cyKey: 'fileContextMenu__addToTimeline',
                    isDisabledForReadonly: true,
                }),
            props.isAlbum &&
                (file as AlbumFile)?.currentUserCan.setFileAsAlbumCoverPhoto &&
                Button(_('set_as_cover_photo'), setAsCoverPhoto, {
                    icon: AlbumCoverIcon,
                    cyKey: 'fileContextMenu__setAsAlbumCover',
                    isDisabledForReadonly: !props.isSharedAlbum,
                    isDisabled: isAlbumCover,
                }),
            isLoggedIn &&
                (!props.isAlbum ||
                    (file as AlbumFile)?.currentUserCan?.deleteFile) &&
                Button(_('delete_file'), deleteFile, {
                    icon: DeleteIcon,
                    cyKey: 'fileContextMenu__delete',
                }),
            Button(_('info'), showSideFileInfo, {
                icon: InfoIcon,
                cyKey: 'fileContextMenu__info',
            }),
        ])

    useEffect(() => {
        emitMenuLoaded()
        hideSideFileInfo()

        // Sets the position of the element on mouse position and positions the menu inside the window
        if (ref.current) {
            // Handling clicking near the window margins using the menuSize state set in the contextMenu component
            // Setting the menu dimesions like this because we cannot get them before rendering
            const menuWidth: number = ref.current.clientWidth
            const menuHight: number = ref.current.clientHeight
            const coordX: string =
                windowWidth - props.position.x < menuWidth
                    ? `${props.position.x - menuWidth}px`
                    : `${props.position.x}px`
            const coordY: string =
                windowHeight - props.position.y < menuHight
                    ? `${props.position.y - menuHight}px`
                    : `${props.position.y}px`
            ref.current.style.transform = `translate(${coordX}, ${coordY})`
        }
    }, [])

    if (!props.showContextMenu) return null

    return (
        <Wrapper ref={ref}>
            <MakeMenuOptions
                options={getMenuItems()}
                afterClick={props.onClickOutside}
                showIcons={props.showIcons}
            />
        </Wrapper>
    )
}

export default FileContextMenu
