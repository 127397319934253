import * as React from 'react'
import type { SyntheticEvent } from 'react'
import styled from 'styled-components'
import CancelIcon from '@capture/capture-components/src/icons/close.svg?react'
import PlusIcon from '@capture/capture-components/src/icons/plus.svg?react'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import ThumbnailPlaceholder from '~/assets/svg/ThumbnailPlaceholder.svg'
import {
    NormalItemImage,
    NormalItemImageWrapper,
} from '~/components/Timeline/GroupListItem'
import type { PendingAlbum } from '~/state/album/selectors'
import { FileUploadStatus } from '~/state/uploader/reducer'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import type { GridStyle } from '~/utilities/gridElementSizeCalculator'
import { calcImagesPerRow } from '~/utilities/gridElementSizeCalculator'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { IconButton } from '../Common/IconTextButton'
import { VideoIndicator } from '../Common/VideoIndicator'
import {
    GridElementIconContainerBottom,
    GridElementIconContainerTop,
} from '../GridView/GridElement'

const AddMoreGridButton = styled.div`
    border: dashed 2px ${colors.captureBlue};
    box-sizing: border-box;

    font-size: ${fontSize.large_24};
    color: ${colors.captureBlue};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &:hover {
        background-color: white;
    }

    svg {
        margin-bottom: 4px;
    }
`

const FileWrapper = styled(NormalItemImage)`
    ${(props: { uploaded: boolean }) =>
        !props.uploaded
            ? `
        opacity: 0.4;
        filter: alpha(opacity=40);
    `
            : ''}
`

const IconContainerTop = styled(GridElementIconContainerTop)`
    position: absolute;
    & > * {
        width: 24px;
        height: 24px;
        display: inline-block;
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 99px;
        }
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(
        ${(props: { imagesPerRow: number; groupStyle: ImageGroupStyle }) =>
            props.imagesPerRow},
        ${(props) => props.groupStyle.elementWidth}px
    );
    grid-auto-rows: ${(props) => props.groupStyle.elementHeight}px;
    grid-gap: ${(props) => 2 * props.groupStyle.elementSpaceAround}px;
`

type PendingFileEntry = {
    url?: string
    uploadIndex?: number
    fileID?: FileID
    uploaded: boolean
    duration?: number
}

type PendingFileProps = {
    file: PendingFileEntry
    removeFile: (fileID: FileID) => void
    styling: GridStyle
}

type PendingFileState = {
    thumbURL?: string
}

class PendingFile extends React.Component<PendingFileProps, PendingFileState> {
    public state: PendingFileState = {}

    private doRemoveFile = () => {
        if (this.props.file.fileID) {
            this.props.removeFile(this.props.file.fileID)
        }
    }

    private updateUploadThumb = (uploadIndex: number | undefined) => {
        if (uploadIndex !== undefined) {
            getConnectedInstance()
                .getUploadFilePreviewThumb(uploadIndex)
                .then((thumbURL) => {
                    this.setState({ ...this.state, thumbURL })
                })
        } else {
            this.setState({ ...this.state, thumbURL: undefined })
        }
    }

    private addFallbackImage = (event: SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.onerror = null // prevents looping
        event.currentTarget.src = ThumbnailPlaceholder
        event.currentTarget.style.opacity = '1'
    }

    public componentDidMount() {
        this.updateUploadThumb(this.props.file.uploadIndex)
    }

    public componentDidUpdate(prevProps: PendingFileProps) {
        if (this.props.file.uploadIndex !== prevProps.file.uploadIndex) {
            this.updateUploadThumb(this.props.file.uploadIndex)
        }
    }

    public render() {
        const videoIndicator = this.props.file.duration && (
            <GridElementIconContainerBottom>
                <VideoIndicator duration={this.props.file.duration} />
            </GridElementIconContainerBottom>
        )

        return (
            <NormalItemImageWrapper>
                <FileWrapper
                    src={
                        this.props.file.url
                            ? this.props.file.url
                            : this.state.thumbURL
                    }
                    onError={this.addFallbackImage}
                    uploaded={this.props.file.uploaded}
                    elementWidth={this.props.styling.elementWidth}
                    elementHeight={this.props.styling.elementHeight}
                    elementSpaceAround={this.props.styling.elementSpaceAround}
                />
                <IconContainerTop>
                    <IconButton
                        onClick={this.doRemoveFile}
                        icon={CancelIcon}
                        color={colors.white}
                    />
                </IconContainerTop>
                {videoIndicator}
            </NormalItemImageWrapper>
        )
    }
}

type Props = {
    isMobile: boolean
    onClickAddMore: () => void
    onRemoveFile: (fileID: FileID) => void
    pendingAlbum: PendingAlbum
    styling: ImageGroupStyle
}

export const EditAlbumFileList: React.FunctionComponent<Props> = (props) => {
    const files = props.pendingAlbum.pendingFiles
        .map(
            (info): PendingFileEntry => ({
                uploadIndex: info.id,
                fileID: info.fileUUID,
                uploaded: info.status === FileUploadStatus.Succeeded,
            }),
        )
        .concat(
            props.pendingAlbum.uploadedFiles.map(
                (file): PendingFileEntry => ({
                    url: file.thumbURLSmall,
                    fileID: file.fileID,
                    uploaded: true,
                    duration: file.duration,
                }),
            ),
        )

    const pendingFiles = files.map((file, index) => {
        return (
            <PendingFile
                key={`pending_file_${index}`}
                file={file}
                removeFile={props.onRemoveFile}
                styling={props.styling}
            />
        )
    })
    const addMoreButton = !props.isMobile && (
        <AddMoreGridButton
            onClick={props.onClickAddMore}
            onKeyUp={props.onClickAddMore}
            role="button"
            tabIndex={0}
            data-cy="album_edit__add_more_photos">
            <PlusIcon width={48} height={48} color={colors.captureBlue} />
            {_('add_photos')}
        </AddMoreGridButton>
    )

    return (
        <Grid
            className="Grid"
            imagesPerRow={calcImagesPerRow(props.styling)}
            groupStyle={props.styling}>
            {addMoreButton}
            {pendingFiles}
        </Grid>
    )
}
