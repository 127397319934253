import { type Ref, type ReactElement } from 'react'
import { ActionableTooltip } from '@capture/capture-components'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Pages } from '~/routing'
import { _ } from '~/assets/localization/util'
import { getUserReadOnlyStatus } from '~/state/currentUser/selectors'
import { isMobileMode } from '../../state/viewMode/selectors'

type ElementRenderFunc = (
    isReadOnlyUser: boolean,
    triggerProps?: any,
    forwardRef?: Ref<any>,
) => ReactElement

interface ReadonlyTooltipWrapperProps {
    renderElement: ElementRenderFunc
}

export const ReadonlyTooltip = ({
    renderElement,
}: ReadonlyTooltipWrapperProps): ReactElement => {
    const isReadOnlyUser = useSelector(getUserReadOnlyStatus)
    const navigate = useNavigate()

    if (isReadOnlyUser) {
        return (
            <ActionableTooltip
                description={_('readonly_tooltip')}
                actionButtonText={_('subscribe')}
                onActionButtonClick={() => navigate(Pages.SettingsStorage.url)}
                openDelay={0}>
                {renderElement(isReadOnlyUser)}
            </ActionableTooltip>
        )
    }

    return renderElement(isReadOnlyUser)
}

export const ReadonlyTooltipOnMenu = ({
    renderElement,
}: ReadonlyTooltipWrapperProps): ReactElement => {
    const isReadOnlyUser = useSelector(getUserReadOnlyStatus)
    const navigate = useNavigate()
    const isMobile = useSelector(isMobileMode)

    if (isReadOnlyUser) {
        return (
            <ActionableTooltip
                description={_('readonly_tooltip')}
                actionButtonText={_('subscribe')}
                onActionButtonClick={() => navigate(Pages.SettingsStorage.url)}
                side={isMobile ? 'bottom' : 'left'}
                openDelay={0}>
                {renderElement(isReadOnlyUser)}
            </ActionableTooltip>
        )
    }

    return renderElement(isReadOnlyUser)
}
