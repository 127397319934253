import * as React from 'react'
import styled from 'styled-components'
import NextIcon from '@capture/capture-components/src/icons/next-photo.svg?react'
import PreviousIcon from '@capture/capture-components/src/icons/previous-photo.svg?react'
import { colors, layout } from '~/assets/styleConstants'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { isBodyScrollable } from '~/utilities/preventBodyScroll'
import { FastScroller, SelectionFastScroller } from './FastScroller'
import type {
    ScrollerGroup,
    ScrollerGroupItem,
    ScrollerItemInfo,
} from './FastScrollerContent'
import { SlideInHoverWrapper } from './SlideInHoverWrapper'

const ScrollerContainerWeb = styled.div`
    height: 100%;
    width: ${(props: { isMobile: boolean; isInSelectMode: boolean }) =>
        props.isInSelectMode
            ? layout.fastScrollerWidthSelection
            : layout.fastScrollerWidth}px;
    padding: ${(props) =>
        props.isMobile ? '0 16px 0 8px' : '21px 24px 0 12px'};
    background-color: rgba(${colors.captureGrey50_rgb}, 0.85);
`

export const HiddenScrollWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 40px; //to cover multple scrollbar widths
`
const ContentWrapper = styled.div`
    min-height: 100%;
    flex: 1;
    padding-top: 56px;
    padding-bottom: 56px;
    box-sizing: border-box;
`

const ToggleScrollerBtn = styled.div`
    width: 24px;
    height: 32px;
    background-color: rgba(${colors.captureGrey50_rgb}, 0.85);
    cursor: pointer;
    border-radius: 50% 0 0 50%;
    padding-left: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
`

const ToggleExpandButton: React.FunctionComponent<{ isExpanded: boolean }> = (
    props,
) => {
    const Icon = props.isExpanded ? NextIcon : PreviousIcon
    return (
        <ToggleScrollerBtn {...props}>
            <Icon color={colors.captureGrey800} />
        </ToggleScrollerBtn>
    )
}

export type TimelineFastScrollerProps = {
    imageGroupStyle: ImageGroupStyle
    scrollerGroups: ScrollerGroup[]
}

type AddedProps = {
    selectedItemKey: string
    scrollerDiv: React.RefObject<HTMLDivElement>
    scrollerItemSelect: (scrollerEntryKey: ScrollerItemInfo) => void
}
type ComponentProps<T = object> = T & AddedProps
export const timelineFastScroller = <T extends TimelineFastScrollerProps>(
    Inner: React.ComponentType<ComponentProps<T>>,
) => {
    type TimelineScrollerState = {
        selectedGroupKey: string
        isExpanded: boolean
    }
    return class TimelineScrollerComponent extends React.Component<
        TimelineFastScrollerProps,
        TimelineScrollerState
    > {
        public state: TimelineScrollerState = {
            isExpanded: false,
            selectedGroupKey: '',
        }
        scrollerDiv = React.createRef<HTMLDivElement>()

        public componentDidUpdate(prevProps: TimelineFastScrollerProps) {
            if (prevProps.scrollerGroups !== this.props.scrollerGroups) {
                this.updateSelectedGroup(this.props)
            }
        }

        handleScroll = () => {
            this.updateSelectedGroup(this.props)
        }
        getNewSelectedGroupRef = (props: TimelineFastScrollerProps) => {
            let focusedGroup: string | undefined
            // tslint:disable-next-line:prefer-const
            for (const scrollerGroup of props.scrollerGroups) {
                const selectedGroup = scrollerGroup.groupItems.find(
                    ({ pageYEnd }) => {
                        return (
                            pageYEnd -
                                props.imageGroupStyle.elementHeight / 2 >=
                            window.pageYOffset
                        )
                    },
                )

                if (selectedGroup) {
                    focusedGroup = selectedGroup.itemKey
                    break
                }
            }
            return focusedGroup
        }
        updateSelectedGroup = (props: TimelineFastScrollerProps) => {
            if (isBodyScrollable()) {
                const newSelectedGroup = this.getNewSelectedGroupRef(props)

                // check if scrolled to bottom, then respect current selectedGroupKey,
                // since focused group is below first visible group when scroll to bottom
                const scrolledToBottom =
                    window.innerHeight + window.pageYOffset >=
                    document.body.scrollHeight

                if (
                    newSelectedGroup &&
                    newSelectedGroup !== this.state.selectedGroupKey &&
                    !scrolledToBottom
                ) {
                    this.setState({ selectedGroupKey: newSelectedGroup })
                }

                // This is not the desired behaviour (CAPWEB-2563) and there is no indication of the purpose of this reset. Restore if needed.
                // if (
                //     this.scrollerDiv.current &&
                //     window.pageYOffset === 0 &&
                //     this.scrollerDiv.current.scrollTop !== 0
                // ) {
                //     this.scrollerDiv.current.scrollTop = 0
                // }
            }
        }

        scrollToImageGroup = (groupRef: ScrollerItemInfo) => {
            window.scrollTo(0, groupRef.targetGroupPosition)
        }

        handleScrollerItemSelected = (newGroupRef: ScrollerItemInfo) => {
            this.scrollToImageGroup(newGroupRef)

            this.setState({
                ...this.state,
                selectedGroupKey: newGroupRef.scrollerItemKey,
            })
        }

        public componentDidMount() {
            window.addEventListener('scroll', this.handleScroll)
        }

        public componentWillUnmount() {
            window.removeEventListener('scroll', this.handleScroll)
        }
        public render() {
            const addedProps: AddedProps = {
                selectedItemKey: this.state.selectedGroupKey,
                scrollerDiv: this.scrollerDiv,
                scrollerItemSelect: this.handleScrollerItemSelected,
            }
            // @ts-expect-error -- not sure how to fix this
            return <Inner {...addedProps} {...this.props} />
        }
    }
}

type TimelineWebSpecificProps = TimelineFastScrollerProps & {
    isInSelectMode?: boolean
    onSelectBtnClicked: () => void
}

const TimelineFastScrollerWeb = timelineFastScroller<TimelineWebSpecificProps>(
    ({
        scrollerDiv,
        selectedItemKey,
        scrollerItemSelect,
        onSelectBtnClicked,
        scrollerGroups,
        isInSelectMode,
    }) => {
        const TimelineScroller = isInSelectMode
            ? SelectionFastScroller
            : FastScroller
        return (
            <HiddenScrollWrapper ref={scrollerDiv}>
                <ContentWrapper>
                    <TimelineScroller
                        scrollerGroups={scrollerGroups}
                        selectedItemKey={selectedItemKey}
                        scrollerItemOnSelected={scrollerItemSelect}
                        scrollerWrapper={scrollerDiv.current}
                        onSelectionBtnClicked={onSelectBtnClicked}
                    />
                </ContentWrapper>
            </HiddenScrollWrapper>
        )
    },
)

type Props = TimelineFastScrollerProps & {
    viewportWidth: number
    isMobileMode: boolean
    isInSelectMode?: boolean
    onSelectBtnClicked?: (item: ScrollerGroupItem | ScrollerGroup) => void
    isFastScrolling?: boolean
    showFastScrollHint?: boolean
}
export const TimelineScrollerPlacement: React.FunctionComponent<Props> = ({
    viewportWidth,
    isMobileMode,
    isFastScrolling,
    showFastScrollHint,
    ...fastScrollerProps
}) => {
    const canToggle = // fast-scroller does not fit in gap between last img column and screen
        (viewportWidth - fastScrollerProps.imageGroupStyle.width) / 2 <= 70
    return (
        <SlideInHoverWrapper
            toggleButton={ToggleExpandButton}
            isFastScrolling={isFastScrolling}
            canToggle={canToggle}
            showFastScrollHint={showFastScrollHint}
            isInSelectMode={fastScrollerProps.isInSelectMode === true}
            isMobile={isMobileMode}
            // 36 for padding
            translateAmount={
                layout[
                    fastScrollerProps.isInSelectMode
                        ? 'fastScrollerWidthSelection'
                        : 'fastScrollerWidth'
                ] + (isMobileMode ? 24 : 36)
            }>
            <ScrollerContainerWeb
                isMobile={isMobileMode}
                isInSelectMode={fastScrollerProps.isInSelectMode === true}>
                <TimelineFastScrollerWeb {...fastScrollerProps} />
            </ScrollerContainerWeb>
        </SlideInHoverWrapper>
    )
}
