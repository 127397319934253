import styled from 'styled-components'
import CloseIcon from '@capture/capture-components/src/icons/close.svg?react'
import WarningIcon from '@capture/capture-components/src/icons/warning.svg?react'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import type { FileInformation } from '~/state/uploader/reducer'
import { FileUploadStatus, RejectReason } from '~/state/uploader/reducer'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import { isSafariBrowser } from '~/utilities/device'
import { AsyncPreviewThumb } from './AsyncPreviewThumb'
import { UploadStatusBar } from './UploadStatusBar'

const ListEntryContainer = styled.div`
    width: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: default;

    &:hover {
        background-color: ${colors.captureBlue50};
    }
`

const ThumbnailContainer = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
`
const GrayThumbOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(${colors.captureGrey800rgb}, 0.6);
`

const FileInfoContainer = styled.div`
    flex: 1;
    font-size: ${fontSize.medium_16};
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
`

const FileName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    white-space: nowrap;
`

const FileStatus = styled.div`
    font-size: ${fontSize.small_12};
    font-style: italic;
`

const SmallIconContainer = styled.div`
    width: 24px;
    height: 24px;
    text-align: right;
`

const StatusBar = styled.div`
    height: 4px;
    background-color: ${colors.captureGrey300};
    margin-top: 8px;
`
const IconWrapper = styled.div`
    cursor: pointer;
`
const EntryWithWarning = styled.div`
    background-color: #fae5e8;
`
const EntryWithGreyText = styled.div`
    color: ${colors.captureGrey400};
`

const rejectReasonMessages: Record<RejectReason, string> = {
    [RejectReason.FileIsFolder]: isSafariBrowser()
        ? _('upload_folder_safari_reject')
        : _('upload_folder_reject'),
    [RejectReason.UnSupported]: _('unsupported_file_reject'),
    [RejectReason.NoStorage]: _('out_of_storage'),
    [RejectReason.LocalFileUnavailable]: _('was_not_uploaded'),
    [RejectReason.FileWasDeleted]: _('was_not_uploaded'),
    [RejectReason.Unknown]: _('was_not_uploaded'),
}

type ListEntryProps = {
    file: FileInformation
    status: React.ReactNode
    icon?: React.ReactNode
    greyOutThumb?: boolean
}

const ListEntry: React.FunctionComponent<ListEntryProps> = (props) => {
    return (
        <ListEntryContainer key={props.file.id}>
            <ThumbnailContainer>
                <AsyncPreviewThumb
                    fileName={props.file.name}
                    uploadId={props.file.id}
                />
                {props.greyOutThumb && <GrayThumbOverlay />}
            </ThumbnailContainer>
            <FileInfoContainer>
                <FileName>{props.file.name}</FileName>
                <FileStatus>{props.status}</FileStatus>
            </FileInfoContainer>
            <SmallIconContainer>{props.icon}</SmallIconContainer>
        </ListEntryContainer>
    )
}

type Props = {
    isOffline: boolean
    file: FileInformation
}

export const UploadStatusBoxListEntry = (props: Props) => {
    const removeFile = () => {
        getConnectedInstance().removeFile(props.file.id)
    }

    const cancelIcon = (
        <IconWrapper
            onClick={removeFile}
            onKeyUp={removeFile}
            role="button"
            tabIndex={0}>
            <CloseIcon />
        </IconWrapper>
    )

    const uploadingStatus = (
        <StatusBar>
            <UploadStatusBar
                progress={props.file.uploadedPercent}
                runAnimation={true}
            />
        </StatusBar>
    )

    let preparingStatus = _('preparing')

    switch (props.file.status) {
        case FileUploadStatus.Uploading:
            return (
                <ListEntry
                    file={props.file}
                    status={uploadingStatus}
                    icon={cancelIcon}
                />
            )
        case FileUploadStatus.BackendSucceeded:
            return <ListEntry file={props.file} status={_('processing')} />
        case FileUploadStatus.Succeeded:
            return <ListEntry file={props.file} status={_('complete')} />
        case FileUploadStatus.Rejected: {
            return (
                <EntryWithWarning>
                    <ListEntry
                        file={props.file}
                        status={
                            rejectReasonMessages[
                                props.file.statusReason ?? RejectReason.Unknown
                            ]
                        }
                        icon={<WarningIcon />}
                    />
                </EntryWithWarning>
            )
        }
        case FileUploadStatus.Cancelled:
            return (
                <EntryWithGreyText>
                    <ListEntry
                        file={props.file}
                        status={_('cancelled')}
                        greyOutThumb={true}
                    />
                </EntryWithGreyText>
            )
        default:
            if (props.isOffline) {
                preparingStatus = _('waiting_for_internet_connection')
            }
            return (
                <ListEntry
                    file={props.file}
                    status={preparingStatus}
                    icon={cancelIcon}
                />
            )
    }
}
