import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
    ManagedModal,
    hideModal,
    showModal,
} from '~/state/modalManager/modalManagerSlice'
import { getVisibleModal } from '~/state/modalManager/selectors'
import {
    getIsNewSignup,
    getSunsetDeletionDate,
} from '~/state/currentUser/selectors'
import { sessionStorageGet, sessionStorageSet } from '~/utilities/webStorage'
import { SubscribeModal } from '../UserAnnouncement/Announcements/SignupModal'
import { AcceptTOSModal } from '../Common/AcceptTOSModal'
import { SunsetDeletionPrompt } from '../Common/SunsetDeletionPrompt'

export const ModalPlacement = () => {
    const visibleModal = useSelector(getVisibleModal)
    const isNewSignup = useSelector(getIsNewSignup)
    const sunsetDeleteDate = useSelector(getSunsetDeletionDate)
    const dispatch = useDispatch()

    useEffect(() => {
        if (visibleModal) {
            return
        }
        const hasSeenModal = sessionStorageGet('hasSeenSunsetDeletionPrompt')
        if (Boolean(sunsetDeleteDate) && !hasSeenModal) {
            dispatch(showModal(ManagedModal.SunsetDeletion))
        }
    }, [dispatch, sunsetDeleteDate, visibleModal])

    const handleClose = () => {
        dispatch(hideModal())
    }

    if (visibleModal === ManagedModal.TermsOfService) {
        return <AcceptTOSModal />
    }

    if (visibleModal === ManagedModal.Subscription || isNewSignup) {
        return <SubscribeModal onClose={handleClose} />
    }

    if (visibleModal === ManagedModal.SunsetDeletion) {
        return (
            <SunsetDeletionPrompt
                onClose={() => {
                    sessionStorageSet('hasSeenSunsetDeletionPrompt', 'true')
                    handleClose()
                }}
            />
        )
    }

    return null
}
