import { createActionCreator } from '../common/actions'

export const Reactions: DictionaryOf<Reaction> = {
    Love: 'love',
    None: '',
}

type ReactionPayload = {
    fileID: FileID
    userUUID: string
}
type ReactionAddedPayload = ReactionPayload & {
    reaction: Reaction
}

export const ReactionAdded =
    createActionCreator<ReactionAddedPayload>('REACTION_ADDED')
export const ReactionDeleted =
    createActionCreator<ReactionPayload>('REACTION_DELETED')
