import { CardElement } from '@stripe/react-stripe-js'
import type { Stripe, StripeElements, Token } from '@stripe/stripe-js'
import { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { deleteCreditCard, fetchCreditCardInfo } from '~/API/storagePlan'
import { trackEventInternal } from '~/analytics/eventTracking'
import { _ } from '~/assets/localization/util'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
} from '~/components/Common/IconTextButton'
import { RippleLoaderOverlay } from '~/components/Common/RippleLoaderOverlay'
import { withStripe } from '~/components/Stripe/withStripe'
import { STRIPE_API_TOKEN } from '~/config/constants'
import {
    CreditCardDeleteFailed,
    CreditCardDeleted,
} from '~/state/storagePlan/actions'
import { Promise_finally } from '~/utilities/promises'
import { TestFlagService } from '~/API/services/TestFlagService'

const SectionWrapper = styled.div`
    max-width: 400px;
`

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 145px;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;

    & > div {
        margin-right: 24px;
    }
`
const InfoText = styled.div`
    margin: 16px 0;
    font-weight: bold;
`
const Label = styled.div`
    font-size: ${fontSize.small_14};
    color: ${colors.captureGrey400};
`
const PaymentInfoWrapper = styled.div`
    display: flex;

    ${mediaQueries.mobile} {
        justify-content: space-between;
    }
`
const PaymentInfo = styled.div`
    display: inline-block;
    margin-right: 48px;

    ${mediaQueries.mobile} {
        margin-right: 0;
    }
`
const CardInfo = styled.div`
    margin: 8px 0;
    font-size: ${fontSize.medium_18};
    letter-spacing: 0.75px;

    ${mediaQueries.mobile} {
        font-size: ${fontSize.medium_16};
    }
`

type Props = {
    doUpdateCreditCardInfo: (token: Token) => Promise<void>
    currentCardInfo: StripePaymentInfoResponse
    hasActiveSubscription: boolean
}

export const PaymentSetting = (props: Props) => {
    const dispatch = useDispatch()
    const [showLoader, setShowLoader] = useState(false)
    const [isEditing, setEditing] = useState(false)

    const startEditing = () => setEditing(true)
    const stopEditing = () => setEditing(false)

    const handleCreditCardSubmit = (token: Token) => {
        setShowLoader(true)
        Promise_finally(props.doUpdateCreditCardInfo(token), () => {
            setShowLoader(false)
            setEditing(false)
        })
    }

    const deleteCreditCardSubmit = async () => {
        setShowLoader(true)
        try {
            const response = await deleteCreditCard(
                props.currentCardInfo.card_id,
            )
            dispatch(CreditCardDeleted())
            trackEventInternal('payment_credit_card_deleted', {
                value: response,
            })
            await fetchCreditCardInfo(dispatch)
        } catch (error) {
            trackEventInternal('payment_credit_card_delete_failed', {
                value: error,
            })
            dispatch(CreditCardDeleteFailed())
        } finally {
            setShowLoader(false)
            setEditing(false)
        }
    }

    const canDeleteCard =
        TestFlagService.isEnabled('delete-card') && !props.hasActiveSubscription

    const SectionContent = () => {
        const month = `0${props.currentCardInfo.exp_month}`.slice(-2)
        const year = props.currentCardInfo.exp_year.toString().slice(-2)
        return isEditing ? (
            <>
                <UpdateStripeCreditCard
                    onTokenCreated={handleCreditCardSubmit}
                    onCancelButtonClicked={stopEditing}
                    key="paymentSetting__UpdateCreditCardField"
                />
                {showLoader && (
                    <RippleLoaderOverlay
                        backgroundColor={`rgba(${colors.captureGrey50_rgb}, 0.8)`}
                    />
                )}
            </>
        ) : (
            <>
                <PaymentInfoWrapper>
                    <PaymentInfo>
                        <Label>{_('card_number')}</Label>
                        <CardInfo>
                            XXXX XXXX XXXX {props.currentCardInfo?.card}
                        </CardInfo>
                    </PaymentInfo>
                    <PaymentInfo>
                        <Label>{_('card_exp_date')}</Label>
                        <CardInfo>
                            {month}/{year}
                        </CardInfo>
                    </PaymentInfo>
                </PaymentInfoWrapper>
                <ButtonWrapper>
                    <BorderedTextButton
                        text={_('change_card_info')}
                        color={colors.captureBlue}
                        onClick={startEditing}
                        hoverFillColor={colors.captureBlue50}
                    />
                    {canDeleteCard && (
                        <BorderedTextButton
                            text={_('delete_card')}
                            color={colors.warningRed}
                            hoverFillColor={colors.warningRed50}
                            onClick={deleteCreditCardSubmit}
                        />
                    )}
                </ButtonWrapper>
            </>
        )
    }

    return (
        <SectionWrapper>
            <ContentWrapper>
                <InfoText>
                    {isEditing
                        ? `${_('update_card_info')}:`
                        : _('payment_settings_info_text')}
                </InfoText>
                <SectionContent />
            </ContentWrapper>
        </SectionWrapper>
    )
}

type InnerProps = {
    onTokenCreated: (token: Token) => void
    onCancelButtonClicked: () => void
    stripe: Stripe
    elements: StripeElements
}

const _UpdateStripeCreditCard = (props: InnerProps) => {
    const handleTokenCreated = async () => {
        const element = props.elements.getElement(CardElement)
        if (element) {
            const { token } = await props.stripe.createToken(element)
            token && props.onTokenCreated(token)
        }
    }

    return (
        <>
            <CardElement options={{ hidePostalCode: true }} />
            <ButtonWrapper>
                <BorderedTextButton
                    text={_('cancel')}
                    onClick={props.onCancelButtonClicked}
                    color={colors.captureBlue}
                />
                <FilledTextButton
                    text={_('save')}
                    onClick={handleTokenCreated}
                    tabIndex={0}
                />
            </ButtonWrapper>
        </>
    )
}

const UpdateStripeCreditCard = withStripe(STRIPE_API_TOKEN, { locale: 'no' })(
    _UpdateStripeCreditCard,
)
