import styled from 'styled-components'
import { fontSize, mediaQueries } from '~/assets/styleConstants'
import { ThreeAreasSymetric } from '../Common/LayoutComponents'

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 8px 24px;
    box-sizing: border-box;
    display: flex;

    font-size: ${fontSize.small_14};

    ${mediaQueries.mobile} {
        padding: 8px;
    }
`

type Props = {
    left: React.ReactNode
    middle: React.ReactNode
    right: React.ReactNode
}

export const ThreeAreasNavBar: React.FunctionComponent<Props> = (props) => {
    return (
        <Wrapper>
            <ThreeAreasSymetric
                left={props.left}
                middle={props.middle}
                right={props.right}
            />
        </Wrapper>
    )
}
