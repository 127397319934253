import styled from 'styled-components'
import PlayIconFilled from '@capture/capture-components/src/icons/video.svg?react'
import { fontSize } from '~/assets/styleConstants'
import { msToHMS } from '~/utilities/dateOperations'

const VideoIndicatorWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const DurationText = styled.span`
    color: #fff;
    margin-left: 8px;
    font-size: ${fontSize.small_14};
    user-select: none;
`

export const VideoIndicator: React.FunctionComponent<{
    duration: number
    color?: string
}> = (props) => (
    <VideoIndicatorWrapper>
        <PlayIconFilled color={props.color || '#FFF'} />
        <DurationText>{msToHMS(props.duration)}</DurationText>
    </VideoIndicatorWrapper>
)
