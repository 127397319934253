import { type ServiceDict, getStoredServiceDict } from './externals'
import type { ServiceDictResponse } from './services/LoginService'
import { HostUrl, getJSON } from './toolbox'

// the operations-team expect us to substitute pN.* to ps.* when using https
export const sanitisePollHost = (pollHost: string) =>
    pollHost.replace(/^(p\d+)/, 'ps')

export const getHostDirectoryFromServiceDictResponse = (
    service: ServiceDictResponse,
): ServiceDict => ({
    appHost: service['app-host'],
    thumbHost: service['thumb-host'],
    videoHost: service['video-host'],
    pollHost: sanitisePollHost(service['poll-host']),
    downloadHost: service['download-host'],
})

export const getServiceDict = async (
    hostname: URLstring,
    job: JobID,
): Promise<{ hosts: ServiceDict }> => {
    const fetchHosts = () => {
        const hostUrl = new HostUrl(hostname, {
            key: import.meta.env.VITE_API_KEY,
            client_v: import.meta.env.VITE_VERSION,
        })

        return getJSON<{ service: ServiceDictResponse }>(
            hostUrl.getPath(`/st/4/jobs/${job}/service`),
        ).then((response) =>
            getHostDirectoryFromServiceDictResponse(response.service),
        )
    }

    return { hosts: getStoredServiceDict() || (await fetchHosts()) }
}
