import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export enum AccountAttributesStatus {
    EMPTY = 'EMPTY',
    FETCHING = 'FETCHING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}

export type AccountAttributesState = {
    attributes: EntityState<UserAccountAttribute>
    status: AccountAttributesStatus
    error?: string
}

// Normalizing file state for ease of access
export const accountAttributeAdapter =
    createEntityAdapter<UserAccountAttribute>({
        selectId: (attribute) => attribute.key,
    })

const initialState: AccountAttributesState = {
    attributes: accountAttributeAdapter.getInitialState(),
    status: AccountAttributesStatus.EMPTY,
}

const accountAttributeSlice = createSlice({
    name: 'accountAttributes',
    initialState,
    reducers: {
        setAccountAttributes: (
            state,
            action: PayloadAction<UserAccountAttribute[]>,
        ) => {
            accountAttributeAdapter.setAll(state.attributes, action.payload)
        },
        setAccountAttribute: (
            state,
            action: PayloadAction<UserAccountAttribute>,
        ) => {
            accountAttributeAdapter.setOne(state.attributes, action.payload)
        },
        setAccountAttributesStatus: (
            state,
            action: PayloadAction<AccountAttributesStatus>,
        ) => {
            state.status = action.payload
        },
        setAccountAttributesError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
    },
})

export const {
    setAccountAttributes,
    setAccountAttribute,
    setAccountAttributesStatus,
    setAccountAttributesError,
} = accountAttributeSlice.actions

export const accountAttributeReducerMapObj = {
    [accountAttributeSlice.name]: accountAttributeSlice.reducer,
}

export type StateWithAccountAttributes = StateOfReducerMapObj<
    typeof accountAttributeReducerMapObj
>
