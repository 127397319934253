import * as React from 'react'
import styled from 'styled-components'
import WarningIcon from '@capture/capture-components/src/icons/warning.svg?react'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import { ItemOverlay } from '../Common/Overlay'

const BoxWrapper = styled.div`
    width: 100%;
    height: 136px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;
    position: absolute;
    bottom: 0;

    display: flex;
`
const Thumbnail = styled.div`
    width: 56px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Content = styled.div`
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
`
const Text = styled.div`
    margin-top: 8px;
`
const Buttons = styled.div`
    color: ${colors.captureBlue};
    display: flex;
    justify-content: space-between;

    div {
        cursor: pointer;
    }
`

type Props = {
    doStopAborted: () => void
    doStop: () => void
}

export class StopPrompt extends React.Component<Props> {
    public render() {
        return (
            <ItemOverlay backgroundRGBA={`rgba(${colors.white_rgb}, 0.7)`}>
                <BoxWrapper>
                    <Thumbnail>
                        <WarningIcon width={56} height={56} />
                    </Thumbnail>
                    <Content>
                        <Text>{_('cancelling_upload_are_you_sure')}</Text>
                        <Buttons>
                            <div
                                onClick={this.props.doStop}
                                onKeyUp={this.props.doStop}
                                role="button"
                                tabIndex={0}>
                                {_('yes')}
                            </div>
                            <div
                                onClick={this.props.doStopAborted}
                                onKeyUp={this.props.doStopAborted}
                                role="button"
                                tabIndex={0}>
                                {_('no')}
                            </div>
                        </Buttons>
                    </Content>
                </BoxWrapper>
            </ItemOverlay>
        )
    }
}
