import { createSlice } from '@reduxjs/toolkit'
import type {
    Dispatch,
    TypedStartListening,
    PayloadAction,
} from '@reduxjs/toolkit'
import { copyShareToTimeline } from '~/API/job'
import { isType } from '~/state/common/actions'
import {
    getTOSVerificationInfo,
    getUserReadOnlyStatus,
} from '../currentUser/selectors'
import { getVisibleModal } from '../modalManager/selectors'
import { PurchaseSucceeded, RefusedSubscription } from '../storagePlan/actions'
import {
    getHasResumableCopyShareToTimeline,
    getResumableCopyShareToTimeline,
} from './selectors'

export enum Resumable {
    CopyShareToTimeline = 'copyShareToTimeline',
}

type ResumableAction<TResumable extends Resumable, TPayload> = {
    type: TResumable
    payload: TPayload
}
export type ResumableCopyShareToTimeline = ResumableAction<
    Resumable.CopyShareToTimeline,
    string
>

export type ResumableState = {
    [Resumable.CopyShareToTimeline]: string | null
}

type ResumableActions = ResumableCopyShareToTimeline

const initialState: ResumableState = {
    [Resumable.CopyShareToTimeline]: null,
}

const resumeSlice = createSlice({
    name: 'resume',
    initialState: initialState,
    reducers: {
        requiredSubscription: (
            state,
            action: PayloadAction<ResumableActions | undefined>,
        ) => {
            if (action.payload?.type === Resumable.CopyShareToTimeline) {
                state.copyShareToTimeline = action.payload.payload
            }
        },
        clearResumableCopyShareToTimeline: (state) => {
            state.copyShareToTimeline = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(RefusedSubscription, (state, _action) => {
            state.copyShareToTimeline = null
        })
    },
})

type ResumeListenerState = StateWithResume &
    StateOfSelector<typeof getHasResumableCopyShareToTimeline> &
    StateOfSelector<typeof getUserReadOnlyStatus> &
    StateOfSelector<typeof getVisibleModal>
export const addResumeListeners = (
    startListening: TypedStartListening<ResumeListenerState, Dispatch>,
) => {
    // if the user buys a subscription we trigger subscription actions
    startListening({
        predicate: (action, currentState, _previousState) => {
            const hasPurchasedSubscription = isType(action, PurchaseSucceeded)
            const hasActionsPendingSubscription =
                getHasResumableCopyShareToTimeline(currentState)

            return hasPurchasedSubscription && hasActionsPendingSubscription
        },
        effect: (_action, listenerApi) => {
            const state = listenerApi.getState()
            const dispatch = listenerApi.dispatch
            const shareID = getResumableCopyShareToTimeline(state)

            if (shareID) {
                try {
                    copyShareToTimeline(dispatch, shareID)
                } finally {
                    dispatch(clearResumableCopyShareToTimeline())
                }
            }
        },
    })
    // asks for subscription if there are pending actions
    startListening({
        predicate: (_action, currentState, _previousState) => {
            const hasActionsPendingSubscription =
                getHasResumableCopyShareToTimeline(currentState)
            const isReadonlyUser = getUserReadOnlyStatus(currentState)

            // TOSToApprove is null if the user has already approved the TOS
            const hasApproved = getTOSVerificationInfo(currentState) === null

            // we wait until the user is available to be prompted
            const hasVisibleModal = getVisibleModal(currentState) !== null

            return (
                hasActionsPendingSubscription &&
                hasApproved &&
                isReadonlyUser &&
                !hasVisibleModal
            )
        },
        effect: (_action, listenerApi) => {
            const dispatch = listenerApi.dispatch
            dispatch(requiredSubscription())
        },
    })
}

export const { requiredSubscription, clearResumableCopyShareToTimeline } =
    resumeSlice.actions

export const resumeReducerMapObj = {
    [resumeSlice.name]: resumeSlice.reducer,
}

export type StateWithResume = StateOfReducerMapObj<typeof resumeReducerMapObj>
