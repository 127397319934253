import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import type {
    Stripe,
    StripeElements,
    StripeElementsOptions,
} from '@stripe/stripe-js'
import * as React from 'react'
import { getStripeObject } from '~/API/3rdParty/stripe'
type StripeProps = { stripe: Stripe | null; elements: StripeElements | null }
type StripePropsReq = { stripe: Stripe; elements: StripeElements }

export const withStripe =
    (apiToken: string, elementOptions?: Partial<StripeElementsOptions>) =>
    <P extends StripePropsReq>(Inner: React.ComponentType<P>) => {
        type PublicProps = Omit<P, keyof StripePropsReq>
        return React.forwardRef(function Component(
            fProps: PublicProps,
            forwardedRef,
        ) {
            return (
                <Elements
                    stripe={getStripeObject(apiToken)}
                    options={elementOptions}>
                    <ElementsConsumer>
                        {(cProps: StripeProps) => {
                            if (cProps.stripe && cProps.elements) {
                                // typescript does not interpret generics as potential objects, so must cast in order to spread
                                const realProps: P = {
                                    ...(fProps as object),
                                    ...cProps,
                                } as P
                                return (
                                    <Inner
                                        {...realProps}
                                        ref={
                                            forwardedRef as React.RefObject<any>
                                        }
                                    />
                                )
                            }

                            return null
                        }}
                    </ElementsConsumer>
                </Elements>
            )
        })
    }
