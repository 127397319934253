import type { Store } from '@reduxjs/toolkit'
import type { NavigateFunction } from 'react-router-dom'
import { whenNetworkGoesOffline } from '~/API/toolbox'
import { createAutoGeneratedAlbum } from '~/API/album'
import { FileTarget, getFileTargetFromName } from '~/utilities/fileTarget'
import { consoleLog } from '~/utilities/logging'
import { uploadFile, uploaderWithTimelineMirroring } from '~/API/job'
import type { Dispatch } from '../common/actions'
import type { StateWithCurrentUser } from '../currentUser/reducer'
import {
    getAvailableStorage,
    isLoggedIn,
    isOutOfStorage,
} from '../currentUser/selectors'
import { isStoryJob } from '../jobInfo/selectors'
import { getTimelineJobID } from '../timeline/selectors'
import { uploaderWithAlbumCreationSupport } from './AlbumAutoCreator'
import type { FileWasAddedPayload } from './actions'
import {
    AddedMoreFilesThanAvailableStorage,
    FileUploadBackendSucceeded,
    FileUploadFailed,
    FileUploadProgress,
    FileUploadRetry,
    FileUploadStarted,
    FileUploadSucceeded,
    FileWasAcceptedToUploadQueue,
    FileWasAddedToUploadQueue,
    FileWasRejected,
    FileWasRemovedFromUploadQueue,
    UploaderFinished,
    UploaderPaused,
    UploaderResumed,
    UploaderStatusBoxShown,
    UploaderStopped,
} from './actions'
import type { FileInformation, StateWithUploader } from './reducer'
import { RejectReason } from './reducer'
import {
    getEnquedFiles,
    getSucceededFiles,
    isCurrentlyUploading,
    isOffline,
    isPaused,
    isStopPrompted,
    isUploaderDone,
} from './selectors'

declare global {
    interface Window {
        uploadQueueInstance?: UploadQueue
    }
}

export enum UploadResponseStatus {
    PROCESSING = 'processing',
    EXISTS = 'exists',
    DELETED = 'deleted',
    TRASHED = 'trashed',
}

export type UploadResponse = {
    content: {
        status: UploadResponseStatus
        bytes_received: number
        uuid: string
        max_space: number
        estimated_used_space: number
    }
}

export type UploadFunction = (
    f: File,
    i: FileInformation,
) => AbortablePromise<UploadResponse>
type UploadCheck = (file: File, targetJob: JobID) => Promise<unknown>
type MultiUploadCheck = (
    files: File[],
    targetJob: JobID,
    targetFolder?: string,
) => Promise<void>

// Combining the state required for the uploader to work
export type UploadQueueCompatibleState = StateWithUploader &
    StateWithCurrentUser

export const checkFolder: UploadCheck = (file: File) => {
    /* Folder-check:
     * FileReader will not be able to read a folder (while it will be ok with any file).
     * Therefore; try to read the first 64 bytes of data from the file - if that fails the user most likely
     * added a folder (or something else that will fail to upload) so reject it from the upload queue.
     * If FileReader does not exist (legacy browsers) - or for some other reason causes a real Exception - accept
     * the file as we failed to assert if it was a folder (and most likely is a legitimate file anyway)
     */
    return new Promise<void>((resolve, reject) => {
        try {
            const fr = new FileReader()
            fr.onload = () => {
                resolve()
            }
            fr.onerror = () => {
                reject(RejectReason.FileIsFolder)
            }
            fr.readAsBinaryString(file.slice(0, 64))
        } catch (e) {
            // No file-reader? We accept the upload by default [to actually function in these cases]
            resolve()
        }
    })
}
export const makeFileTypeCheck =
    (
        allowedFileTypes: FileTarget[] = [
            FileTarget.Pictures,
            FileTarget.Movies,
        ],
    ): UploadCheck =>
    (file: File) => {
        return new Promise<void>((resolve, reject) => {
            const fileType = getFileTargetFromName(file.name)
            if (allowedFileTypes.some((type) => fileType === type)) {
                resolve()
            } else {
                reject(RejectReason.UnSupported)
            }
        })
    }

type EnforceAvailableStorageCheckState = StateOfSelector<typeof isStoryJob> &
    StateOfSelector<typeof isLoggedIn> &
    StateOfSelector<typeof getAvailableStorage>
export const makeEnforceAvailableStorageCheck =
    (store: Store<EnforceAvailableStorageCheckState>): MultiUploadCheck =>
    async (files: File[], jobID: JobID) => {
        if (
            isStoryJob(store.getState(), jobID) &&
            !isLoggedIn(store.getState())
        ) {
            return // Allow uploads from non-logged-in-users even if they do not have space
        }
        const availableStorage = getAvailableStorage(store.getState())
        const totalSize = files.reduce((sum, file) => sum + file.size, 0)
        if (totalSize > availableStorage) {
            store.dispatch(AddedMoreFilesThanAvailableStorage())
            throw new Error(
                'Rejecting file additions: There is not enough space',
            )
        }
    }

export enum UploadFailedReason {
    NetworkError = 'NetworkError',
    LocalFileUnavailable = 'LocalFileUnavailable',
    FileError = 'FileError',
    OutOfStorage = 'OutOfStorage',
}
export class UploadError extends Error {
    constructor(
        public reason: UploadFailedReason,
        public message: string,
    ) {
        super(message)
    }
}

class UploadQueue {
    private files: File[] = []
    private previewThumbs: DictionaryOf<string> = {}
    private currentlyUploadingToBackend?: FileInformation
    private abortCurrent?: () => void

    constructor(
        private store: Store<UploadQueueCompatibleState>,
        private uploadFunction: UploadFunction,
        private uploadChecks: UploadCheck[],
        private multiUploadChecks: MultiUploadCheck[],
    ) {
        this.addFile = this.addFile.bind(this)
        this.stop = this.stop.bind(this)
    }

    private handleEnquedUploading = (fileToUpload: FileInformation) => {
        this.currentlyUploadingToBackend = fileToUpload
        this.store.dispatch(FileUploadStarted({ fileID: fileToUpload.id }))
        this.store.dispatch(UploaderStatusBoxShown())
        const call = this.uploadFunction(
            this.files[fileToUpload.id],
            fileToUpload,
        )
        this.abortCurrent = call.abort

        call.then(
            (resolved: UploadResponse) => {
                this.abortCurrent = undefined
                this.currentlyUploadingToBackend = undefined

                if (
                    resolved.content.status === UploadResponseStatus.PROCESSING
                ) {
                    this.store.dispatch(
                        FileUploadBackendSucceeded({
                            fileID: fileToUpload.id,
                            fileUUID: resolved.content.uuid,
                        }),
                    )
                } else {
                    const state = this.store.getState()
                    if (
                        resolved.content.status === UploadResponseStatus.EXISTS
                    ) {
                        this.store.dispatch(
                            FileUploadSucceeded({
                                fileID: fileToUpload.id,
                                fileUUID: resolved.content.uuid,
                                usedStorage:
                                    resolved.content.estimated_used_space,
                            }),
                        )
                    } else if (
                        resolved.content.status ===
                            UploadResponseStatus.DELETED ||
                        resolved.content.status === UploadResponseStatus.TRASHED
                    ) {
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.FileWasDeleted,
                            }),
                        )
                    }

                    if (isUploaderDone(state)) {
                        this.store.dispatch(
                            UploaderFinished({
                                filesCount: getSucceededFiles(state).length,
                            }),
                        )
                    }
                }
            },
            (error?: UploadError) => {
                this.abortCurrent = undefined
                this.currentlyUploadingToBackend = undefined
                switch (error?.reason) {
                    case UploadFailedReason.FileError:
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.UnSupported,
                            }),
                        )
                        break
                    case UploadFailedReason.LocalFileUnavailable:
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.LocalFileUnavailable,
                            }),
                        )
                        break
                    case UploadFailedReason.OutOfStorage:
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.NoStorage,
                            }),
                        )
                        break
                    default:
                        this.store.dispatch(
                            FileUploadFailed({
                                fileID: fileToUpload.id,
                                message: error?.message,
                            }),
                        )
                }
            },
        )
    }

    public digestNewState(newState?: UploadQueueCompatibleState) {
        if (newState === undefined) {
            console.warn('UploadQueue: undefined state.')
            return
        }

        if (
            !isPaused(newState) &&
            !isStopPrompted(newState) &&
            !isOutOfStorage(newState) &&
            !isOffline(newState) &&
            !isCurrentlyUploading(newState) &&
            getEnquedFiles(newState).length > 0
        ) {
            this.handleEnquedUploading(getEnquedFiles(newState)[0])
        }
    }

    public addFiles(
        files: File[],
        targetJob: JobID,
        targetFolder?: string,
        copyToTimeline?: boolean,
    ): Promise<void> {
        return Promise.all(
            this.multiUploadChecks.map((check) =>
                check(files, targetJob, targetFolder),
            ),
        ).then(
            () =>
                files.forEach((file) =>
                    this.addFile(file, targetJob, targetFolder, copyToTimeline),
                ),
            () => {
                /* If the checks failed, the user should get notified by the UI-changes, swallow error here */
            },
        )
    }

    public addFile(
        file: File,
        targetJob: JobID,
        targetFolder?: string,
        copyToTimeline?: boolean,
    ): Promise<void> {
        const id = this.files.length
        this.files[id] = file

        const checksInSerial = (checks: UploadCheck[]): Promise<unknown> =>
            checks.reduce(
                (prev: Promise<unknown>, c) =>
                    prev.then(() => c(file, targetJob)),
                Promise.resolve(''),
            )

        const newFile: FileWasAddedPayload = {
            id,
            targetJob,
            targetFolder,
            alsoTargetTimeline: copyToTimeline,
            name: file.name,
            size: file.size,
        }

        this.store.dispatch(FileWasAddedToUploadQueue(newFile))
        return checksInSerial(this.uploadChecks)
            .then(() => {
                this.store.dispatch(
                    FileWasAcceptedToUploadQueue({ fileID: id }),
                )
            })
            .catch((reason: RejectReason) => {
                this.store.dispatch(FileWasRejected({ fileID: id, reason }))
            })
    }

    public async getUploadFilePreviewThumb(id: number): Promise<string> {
        const file = this.files[id]
        if (file) {
            if (this.previewThumbs[id] === undefined) {
                this.previewThumbs[id] = window.URL.createObjectURL(file)
            }

            return this.previewThumbs[id]
        }

        return Promise.reject('file does not exist')
    }

    public clearUploadFiles() {
        Object.keys(this.previewThumbs).forEach((id) => {
            const thumb = this.previewThumbs[id]
            window.URL.revokeObjectURL(thumb)
        })

        this.previewThumbs = {}
        this.files = []
    }

    public retryUpload() {
        this.store.dispatch(FileUploadRetry())
    }

    public pause() {
        this.store.dispatch(UploaderPaused())
    }
    public resume() {
        this.store.dispatch(UploaderResumed())
    }

    public stop(navigate?: NavigateFunction) {
        if (this.abortCurrent) {
            this.abortCurrent()
            this.abortCurrent = undefined
        }
        this.store.dispatch(UploaderStopped({ navigate }))
    }

    public removeFile(fileID: number) {
        this.store.dispatch(FileWasRemovedFromUploadQueue({ fileID }))
        if (
            this.currentlyUploadingToBackend &&
            this.currentlyUploadingToBackend.id === fileID &&
            this.abortCurrent
        ) {
            this.abortCurrent()
        }
    }
}

export const getConnectedInstance = (): UploadQueue => {
    if (!window.uploadQueueInstance) {
        throw new Error(
            'Must connectUploadQueue before fetching the connectedInstance',
        )
    }
    return window.uploadQueueInstance
}

export function connectUploadQueue(
    store: Store<UploadQueueCompatibleState>,
    uploadFunction: UploadFunction,
    checks: UploadCheck[] = [],
    multiChecks: MultiUploadCheck[] = [],
): UploadQueue {
    const q = new UploadQueue(store, uploadFunction, checks, multiChecks)
    store.subscribe(() => q.digestNewState(store.getState()))
    if (typeof window !== 'undefined') {
        window.uploadQueueInstance = q
    }
    return q
}

export function connectUploader(store: Store) {
    const doUpload = [
        uploaderWithTimelineMirroring(store, store.dispatch, () =>
            getTimelineJobID(store.getState()),
        ),
        uploaderWithAlbumCreationSupport((name, tempID) =>
            createAutoGeneratedAlbum(store.dispatch, name, tempID),
        ),
    ].reduce((p, m) => m(p), uploadFile)

    connectUploadQueue(
        store,
        makeUploadFunction(store.dispatch, doUpload),
        [checkFolder, makeFileTypeCheck()],
        [makeEnforceAvailableStorageCheck(store)],
    )
}

/**
 * For an UploadFunction that accepts the request as an argument and uses that for the upload:
 * Make the Promise of an UploadFunctionResolveValue into an abortable and progress-tracking Promise
 */
export type UploadMethod = (
    f: File,
    i: FileInformation,
    r?: XMLHttpRequest,
) => Promise<UploadResponse>
export type UploadDecorator = (u: UploadMethod) => UploadMethod
export const makeUploadFunction = (
    dispatch: Dispatch,
    doUpload: UploadMethod,
): UploadFunction => {
    let request: XMLHttpRequest | undefined

    // If other requests detects that the network is missing, abort the uploader one.
    // The request may still be hanging for some time before it is aborted (as the connection have been made and the efforts to keep it may leave it hanging for several minutes)
    whenNetworkGoesOffline(() => {
        if (request) {
            consoleLog('aborting request as internet is gone')
            request.abort()
            request = undefined
        }
    })

    return (f: File, i: FileInformation) => {
        request = new XMLHttpRequest()
        let isTimeBlocked = false // Avoid triggering too many actions within the same timeframe
        let lastDispatchedDoneRatio = 0 // ... or when the change doesn't matter
        request.upload.addEventListener('progress', (event: ProgressEvent) => {
            if (event.loaded && event.total) {
                const doneRatio = event.loaded / event.total
                if (
                    !isTimeBlocked &&
                    doneRatio - lastDispatchedDoneRatio > 0.01
                ) {
                    lastDispatchedDoneRatio = doneRatio
                    isTimeBlocked = true
                    setTimeout(() => {
                        isTimeBlocked = false
                    }, 3)
                    dispatch(
                        FileUploadProgress({
                            fileID: i.id,
                            percentComplete: doneRatio,
                        }),
                    )
                }
            }
        })

        return Object.assign(
            doUpload(f, i, request).then(
                (resp) => {
                    request = undefined
                    return resp
                },
                (err) => {
                    request = undefined
                    throw err
                },
            ),
            {
                abort: () => {
                    if (request && lastDispatchedDoneRatio !== 1) {
                        request.abort()
                    }
                },
            },
        )
    }
}
