import { useDispatch, useSelector } from 'react-redux'
import { isOutOfStorage } from '~/state/currentUser/selectors'
import {
    FileUploadRetry,
    UploadStatusListFiltered,
    UploaderStatusBoxCollapsed,
    UploaderStatusBoxDismissed,
    UploaderStatusBoxExpanded,
    UploaderStatusBoxShown,
    UploaderStopAborted,
    UploaderStopPrompted,
} from '~/state/uploader/actions'
import {
    getAllCurrentFiles,
    getCurrentlyDoneRatio,
    getCurrentlyUploadingFile,
    getEnquedFiles,
    getFinishedThumbs,
    getNotUploadedFiles,
    getPendingFiles,
    getRejectedFiles,
    getUploadStatusHeight,
    getVisibleFiles,
    isOffline,
    isRetrying,
    isStatusBoxExpanded,
    isStatusBoxVisible,
    isStopPrompted,
    isUploadStatusBackendSucceeded,
    isUploaderDone,
} from '~/state/uploader/selectors'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import { isMobileMode } from '~/state/viewMode/selectors'

export const useUploadStatusPlacement = () => {
    const currFile = useSelector(getCurrentlyUploadingFile)
    const visFiles = useSelector(getVisibleFiles)
    const fileToCheckForUploading = currFile || visFiles[0]

    const isMobile = useSelector(isMobileMode)
    const isExpanded = useSelector(isStatusBoxExpanded)
    const isVisible = useSelector(isStatusBoxVisible)
    const isUploadOffline = useSelector(isOffline)
    const isUploadRetrying = useSelector(isRetrying)
    const isUploading = !useSelector(isUploaderDone)
    const isUploadPendingStop = useSelector(isStopPrompted)
    const isUploadOutOfStorage = useSelector(isOutOfStorage)

    const uploadProgress = useSelector(getCurrentlyDoneRatio)
    const visibleFiles = visFiles
    const totalFilesInQueue = useSelector(getAllCurrentFiles).length
    const completedFiles =
        useSelector(getAllCurrentFiles).length -
        useSelector(getEnquedFiles).length -
        useSelector(getPendingFiles).length
    const unSuccessfulFiles = useSelector(getNotUploadedFiles).length
    const numberOfRejectedFiles = useSelector(getRejectedFiles).length

    const currentFile = currFile
    const uploaderHeight = useSelector(getUploadStatusHeight)
    const randomFinishedImages = useSelector(getFinishedThumbs)
    const isBackendSucceeded =
        fileToCheckForUploading !== undefined &&
        isUploadStatusBackendSucceeded(fileToCheckForUploading)

    const dispatch = useDispatch()
    const doExpand = () => dispatch(UploaderStatusBoxExpanded())
    const doCollapse = () => dispatch(UploaderStatusBoxCollapsed())
    const doClose = () => dispatch(UploaderStatusBoxDismissed())
    const doOpen = () => dispatch(UploaderStatusBoxShown())
    const doRetry = () => dispatch(FileUploadRetry())
    const doStopPrompt = () => dispatch(UploaderStopPrompted())
    const doStopAborted = () => dispatch(UploaderStopAborted())
    const onFilterList = () => dispatch(UploadStatusListFiltered())
    const clearUploadQueue = () => getConnectedInstance().clearUploadFiles()

    return {
        fileToCheckForUploading,
        isMobile,
        isExpanded,
        isVisible,
        isUploadOffline,
        isUploadRetrying,
        isUploading,
        isUploadPendingStop,
        isUploadOutOfStorage,
        uploadProgress,
        visibleFiles,
        totalFilesInQueue,
        completedFiles,
        unSuccessfulFiles,
        numberOfRejectedFiles,
        currentFile,
        uploaderHeight,
        randomFinishedImages,
        isBackendSucceeded,
        doExpand,
        doCollapse,
        doClose,
        doOpen,
        doRetry,
        doStopPrompt,
        doStopAborted,
        onFilterList,
        clearUploadQueue,
    }
}
