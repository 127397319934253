export const strings = {
    // General
    of: 'of',
    ok: 'OK',
    yes: 'Yes',
    got_it: 'Got it',
    see_more: 'See more',
    see_album: 'See album',
    read_more: 'Read more',
    skip: 'Skip',
    no: 'No',
    done: 'Done',
    reactivate: 'Reactivate',
    subscribe: 'Subscribe',
    unsubscribe: 'Unsubscribe',
    delete_: 'Delete',
    download: 'Download',
    export: 'Export',
    edit: 'Edit',
    copy: 'Copy',
    update: 'Update',
    cancel: 'Cancel',
    share: 'Share',
    undo: 'Undo',
    back: 'Back',
    go_back: '< Go back',
    select_all: 'Select all',
    restore: 'Restore',
    filename: 'filename',
    file_type: 'file type',
    deleted: 'deleted',
    unknown: 'unknown',
    retry: 'Retry',
    create_account: 'Create an account',
    sign_in: 'Sign in',
    only_accessible_to_logged_in_users:
        'This feature is only accessible for logged in users',
    login_again_for_security:
        'For security purposes please log in to access your privacy settings',
    logged_out_prompt: 'Oops, you were logged out. To continue, please sign in',
    capture_save_see_share: 'Save, see and share all your photos and videos.',
    feature_only_available_in_app:
        'This feature is currently only available in the Capture app.',
    open_capture: 'Open Capture',
    open_in_app: 'Open in app',
    storage: 'Storage',
    active: 'Active',
    save: 'Save', // for buttons
    details: 'Details',
    accept: 'Accept',
    deny: 'Deny',
    num_of_total__format: '%num% of %total%',
    autoplay: 'autoplay',
    something_went_wrong: 'Oops! Something went wrong.',
    cookies: 'Cookies',
    invalid_field: 'Value is invalid',
    mandatory_field: 'Field is required',
    movies: 'Videos',
    here: 'here',
    recents: 'Recents',
    screenshots: 'Screenshots',

    // media types
    image: 'Image',
    livephoto: 'Live photo',
    movie: 'Video',

    // Time
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    monthly: 'monthly',
    yearly: 'yearly',

    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',

    num_days__format: '%d days',
    one_day: '1 day',

    // User Action Prompts & Alerts
    delete_files_prompt__format: `%d files will be moved to Deleted Items. The files will be permanently deleted after 30 days.`,
    delete_single_file_prompt: `File will be moved to Deleted Items. The file will be permanently deleted after 30 days.`,
    delete_file_failed_message__format:
        'An error occurred when trying to delete "%s". Please try again.',
    // --- album prompts ---
    delete_album_file_prompt_text:
        'File will be removed from album. Any comments and loves related to this file will also be deleted.',
    delete_last_file_prompt_text:
        'File will be removed from album. Removing the last file in an album will also delete the album.',
    unsubscribe_album_prompt_text:
        'You will no longer see this album on your album list or receive updates on this album.',
    delete_album_prompt_text:
        'Album will be deleted and removed from your album list.',
    delete_comment_prompt_text: 'Comment will be deleted.',
    copy_files_to_timeline: "The album's files will be copied to your photos.",
    // --- settings page prompts ---
    remove_device_prompt__format:
        'You will be logged out of %s on this device.',
    cancel_storage_plan_prompt__format:
        'Subscription will expire on %expired_date%. Total storage will be reduced to %storage_amount% once subscription expires.',
    reactivate_storage_plan_prompt__format:
        'Subscription will continue current %period% billing cycle.',
    cancel_plan: 'Cancel Subscription',
    keep_plan: 'Keep Subscription',
    change_storage_plan_period_prompt:
        "Confirm change in current subscription's billing period. The new billing cycle will begin immediately", // replace with plan_upgrade when charge amount is available
    upgrade_storage_plan_size_prompt__format:
        'Confirm change in your current subscription from %current_size% to %new_size%. Changes will take place immediately.', // remove once getting charge amount is possible
    upgrade_storage_plan_prompt__format:
        'Subscription will be upgraded to %plan_price%/%period% (%size%). You will be charged %amount% and the billing cycle will begin immediately.',
    insufficient_plan_selected: 'Insufficient subscription selected',
    insufficient_plan_explanation__format:
        'Your total storage after purchasing this subscription (%max_storage%) is less than your currently used space (%used_space%). Please choose a larger subscription or reduce your used space.',
    downgrade_not_available:
        'Downgrading your current subscription is not available at this time.',
    downgrade_active_plan_alert:
        'You must cancel your current subscription and wait for it to expire before you may select this subscription.',
    downgrade_canceled_plan_alert:
        'You must wait for your current subscription to expire before you may select this subscription.',
    // --- terms of service prompts ---
    accept_tos__header: 'Accept Terms of Service',
    accept_tos__we_have_made_changes__format:
        'We have made changes to our %link_start%Terms of Service%link_end%.',
    accept_tos__please_read_and_accept:
        'By using the service you accept the %terms_of_service%. Please read them carefully.',
    decline_tos: 'Decline and log out',

    // --- trash and document prompts ---
    delete_single_document_file: 'File will be permanently deleted.',
    delete_document_files__format: '%d files will be permanently deleted.',
    restore_single_file_prompt: 'File will be restored to your photos.',
    restore_files_prompt__format: '%d files will be restored to your photos.',
    permanently_delete_single_file_prompt:
        'File will be permanently deleted. This action cannot be undone.',
    permanently_delete_files_prompt__format:
        '%d files will be permanently deleted. This action cannot be undone.',

    // Navigation
    timeline: 'Photos',
    albums: 'Albums',
    add_files: 'Add files',
    documents: 'Documents',
    settings: 'Settings',
    telenor_ID: 'Telenor ID',
    account: 'Account',
    help: 'Help',
    help_support: 'Help & Support',
    deleted_items: 'Deleted items',
    login: 'Log in',
    log_out: 'Log out',
    close: 'Close',
    timeline_desc: 'All your photos and videos, organized by date.',
    albums_desc: 'Add photos to an Album and share it with friends!',
    search_albums: 'Search for album',
    no_album_results:
        'No results for "%link_start%%search_string%%link_end%". Try searching for another album.',

    // Drag and drop
    drag_drop: 'Drag & Drop',
    drag_drop_files__format: 'Drag & drop your files %split%or',
    drop_your_files__format:
        'Drop your files to instantly upload them to %product_name%!',
    dropZone_text__timeline:
        'files to upload them, or a folder to create a new album',
    dropZone_text__albums: 'files or folders to create new albums',
    dropZone_text__singleAlbum: 'files anywhere to upload them to %album_name%',
    // click_here_to_select: 'or click here to select from you computer',

    // UploaderBox
    files: 'file(s)',
    uploaded: 'uploaded',
    did_not_upload: "didn't upload",
    upload_folder_reject: 'Cannot upload folders',
    upload_folder_safari_reject: "Safari doesn't support uploading folders",
    unsupported_file_reject: 'Unsupported file',
    was_not_uploaded: 'Was not uploaded',
    see_which: 'See which',
    // show_files: 'Show files',
    // show_photos: 'Show photos',
    offline: 'No Internet connection',
    upload: 'Upload',
    uploading: 'Uploading',
    // pause: 'Pause',
    // resume: 'Resume',
    retrying_in: 'Retrying in',
    sec: 'sec',
    retrying_now: 'Retrying now...',
    try_again: 'Try again',
    stop: 'Stop',
    processing: 'Processing',
    complete: 'Complete',
    cancelled: 'Cancelled',
    // unknown_format: 'Unknown format',
    preparing: 'Preparing...',
    waiting_for_internet_connection: 'Waiting for Internet connection...',
    out_of_storage: 'Out of storage',
    free_up_space: 'Delete files to resume the upload',
    cancelling_upload_are_you_sure: 'Are you sure you want to stop uploading?',
    processing_video: 'Processing video',

    // upload options modal
    select_from_device: 'Upload photos from device',
    add_from_capture__format: 'Add from %product_name%',

    // Popup
    reached_given_storage_ratio: 'Running out of storage',
    reached_given_storage_ratio_subText:
        'You have used %used_storage%GB out of %max_storage%GB. Free up space by deleting photos or videos, or buy more storage in Settings.',

    not_enough_storage_for_add: 'Insufficient storage',
    not_enough_storage_for_add_subText:
        'You do not have enough storage for all the files you are trying to add. Free up space by deleting photos or videos, or buy more storage in Settings.',

    not_enough_storage_for_restore: 'Insufficient storage',
    not_enough_storage_for_restore_subText:
        'You do not have enough storage for all the files you are trying to restore. Free up space by deleting photos or videos, or buy more storage in Settings.',

    // Popover
    popover__profile_picture_heading: 'Change your profile picture?',
    popover__profile_picture_body:
        'You can change your profile picture in settings, so that everyone knows that you are you!',

    // T I M E L I N E
    // subscription required
    timeline_subscription_required_header:
        'A Capture subscription is required to upload photos.',
    timeline_subscription_required_sub:
        'See our subscription plans and start your free trial today!',
    timeline_subscription_required_cta: 'See subscriptions',
    // emtpy state
    timeline_is_empty: 'You have no photos backed up here.',
    timeline_filtered_photos_empty: "You don't have any photos.",
    timeline_filtered_recents_empty: "You don't have any recents.",
    start_uploading_photos_now: 'Why don’t you start uploading photos now?',
    start_uploading_now: 'Why don’t you start uploading some now?',
    drag_drop_photos_to_upload:
        'You can drag and drop your photos here or press the button below.',
    download_app_to_upload_directly_from_device:
        "Install the Capture app on your mobile device to upload photos and videos directly from your device's camera and photo gallery",
    timeline_empty_screenshot_filter:
        "You don't have any screenshots. Good for you!", // TODO: verify text
    timeline_filtered_videos_empty: "You don't have any videos.",
    drag_drop_files_to_upload:
        'You can drag and drop your files here or press the button below.',
    add_videos: 'Add videos',

    // select from timeline empty
    upload_photos_add_album:
        'Upload photos in order to add them to your album.',

    // timeline page
    selected__format: '%d selected',
    none_selected: 'No items selected',
    select_files: 'Select files',
    file_count_exceeds_download_limit:
        'You cannot download more than 500 items at one time. Please select fewer items.',
    size_exceeds_download_limit:
        'You cannot download more than 3.5 GB at one time. Please change your selection.',
    fetching_file_selection_info:
        'Fetching file information to perform selection',
    shift_select_hint_header:
        'Want to quickly select multiple photos at the same time?',
    shift_select_hint_body:
        'Hold shift (⇧) and click to select multiple items at once.',
    fastscroller_hint_body: 'Go directly to any month by clicking on it',
    fastscroller_select_hint_body:
        'Easily select a whole month or year by clicking here',
    filter_by_videos: 'Videos',
    filter_by_images: 'Images',
    filter_by_recents: 'Recently added',
    filtered_by_videos: 'Filtered by videos',
    filtered_by_images: 'Filtered by images',
    filtered_by_recents: 'Filtered by recents',
    show_all: 'Show all',
    download_heic: 'Download HEIC',
    download_heic_desc_single: 'Original file. Compatible with iOS and macOS',
    download_heic_desc_multi:
        'files as uploaded. Compatible with iOS and macOS',
    download_jpeg: 'Download JPEG',
    download_jpeg_desc: 'Compatible with most devices. Recommended',
    download_original: 'Download original',
    download_original_desc: 'files as uploaded',
    download_converted: 'Convert and download',
    download_converted_desc: 'to a format compatible with most devices',
    show: 'Show',
    added_today: 'Added today',
    added_days__format: 'Added last %d days',

    // paginated download
    file__format: '%d file',
    files__format: '%d files',
    about_to_download_files__format:
        'You are about to download %file_count_size%',
    about_to_download_paginated_description:
        'Downloading a lot of files at once may take some time. Make sure your network connection is stable and that your device is connected to a power source.',
    download_all_at_once: 'Download all at once',
    download_your_photos_and_videos: 'Download your photos and videos',
    download_in_smaller_parts: 'Download in smaller parts',
    download_page__format: 'Download part %part% (%file_count%)',
    download_paginated_parts_below:
        'Please download each of the smaller parts below.',
    download_paginated__format:
        'Your selection of %file_count% %file_count_size% has been split into smaller parts for best experience. Click on each zip file below to download it.',
    download_pagination_convenience:
        'For convenience, you can minimize this window and continue using %product_name% while the files are being downloaded.',
    download_files: 'Download %file_count% (%file_count_size%)',
    close_window: 'Close window',

    // close to quota - text ribbon
    close_to_quota__format: '%used_storage% GB used out of %max_storage% GB',

    // A L B U M S
    album_name: 'Album name',
    album: 'album',
    unnamed_album_placeholder: 'Unnamed album',
    album_settings: 'Album settings',
    add_to_albums: 'Add to My Albums',
    subscribe_to_album: 'Subscribe to album',
    item__format: '%d item',
    items__format: '%d items',
    participant__format: '%d participant',
    participants__format: '%d participants',
    participants: 'Participants',
    owner: 'Owner',
    you_are_participating: 'You are participating',
    by: 'by',
    create_album: 'Create album',
    auto_created_album_title: 'Untitled album',
    new_album: 'New album',
    filter_by: 'Filter by',
    sort_by: 'Sort by',
    filter_albums: 'Filter albums',
    filtered_by_private: 'Filtered by private',
    filtered_by_shared: 'Filtered by shared',
    sort_recent_activity: 'Recent activity',
    sort_newest_created: 'Newest created',
    sort_oldest_created: 'Oldest created',
    sort_name: 'Album name',
    all_albums: 'All albums',
    shared_albums: 'Shared albums',
    private_albums: 'Private albums',
    download_album: 'Download album',
    add_photos: 'Add photos',
    add_to_album: 'Add to album',
    you_and_others_love_message_format: 'You and %d others love this',
    you_and_one_love_message: 'You and 1 other love this',
    you_love_message: 'You love this',
    one_person_love_message: '1 loves this',
    people_love_message_format: '%d love this',
    leave_album: 'Leave album',
    delete_album: 'Delete album',
    delete_file: 'Delete file',
    download_file: 'Download file',
    download_files_failed_message:
        'An error occurred when trying to download. Please try again later.',
    set_as_cover_photo: 'Set as cover photo',
    add_file_to_timeline: `Add to "Photos"`,
    add_album_to_timeline: `Add album to "Photos"`,
    change_album_viewMode: 'Change view mode',
    sort_album_files: 'Sort album',
    sort_album_files_title: 'Sort album by',
    sort_album_files_by_added: 'Recently added',
    sort_album_files_by_newest_first: 'Newest first',
    sort_album_files_by_oldest_first: 'Oldest first',
    album_file_count_exceeds_download_limit:
        'You cannot download an album with more than 500 items.',
    album_size_exceeds_download_limit:
        'You cannot download more than 3.5 GB at one time.',

    // empty album list state
    album_list_is_empty: "You don't have any albums yet.",
    empty_albumList_secondTitle: 'Why don’t you create one now?',
    empty_filtered_private_albumList_title:
        'You don’t have any private albums.',
    empty_filtered_shared_albumList_title: 'You don’t have any shared albums.',
    empty_filtered_albumList_subtext:
        'It’s easy to make an album! Press the button below to start.',
    empty_album_list_read_only_header:
        'A Capture subscription is required to create albums.',

    // empty album state
    empty_album_photos: 'There are no photos in this album yet',

    // add to album
    album_select: 'Select an album',

    // create album
    create_new: 'Create new',
    private_album: 'Private album',
    shared_album: 'Shared album',
    share_created_album_prompt_header: 'You have created an album!',
    share_created_album_prompt_text:
        'Share it with friends and family to allow them to add photos and comment on your album.',
    share_later: 'Share later',
    share_now: 'Share now',
    name_your_album: 'Name your album',
    add_photos_to_album: 'Add photos to album',
    create_empty_album: 'Create empty album and add photos later',
    save_create_album: 'Save & create album',
    set_name_for_shared_album:
        'Account user name is required for Shared albums so that your friends know who you are.',
    album_name_is_required_for_shared_albums:
        'Album name is required for shared albums',

    // Uploading to album
    also_upload_to_timeline: 'Upload to your photos?',
    uploading_to_album_name__format: 'You are uploading to "%album_name%"',
    uploading_to_album_also_to_timeline__format:
        'Should these files also be added to your %product_name%-photos?',

    uploading_files_to_new_album: 'You are uploading files to a new album.',
    uploading_files_to_multiple_albums__format:
        'You are uploading files to %album_count% new albums.',

    // Uploading folder to albums
    create_albums_prompt: 'Create albums from folders?',
    create_album_prompt: 'Create an album?',
    also_create_albums__format:
        'You are uploading a folder named “%album_name%”. Should we create an album with this content as well?',
    also_create_albums_multiple__format:
        'You are uploading %d folders. Should we also create albums for these?',

    // misc album
    fetching_albums: 'Fetching albums',

    // Comments
    comment: 'comment',
    comments: 'Comments',
    write_your_comment: 'Write your comment',
    comment_error_message: 'Error posting comment. Please try again.',
    more: 'More',
    edited: 'Edited',
    edit_comment_error: 'Could not edit comment',
    delete_comment_error: 'Could not delete comment',

    view_all_comments: 'View all comments',

    what_is_your_name: "What's your name?",
    how_would_you_continue: 'How would you prefer to continue?',
    hey: 'Hey',
    tap_here_to_enter_name: 'Tap here to enter name',
    click_here_to_enter_name: 'Enter name here',
    enter: 'enter',
    register_or_sign_in: 'Register / Sign in',
    continue_as_guest: 'Continue as Guest',
    provide_name_description__format:
        'Let others in the album know who you are by continuing with a %product_name% account.',

    year_ago__format: '%d year ago',
    years_ago__format: '%d years ago',
    month_ago__format: '%d month ago',
    months_ago__format: '%d months ago',
    day_ago__format: '%d day ago',
    days_ago__format: '%d days ago',
    hour_ago__format: '%d hour ago',
    hours_ago__format: '%d hours ago',
    minute_ago__format: '%d minute ago',
    minutes_ago__format: '%d minutes ago',
    just_now: 'just now',
    post: 'Post',

    // C A R O U S E L   V I E W
    slideshow: 'Slideshow',
    play: 'Play',
    pause: 'Pause',
    previous: 'Previous',
    next: 'Next',
    exit_fullscreen: 'Exit',
    info: 'Info',

    // F I L E  I N F O
    file_info: 'File information',

    // S E T T I N G S
    // profile
    profile_info: 'Profile info',
    enter_your_name: 'Enter your name',
    set_your_name: 'Set your name',
    update_profile_picture: 'Update Profile Picture',

    // storage
    other_sources: 'other sources',
    current_plan: 'current subscription',
    storage_management: 'Storage Management',
    manage_storage: 'Manage storage',
    total_storage: 'Total storage',
    remaining_storage: 'Remaining',
    used_storage: 'Used',
    buy_more_storage: 'Buy more storage',
    buy_more_storage_install_app:
        'In your location it is only possible to buy storage in Capture app. Please download the app, go to settings and tap “Buy more storage”.',
    buy_more_storage_open_app:
        'In your location it is only possible to buy storage in Capture app.',
    connected_devices: 'Logged in devices',
    unlimited: 'Unlimited',
    your_storage_plan: 'Your Subscription',
    next_billing_date__format: 'Next billing date: %date%',
    expires_date__format:
        'Subscription is cancelled. Storage expires on %date%',
    plan_is_canceled: 'You have cancelled this subscription',
    has_credit:
        'You have %credit_amount% credit in %product_name%. Credit will be automatically applied to future payments.',
    manage_plan: 'Manage',
    storage_used_out_of__format: '%storage_used% used out of %storage_total%',
    upgrade_storage_plan: 'Upgrade subscription',
    cancel_storage_plan: 'Cancel subscription',
    renew_status__format: 'Subscription will be renewed on %s',
    expired_status__format: 'Subscription will expire on %s',
    reactivate_storage_plan: 'Reactivate subscription',
    choose_subscription: 'Choose a subscription',
    subscribe_to_product: 'Subscribe to %product_name%',

    // storage sources
    storage_source_capture: 'Granted by %product_name%',
    storage_source_b2b_customer__format: 'Only for %BU_name% customers',
    storage_source_web: 'Purchased on web',
    storage_source_apple_store: 'In-app purchase iTunes',
    storage_source_googlePlay_store: 'In-app purchase Google Play Store',
    storage_source_connect_store: 'Purchased on old web store',
    storage_source_other: 'Other sources',
    storage_source_customer_service: 'Granted by Customer Support',
    storage_source_b2b__format: 'Granted by %BU_name%',
    free__as_in_no_cost: 'Free',

    details_unlimited_storage: 'You have unlimited storage!',
    details_text_unlimited_storage_1__format:
        'As a %BU_name% customer, you are entitled to %product_name%’s Unlimited storage with no extra cost, so you never have to worry about running out of space!',
    details_text_unlimited_storage_2__format:
        'Still curious about storage prices for non-%BU_name% customers? See below:',

    // payment settings
    payment_settings: 'Payment Settings',
    payment_settings_info_text: 'Your registered card information:',
    change_card_info: 'Change card info',
    delete_card: 'Delete card',
    update_card_info: 'Update card info',
    card_number: 'Card number',
    card_exp_date: 'Expiration date',
    card_cvc: 'CVC/CVV',
    confirm_payment: 'Confirm payment',

    // Buy more storage
    save__format: 'Save %d',
    get_started: 'Get Started!',
    telenor_business_discount_hook_header: 'Telenor Business customer?',
    telenor_business_discount_hook:
        'If you are a Telenor Business customer, you are eligible for discounted prices.',
    telenor_business_discount_hook_link: 'Go to %telenor_mine_sider%.',
    telenor_mine_sider: 'Telenor Mine sider',
    mine_sider: 'Mine sider',
    telenor_unlimited_hook__format:
        'Telenor Mobile customers have access to unlimited storage in %product_name%',
    telenor_unlimited_hook_subtext:
        'Norway: Provided with all private mobile subscriptions, except U11, and Frihet/Flyt/Fri+ business subscriptions.',
    monthly_sub: 'Monthly Plans',
    yearly_sub: 'Yearly Plans',
    email_request_explanation:
        'Email address will be used for updates regarding your subscription',
    fetch_storage_plan_failed:
        'We could not reliably retrieve your subscription',
    with_free_trial_detail:
        '%num_free_trial% months free trial, %price_string% per %period% after that',
    downgrade_refund_detail:
        'You will get refunded with credit in %product_name% that will be applied on future billing',
    without_free_trial_detail: 'You will pay %price_string% per %period%',
    choose_subscription_plan_first: 'Choose a subscription first',
    choose_subscription_plan_to_see_details:
        'Choose one of the options to see the details',
    choose_subscription_plan_to_start_free_trial:
        'Select one of the payment plans above to start your free trial',
    change_subscription_plan: 'Change subscription',
    subscribe_with_free_trial: 'Get %num_free_trial% months free',
    proceed_to_pay: 'Proceed to pay',
    select_plan_to_see_details: 'Select a plan to see details.',
    subscribe_to_receive: 'Choose a subscription to get started and receive a',
    months_free_trial: '%num_free_trial% month free trial',
    learn_more: 'Learn more',
    subscription_notes1:
        'Plans smaller than your current usage are not available',

    // Update popup
    update_pop_header:
        'We have done a security update in the %product_name% app.',
    update_pop_body:
        'Please make sure you have updated to the latest app version (%version_number%) in the App Store or Play Store on your phone',

    // select language
    language: 'Language',
    select_language: 'Select language',

    // privacy
    privacy_info:
        'Review your data and take control of your settings all in one place.',
    privacy: 'Privacy',
    privacy_statement: 'Privacy Statement',
    open_privacy_pages: 'Open Privacy Pages',

    consents: 'Consents',
    facial_recognition: 'Facial recognition',
    privacy_desc__can_process_images_categorisation__format:
        '%company_name% can process my images to provide content categorisation. This includes grouping similar faces together.',

    face_grouping: 'Face grouping',
    privacy_desc__can_process_face_details__format:
        '%company_name% can process face details in images to provide content categorisation. This includes grouping similar faces together.',

    advanced_filtering_and_categorization:
        'Advanced filtering and categorisation',
    privacy_desc__can_process_images_advanced_filtering__format:
        '%company_name% can process the contents of images to provide features such as advanced filtering and presenting sets of photos as memories.',

    help_us_improve: 'Help us improve',
    privacy_desc__can_process_data_for_improvement__format:
        '%company_name% can process data about how I use their services to improve their products e.g. how often I use the app.',

    stay_up_to_date: 'Stay up to date',
    privacy_desc__let_you_know_about_updates:
        "We want to let you know about updates, new features, and great new products and services we offer. We'll also send you helpful tips to improve your experience, and other relevant suggestions.",
    can_contact_me_via__format: '%company_name% can contact me via:',
    push_notification: 'Push Notification',

    access_your_data: 'Access your data',
    privacy_desc__processes_with_privacy_policy__format:
        '%company_name% processes certain categories of user data in line with the purposes detailed in our %link_start%Privacy Policy%link_end%.',
    privacy_desc__download_personal_information__format:
        'You can download a copy of the personal information we hold about you here.',
    privacy_request_a_copy_of_data: 'Request a copy of your personal data',
    privacy_download_all:
        'You can download all your photos, videos, and albums from Capture to your Mac/PC. This is useful when you want to have all your photos on your computer, transfer them to an external hard drive, or back them up in a different cloud service.',
    privacy_download_all_button: 'Download all from Capture',
    per_gdpr_right_to_request:
        'Per the GDPR you have the right to request a copy of all data we store about you.',
    if_you_continue_metadata_download__format:
        'If you continue, we will package all your metadata and provide it for download. You can only make one request every %d hours.',
    metadata_recently_downloaded_please_wait__format:
        'You have recently downloaded your metadata. You can only make one request every %d hours.',

    you_can_request_delete_account__format:
        'You can request that %company_name% delete your account, including all content and data we hold about you.',
    delete_account_includes_irreversible_deletion:
        'This will include the irreversible deletion of all photo and video content in your Capture account, so make sure you take a backup before making this request.',
    it_may_take_up_to_days__format:
        'It may take up to %d days for us to complete this request.',
    request_deletion: 'Request Deletion',
    delete_account: 'Delete Account',
    delete_your_account: 'Delete your Capture account',
    delete_your_account__info1:
        'You can request that Telenor Software Lab AS delete your account, including all content and data we hold about you.',
    delete_your_account__info2:
        'This will include the irreversible deletion of all photo and video content in your Capture account, so make sure you take a backup before making this request.',
    delete_your_account__info3:
        'It may take up to 30 days for us to complete this request.',
    delete_your_account__info4:
        'Deleting your Capture account will permanently delete all your photos and videos, and any other information on your Capture account.',
    delete_your_account__info5:
        'This is irreversible so please make sure you have another backup of your photos and videos before you proceed.',
    delete_your_account__info6:
        'When you click “Delete account” you will be logged out of all devices.',

    delete_your_account__success1:
        'Your request for deletion has been received.',
    delete_your_account__success2__format:
        'You have been logged out from all devices, and we will process your request for deletion within %d days. You will receive an email once your request is complete.',
    delete_your_account__failed1:
        'Something went wrong when we processed your request for deletion.',
    generic_explanation_for_why_it_may_failed:
        'Your connection to Internet may be interrupted, or we may have a temporary problem on our servers.',

    // TAKEOUT PAGE
    takeout_file: 'file',
    takeout_files: 'files',
    capture_settings: 'Capture Settings',
    takeout_mobile_text:
        'This page is not available on mobile devices, please use a Mac/PC.',
    takeout_download_all_title: 'Download all photos, videos, and albums',
    takeout_download_all_body: `Download all your photos, videos, and albums from
                        Capture to your Mac/PC. This is useful when you want to
                        have all your files on your computer, transfer them to
                        an external hard drive, or back them up in a different
                        cloud service. For questions about downloading, visit
                        the %support_url% page. For information about our
                        subscriptions, go to %capture_settings%.`,
    takeout_subheader_photos: 'Capture Photos',
    takeout_body_photos: `Download all the photos and videos which are backed up
                        in your Capture Photos tab. This will include any
                        documents you may have, but does not include albums. All
                        photos and videos will be downloaded in their original
                        format. For more information on formats, visit our %support_url% page.`,

    takeout_subheader_albums: 'Capture Albums',
    takeout_body_albums: `Below is a list of all the private and shared albums in
                        your Capture Albums tab. Click the ones you would like
                        to download.`,
    takeout_stats:
        'You have %num_files files (%total_size) in your Capture Photos.',
    takeout_download_button: 'Download all from Capture photos',

    // SUBSCRIBE MODAL
    subscribe_title: 'Subscribe to %product_name%',
    subscribe_welcome_text: 'Subscribe to %product_name% today and receive a ',
    subscribe_welcome_text2:
        'You can learn more about %product_name% %faq_pages%.',
    subscribe_free: 'FREE',
    subscribe_sub: 'PAID',
    subscribe_text1: 'Upload photos and videos',
    subscribe_text2: 'Organize photos in albums',
    subscribe_text3: 'Share photos and albums',
    subscribe_text4: 'Edit photos and videos',
    subscribe_text5: 'Join shared albums',
    subscribe_accept_tos: 'I accept the Capture %terms_of_service%',
    subscribe_confirm: 'Choose a subscription',
    subscribe_skip: 'Continue without a subscription',
    subscribe_reconsider: 'See subscription plans',
    subscribe_error: 'You must accept the Terms of Service',
    no_subscription_title: 'No subscription',
    no_subscription_limit:
        'Using Capture without a subscription only lets you participate in albums others have shared with you.',
    no_subscription_reconsider:
        'You can get a subscription later or manage your account from Settings.',
    enter_payment_information: 'Enter payment information',
    business_users:
        '*Telenor Business customers may be eligible for discounted prices. See %telenor_mine_sider%.',

    // UNSUBSCRIBE PAGE
    unsubscribe_from_email_form_title: 'Unsubscribe from Capture emails',
    unsubscribe_from_email_form_text1:
        'We send one email a month with helpful suggestions, tips, and new app features.',
    unsubscribe_from_email_form_text2:
        'Are you sure you want to unsubscribe from our mailing list?',

    unsubscribe_from_email_form_button_stay: 'Stay on the list!',
    unsubscribe_from_email_form_button_unsubscribe: 'Unsubscribe me',

    unsubscribe_from_email_success_title: 'Unsubscribe successful!',
    unsubscribe_from_email_success_text:
        'You can change your preferences anytime in the “Privacy settings” in Capture.',
    unsubscribe_from_email_stay_title:
        'Your Capture email settings have been saved!',
    unsubscribe_from_email_stay_text:
        'You can change your preferences anytime in the “Privacy settings” in Capture.',

    // I N F O
    // Download app page
    thanks_for_signing_in: 'Thanks for signing in!',
    get_capture_to_create_album:
        'Get Capture on your mobile device to create your own Albums.',
    return_to_album: 'Return to the album',

    // Footer
    telenor_capture_text:
        "Capture is developed by Telenor Digital, a Telenor company that provides tomorrow's digital solutions. The Connect account is used to log in to services provided by Telenor Digital.",
    terms_of_service: 'Terms of Service',
    customer_service: 'Customer Service',
    capture_footer_text_mobile: 'View in Capture App for best experience',
    open: 'open',
    legal: 'Legal',

    // Album Not Found
    album_not_found: 'Album not found',
    album_not_found_description:
        'Sorry! We were unable to find the Album. You may have clicked an expired, deleted, or mistyped link.',
    capture_create_user_description:
        'Capture is a safe and easy-to-use storage service for pictures and videos. If you are not a Capture user, create an account today.',
    capture_more_link: 'Learn more about Capture',

    // Share Not Found
    share_not_found: 'Share not found',
    share_not_found_description:
        'You may have clicked an expired, deleted, or mistyped link.',

    // Page Not Found
    page_not_found: "We can't seem to find the page you're looking for!",
    oops_page_not_found: 'Oops! Page not found.',
    helpful_links_description: 'Here are some helpful links instead',
    helpful_links_about_capture: 'About Capture',

    // Provide Password
    password_required: 'Password required',
    password_input_placeholder: 'Click here to enter password',
    password_required_submit: 'enter',
    password_incorrect: 'Incorrect password',
    album_password_required_description:
        'The sender has protected this Album with a password. Please enter the password in the box above to unlock the Album.',

    // Require login to perform action
    needs_login_subscribe_to_album:
        'You need a %product_name% account in order to receive updates from this album',
    needs_connect_to_create_album:
        'You need a %product_name% account with a subscription to create albums',
    needs_login_to_copy_files_to_timeline:
        'You need a %product_name% account with a subscription to save these files',
    needs_login_to_copy_album_to_timeline:
        'You need a %product_name% account with a subscription to save files from this album',
    needs_login_to_view_share:
        'You need to be logged in to %product_name% to view this share',

    // Edit/Create album page
    no_title_yet: 'No title yet',
    add_album_title: 'Enter album title',

    // Suggest Modern Browser
    upgrade_your_browser: 'Please upgrade your browser',
    upgrade_your_browser_des:
        'Sorry! This content can’t be viewed in your current browser. Upgrade or use a different browser like Chrome, Firefox or Microsoft Edge.',

    // Album settings
    share_album: 'Share album',
    edit_album_settings: 'Edit album settings',
    enabled: 'Enabled',
    disabled: 'Disabled',
    privacy_private: 'Private',
    privacy_shared: 'Shared',
    edit_album_title_text: 'Album title (Tap to change):',
    edit_allow_comments_text: 'Anyone can view and add comments',
    edit_allow_upload_text: 'Anyone can add photos',
    edit_privacy_mode_text: 'Privacy level',

    // Toasts
    toast__coverPhoto_was_set: 'Cover photo changed',
    set_cover_photo_failed_message: 'Failed to change cover photo.',
    toast__delete_album_failed_message: 'Failed to delete the album.',
    toast__unsubscribe_album_failed_message: 'Failed to leave the album.',

    toast__multiple_files_deleted__format: '%d files deleted',
    toast__single_file_deleted: 'File deleted',
    delete_file_failed_message: 'Failed to delete file',
    toast__delete_multiple_files_failed__format: 'Failed to delete %d files',

    toast__multiple_files_perm_deleted__format: '%d files permanently deleted',
    toast__single_file_perm_deleted: 'File permanently deleted',
    toast__multiple_files_perm_deleted_failed__format:
        'Error - %d files not permanently deleted',
    toast__single_file_perm_deleted_failed:
        'Error - file not permanently deleted',

    toast__single_file_restored: 'File restored',
    toast__multiple_files_restored__format: '%d files restored',
    toast__single_file_restored_failed: 'Error - file not restored',
    toast__multiple_files_restored_failed__format:
        'Error - %d files not restored',

    toast__single_file_added_to_album__format:
        'File added to album %album_name%',
    toast__multiple_files_added_to_album__format:
        '%d files added to album %album_name%',
    toast__single_file_added_to_album_failed__format: 'Error - file not added',
    toast__multiple_file_added_to_album_failed__format:
        'Error - %d files not added',

    toast__album_auto_crated__format:
        'Album "%album_name%" was created for you',
    toast__see_album: 'See album',
    toast__album_added_to_timeline: 'Album added to Photos',
    toast__album_added_to_timeline_failed:
        'Failed to add album - please try again',
    toast__album_changed_to_private: 'Album changed to Private',
    toast__file_added_to_timeline: 'File added to Photos',
    toast__multiple_files_added_to_timeline: 'Files added to Photos',
    toast__file_added_to_timeline_failed:
        'Failed to add file - please try again',
    toast__files_were_shared: 'Your files were shared!',

    toast__files_are_deleting: 'Deleting files...',
    toast__files_are_restoring: 'Restoring files...',
    toast__files_are_copying: 'Copying files...',
    toast__preparing_to_download: 'Preparing to download...',

    toast__credit_card_updated: 'Payment card info updated',
    toast__credit_card_update_failed: 'Failed to update info',

    toast__purchase_successful: 'Subscription purchased',
    toast__purchase_failed: 'Failed to purchase subscription',

    toast__plan_canceled: 'Subscription canceled',
    toast__plan_cancel_failed: 'Failed to cancel subscription',

    toast__plan_change_succeeded: 'Subscription successfully updated',
    toast__plan_change_failed: 'Failed to update subscription',

    toast__plan_reactivated: 'Subscription reactivated',
    toast__plan_reactivation_failed: 'Failed to reactivate subscription',

    toast__delete_user_card_success: 'Payment card deleted',
    toast__delete_user_card_failed: 'Failed deleting card info',

    toast__change_profile_picture_failed: 'Failed to update profile picture',

    toast__selection_count_limit_reached: 'You have selected too many photos',
    toast__file_copied_to_clipboard: 'Copied to clipboard!',
    toast__file_copy_to_clipboard_failed: 'Failed to copy!',

    toast__change_user_name_failed: 'Failed to update profile name',

    // Trash
    photos_and_videos: 'Photos and videos',
    trash_delete_info:
        'Deleted files will be permanently deleted after 30 days.',
    trash_no_files: "You don't have any deleted files.",
    trash_restore_info: 'You can restore them any time before that!',
    deleted_today: 'Deletes today',
    permanently_deleted_soon: 'Permanently deleted soon',
    recently_deleted: 'Recently deleted',

    // Documents
    documents_no_files: "You don't have any documents.",

    // Share files popup
    share_as_album: 'Share as album',
    share_album_explanation:
        'Create an album with all the photos you want to share. Everyone you share it with can like, comment, and add their photos as well.',
    share_get_link_explanation:
        'Share photos with a temporary link. The link expires after 30 days.',
    share_select_how_to: 'Select how you would like to share',
    share_album_created_link:
        'Album created! Share album using one of the options below:',
    share_disclaimer:
        'When you share an album, anyone with the link will be able to view it, even if not personally invited by you.',
    my_new_shared_album: 'My new shared album',
    share_to: 'Share by',
    email: 'Email',
    SMS: 'SMS',
    get_link: 'Get link',
    hold_to_copy_link: 'Press and hold to copy link',
    copy_link: 'Copy link',
    link_copied: 'Link was copied!',
    share_files_success_header: 'You’ve shared an album!',
    can_not_share_video_to_fb_album:
        'Ops! Videos can not be shared to Facebook Album',
    share_stop_sharing: 'Do you want to stop sharing these files?',
    share_login_to_stop_sharing:
        'If you have shared these files and wish to stop sharing them, you must first %link_start%login%link_end%.',

    // share album form
    allow_others_comment: 'Allow others to comment on photos',
    allow_others_upload: 'Allow others to upload photos',

    // email sharing form
    send_email_to: 'Send email to:',
    enter_receivers_address: "Enter receiver's address here",
    email_subject: 'Subject:',
    enter_subject: 'Enter subject here',
    email_message: 'Message (Optional):',
    enter_message: 'Enter your message here',
    invalid_input: 'Invalid',

    // S H A R E   R E C E I V E R
    share__header_SingleFile__format:
        '%user_name% has shared a file with you using %product_name%',
    share__header_MultipleFiles__format:
        '%user_name% has shared some files with you using %product_name%',
    share__header_SingleFile_owner__format:
        'You have shared a file using %product_name%',
    share__header_MultipleFiles_owner__format:
        'You have shared some files using %product_name%',
    share__add_singleFile_to_capture__format: 'Add photo to %s',
    share__add_multipleFiles_to_capture__format: 'Add photos to %s',
    download_all: 'Download all',
    share_added_to_timeline: 'Files were added',
    share_added_to_timeline_failed: 'Failed to add files - please try again',
    share__stop: 'Stop sharing',
    share__confirm_stop: 'Are you sure you want to stop sharing?',
    share__deletion_failed: 'Failed to stop sharing files',
    share__deleted_SingleFile: 'File is no longer shared',
    share__deleted_MultipleFiles: 'Files are no longer shared',
    share_failed: 'Failed to share files - please try again',
    share__not_capture_user: 'Not a Capture user?',
    share__try_capture: 'Click here to give it a try!',
    share__service_info_part1__format:
        '%product_name% is made to keep your photos and videos safe! It is the easiest way to automatically backup photos and videos from your phone, so you can access them anytime anywhere, and share them with your friends and family.',
    share__service_info_part2__format:
        'Sign up to %product_name% and get 2 months of free usage. If you are a Telenor Norway mobile customer, you are likely to have unlimited storage in %product_name% included in your subscription.',
    share__service_info_part2__minSky__format:
        'Sign up to %product_name% and get 2 months of free usage. If you have a private mobile subscription or a Frihet/Flyt/Fri+ business subscription from Telenor Norway, you have free unlimited storage in %product_name% included. Send an SMS to 1999 if your free storage is not yet activated.',
    share__service_info_footer__format:
        'Provided with all private mobile subscriptions and Frihet, Flyt and Fri+ business subscriptions.',
    // Downloading share content on iOS
    share__download_to_ios_image_gallery: 'Download to image gallery',
    share__download_to_ios_image_gallery_desc1:
        'To download in full resolution to your image gallery, press and hold the image below and select "Save image" in the menu that appears.',
    share__download_to_ios_image_gallery_desc2:
        'Repeat for each of the shared images.',
    share__download_to_ios_files: 'Download to files',
    share__download_to_ios_files_desc:
        'The button below downloads a zip-file containing all the shared images. On iOS this file becomes available in the Files-app after download.',

    // Password required
    share_password_required_description:
        'The sender has protected this Share with a password. Please enter the password in the box above to view the Share.',

    // Select from external page
    choose_photo_selection_source: 'Select photos from ',
    all_photos: 'All Photos',

    // user hints
    hint_longpress_show_full_image: 'Press and hold picture to show full image',
    editor_hint_body: 'Add filters, crop photos, and more!',

    // Tooltip
    readonly_tooltip: 'Subscription required for full access.',

    // Sunset
    deletion_prompt__title: 'Changes to your Capture terms',
    deletion_prompt__body:
        'Capture will become a paid service on %delete_date%. To keep your photos and videos and continue using Capture, see our subscription plans. The first 6 months are free of charge.',
    deletion_prompt__more_info: 'More information',
    deletion_prompt__plans: 'Subscription plans',

    // casting
    cast: 'Cast',
    casting_to: 'Casting to %s',

    // consent
    cookie_explanation__format:
        '%product_name% uses cookies in order to analyze site usage and improve user experience. Some of the information gathered is used by third parties.',
    cookie_explanaion_link__format:
        'Read our %link_start%Privacy Statement%link_end% to learn more about how we use cookies.',

    account_terminated_error: 'The account has been terminated',
    account_terminated_desc__format:
        'The account has been terminated by its user and is therefore no longer accessible. Please contact our %link_start%customer service%link_end% for questions or assistance.',

    // PhotoEditorSDK
    common_error: 'Error',
    common_warning: 'Warning',
    common_color_colorPicker_hex: 'Hex',
    common_color_colorPicker_r: 'R',
    common_color_colorPicker_g: 'G',
    common_color_colorPicker_b: 'B',
    common_color_colorPicker_sliderHue: 'Color',
    common_color_colorPicker_sliderOpacity: 'Color Opacity',
    mainCanvasActions_buttonExport: 'Save',
    mainCanvasActions_buttonUndo: 'Undo',
    mainCanvasActions_buttonRedo: 'Redo',
    mainCanvasActions_buttonClose: 'Close',
    infoModals_exporting_heading: 'Exporting...',
    infoModals_exporting_body: 'Just a few seconds...',
    infoModals_saving_heading: 'Saving...',
    infoModals_saving_body: 'Just a few seconds...',
    infoModals_loading_heading: 'Loading...',
    infoModals_loading_body: 'Just a few seconds...',
    infoModals_resizing_heading: 'Resizing...',
    infoModals_resizing_body: 'Just a few seconds...',
    infoModals_loadingFonts_heading: 'Loading Fonts...',
    infoModals_loadingFonts_body: 'Just a few seconds...',
    infoModals_stickerLoading_heading: 'Loading Sticker...',
    infoModals_stickerLoading_body: 'Just a few seconds...',
    errorModals_imageLoading_headingDelimiter: ':',
    errorModals_imageLoading_body:
        'Failed to load image. This can have multiple reasons, e.g. the file is corrupted or the file type is not supported',
    errorModals_imageLoading_buttonYes: 'Retry',
    errorModals_imageLoading_buttonNo: 'Close',
    errorModals_rendering_headingDelimiter: ':',
    errorModals_rendering_body:
        'An error has occurred while rendering the image',
    errorModals_rendering_buttonYes: 'Retry',
    errorModals_rendering_buttonNo: 'Close',
    errorModals_fontLoading_headingDelimiter: ':',
    errorModals_fontLoading_heading: 'Failed to load font',
    errorModals_fontLoading_body:
        'The following fonts could not be loaded: ${error}',
    errorModals_fontLoading_buttonNo: 'Close',
    errorModals_webcamUnavailable_headingDelimiter: ':',
    errorModals_webcamUnavailable_body:
        'Unable to display webcam image (Error: ${error})',
    errorModals_webcamUnavailable_buttonYes: 'Close',
    errorModals_stickerLoading_headingDelimiter: ':',
    errorModals_stickerLoading_body: 'Unable to load the sticker',
    errorModals_stickerLoading_buttonNo: 'Close',
    errorModals_unexpectedError_headingDelimiter: ':',
    errorModals_unexpectedError_body:
        'An unexpected error has occured ${error}',
    errorModals_unexpectedError_buttonYes: 'Retry',
    errorModals_unexpectedError_buttonNo: 'Close',
    warningModals_imageResized_headingDelimiter: ':',
    warningModals_imageResized_heading: 'Image resized',
    warningModals_imageResized_body:
        'Your image exceeds the maximum size of ${megapixels} megapixels and has been resized to ${width}x${height} pixels',
    warningModals_imageResized_buttonYes: 'Continue',
    warningModals_unsupportedSerializationVersion_headingDelimiter: ':',
    warningModals_unsupportedSerializationVersion_body:
        'Your data was restored from a legacy data format and might look different',
    warningModals_unsupportedSerializationVersion_buttonYes: 'Apply changes',
    warningModals_unsupportedSerializationVersion_buttonNo: 'Cancel',
    warningModals_discardChanges_headingDelimiter: ':',
    warningModals_discardChanges_body:
        'You have unsaved changes. Are you sure you want to discard the changes?',
    warningModals_discardChanges_buttonYes: 'Discard changes',
    warningModals_discardChanges_buttonNo: 'Keep Changes',
    warningModals_unsavedChanges_headingDelimiter: ':',
    warningModals_unsavedChanges_body:
        'You have unsaved changes. Are you sure you want to exit?',
    warningModals_unsavedChanges_buttonYes: 'Exit without saving',
    warningModals_unsavedChanges_buttonNo: 'Cancel',
    warningModals_unsupportedWebGLRenderer_body:
        'A problem has been detected in the browser that could cause long loading times. Please try a different browser.',
    warningModals_unsupportedWebGLRenderer_buttonYes: 'Continue',
    library_title: 'Library',
    library_controls_buttonUpload: 'Upload Image',
    library_controls_buttonWebcamOpen: 'Open Webcam',
    library_controls_buttonWebcamClose: 'Close Webcam',
    library_controls_placeholderSearch: 'Search Library',
    library_controls_noResults: 'No Results',
    filter_title: 'Filters',
    filter_controls_buttonReset: 'Remove Filter',
    filter_controls_sliderIntensity: 'Filter Intensity',
    filter_categories_imgly_filter_category_duotone: 'DuoTone',
    filter_categories_imgly_filter_category_bw: 'B & W',
    filter_categories_imgly_filter_category_vintage: 'Vintage',
    filter_categories_imgly_filter_category_smooth: 'Smooth',
    filter_categories_imgly_filter_category_cold: 'Cold',
    filter_categories_imgly_filter_category_warm: 'Warm',
    filter_categories_imgly_filter_category_legacy: 'Legacy',
    filter_items_imgly_lut_celsius: 'Inferno',
    filter_items_imgly_lut_chest: 'Chestnut',
    filter_items_imgly_lut_fixie: 'Fixie',
    filter_items_imgly_lut_fridge: 'Fridge',
    filter_items_imgly_lut_front: 'Sunny 70s',
    filter_items_imgly_lut_k2: 'Flat Black',
    filter_items_imgly_lut_mellow: 'Mellow',
    filter_items_imgly_lut_sin: 'Hard Stuff',
    filter_items_imgly_lut_texas: 'Oldtimer',
    filter_items_imgly_lut_ad1920: '1920 A.D.',
    filter_items_imgly_lut_ancient: 'Ancient',
    filter_items_imgly_lut_bleached: 'Kalmen',
    filter_items_imgly_lut_bleachedblue: 'Joran',
    filter_items_imgly_lut_blues: 'Polaroid',
    filter_items_imgly_lut_blueshadows: 'Zephyr',
    filter_items_imgly_lut_breeze: 'Levante',
    filter_items_imgly_lut_bw: 'Greyed',
    filter_items_imgly_lut_classic: 'Classic',
    filter_items_imgly_lut_colorful: 'Colorful',
    filter_items_imgly_lut_cool: 'Snappy',
    filter_items_imgly_lut_cottoncandy: 'Candy',
    filter_items_imgly_lut_creamy: 'Creamy',
    filter_items_imgly_lut_eighties: 'Low Fire',
    filter_items_imgly_lut_elder: 'Colla',
    filter_items_imgly_lut_evening: 'Sunrise',
    filter_items_imgly_lut_fall: 'Moss',
    filter_items_imgly_lut_food: 'Food',
    filter_items_imgly_lut_glam: 'Glam',
    filter_items_imgly_lut_gobblin: 'Gobblin',
    filter_items_imgly_lut_highcarb: 'High Carb',
    filter_items_imgly_lut_highcontrast: 'Hicon',
    filter_items_imgly_lut_k1: 'K1',
    filter_items_imgly_lut_k6: 'K6',
    filter_items_imgly_lut_kdynamic: 'Pebble',
    filter_items_imgly_lut_keen: 'Keen',
    filter_items_imgly_lut_lenin: 'Lemon',
    filter_items_imgly_lut_litho: 'Litho',
    filter_items_imgly_lut_lomo: 'Lomo',
    filter_items_imgly_lut_lomo100: 'Lomo 100',
    filter_items_imgly_lut_lucid: 'Lucid',
    filter_items_imgly_lut_neat: 'Neat',
    filter_items_imgly_lut_nogreen: 'Pumpkin',
    filter_items_imgly_lut_orchid: 'Solanus',
    filter_items_imgly_lut_pale: 'Pale',
    filter_items_imgly_lut_pitched: 'Pitched',
    filter_items_imgly_lut_plate: 'Weathered',
    filter_items_imgly_lut_pola669: 'Green Gap',
    filter_items_imgly_lut_polasx: 'Pola SX',
    filter_items_imgly_lut_pro400: 'Pro 400',
    filter_items_imgly_lut_quozi: 'Quozi',
    filter_items_imgly_lut_sepiahigh: 'Sepia',
    filter_items_imgly_lut_settled: 'Settled',
    filter_items_imgly_lut_seventies: 'Seventies',
    filter_items_imgly_lut_soft: 'Soft',
    filter_items_imgly_lut_steel: 'Steel',
    filter_items_imgly_lut_summer: 'Summer',
    filter_items_imgly_lut_sunset: 'Golden',
    filter_items_imgly_lut_tender: 'Tender',
    filter_items_imgly_lut_twilight: 'Twilight',
    filter_items_imgly_lut_winter: 'Softy',
    filter_items_imgly_lut_x400: 'Dusty',
    filter_items_imgly_duotone_desert: 'Desert',
    filter_items_imgly_duotone_peach: 'Peach',
    filter_items_imgly_duotone_clash: 'Clash',
    filter_items_imgly_duotone_plum: 'Plum',
    filter_items_imgly_duotone_breezy: 'Breezy',
    filter_items_imgly_duotone_deepblue: 'Deep Blue',
    filter_items_imgly_duotone_frog: 'Frog',
    filter_items_imgly_duotone_sunset: 'Sunset',
    adjustment_title: 'Adjust',
    adjustment_controls_buttonReset: 'Reset Adjustment',
    adjustment_categories_basics: 'Basic',
    adjustment_categories_refinements: 'Refinements',
    adjustment_items_brightness: 'Brightness',
    adjustment_items_saturation: 'Saturation',
    adjustment_items_contrast: 'Contrast',
    adjustment_items_gamma: 'Gamma',
    adjustment_items_sharpness: 'Sharpness',
    adjustment_items_clarity: 'Clarity',
    adjustment_items_exposure: 'Exposure',
    adjustment_items_shadows: 'Shadows',
    adjustment_items_highlights: 'Highlights',
    adjustment_items_whites: 'Whites',
    adjustment_items_blacks: 'Blacks',
    adjustment_items_temperature: 'Temperature',
    focus_title: 'Focus',
    focus_controls_buttonReset: 'Remove Focus',
    focus_controls_sliderIntensity: 'Focus Intensity',
    focus_items_radial: 'Radial',
    focus_items_mirrored: 'Mirrored',
    focus_items_linear: 'Linear',
    focus_items_gaussian: 'Gaussian',
    focus_history_focusPosition: 'Focus Position',
    focus_history_focusSize: 'Focus Size',
    overlay_title: 'Overlays',
    overlay_controls_buttonReset: 'Remove Overlay',
    overlay_controls_sliderOpacity: 'Overlay Opacity',
    overlay_controls_carouselBlendMode: 'Overlay Blend mode',
    overlay_controls_blendModeNormal: 'Normal',
    overlay_controls_blendModeOverlay: 'Overlay',
    overlay_controls_blendModeHardLight: 'Hard Light',
    overlay_controls_blendModeSoftLight: 'Soft Light',
    overlay_controls_blendModeMultiply: 'Multiply',
    overlay_controls_blendModeDarken: 'Darken',
    overlay_controls_blendModeLighten: 'Lighten',
    overlay_controls_blendModeScreen: 'Screen',
    overlay_controls_blendModeColorBurn: 'Color Burn',
    overlay_controls_tabOpacity: 'Opacity',
    overlay_controls_tabBlendMode: 'Blend Mode',
    overlay_items_imgly_overlay_bokeh: 'Bokeh',
    overlay_items_imgly_overlay_chop: 'Chop',
    overlay_items_imgly_overlay_clouds: 'Clouds',
    overlay_items_imgly_overlay_golden: 'Golden',
    overlay_items_imgly_overlay_grain: 'Grain',
    overlay_items_imgly_overlay_hearts: 'Hearts',
    overlay_items_imgly_overlay_lightleak1: 'Light Leak 1',
    overlay_items_imgly_overlay_lightleak2: 'Light Leak 2',
    overlay_items_imgly_overlay_metal: 'Metal',
    overlay_items_imgly_overlay_mosaic: 'Mosaic',
    overlay_items_imgly_overlay_painting: 'Painting',
    overlay_items_imgly_overlay_paper: 'Paper',
    overlay_items_imgly_overlay_rain: 'Rain',
    overlay_items_imgly_overlay_vintage: 'Vintage',
    overlay_items_imgly_overlay_wall1: 'Wall',
    overlay_items_imgly_overlay_wall2: 'Wall 2',
    overlay_items_imgly_overlay_wood: 'Wood',
    sticker_title: 'Stickers',
    sticker_controls_buttonUpload: 'Upload Sticker',
    sticker_controls_sliderOpacity: 'Sticker Opacity',
    sticker_controls_selectColor: 'Sticker Color',
    sticker_controls_tabColor: 'Color',
    sticker_controls_tabOpacity: 'Opacity',
    sticker_categories_imgly_sticker_emoticons: 'Emoticons',
    sticker_categories_imgly_sticker_shapes: 'Shapes',
    sticker_categories_imgly_sticker_custom: 'Custom',
    sticker_items_imgly_sticker_emoticons_alien: 'Alien',
    sticker_items_imgly_sticker_emoticons_angel: 'Angel',
    sticker_items_imgly_sticker_emoticons_angry: 'Angry',
    sticker_items_imgly_sticker_emoticons_anxious: 'Anxious',
    sticker_items_imgly_sticker_emoticons_asleep: 'Asleep',
    sticker_items_imgly_sticker_emoticons_attention: 'Attention',
    sticker_items_imgly_sticker_emoticons_baby_chicken: 'Baby Chicken',
    sticker_items_imgly_sticker_emoticons_batman: 'Batman',
    sticker_items_imgly_sticker_emoticons_beer: 'Beer',
    sticker_items_imgly_sticker_emoticons_black: 'Black',
    sticker_items_imgly_sticker_emoticons_blue: 'Blue',
    sticker_items_imgly_sticker_emoticons_blush: 'Blush',
    sticker_items_imgly_sticker_emoticons_boxer: 'Boxer',
    sticker_items_imgly_sticker_emoticons_business: 'Business',
    sticker_items_imgly_sticker_emoticons_chicken: 'Chicken',
    sticker_items_imgly_sticker_emoticons_cool: 'Cool',
    sticker_items_imgly_sticker_emoticons_cry: 'Cry',
    sticker_items_imgly_sticker_emoticons_deceased: 'Deceased',
    sticker_items_imgly_sticker_emoticons_devil: 'Devil',
    sticker_items_imgly_sticker_emoticons_duckface: 'Duckface',
    sticker_items_imgly_sticker_emoticons_furious: 'Furious',
    sticker_items_imgly_sticker_emoticons_grin: 'Grin',
    sticker_items_imgly_sticker_emoticons_guitar: 'Guitar',
    sticker_items_imgly_sticker_emoticons_harry_potter: 'Harry Potter',
    sticker_items_imgly_sticker_emoticons_hippie: 'Hippie',
    sticker_items_imgly_sticker_emoticons_hitman: 'Hitman',
    sticker_items_imgly_sticker_emoticons_humourous: 'Humourous',
    sticker_items_imgly_sticker_emoticons_idea: 'Idea',
    sticker_items_imgly_sticker_emoticons_impatient: 'Impatient',
    sticker_items_imgly_sticker_emoticons_kiss: 'Kiss',
    sticker_items_imgly_sticker_emoticons_kisses: 'Kisses',
    sticker_items_imgly_sticker_emoticons_laugh: 'Laugh',
    sticker_items_imgly_sticker_emoticons_loud_cry: 'Loud Cry',
    sticker_items_imgly_sticker_emoticons_loving: 'Loving',
    sticker_items_imgly_sticker_emoticons_masked: 'Masked',
    sticker_items_imgly_sticker_emoticons_music: 'Music',
    sticker_items_imgly_sticker_emoticons_nerd: 'Nerd',
    sticker_items_imgly_sticker_emoticons_ninja: 'Ninja',
    sticker_items_imgly_sticker_emoticons_not_speaking_to_you:
        'Not speaking to you',
    sticker_items_imgly_sticker_emoticons_pig: 'Pig',
    sticker_items_imgly_sticker_emoticons_pumpkin: 'Pumpkin',
    sticker_items_imgly_sticker_emoticons_question: 'Question',
    sticker_items_imgly_sticker_emoticons_rabbit: 'Rabbit',
    sticker_items_imgly_sticker_emoticons_sad: 'Sad',
    sticker_items_imgly_sticker_emoticons_sick: 'Sick',
    sticker_items_imgly_sticker_emoticons_skateboard: 'Skateboard',
    sticker_items_imgly_sticker_emoticons_skull: 'Skull',
    sticker_items_imgly_sticker_emoticons_sleepy: 'Sleepy',
    sticker_items_imgly_sticker_emoticons_smile: 'Smile',
    sticker_items_imgly_sticker_emoticons_smoking: 'Smoking',
    sticker_items_imgly_sticker_emoticons_sobbing: 'Sobbing',
    sticker_items_imgly_sticker_emoticons_star: 'Star',
    sticker_items_imgly_sticker_emoticons_steaming_furious: 'Steaming Furious',
    sticker_items_imgly_sticker_emoticons_sunbathing: 'Sunbathing',
    sticker_items_imgly_sticker_emoticons_tired: 'Tired',
    sticker_items_imgly_sticker_emoticons_tongue_out_wink: 'Tongue out wink',
    sticker_items_imgly_sticker_emoticons_wave: 'Wave',
    sticker_items_imgly_sticker_emoticons_wide_grin: 'Wide Grin',
    sticker_items_imgly_sticker_emoticons_wink: 'Wink',
    sticker_items_imgly_sticker_emoticons_wrestler: 'Wrestler',
    sticker_items_imgly_sticker_shapes_arrow_02: 'Arrow 1',
    sticker_items_imgly_sticker_shapes_arrow_03: 'Arrow 2',
    sticker_items_imgly_sticker_shapes_badge_01: 'Badge 1',
    sticker_items_imgly_sticker_shapes_badge_11: 'Badge 5',
    sticker_items_imgly_sticker_shapes_badge_12: 'Badge 6',
    sticker_items_imgly_sticker_shapes_badge_13: 'Badge 7',
    sticker_items_imgly_sticker_shapes_badge_15: 'Badge 8',
    sticker_items_imgly_sticker_shapes_badge_18: 'Badge 9',
    sticker_items_imgly_sticker_shapes_badge_19: 'Badge 10',
    sticker_items_imgly_sticker_shapes_badge_20: 'Badge 11',
    sticker_items_imgly_sticker_shapes_badge_28: 'Badge 12',
    sticker_items_imgly_sticker_shapes_badge_32: 'Badge 13',
    sticker_items_imgly_sticker_shapes_badge_35: 'Badge 14',
    sticker_items_imgly_sticker_shapes_badge_36: 'Badge 15',
    sticker_items_imgly_sticker_shapes_badge_04: 'Badge 2',
    sticker_items_imgly_sticker_shapes_badge_06: 'Badge 3',
    sticker_items_imgly_sticker_shapes_badge_08: 'Badge 4',
    sticker_items_imgly_sticker_shapes_spray_01: 'Spray 1',
    sticker_items_imgly_sticker_shapes_spray_03: 'Spray 2',
    sticker_items_imgly_sticker_shapes_spray_04: 'Spray 3',
    sticker_canvasActions_buttonDelete: 'Delete',
    sticker_canvasActions_buttonBringToFront: 'Move to front',
    sticker_canvasActions_buttonDuplicate: 'Duplicate',
    sticker_canvasActions_buttonFlipHorizontal: 'Flip',
    sticker_canvasActions_buttonFlipVertical: 'Flip',
    sticker_history_add: 'Sticker',
    sticker_history_resize: 'Sticker resize',
    sticker_history_position: 'Sticker position',
    sticker_history_color: 'Sticker color',
    sticker_history_delete: 'Sticker delete',
    sticker_history_order: 'Sticker order',
    sticker_history_opacity: 'Sticker opacity',
    sticker_history_flip: 'Sticker flip',
    text_title: 'Text',
    text_controls_buttonNew: 'New Text',
    text_controls_dropdownFontFamily: 'Font Family',
    text_controls_textFontSize: 'Font Size',
    text_controls_selectAlignment: 'Alignment',
    text_controls_selectFontColor: 'Font Color',
    text_controls_selectBackgroundColor: 'Background Color',
    text_controls_sliderLineSpacing: 'Line Spacing',
    text_controls_tabColor: 'Color',
    text_controls_tabBgColor: 'Bg Color',
    text_controls_tabAlignment: 'Alignment',
    text_controls_tabLineHeight: 'Line Height',
    text_controls_tabFontSize: 'Font Size',
    text_canvasControls_placeholderText: 'Write Something',
    text_canvasControls_buttonSave: 'Done',
    text_canvasControls_buttonClose: 'Cancel',
    text_canvasControls_inputText: 'Text Input',
    text_canvasActions_buttonEdit: 'Edit',
    text_canvasActions_buttonDelete: 'Delete',
    text_canvasActions_buttonBringToFront: 'Move to front',
    text_canvasActions_buttonDuplicate: 'Duplicate',
    text_history_add: 'Text',
    text_history_edit: 'Text edit',
    text_history_resize: 'Text resize',
    text_history_position: 'Text position',
    text_history_alignment: 'Text alignment',
    text_history_textColor: 'Text color',
    text_history_backgroundColor: 'Text background color',
    text_history_fontFamily: 'Font family',
    text_history_fontStyle: 'Font style',
    text_history_lineSpacing: 'Line spacing',
    text_history_width: 'Text width',
    text_history_delete: 'Text delete',
    text_history_order: 'Text order',
    textdesign_title: 'Text Design',
    textdesign_controls_buttonNew: 'New Text Design',
    textdesign_controls_buttonShuffle: 'Shuffle Layout',
    textdesign_controls_selectColor: 'Text Color',
    textdesign_controls_tabColor: 'Color',
    textdesign_controls_tabShuffle: 'Shuffle',
    textdesign_canvasControls_placeholderText: 'Write Something With Style',
    textdesign_canvasControls_buttonSave: 'Done',
    textdesign_canvasControls_buttonClose: 'Close',
    textdesign_canvasControls_inputText: 'Text Input',
    textdesign_canvasActions_buttonEdit: 'Edit',
    textdesign_canvasActions_buttonInvert: 'Invert',
    textdesign_canvasActions_buttonDelete: 'Delete',
    textdesign_canvasActions_buttonBringToFront: 'Move to front',
    textdesign_canvasActions_buttonDuplicate: 'Duplicate',
    textdesign_history_add: 'Text design',
    textdesign_history_edit: 'Text design edit',
    textdesign_history_resize: 'Text design resize',
    textdesign_history_position: 'Text design position',
    textdesign_history_color: 'Text design color',
    textdesign_history_shuffle: 'Text design shuffle',
    textdesign_history_invert: 'Text design invert',
    textdesign_history_padding: 'Text design padding',
    textdesign_history_order: 'Text design order',
    textdesign_history_delete: 'Text design delete',
    frame_title: 'Frames',
    frame_controls_buttonReset: 'Remove Frame',
    frame_controls_sliderOpacity: 'Frame Opacity',
    frame_controls_sliderSize: 'Frame Size',
    frame_controls_selectColor: 'Frame Color',
    frame_controls_tabColor: 'Color',
    frame_controls_tabOpacity: 'Opacity',
    frame_controls_tabSize: 'Size',
    frame_items_imgly_frame_dia: 'Dia',
    frame_items_imgly_frame_art_decor: 'Art Decor',
    frame_items_imgly_frame_black_passepartout: 'Black',
    frame_items_imgly_frame_lowpoly_shadow: 'Low Poly',
    frame_items_imgly_frame_wood_passepartout: 'Wood',
    brush_title: 'Brush',
    brush_controls_buttonReset: 'Remove Brush',
    brush_controls_sliderSize: 'Brush Size',
    brush_controls_sliderHardness: 'Brush Hardness',
    brush_controls_selectColor: 'Brush Color',
    brush_controls_tabSize: 'Size',
    brush_controls_tabHardness: 'Hardness',
    brush_controls_tabColor: 'Color',
    brush_history_brushStroke: 'Brush Stroke',
    transform_title: 'Transform',
    transform_controls_buttonReset: 'Reset to default',
    transform_controls_checkboxKeepResolution: 'Keep Resolution',
    transform_controls_inputCropSize: 'Crop Size',
    transform_controls_inputHeight: 'h',
    transform_controls_inputWidth: 'w',
    transform_controls_tabFlipAndRotate: 'Flip & Rotate',
    transform_controls_tabResolution: 'Resolution',
    transform_controls_tabCropSize: 'Crop Size',
    transform_categories_imgly_transforms_common: 'Common',
    transform_categories_imgly_transforms_facebook: 'Facebook',
    transform_categories_imgly_transforms_twitter: 'Twitter',
    transform_categories_imgly_transforms_instagram: 'Instagram',
    transform_items_imgly_transform_common_custom: 'Custom',
    transform_items_imgly_transform_common_square: 'Square',
    transform_items_imgly_transform_common_4: '4:3',
    transform_items_imgly_transform_common_16: '16:9',
    transform_items_imgly_transform_common_3: '3:4',
    transform_items_imgly_transform_common_9: '9:16',
    transform_items_imgly_transform_facebook_profile: 'Profile',
    transform_items_imgly_transform_facebook_title: 'Title',
    transform_items_imgly_transform_facebook_post: 'Post',
    transform_items_imgly_transform_instagram_story: 'Story',
    transform_items_imgly_transform_instagram_landscape: 'Landscape',
    transform_items_imgly_transform_instagram_portrait: 'Portrait',
    transform_items_imgly_transform_instagram_square: 'Square',
    transform_items_imgly_transform_twitter_profile: 'Profile',
    transform_items_imgly_transform_twitter_title: 'Title',
    transform_items_imgly_transform_twitter_post: 'Post',
    transform_transformActions_buttonFlipHorizontal: 'Flip Horizontal',
    transform_transformActions_buttonFlipVertical: 'Flip Vertical',
    transform_transformActions_buttonRotateClockwise: 'Rotate Clockwise',
    transform_transformActions_buttonRotateAntiClockwise:
        'Rotate Counterclockwise',
}
