import styled, { css } from 'styled-components'
import CheckIcon from '@capture/capture-components/src/icons/check.svg?react'
import { _ } from '~/assets/localization/util'
import { colors, mediaQueries } from '~/assets/styleConstants'

const GridRow = styled.div<{ isHeader?: boolean; noBorder?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 96px 96px;
    grid-auto-rows: minmax(40px, auto);
    align-items: center;
    border-bottom: 1px solid ${colors.captureGrey400};
    ${(props) =>
        props.noBorder &&
        css`
            border-bottom: none;
        `}
    ${(props) =>
        props.isHeader &&
        css`
            font-weight: 600;
        `}

    ${mediaQueries.mobile} {
        padding: 3px 0;
    }
`

const Content = styled.div`
    color: ${colors.captureGrey800};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 16px;
`
const GridText = styled.div`
    min-width: 96px;
`

const GridFree = styled.div`
    text-align: center;
    min-width: 96px;
`

const GridSub = styled.div<{ roundTop?: boolean }>`
    text-align: center;
    background-color: rgb(236, 250, 255);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    ${(props) =>
        props.roundTop &&
        css`
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        `}
`

const subscriptionText = [
    {
        text: _('subscribe_text1'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text2'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text3'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text4'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text5'),
        free: true,
        sub: true,
    },
]

export const PaidPerksTable = () => {
    return (
        <Content>
            <GridRow isHeader noBorder={true}>
                <GridText />
                <GridFree>{_('subscribe_free')}</GridFree>
                <GridSub roundTop>{_('subscribe_sub')}</GridSub>
            </GridRow>
            {subscriptionText.map((el) => {
                return (
                    <GridRow key={el.text}>
                        <GridText>{el.text}</GridText>
                        <GridFree>
                            {el.free && (
                                <CheckIcon color={colors.captureBlue} />
                            )}
                        </GridFree>
                        <GridSub>
                            {el.sub && <CheckIcon color={colors.captureBlue} />}
                        </GridSub>
                    </GridRow>
                )
            })}
        </Content>
    )
}
