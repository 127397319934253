import { getCurrentLangDefinition, getDeviceProps } from '~/config/constants'
import { parseSearchString } from '~/utilities/urlParsing'
import { sessionStorageSet } from '~/utilities/webStorage'
import {
    BrowserFetchObject,
    HostUrl,
    SHA256,
    base64URLEncode,
    generatePkce,
    getJSON,
    getQueryString,
} from '../toolbox'

export type ServiceDictResponse = {
    'thumb-host': string
    'app-host': string
    'video-host': string
    'poll-host': string
    'search-host': string
    'download-host': string
}

export type DeviceFlowInitiateLoginResponse = {
    device_code: string
    expires_in_sec: number
    poll_interval_sec: number
    poll_url: URLstring
    user_code: string
    verification_uri: URLstring
    verification_uri_with_user_code: URLstring
}

export type DeviceFlowPollResponseOK = {
    auth: string
    account_info: AccountInfo
    defaultJob: JobID
    user_creation: boolean
    service: ServiceDictResponse
}

export class LoginService {
    public hostUrl: HostUrl

    constructor(hostname: string) {
        this.hostUrl = new HostUrl(hostname, {
            key: import.meta.env.VITE_API_KEY,
            client_v: import.meta.env.VITE_VERSION,
        })
    }

    public login = async (utmSource?: string) => {
        // Generate the PKCE and the PKCE challenge
        const pkce = generatePkce()
        const pkceChallenge = await SHA256(pkce)

        // Save the path and the PKCE for the postflight request
        const callbackPath = this.hostUrl.getCleanPath(
            '/st/4/telenorid/postflight_oauth2callback',
        )
        const callbackData = JSON.stringify({
            callbackURL: callbackPath,
            codeVerifier: pkce,
        })
        sessionStorageSet('callbackData', callbackData)

        // Set the parameters for the preflight call
        const baseParams = {
            redir: window.location.host.split(':')[0],
            locale: getCurrentLangDefinition().connectKey,
            utm_source:
                utmSource ||
                parseSearchString(window.location.search).utm_source ||
                import.meta.env.VITE_ARTIFACT,
            code_challenge: base64URLEncode(pkceChallenge),
            key: import.meta.env.VITE_API_KEY,
            oauth_redirect_uri: `https://${window.location.host}/oauth2callback.html`,
        }
        const preflightPath = '/st/4/telenorid/preflight_oauth2login'

        const preflightParams = {
            ...baseParams,
            ...getDeviceProps(),
        }

        const preflightRequest = this.hostUrl.getPath(
            preflightPath,
            preflightParams,
        )

        // Make the preflight REST call to backend
        BrowserFetchObject.get(preflightRequest)
            .asJson()
            // Process the response from the backend and prepare the call for Telenor ID
            .then((response: any) => {
                const queryString: string =
                    '?' + getQueryString(response.authorization_params)

                const mainPath: string = response.authorization_url
                // Make the browser call to telenor ID
                window.location.href = mainPath + queryString
            })
            .catch((error) => console.log(error))
    }

    public async initiateDeviceFlowLogin(): Promise<{
        data: DeviceFlowInitiateLoginResponse
    }> {
        const params = {
            redir: window.location.host.split(':')[0],
            locale: getCurrentLangDefinition().connectKey,
            utm_source: import.meta.env.VITE_ARTIFACT,
            ...getDeviceProps(),
        }
        return getJSON<{ data: DeviceFlowInitiateLoginResponse }>(
            this.hostUrl.getPath(
                '/st/4/telenorid/oauth2initiate_device_login',
                params,
            ),
        )
    }
}
