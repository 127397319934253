import { _, getNumOfTotal } from '@capture/client/assets/localization/util'
import type { Dispatch } from '@capture/client/state/common/actions'
import * as React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { getDownloadURL } from '@capture/client/API/job'
import { downloadShare } from '@capture/client/API/share'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import { colors } from '@capture/client/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
    IconButton,
} from '@capture/client/components/Common/IconTextButton'
import { ThreeAreasSymetric } from '@capture/client/components/Common/LayoutComponents'
import { PageWrapper } from '@capture/client/components/Common/PageWrapper'
import { ContainerFillingSpinnerOverlay } from '@capture/client/components/Common/SpinnerComponent'
import { TwoAreasTopNavBar } from '@capture/client/components/Navigation/TwoAreasTopNavBar'
import type { Share } from '@capture/client/state/share/selectors'
import { goBack } from '@capture/client/utilities/navigation'
import { ShareContainer } from './ShareContainer'
import { ButtonWrapper } from './ShareReceiverPage'

const ContentWrapper = styled.div`
    margin: 24px 16px;
    display: flex;
    flex-direction: column;
`

const smallButton = (
    text: string,
    onClick: () => void,
    isDisabled: boolean,
) => (
    <FilledTextButton
        text={text}
        onClick={onClick}
        isDisabled={isDisabled}
        fillColor={isDisabled ? colors.captureGrey500 : colors.captureBlue}
    />
)

type DispatchProps = {
    doDownloadFiles: () => void
}

type OwnProps = {
    share: Share
}

type Props = DispatchProps & OwnProps

type ComponentState = {
    current?: { index: number; src: URLstring; loaded: boolean }
}

class DownloadAll extends React.Component<Props, ComponentState> {
    public state: ComponentState = {}

    private setCurrent = async (index: number) => {
        const file = this.props.share.thumbFiles[index]
        if (file) {
            const src = await getDownloadURL(
                this.props.share.id,
                file.fileID,
                true /* Safari on iOS don't handle HEICs :( */,
            )
            this.setState({ current: { index, src, loaded: false } })
        }
    }
    private onPrev = () =>
        this.setCurrent(this.state.current ? this.state.current.index - 1 : 0)
    private onNext = () =>
        this.setCurrent(this.state.current ? this.state.current.index + 1 : 0)
    private onImageLoad = () => {
        this.setState(({ current }) => ({
            current: current && { ...current, loaded: true },
        }))
    }

    private getDownloadAllSection = () => {
        return (
            <ContentWrapper>
                <div>
                    <b>{_('share__download_to_ios_files')}:</b>
                    <br />
                    {_('share__download_to_ios_files_desc')}
                    <br />
                </div>
                <ButtonWrapper>
                    <BorderedTextButton
                        onClick={this.props.doDownloadFiles}
                        text={_('download_all')}
                        color={colors.captureBlue}
                    />
                </ButtonWrapper>
            </ContentWrapper>
        )
    }

    private getSingleImageSection = () => {
        const { current } = this.state

        if (!current) {
            return null
        }

        const hasPrev = current.index > 0
        const hasNext = current.index < this.props.share.thumbFiles.length - 1

        return (
            <ContentWrapper>
                <div>
                    <b>{_('share__download_to_ios_image_gallery')}:</b>
                    <br />
                    {_('share__download_to_ios_image_gallery_desc1')}
                    <br />
                    {_('share__download_to_ios_image_gallery_desc2')}
                </div>
                <div
                    style={{
                        display: 'block',
                        margin: '10px auto 4px auto',
                        position: 'relative',
                    }}>
                    <img
                        alt={'download all page section'}
                        src={current.src}
                        onLoad={this.onImageLoad}
                        style={{ height: '150px', maxWidth: '100%' }}
                    />
                    {!current.loaded && (
                        <ContainerFillingSpinnerOverlay spinnerSize={30} />
                    )}
                </div>
                <ThreeAreasSymetric
                    left={smallButton(_('previous'), this.onPrev, !hasPrev)}
                    middle={getNumOfTotal(
                        current.index + 1,
                        this.props.share.thumbFiles.length,
                    )}
                    right={smallButton(_('next'), this.onNext, !hasNext)}
                />
            </ContentWrapper>
        )
    }

    public componentDidMount() {
        this.setCurrent(0)
    }
    public componentDidUpdate() {
        if (
            this.state.current === undefined &&
            this.props.share.thumbFiles.length > 0
        ) {
            this.setCurrent(0)
        }
    }

    public render() {
        const backButton = <IconButton icon={BackArrowIcon} onClick={goBack} />
        return (
            <PageWrapper
                navBar={
                    <TwoAreasTopNavBar left={backButton} hideUserAvi={true} />
                }>
                <div>
                    {this.getDownloadAllSection()}
                    {this.getSingleImageSection()}
                </div>
            </PageWrapper>
        )
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    doDownloadFiles: () => downloadShare(dispatch, ownProps.share.id),
})
export const DownloadAllPage = connect(null, mapDispatchToProps)(DownloadAll)

export const DownloadShareMount: React.FunctionComponent = () => {
    const { shareID } = useParams<{ shareID: string }>()
    return shareID ? (
        <ShareContainer component={DownloadAllPage} shareID={shareID} />
    ) : null
}
