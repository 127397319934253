import {
    AlbumDetailsFetched,
    AutoGeneratedAlbumCanceled,
    AutoGeneratedAlbumCreated,
    AutoGeneratedAlbumFinished,
} from '../album/actions'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { ChangeJobPropertySucceeded } from '../jobInfo/actions'
import type { User } from '../users/reducer'
import { AlbumListFilterSet, AlbumListSortModeSet } from './actions'

export type AlbumListFilter = 'shared' | 'private' | 'all'

type PendingAlbum = {
    jobID: JobID
    tempID: string
    name: string
}

export type AlbumNumberOf = {
    contributors: number
    files: number
    comments: number
    loves: number
}

export type AlbumSortOrder = 'ADDED' | 'NEWEST_TAKEN' | 'OLDEST_TAKEN'
export type ConfigurableAlbumDetails = {
    title: string
    allow_comments: boolean
    allow_uploads: boolean
    isShared: boolean
    sort_order?: AlbumSortOrder
}

export type AlbumDetails = ConfigurableAlbumDetails & {
    albumID: JobID
    owner: User
    coverPhotoID: string
    ctime: number
    mtime: number
    numberOf: AlbumNumberOf
    size: number
    hasHEIC: boolean
}

export type AlbumListSortOrder = UserAccountAttributeValue['album_list_sort']

export type AlbumListState = {
    filter: AlbumListFilter
    sortMode: AlbumListSortOrder
    pendingAlbums: PendingAlbum[]
    albumDetailsList: DictionaryOf<AlbumDetails>
}

const initialState: AlbumListState = {
    filter: 'all',
    sortMode: 'RECENT_ACTIVITY',
    pendingAlbums: [],
    albumDetailsList: {},
}

export const albumListReducer = (
    state: AlbumListState = initialState,
    action: Action,
): AlbumListState => {
    if (isType(action, AlbumListFilterSet)) {
        return {
            ...state,
            filter: action.payload,
        }
    }

    if (isType(action, AlbumListSortModeSet)) {
        return {
            ...state,
            sortMode: action.payload,
        }
    }

    if (isType(action, AutoGeneratedAlbumCreated)) {
        return {
            ...state,
            pendingAlbums: state.pendingAlbums.concat(action.payload),
        }
    }

    if (
        isType(action, AutoGeneratedAlbumFinished) ||
        isType(action, AutoGeneratedAlbumCanceled)
    ) {
        return {
            ...state,
            pendingAlbums: state.pendingAlbums.filter(
                (a) => a.tempID !== action.payload.tempID,
            ),
        }
    }

    if (isType(action, AlbumDetailsFetched)) {
        return {
            ...state,
            albumDetailsList: {
                ...state.albumDetailsList,
                [action.payload.albumID]: action.payload,
            },
        }
    }

    if (isType(action, ChangeJobPropertySucceeded)) {
        const { job, property, value } = action.payload
        const relatedAlbum = state.albumDetailsList[job]
        if (relatedAlbum) {
            return {
                ...state,
                albumDetailsList: {
                    ...state.albumDetailsList,
                    [job]: {
                        ...relatedAlbum,
                        [property]: value,
                    },
                },
            }
        }
    }

    return state
}

export const albumListReducerMapObj = {
    albumList: albumListReducer,
}

export type StateWithAlbumList = StateOfReducerMapObj<
    typeof albumListReducerMapObj
>
