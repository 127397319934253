import type { FetchMethod } from './toolbox'

/**
 * All kind of `talking to a web-deployed endpoint` stash/helpers wrapped in a class
 * Different instances of `fetch` is used when running on node and on the web compared to running on node, and this
 * class decorates around that to provide useful tool-methods while also giving the desired flexibility to use on both
 * serverside and clientside platform
 */

class FetchResponse {
    constructor(private responsePromise: Promise<Response>) {}

    public rawResponse(): Promise<Response> {
        return this.responsePromise
    }

    public asText(): Promise<string> {
        return this.responsePromise.then((r) => r.text())
    }

    public asJson<T>(): Promise<T> {
        return this.responsePromise.then((r) => r.json())
    }
}

export class FetchObject {
    constructor(private fetchMethod: FetchMethod) {}

    private doFetch(
        method: string,
        url: string,
        fetchParams: Partial<RequestInit> = {},
    ) {
        return new FetchResponse(
            this.fetchMethod(url, { method, ...fetchParams }),
        )
    }

    public get(
        path: string,
        headers: DictionaryOf<string> = {},
    ): FetchResponse {
        return this.doFetch('GET', path, { headers })
    }

    public post(
        url: string,
        body?: FormData | string,
        headers: DictionaryOf<string> = {},
    ): FetchResponse {
        return this.doFetch('POST', url, { body, headers })
    }

    public delete(
        url: string,
        body?: FormData | string,
        headers: DictionaryOf<string> = {},
    ): FetchResponse {
        return this.doFetch('DELETE', url, { body, headers })
    }

    public put(
        url: string,
        body?: FormData | string,
        headers: DictionaryOf<string> = {},
    ): FetchResponse {
        return this.doFetch('PUT', url, { body, headers })
    }
}
