import type { MergeDeep } from 'type-fest'
import { createActionCreator, createVoidActionCreator } from '../common/actions'

export const StartFetchingAccountInfo = createVoidActionCreator(
    'START_FETCHING_ACCOUNT_INFO',
)

export type UserInformation = MergeDeep<
    AccountInfo,
    {
        file_type_counters: {
            picture_count: number
            screenshot_count: number
            video_count: number
            document_count?: number
        }
        allowAnalytics: boolean
        auth_token: string
        has_unlimited_quota: boolean
        isBeingShutDown?: boolean
        max_space: number
        number_of_subscribed_albums: number
        profile_picture?: URLstring
        tos_to_approve?: null | { link: string; version: string }
        used_space: number
    }
>

export const FetchedAccountInfo = createActionCreator<UserInformation>(
    'FETCHED_ACCOUNT_INFO',
)

type StorageDetails = Pick<UserInformation, 'max_space' | 'used_space'>
export const FetchedStorageDetails = createActionCreator<StorageDetails>(
    'FETCHED_STORAGE_DETAILS',
)

export const FetchStorageDetailsFailed = createVoidActionCreator(
    'FETCH_STORAGE_DETAILS_FAILED',
)

type CountDetails = Partial<
    Pick<UserInformation, 'file_type_counters' | 'number_of_subscribed_albums'>
>

export const FetchedFileCount =
    createActionCreator<CountDetails>('FETCHED_FILE_COUNT')
export const FetchedAlbumCount = createActionCreator<CountDetails>(
    'FETCHED_ALBUM_COUNT',
)

export const UnableToFetchAccountInfo = createVoidActionCreator(
    'UNABLE_TO_FETCH_ACCOUNT_INFO',
)
export const DetectedThatUserIsUnauthenticated = createVoidActionCreator(
    'DETECTED_THAT_USER_IS_UNAUTHENTICATED',
)

export type DeviceInfo = {
    id: string
    name: string
    expires: number
}
export const FetchingConnectedDevicesStarted = createVoidActionCreator(
    'FETCHING_CONNECTED_DEVICES_STARTED',
)
export const ConnectedDevicesWasFetched = createActionCreator<{
    currentDeviceID: string
    devices: DeviceInfo[]
}>('CONNECTED_DEVICES_WAS_FETCHED')
export const FetchingConnectedDevicesFailed = createVoidActionCreator(
    'FETCHING_CONNECTED_DEVICES_FAILED',
)

export const DeleteConnectedDeviceStarted = createActionCreator<{
    deviceID: string
}>('DELETE_CONNECTED_DEVICE_STARTED')
export const ConnectedDeviceWasDeleted = createActionCreator<{
    deviceID: string
}>('CONNECTED_DEVICE_WAS_DELETED')
export const DeleteConnectedDeviceFailed = createActionCreator<{
    deviceID: string
}>('DELETE_CONNECTED_DEVICE_FAILED')

export const FetchedLastWebVersionSeen = createActionCreator<number>(
    'FETCHED_LAST_WEB_VERSION_SEEN',
)
export const CurrentWebVersionWasSeen = createActionCreator<number>(
    'CURRENT_WEB_VERSION_WAS_SEEN',
)

export const UnverifiedTermsDetected = createActionCreator<{
    link: string
    version: string
}>('UNVERIFIED_TERMS_DETECTED')
export const TOSTermsVerified = createVoidActionCreator('TOS_TERMS_VERIFIED')

export const UserLoggedOut = createVoidActionCreator('USER_LOGGED_OUT')

// Dispatched when some action requires an authToken (such as commenting being blocked until user is logged in)
export type RequireUserInfoReason = 'addPhotoToAlbum'
export const ValidUserInfoRequired = createActionCreator<
    RequireUserInfoReason | undefined
>('VALID_USER_INFO_REQUIRED')
export const ValidUserInfoRequestDeniedByUser = createVoidActionCreator(
    'VALID_USER_INFO_REQUEST_DENIED_BY_USER',
)

/** InvalidLoginStateDetected occurs when some calls to the server that assumes the user is logged in fails to authenticate */
export const InvalidLoginStateDetected = createVoidActionCreator(
    'INVALID_LOGIN_STATE_DETECTED',
)

export type TempPrintUserInfo = {
    auth_token: string
    uuid: UserID
    targetJob: JobID
}
export const FetchedTempPrintUserInfo = createActionCreator<TempPrintUserInfo>(
    'FETCHED_TEMP_PRINT_USER_INFO',
)
export const JobSubscriptionsDetected = createActionCreator<JobID[]>(
    'JOB_SUBSCRIPTIONS_DETECTED',
)
export const UserSubscribedToAlbum = createActionCreator<JobID>(
    'USER_SUBSCRIBED_TO_ALBUM',
)
export const UserUnsubscribedFromAlbum = createActionCreator<JobID>(
    'USER_UNSUBSCRIBED_FROM_ALBUM',
)
export const AlbumUnsubscriptionConfirmed = createActionCreator<JobID>(
    'ALBUM_UNSUBSCRIPTION_CONFIRMED',
)
export const AlbumUnsubscriptionFailed = createActionCreator<JobID>(
    'ALBUM_UNSUBSCRIPTION_FAILED',
)

export enum MissingNameModalContext {
    AlbumReceiver = 'albumReceiver',
    AlbumSettings = 'albumSettings',
    AlbumCreation = 'albumCreation',
    NoContext = '',
}

export const ShowMissingNameModal =
    createActionCreator<MissingNameModalContext>('SHOW_MISSING_NAME_MODAL')
export const HideMissingNameModal = createVoidActionCreator(
    'HIDE_MISSING_NAME_MODAL',
)

export const SetIsNewSignup = createActionCreator<boolean>('SET_IS_NEW_SIGNUP')
