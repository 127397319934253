import * as React from 'react'
import { connect } from 'react-redux'
import UploadIcon from '@capture/capture-components/src/icons/upload.svg?react'
import { acceptedExtensions } from '~/utilities/fileTarget'
import { getUserReadOnlyStatus } from '~/state/currentUser/selectors'
import { _ } from '~/assets/localization/util'
import { trackEvent } from '~/analytics/eventTracking'
import { colors } from '~/assets/styleConstants'
import { getTimelineJobID } from '~/state/timeline/selectors'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import { IconButton, IconTextButton } from '../Common/IconTextButton'
import { ReadonlyTooltip } from '../Common/ReadonlyTooltip'
import { FileAcceptElement } from './FileAcceptElement'

type OwnProps = {
    showText?: boolean
    targetJob?: JobID
}
type StateProps = {
    targetJob: JobID
    isReadOnlyUser: boolean
}
type Props = OwnProps & StateProps

class _UploadButton extends React.Component<Props> {
    private fileHandler = (files: File[]) => {
        trackEvent('Uploader', 'FilesAddedBySelection', '', files.length)
        getConnectedInstance().addFiles(files, this.props.targetJob, '')
    }
    private trackUploadButtonClick = () => {
        trackEvent('Uploader', 'UploadButton_click')
    }

    public render() {
        const iconTextButton = (
            <IconTextButton
                onClick={this.trackUploadButtonClick}
                icon={UploadIcon}
                hoverColor={colors.captureBlue}
                text={_('upload')}
                isDisabled={this.props.isReadOnlyUser}
            />
        )
        const iconButton = (
            <IconButton
                onClick={this.trackUploadButtonClick}
                icon={UploadIcon}
                hoverColor={colors.captureBlue}
                data-cy="timeline__uploadBtn"
                isDisabled={this.props.isReadOnlyUser}
            />
        )

        return (
            <ReadonlyTooltip
                renderElement={(isReadOnlyUser) => (
                    <FileAcceptElement
                        fileHandler={this.fileHandler}
                        acceptTypes={acceptedExtensions}
                        isDisabled={isReadOnlyUser}>
                        {this.props.showText ? iconTextButton : iconButton}
                    </FileAcceptElement>
                )}
            />
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getTimelineJobID> &
        StateOfSelector<typeof getUserReadOnlyStatus>,
    ownProps: OwnProps,
) => ({
    targetJob: ownProps.targetJob || getTimelineJobID(state) || '',
    isReadOnlyUser: getUserReadOnlyStatus(state),
})

export const UploadButton = connect(mapStateToProps)(_UploadButton)
