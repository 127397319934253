import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import HeartIconFilled from '@capture/capture-components/src/icons/like-filled.svg?react'
import HeartIcon from '@capture/capture-components/src/icons/like.svg?react'
import { animations, colors } from '~/assets/styleConstants'
import { CenteredElementWrapper } from '../Common/LayoutComponents'

type LoveButtonProps = {
    isLoved: boolean
    onAnimationEnd: () => void
    onLoveChanged: (isLoved: boolean) => void
    color?: string
}

type LoveButtonState = {
    isAnimating: boolean
}

const Container = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transform: translate(-3px, 0);
`

const rippleEffect = keyframes`
    0% {
        opacity: 1;
        width: 10%;
        height: 10%;
    }
    100% {
        opacity: 0;
        width: 160%;
        height: 160%;
    }
`

const heartPumpEffect = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
`

const RippleAnimation = styled(CenteredElementWrapper)`
    width: 24px;
    height: 24px;
    border: 2px solid ${(props) => props.color || colors.captureMagenta};
    border-radius: 50%;
    opacity: 0;

    animation-name: ${rippleEffect};
    animation-delay: 0.1s;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
`

const HeartWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
`

const HeartFilledAnimationWrapper = styled(HeartWrapper)`
    svg {
        opacity: 1;
        animation-name: ${animations.fadeIn};
        animation-duration: 1.5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }
`

const HeartAnimationWrapper = styled(HeartWrapper)`
    svg {
        opacity: 0;
        animation-name: ${heartPumpEffect};
        animation-duration: 0.8s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }
`

const LoveAnimation: React.FunctionComponent<{
    onAnimationEnd: () => void
    color?: string
}> = (props) => (
    <div>
        <RippleAnimation
            onAnimationEnd={props.onAnimationEnd}
            color={props.color}
        />
        <HeartAnimationWrapper>
            <AnimatedHeartIcon isFilled={false} color={props.color} />
        </HeartAnimationWrapper>
        <HeartFilledAnimationWrapper>
            <AnimatedHeartIcon isFilled={true} color={props.color} />
        </HeartFilledAnimationWrapper>
    </div>
)

const AnimatedHeartIcon: React.FunctionComponent<{
    isFilled: boolean
    color?: string
}> = (props) => {
    if (props.isFilled) {
        return <HeartIconFilled color={props.color || colors.captureMagenta} />
    }
    return <HeartIcon color={props.color || colors.captureGrey800} />
}

export class LoveButton extends React.Component<
    LoveButtonProps,
    LoveButtonState
> {
    public state: LoveButtonState = {
        isAnimating: false,
    }

    public shouldComponentUpdate(): boolean {
        // do not re-render during animation
        return this.state.isAnimating !== true
    }

    public render() {
        const { isLoved } = this.props

        let content: React.ReactNode = null

        if (this.state.isAnimating) {
            content = (
                <LoveAnimation
                    onAnimationEnd={this.handleLoveAnimationEnd}
                    color={this.props.color}
                />
            )
        } else {
            content = (
                <AnimatedHeartIcon
                    isFilled={isLoved}
                    color={this.props.color}
                />
            )
        }

        return (
            <Container
                onClick={this.handleClick}
                onKeyUp={this.handleClick}
                role="button"
                tabIndex={0}>
                {content}
            </Container>
        )
    }

    private handleLoveAnimationEnd = () => {
        this.props.onAnimationEnd()

        this.setState({
            isAnimating: false,
        })
    }

    private handleClick = () => {
        if (!this.state.isAnimating) {
            this.props.onLoveChanged(!this.props.isLoved)

            if (!this.props.isLoved) {
                this.setState({
                    isAnimating: true,
                })
            }
        }
    }
}
