import type { Token } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import InfoIcon from '@capture/capture-components/src/icons/info.svg?react'
import {
    fetchCreditCardInfo,
    fetchStoragePlanInfo,
    startPlanSubscription,
} from '~/API/storagePlan'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import { FilledTextButton } from '~/components/Common/IconTextButton'
import { RippleLoaderOverlay } from '~/components/Common/RippleLoaderOverlay'
import {
    PRODUCT_NAME,
    SUPPORT_SITE_ADDRESS,
    TELENOR_MINE_SIDER_LINK,
    isMinSky,
} from '~/config/constants'
import {
    getEmailAdressOfLoggedInUser,
    getMaxStorage,
} from '~/state/currentUser/selectors'
import { PlanDowngradeClicked } from '~/state/storagePlan/actions'
import type {
    CaptureStripeProduct,
    PlanGroup,
} from '~/state/storagePlan/selectors'
import {
    UnavailableReason,
    getAvailableTrialPeriod,
    getCurrentStripeStoragePlan,
    getStoragePlanGroups,
    isUpdatingStoragePlan,
} from '~/state/storagePlan/selectors'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import {
    InsufficientPlanClicked,
    PlanChangeInitiated,
} from '~/state/userActions/actions'
import { ProductRow, StoragePlanProduct } from './StoragePlanProduct'
import { BuyStorageForm } from './BuyStorageForm'

const TextOverProceedButton = styled(ProductRow)`
    display: flex;
    color: ${colors.captureGreen};
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 10px;
    padding: 0;
`
const TextUnderProceedButton = styled(ProductRow)`
    display: flex;
    color: ${colors.warningRed};
    justify-content: center;
    font-size: ${fontSize.medium_16};
    margin-top: ${(props: { hasFreeTrial: boolean }) =>
        props.hasFreeTrial ? '16px' : '10px'};
    margin-bottom: ${(props) => (props.hasFreeTrial ? '0px' : '30px')};
    padding: 0;
`

const BusinessCustomerInfoWrapper = styled(ProductRow)`
    display: flex;
    background-color: ${colors.captureGrey100};
    background-image: none;
    margin-bottom: 8px;
    margin-top: 24px;

    svg {
        flex-shrink: 0;
    }
`
const BusinessCustomerInfo = styled(BusinessCustomerInfoWrapper)`
    margin-left: '6%';
    align-items: 'center';
`
const BusinessCustomerInfoText = styled.div`
    display: inline-block;
    margin-left: 8%;
    text-align: left;
    font-weight: bold;

    a {
        text-decoration: underline;
    }
    a:hover {
        color: ${colors.captureBlue};
    }
`
const BuyFormWrapper = styled.div`
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
`
const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
`

const SubscriptionNotes = styled.div`
    font-size: ${fontSize.small_14};
    color: ${colors.captureGrey800};
    margin-top: 24px;
`

export const BuyMoreWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
`

export const ProductHeader = () => (
    <ProductRow>
        <div>{_('storage')}</div>
        <div>{_('monthly_sub')}</div>
        <div>{_('yearly_sub')}</div>
    </ProductRow>
)

const SubscriptionTextWrapper = styled.div`
    margin-bottom: 16px;
    color: ${colors.defaultIconColor};
    font-size: ${fontSize.medium_16};
`
export const BusinessLink = styled.a`
    text-decoration: underline;
`

export const BuyMoreStorage = () => {
    const [selectedPlan, setSelectedPlan] = useState<CaptureStripeProduct>()
    const [showWarningText, setShowWarningText] = useState(false)
    const [showBuyStorageForm, setShowBuyStorageForm] = useState(false)

    const currentPlan = useSelector(getCurrentStripeStoragePlan)
    const maxStorage = useSelector(getMaxStorage)
    const planGroups = useSelector(getStoragePlanGroups)
    const isCurrentlyUpdatingStoragePlan = useSelector(isUpdatingStoragePlan)
    const userEmail = useSelector(getEmailAdressOfLoggedInUser)
    const availableTrialPeriod = useSelector(getAvailableTrialPeriod)
    const hasFreeTrialIncluded = Boolean(availableTrialPeriod)

    const dispatch = useDispatch()

    // TODO: CAPWEB-2853 - Send email once backend is ready
    const doPurchasePlan = (planID: string, token: Token, _email: string) =>
        startPlanSubscription(dispatch, planID, token.id, token.card!.id)
    const doChangePlan = (plan: CaptureStripeProduct) =>
        dispatch(PlanChangeInitiated(plan.id))
    const showPlanDowngradeAlert = () => dispatch(PlanDowngradeClicked())
    const showInsufficientPlanAlert = (
        currMaxStorage: number,
        newPlanSize: number,
        currPlanSize: number,
    ) =>
        dispatch(
            InsufficientPlanClicked(currMaxStorage, newPlanSize, currPlanSize),
        )

    useEffect(() => {
        if (!currentPlan) {
            fetchStoragePlanInfo(dispatch)
        }
    }, [currentPlan, dispatch])

    if (planGroups.length === 0) {
        return null
    }

    const handlePaymentSubmitted = (paymentToken: Token, email: string) => {
        if (selectedPlan) {
            doPurchasePlan(selectedPlan.id, paymentToken, email)
            setShowBuyStorageForm(false)
            setSelectedPlan(undefined)
            fetchCreditCardInfo(dispatch)
        }
    }

    const cancelPayment = () => {
        setSelectedPlan(undefined)
    }

    const handlePlanSelection = () => {
        if (selectedPlan) {
            if (selectedPlan.unavailableReason !== undefined) {
                switch (selectedPlan.unavailableReason) {
                    case UnavailableReason.PLAN_IS_DOWNGRADE:
                        showPlanDowngradeAlert()
                        break
                    case UnavailableReason.INSUFFICIENT_STORAGE_PLAN:
                        showInsufficientPlanAlert(
                            maxStorage,
                            selectedPlan.size,
                            currentPlan ? currentPlan.size : 0,
                        )
                        break
                }
                return
            }

            if (currentPlan) {
                doChangePlan(selectedPlan)
            } else {
                setShowBuyStorageForm(true)
            }
        } else {
            setShowWarningText(true)
        }
    }

    const SubscriptionText = () => {
        const montheTrialText = ` ${_('months_free_trial').replace(
            '%num_free_trial%',
            availableTrialPeriod.toString(),
        )}. `

        if (hasFreeTrialIncluded && !currentPlan) {
            return (
                <SubscriptionTextWrapper>
                    <p>
                        {_('subscribe_to_receive')}
                        <span>
                            <b>{montheTrialText}</b>
                        </span>
                        <BusinessLink
                            target="_blank"
                            href={SUPPORT_SITE_ADDRESS}
                            rel="noreferrer">
                            {_('learn_more')}
                        </BusinessLink>
                    </p>
                </SubscriptionTextWrapper>
            )
        }

        return (
            <SubscriptionTextWrapper>
                <p>{`${_('select_plan_to_see_details')}*`}</p>
            </SubscriptionTextWrapper>
        )
    }

    const getTextOverProceedButton = () => {
        if (selectedPlan) {
            const monthlyOrYearly =
                selectedPlan.period === 'monthly'
                    ? _('month').toLowerCase()
                    : _('year').toLowerCase()

            const willHaveCredit =
                (currentPlan?.price ?? 0) > selectedPlan.price
            if (willHaveCredit) {
                return _('downgrade_refund_detail').replace(
                    '%product_name%',
                    PRODUCT_NAME,
                )
            }
            if (hasFreeTrialIncluded && !currentPlan) {
                return _('with_free_trial_detail')
                    .replace(
                        '%num_free_trial%',
                        availableTrialPeriod.toString(),
                    )
                    .replace('%price_string%', selectedPlan.priceString)
                    .replace('%period%', monthlyOrYearly)
            }
            return _('without_free_trial_detail')
                .replace('%price_string%', selectedPlan.priceString)
                .replace('%period%', monthlyOrYearly)
        }
    }

    const getTextInsideProceedButton = () => {
        if (currentPlan) {
            return _('change_subscription_plan')
        }
        if (hasFreeTrialIncluded) {
            return _('subscribe_with_free_trial').replace(
                '%num_free_trial%',
                availableTrialPeriod.toString(),
            )
        }
        return _('proceed_to_pay')
    }

    return (
        <ContentWrapper>
            {showBuyStorageForm && selectedPlan ? (
                <BuyFormWrapper>
                    <div>
                        <h2 style={{ marginBottom: 5 }}>
                            {bytesToSize(selectedPlan.size)}
                        </h2>
                        <div
                            style={{
                                fontSize: fontSize.medium_16,
                                color: colors.captureGrey700,
                            }}>
                            {`${selectedPlan.priceString} ${
                                selectedPlan.period === 'monthly'
                                    ? _('monthly')
                                    : _('yearly')
                            }`}
                        </div>
                    </div>
                    <BuyStorageForm
                        onPaymentDetailsAccepted={handlePaymentSubmitted}
                        onCancel={cancelPayment}
                        userEmail={userEmail}
                    />
                </BuyFormWrapper>
            ) : (
                <BuyMoreWrapper>
                    <SubscriptionText />
                    <ProductHeader />
                    {planGroups.map((planGroup: PlanGroup) => (
                        <StoragePlanProduct
                            key={`prod_${planGroup.size}`}
                            group={planGroup}
                            currentPlan={currentPlan}
                            selectedPlan={selectedPlan}
                            selectPlan={setSelectedPlan}
                            hideWarningText={() => setShowWarningText(false)}
                        />
                    ))}
                    <SubscriptionNotes>
                        <div>{`*${_('subscription_notes1')}`}</div>
                    </SubscriptionNotes>
                    <TextOverProceedButton>
                        {getTextOverProceedButton()}
                    </TextOverProceedButton>{' '}
                    <FilledTextButton
                        text={getTextInsideProceedButton()}
                        onClick={handlePlanSelection}
                        color={colors.white}
                        fillColor={
                            selectedPlan
                                ? colors.captureBlue
                                : colors.captureBlueDisabled
                        }
                    />
                    {showWarningText && (
                        <TextUnderProceedButton
                            hasFreeTrial={hasFreeTrialIncluded}>
                            {availableTrialPeriod
                                ? _(
                                      'choose_subscription_plan_to_start_free_trial',
                                  )
                                : _('choose_subscription_plan_first')}
                        </TextUnderProceedButton>
                    )}
                    {isMinSky && (
                        <BusinessCustomerInfoWrapper>
                            <BusinessCustomerInfo>
                                <InfoIcon />
                                <BusinessCustomerInfoText>
                                    <h3 style={{ margin: 0 }}>
                                        {_(
                                            'telenor_business_discount_hook_header',
                                        )}
                                    </h3>
                                    <div
                                        style={{
                                            color: colors.captureGrey700,
                                        }}>
                                        {_('telenor_business_discount_hook')}{' '}
                                        <a
                                            target="_blank"
                                            href={TELENOR_MINE_SIDER_LINK}
                                            rel="noreferrer">
                                            {_(
                                                'telenor_business_discount_hook_link',
                                            ).replace(
                                                '%telenor_mine_sider%',
                                                _('telenor_mine_sider'),
                                            )}
                                        </a>
                                    </div>
                                </BusinessCustomerInfoText>
                            </BusinessCustomerInfo>
                        </BusinessCustomerInfoWrapper>
                    )}
                </BuyMoreWrapper>
            )}
            {isCurrentlyUpdatingStoragePlan && (
                <RippleLoaderOverlay
                    backgroundColor={`rgba(${colors.captureGrey50_rgb}, 0.8)`}
                />
            )}
        </ContentWrapper>
    )
}
