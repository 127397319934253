import * as React from 'react'
import styled from 'styled-components'
import WarningIcon from '@capture/capture-components/src/icons/warning.svg?react'
import type { FileInformation } from '~/state/uploader/reducer'
import { CenteredElementWrapper } from '../Common/LayoutComponents'
import { AsyncPreviewThumb } from './AsyncPreviewThumb'

type ThumbSize = { w: number; h: number }
const ThumbWrapper = styled.div`
    width: ${(props: ThumbSize) => props.w}%;
    height: ${(props) => props.h}%;
    display: inline-block;
`

const multiThumbLayout: { [thumbCount: number]: ThumbSize[] } = {
    1: [{ w: 100, h: 100 }],
    2: [
        { w: 100, h: 50 },
        { w: 100, h: 50 },
    ],
    3: [
        { w: 50, h: 50 },
        { w: 50, h: 50 },
        { w: 100, h: 50 },
    ],
    4: [
        { w: 50, h: 50 },
        { w: 50, h: 50 },
        { w: 50, h: 50 },
        { w: 50, h: 50 },
    ],
}

const ThumbContainer = styled.div`
    height: 100%;
    width: 100%;
    lineheight: 0;
`

type Props = {
    files: FileInformation[]
}

export class MultiThumb extends React.Component<Props> {
    private getContent = () => {
        const thumbCount = Math.min(this.props.files.length, 4)
        if (multiThumbLayout[thumbCount] === undefined) {
            return (
                <CenteredElementWrapper>
                    <WarningIcon width={56} height={56} />
                </CenteredElementWrapper>
            )
        }

        return multiThumbLayout[thumbCount].map((size, i) => (
            <ThumbWrapper key={`thumb${i}`} {...size}>
                <AsyncPreviewThumb
                    fileName={this.props.files[i].name}
                    uploadId={this.props.files[i].id}
                />
            </ThumbWrapper>
        ))
    }

    public render() {
        return <ThumbContainer>{this.getContent()}</ThumbContainer>
    }
}
