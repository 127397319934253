import type { NavigateFunction } from 'react-router-dom'
import type { PageDescription } from '~/utilities/navigation'
import { LOGGED_IN_SITE_ADDRESS } from '~/config/constants'
import type { Dispatch } from '~/state/common/actions'
import {
    CurrentWebVersionWasSeen,
    FetchedAccountInfo,
    FetchedLastWebVersionSeen,
    StartFetchingAccountInfo,
    TOSTermsVerified,
    UnableToFetchAccountInfo,
    UnverifiedTermsDetected,
    UserLoggedOut,
} from '~/state/currentUser/actions'
import { currentAppVersion } from '~/state/currentUser/selectors'
import { FetchedHostDirectory } from '~/state/hosts/actions'
import { FetchedDefaultJob } from '~/state/job/actions'
import { goToOpenWeb } from '~/utilities/appLink'
import { getAbsoluteURLOfPage } from '~/utilities/urlParsing'
import { localStorageSet, sessionStorageSet } from '~/utilities/webStorage'
import { BrowserFetchObject } from './toolbox'
import type {
    DeviceFlowInitiateLoginResponse,
    DeviceFlowPollResponseOK,
} from './services/LoginService'
import { AppService } from './services/AppService'
import { getLoginServiceForApp } from './services'
import { fetchDefaultJobID } from './job'
import {
    clearLoginInfo,
    getAuthToken,
    getStoredServiceDict,
    setInitialAuthValues,
} from './externals'
import { fetchAccountAttributes } from './currentUser'
import { getHostDirectoryFromServiceDictResponse } from './apiUtils'
import { getServiceProvider } from './HostProvider'

export async function fetchAccountInfoNicely(
    dispatch: Dispatch,
): Promise<void> {
    const authToken = getAuthToken()
    const hosts = getStoredServiceDict()

    if (!authToken || !hosts) {
        clearLoginInfo()
        dispatch(UnableToFetchAccountInfo())
        return Promise.reject(Error('UnableToFetchAccountInfo'))
    }
    const service = new AppService(BrowserFetchObject, hosts, authToken)
    return fetchAccountInfo(dispatch, service, authToken)
        .then((accountInfo) => {
            if (accountInfo === undefined) {
                return
            }

            if (accountInfo.tos_to_approve) {
                dispatch(UnverifiedTermsDetected(accountInfo.tos_to_approve))
            }
            getLastSeenVersion(dispatch, service)
            fetchDefaultJobID(dispatch)
        })
        .then(() => {
            fetchAccountAttributes(dispatch)
        })
}

const transformedFetchedAccountInfo = (
    accountInfo: AccountInfo,
    authToken: string,
) =>
    FetchedAccountInfo({
        allowAnalytics:
            accountInfo.permitted_services !== undefined &&
            accountInfo.permitted_services.analytics,
        auth_token: authToken,
        file_type_counters: accountInfo.file_type_counters,
        files_disabled: accountInfo.files_disabled,
        has_unlimited_quota: Boolean(accountInfo.has_unlimited_quota),
        isBeingShutDown: Boolean(accountInfo.shutdown_info),
        is_read_only_user: accountInfo.is_read_only_user,
        latest_account_attribute_update_timestamp:
            accountInfo.latest_account_attribute_update_timestamp,
        logged_in_as: accountInfo.logged_in_as,
        max_space: parseInt(accountInfo.max_space, 10),
        name: accountInfo.name,
        number_of_subscribed_albums:
            accountInfo.number_of_subscribed_albums ?? 0,
        profile_picture: accountInfo.profile_picture_url,
        sunset_delete_date: accountInfo.sunset_delete_date,
        tos_to_approve: accountInfo.tos_to_approve ?? null,
        used_space: parseInt(accountInfo.used_space, 10),
        username: accountInfo.username,
        uuid: accountInfo.uuid,
    })

// Inner method that allows testing with mocked service and no localStorage-variables. Use fetchAccountInfoNicely
export const fetchAccountInfo = async (
    dispatch: Dispatch,
    service: AppService,
    authToken: string,
): Promise<AccountInfo | undefined> => {
    try {
        dispatch(StartFetchingAccountInfo())
        const accountInfo = await service.getAccountInfo()
        dispatch(transformedFetchedAccountInfo(accountInfo, authToken))
        return accountInfo
    } catch (e) {
        clearLoginInfo()
        dispatch(UnableToFetchAccountInfo())
        throw e
    }
}

export const getLastSeenVersion = async (
    dispatch: Dispatch,
    appService?: AppService,
) => {
    try {
        const service =
            appService ||
            (await getServiceProvider().getAppServiceForLoggedInUserDefaults())
        const response = await service.getUserOption('lastWebVersionSeen')
        dispatch(
            FetchedLastWebVersionSeen(response ? parseInt(response, 10) : -1),
        )
    } catch (e) {
        // eslint-disable-next-line no-empty
    }
}

export const setLastSeenVersion = async (dispatch: Dispatch) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.setUserOption(
            'lastWebVersionSeen',
            currentAppVersion.toString(),
        )
        dispatch(CurrentWebVersionWasSeen(currentAppVersion))
    } catch (e) {
        // eslint-disable-next-line no-empty
    }
}

export const acceptTOSVersion = async (dispatch: Dispatch, version: string) => {
    const service =
        await getServiceProvider().getAppServiceForLoggedInUserDefaults()
    await service.approveTOS(version)
    dispatch(TOSTermsVerified())
}

export const requireAccountInfo = async (
    dispatch: Dispatch,
    gotoIfLoginFail?: string,
    utmOverride?: string,
): Promise<void> => {
    try {
        await fetchAccountInfoNicely(dispatch)
    } catch {
        loginAndGoToUrl(window.location.href, gotoIfLoginFail, utmOverride)
    }
}
export const loadAccountInfoIfAvailable = async (
    dispatch: Dispatch,
): Promise<void> => {
    try {
        await fetchAccountInfoNicely(dispatch)
    } catch {
        /* We didn't get account info - that is okay */
    }
}

const _logout = async () => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.logout()
    } catch (_e) {
        // we do not handle errors here
    } finally {
        clearLoginInfo()
    }
}
export const logout = async (
    dispatch: Dispatch,
    navigate: NavigateFunction,
) => {
    // TODO: TESTS etc
    try {
        await _logout()
        dispatch(UserLoggedOut())
        navigate('/')
    } catch (e) {
        /* What if logout fails */
    }
}

// Do not dispatch UserLoggedOut action, to avoid transit to LoginRequiredPage before redirect
export const logoutAndRedirectToOpenWeb = async (dispatch: Dispatch) => {
    try {
        await _logout()
        dispatch(UserLoggedOut())
    } catch (_e) {
        /* What if logout fails */
    } finally {
        goToOpenWeb()
    }
}

export const doLogin = (utmOverride?: string) =>
    loginAndGoToUrl(location.href, undefined, utmOverride)
export const loginAndGoToUrl = (
    targetPath: URLstring,
    ifFail?: URLstring,
    utmOverride?: string,
) => {
    sessionStorageSet('historyLengthBeforeLogin', history.length.toString())
    localStorageSet('locationBeforeLogin', targetPath)

    if (ifFail) {
        localStorageSet('locationIfLoginFails', ifFail)
    }
    // Start the login flow using the method from the LoginService
    getLoginServiceForApp().login(utmOverride)

    // Return a never-resolving Promise as we will be redirecting insteadof returning any value.
    return new Promise(() => {})
}

export const loginToLoggedInWebPage = (
    targetPage: PageDescription,
    ifFail?: URLstring,
) => {
    loginAndGoToUrl(
        LOGGED_IN_SITE_ADDRESS + targetPage.url,
        ifFail || window.location.href,
    ).catch()
}

export const loginAndGoToPage = (
    page: PageDescription,
    ifFail?: URLstring,
    utmOverride?: string,
) => {
    loginAndGoToUrl(getAbsoluteURLOfPage(page), ifFail, utmOverride).catch()
}

// Internal helper to decode the backend-response when polling for DeviceFlow-status
type DeviceFlowPollResponse =
    | { status: 'ok'; data: DeviceFlowPollResponseOK }
    | { status: 'pending' }
    | { status: 'failed' }
const pollDeviceFlowLogin = async (
    poll_url: URLstring,
): Promise<DeviceFlowPollResponse> => {
    const resp = await fetch(poll_url)
    switch (resp.status) {
        case 200: {
            const { data } = await resp.json()
            return { status: 'ok', data }
        }
        case 202:
            return { status: 'pending' }
        default:
            return { status: 'failed' }
    }
}
export type DeviceFlowInitResponse = {
    userCode: string
    displayUrl: URLstring
    verificationUrlFull: URLstring
    verification: Promise<DeviceFlowPollResponseOK> // or onStatusChange?
    doStopPolling: () => void
}

const verificationUrls = (data: DeviceFlowInitiateLoginResponse) => {
    switch (data.verification_uri) {
        case 'http://td.gl/device':
        case 'https://connect.telenordigital.com/oauth/device': // Production URL - too long to show
            return {
                displayUrl: 'min-sky.no/tv',
                verificationUrlFull: `https://min-sky.no/tv.php?code=${data.user_code}`,
            }
        case 'http://staging.td.gl/device':
        case 'https://connect.staging.telenordigital.com/oauth/device':
            return {
                displayUrl: 'min-sky.no/tv-staging',
                verificationUrlFull: `https://min-sky.no/tv.php?mode=staging&code=${data.user_code}`,
            }
        default:
            return {
                displayUrl: data.verification_uri.replace(/^https?:\/\//, ''),
                verificationUrlFull: data.verification_uri_with_user_code,
            }
    }
}

export const initiateDeviceFlow = async (): Promise<DeviceFlowInitResponse> => {
    const { data } = await getLoginServiceForApp().initiateDeviceFlowLogin()
    let isPolling = true
    const verification: Promise<DeviceFlowPollResponseOK> = new Promise(
        (resolve, reject) => {
            const doPoll = async () => {
                if (!isPolling) {
                    return
                }
                const pollResult = await pollDeviceFlowLogin(data.poll_url)
                switch (pollResult.status) {
                    case 'ok':
                        resolve(pollResult.data)
                        break
                    case 'failed':
                        reject(Error('Login was rejected'))
                        break
                    default:
                        setTimeout(doPoll, data.poll_interval_sec * 1000)
                        break
                }
            }
            setTimeout(doPoll, data.poll_interval_sec * 1000)
        },
    )
    return {
        userCode: data.user_code,
        ...verificationUrls(data),
        verification,
        doStopPolling: () => {
            isPolling = false
        },
    }
}
export const handleDeviceFlowSuccess = async (
    dispatch: Dispatch,
    data: DeviceFlowPollResponseOK,
    isSticky: boolean,
) => {
    const hosts = getHostDirectoryFromServiceDictResponse(data.service)
    setInitialAuthValues(data.auth, hosts, isSticky)
    if (data.user_creation) {
        sessionStorageSet('userCreated', '1')
    }
    dispatch(transformedFetchedAccountInfo(data.account_info, data.auth))
    dispatch(FetchedHostDirectory({ hosts }))
    dispatch(FetchedDefaultJob(data.defaultJob))
}
