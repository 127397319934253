import type { Store } from '@reduxjs/toolkit'
import {
    isLoggingIn,
    getCurrentUserUUID,
    isLoggedIn,
} from '~/state/currentUser/selectors'
import {
    ErrorWhenFetchingJobInfo,
    FetchedJobInfo,
    JobNotFound,
    JobRequiresLoggedInUser,
    JobRequiresPassword,
    StartedFetchingJobInfo,
} from '~/state/jobInfo/actions'
import type { JobInfo } from '~/state/jobInfo/reducer'
import { JobInfoStatus } from '~/state/jobInfo/reducer'
import { getTheJobCurrentlyDisplayed } from '~/state/jobSyncing/selectors'
import { UserInfoWasFetched } from '~/state/users/actions'
import { inArray } from '~/utilities/arrayUtils'
import { consoleLog } from '~/utilities/logging'
import type { StateWithAlbumCreation } from '~/state/album/reducer'
import type { ResponseNotOKError } from '../toolbox'
import { fetchContributingUsers } from '../job'
import { getServiceProvider } from '../HostProvider'
import { getUserDefinedViewMode } from '../album'

/**
 * The job of the JobInfoSyncer:
 *  - Detect which (if any) job the app is currently interested in
 *  - If the JobInfo is not present for current job, fetch it (including host-directory if needed)
 */

class JobInfoSyncer {
    private blocked: DictionaryOf<boolean> = {}

    constructor(private store: Store) {
        store.subscribe(() => this.digestState(store.getState()))
    }

    private digestState = async (
        newState?: StateOfSelector<typeof getTheJobCurrentlyDisplayed> &
            StateWithAlbumCreation,
    ) => {
        if (newState === undefined) {
            console.warn('JobInfoSyncer: undefined state.')
            return
        }

        const currentlyInFocus = getTheJobCurrentlyDisplayed(newState)

        if (
            currentlyInFocus === undefined ||
            this.blocked[currentlyInFocus] === true
        ) {
            return
        }

        const currentJobInfoElement = newState.jobInfo[currentlyInFocus]

        // Only fetch jobInfo for JobInfoStatuses that requires fetching
        const nonFetchableStates = [
            JobInfoStatus.PENDING,
            JobInfoStatus.FETCHED,
            JobInfoStatus.PASSWORD_REQUIRED,
            JobInfoStatus.RECENTLY_DELETED,
            JobInfoStatus.NOT_FOUND,
            JobInfoStatus.FETCH_FAILED,
            JobInfoStatus.LOGIN_REQUIRED,
        ] //
        if (
            currentJobInfoElement &&
            inArray(nonFetchableStates, currentJobInfoElement.status)
        ) {
            return
        }

        // Do not start fetching if there is a login-process ongoing
        if (isLoggingIn(newState)) {
            return
        }

        // Only keep one thread for each job at the same time (no need to to work already started by another state change)
        this.blocked[currentlyInFocus] = true
        consoleLog('started fetching jobInfo for job ', currentlyInFocus)

        // Do not send StartedFetchingJobInfo if the reason we are refecting is due to exipired jobinfo
        if (
            !(
                currentJobInfoElement &&
                currentJobInfoElement.status === JobInfoStatus.PARTIALLY_FETCHED
            )
        ) {
            this.store.dispatch(StartedFetchingJobInfo(currentlyInFocus))
        }

        // Get hosts for job, then fetch job info as required;
        getServiceProvider()
            .getAppServiceForJob(currentlyInFocus)
            .then((service) =>
                service.getJobInfo(
                    currentlyInFocus,
                    !(
                        newState.albumCreation?.albumJustCreated ===
                        currentlyInFocus
                    ),
                ),
            )
            .then(async (jobInfo: JobInfoResponse) => {
                const info = (function (): JobInfo {
                    if ('type' in jobInfo && jobInfo.type === 'story') {
                        return {
                            type: jobInfo.type,
                            ctime: jobInfo.ctime,
                            mtime: jobInfo.mtime,
                            owner: jobInfo.owner.uuid,
                            title: jobInfo.name || '',
                            coverPhoto: jobInfo.cover_id,
                            allow_comments: jobInfo.permissions
                                ? jobInfo.permissions.allow_comments !== 0
                                : true,
                            allow_uploads: jobInfo.permissions
                                ? jobInfo.permissions.allow_uploads !== 0
                                : true,
                            isShared: jobInfo.privacy_mode
                                ? jobInfo.privacy_mode === 'shared'
                                : false,
                            last_update:
                                jobInfo.last_update ||
                                -1 /* May be unknown for timeline, only used with albums */,
                            sort_order: jobInfo.attributes?.sort_order,
                            size: jobInfo.size,
                            has_heic: jobInfo.has_heic,
                        }
                    }

                    if ('type' in jobInfo && jobInfo.type === 'share') {
                        return {
                            type: 'share',
                            ctime: jobInfo.ctime,
                            mtime: jobInfo.mtime,
                            owner: jobInfo.owner.uuid,
                        }
                    }

                    return {
                        type: 'timeline',
                        ctime: jobInfo.ctime,
                        mtime: jobInfo.mtime,
                        owner: jobInfo.owner.uuid,
                    }
                })()
                const { uuid, name, email, profile_picture_url } = jobInfo.owner
                this.store.dispatch(
                    UserInfoWasFetched([
                        {
                            userID: uuid,
                            name,
                            email,
                            profilePicture: profile_picture_url,
                        },
                    ]),
                )

                // Whenever a story-job is fetched, also fetch the list of contributing users.
                if ('type' in jobInfo && jobInfo.type === 'story') {
                    const currentUserID = getCurrentUserUUID(
                        this.store.getState(),
                    )

                    const isOwnAlbum = jobInfo.owner.uuid === currentUserID
                    fetchContributingUsers(
                        this.store.dispatch,
                        currentlyInFocus,
                        isOwnAlbum ? undefined : currentUserID,
                    )
                    if (isLoggedIn(newState)) {
                        await getUserDefinedViewMode(
                            this.store.dispatch,
                            currentlyInFocus,
                        )
                    }
                }

                this.store.dispatch(
                    FetchedJobInfo({ job: currentlyInFocus, info }),
                )
                this.blocked[currentlyInFocus] = false
            })
            .catch((err: ResponseNotOKError) => {
                // Checking for err.response below as the promise may have been rejected for other reasons than ResponseNotOK
                // and then the response will not be on the Error-object.
                const status = err.response ? err.response.status : undefined
                // Backend returns HTTP400 for password-protected stories with auth="", and sometimes 403
                if (status === 400 || status === 401 || status === 403) {
                    // NB: Backend also returns 400-errors when fetching hosts for jobs that does not match valid formats.
                    // We want to treat invalid IDs as if they do not exist (since the user most likely have copied the URL incorrectly or in other ways tampered with the URL)
                    // Read the returned string and do magic here:
                    err.response.text().then((s: string) => {
                        if (s.indexOf('public job auth failed') !== -1) {
                            this.store.dispatch(
                                JobRequiresPassword(currentlyInFocus),
                            )
                        } else if (
                            s.indexOf(
                                'User does not have access to secured job',
                            ) !== -1
                        ) {
                            const currentUserID = getCurrentUserUUID(
                                this.store.getState(),
                            )
                            if (currentUserID) {
                                this.store.dispatch(
                                    JobNotFound(currentlyInFocus),
                                )
                            } else {
                                this.store.dispatch(
                                    JobRequiresLoggedInUser(currentlyInFocus),
                                )
                            }
                        } else {
                            this.store.dispatch(JobNotFound(currentlyInFocus))
                        }
                    })
                } else if (status === 404) {
                    this.store.dispatch(JobNotFound(currentlyInFocus))
                } else {
                    this.store.dispatch(
                        ErrorWhenFetchingJobInfo(currentlyInFocus),
                    )
                }
                this.blocked[currentlyInFocus] = false
            })
    }
}

export const connectJobInfoSyncer = (store: Store) => {
    new JobInfoSyncer(store)
}
