import * as React from 'react'
import styled from 'styled-components'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import CheckIcon from '@capture/capture-components/src/icons/check.svg?react'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import { goBack } from '~/utilities/navigation'
import { IconButton, TextButton } from '../Common/IconTextButton'
import { ThreeAreasNavBar } from '../Navigation/ThreeAreasNavBar'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'

const IconWrapper = styled.div`
    height: 24px;
`
const TextButtonWrapper = styled.div`
    text-transform: uppercase;
    font-weight: bold;
`
const PageTitle = styled.div`
    margin-left: 24px;
    font-size: ${fontSize.medium_16};
`
const Count = styled.span`
    color: ${(props: { isValid: boolean }) =>
        props.isValid ? colors.captureBlue : colors.captureMagenta};
    margin-right: 16px;
    font-size: ${fontSize.small_14};
`

type Props = {
    nextButtonLabel?: string
    headerTitle?: string
    onNextBtnClick: () => void
    selectedCount: number
    selectionMax?: number
    isMobileMode: boolean
    showBtnTextAlways?: boolean
}

export class PhotoSelectionNavbar extends React.Component<Props> {
    private isCountValid = () => {
        return this.props.selectedCount <= (this.props.selectionMax || Infinity)
    }
    private getNextButton = (type: 'text' | 'icon') => {
        const isNextButtonActive =
            this.props.selectedCount > 0 && this.isCountValid()
        const nextButtonProps = {
            onClick: this.props.onNextBtnClick,
            text: this.props.nextButtonLabel || _('next'),
            icon: CheckIcon,
            color: isNextButtonActive
                ? colors.captureBlue
                : colors.captureGrey400,
            isActive: isNextButtonActive,
        }
        if (type === 'text') {
            return (
                <TextButtonWrapper>
                    <TextButton
                        data-cy="photoSelectionNext"
                        {...nextButtonProps}
                        fontSize={fontSize.small_14}
                    />
                </TextButtonWrapper>
            )
        }
        return <IconButton data-cy="photoSelectionNext" {...nextButtonProps} />
    }
    public render() {
        const { selectedCount, selectionMax } = this.props

        const leftElement = (
            <IconWrapper>
                <IconButton onClick={goBack} icon={BackArrowIcon} />
            </IconWrapper>
        )

        const selectedStr = _('selected__format').replace(
            '%d',
            selectionMax
                ? `${selectedCount}/${selectionMax}`
                : `${selectedCount}`,
        )

        if (this.props.isMobileMode) {
            const rightElements = (
                <>
                    <Count isValid={this.isCountValid()}>{selectedStr}</Count>
                    {this.props.showBtnTextAlways
                        ? this.getNextButton('text')
                        : this.getNextButton('icon')}
                </>
            )

            return (
                <TwoAreasTopNavBar
                    left={leftElement}
                    right={rightElements}
                    hideUserAvi={true}
                />
            )
        }

        const middleElement = this.props.headerTitle && (
            <PageTitle>{this.props.headerTitle}</PageTitle>
        )
        const rightElements = (
            <>
                <Count isValid={this.isCountValid()}>{selectedStr}</Count>
                {this.getNextButton('text')}
            </>
        )
        return (
            <ThreeAreasNavBar
                left={leftElement}
                middle={middleElement}
                right={rightElements}
            />
        )
    }
}
