import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import { _ } from '~/assets/localization/util'
import { fontSize } from '~/assets/styleConstants'
import { IconTextButton } from '~/components/Common/IconTextButton'
import { PageWrapper } from '~/components/Common/PageWrapper'
import { TwoAreasTopNavBar } from '~/components/Navigation/TwoAreasTopNavBar'
import { SettingsPrivacy } from '~/routing/pages'
import { takeoutAnalytics } from './takeoutAnalytics'

const CenteredContent = styled.p`
    display: grid;
    place-content: center;
    max-width: 275px;
    text-align: center;
`

export const _TakeoutPageMobile = () => {
    const navigate = useNavigate()
    const navigateToSettings = () => navigate(SettingsPrivacy.url)

    useEffect(() => {
        takeoutAnalytics.takeoutInMobile()
    }, [])

    return (
        <PageWrapper
            navBar={
                <TwoAreasTopNavBar
                    left={
                        <IconTextButton
                            onClick={navigateToSettings}
                            text={_('privacy')}
                            icon={BackArrowIcon}
                            fontSize={fontSize.small_14}
                        />
                    }
                />
            }>
            <CenteredContent>{_('takeout_mobile_text')}</CenteredContent>
        </PageWrapper>
    )
}
