import * as React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { SetIsNewSignup } from '~/state/currentUser/actions'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { HOME_SITE_ADDRESS } from '~/config/constants'
import { loadAccountInfoIfAvailable, requireAccountInfo } from '~/API/login'
import {
    setDimension,
    trackEvent,
    trackMultipleEventsInternal,
} from '~/analytics/eventTracking'
import {
    getTOSVerificationInfo,
    getUserLoginStatus,
    isTNNCustomer,
} from '~/state/currentUser/selectors'
import { getBrowserInfo, getOSInfo } from '~/utilities/device'
import {
    localStorageGet,
    localStorageSet,
    sessionStorageGet,
    sessionStorageRemove,
} from '~/utilities/webStorage'
import { LoginProgressStep } from '~/state/currentUser/reducer'
import { LoginRequiredPage } from '../Info/LoginRequiredPage'
import { LoggedOutPromptOverlay } from './DialoguePromptOverlay'
import { LoadingPage } from './LoadingPage'

type Config<P> = {
    loginFailedURLProvider?: (props: P) => URLstring // Allow override of endpoint if login is canceled (defaults to /)
    loginUtmSource?: (props: P) => string | undefined // Override/set utm_source passed to backend when calling login
}

type StateProps<T> = {
    loginStatus: LoginProgressStep
    tosVersionToAccept: { link: URLstring; version: string } | null | undefined
    isTNNCustomer: boolean | undefined
    innerProps: T
}

const mapStateToProps = <T extends object>(
    state: StateOfSelector<typeof getUserLoginStatus> &
        StateOfSelector<typeof getTOSVerificationInfo> &
        StateOfSelector<typeof isTNNCustomer>,
    ownProps: T,
): StateProps<T> => ({
    loginStatus: getUserLoginStatus(state),
    tosVersionToAccept: getTOSVerificationInfo(state),
    isTNNCustomer: isTNNCustomer(state),
    innerProps: ownProps,
})

/**
 * Higher-Order-Component for decorating components so that they are only available if the user is logged in.
 * The decorated component will require the user to log in if not already logged in.
 */
export const requiresLogin =
    <P extends object>(conf: Config<P> = {}, fromInsideApp?: boolean) =>
    (Inner: React.ComponentType<P>): React.ComponentType<P> => {
        type DispatchProps = {
            fetchAccountInfo: () => Promise<void>
            doLogIn: (
                gotoIfLoginFails: string,
                utmStringOverride?: string,
            ) => void
            setIsNewSignup: (status: boolean) => void
        }
        type Props = StateProps<P> & DispatchProps
        class RequireLoggedIn extends React.Component<Props> {
            private tryLogIn = (utmOverride?: string) => {
                this.props.doLogIn(
                    conf.loginFailedURLProvider
                        ? conf.loginFailedURLProvider(this.props.innerProps)
                        : '/',
                    utmOverride ||
                        (conf.loginUtmSource
                            ? conf.loginUtmSource(this.props.innerProps)
                            : undefined),
                )
            }
            private doTryLogIn = () =>
                this.tryLogIn(/* to avoid click-event being passed as utmOverride */)

            private goBackIfNotLoggedIn = () => {
                window.location.href = conf.loginFailedURLProvider
                    ? conf.loginFailedURLProvider(this.props.innerProps)
                    : HOME_SITE_ADDRESS
            }

            private handleClickSignInInLoggedOutPrompt = () => {
                this.setState({ showLoggedOutDialog: false })
                this.tryLogIn('session_expired')
            }
            private hasSentPropEvents = () =>
                localStorageGet('propEventsSent') === '1'
            private sendPropertyEvents = () => {
                if (!this.hasSentPropEvents()) {
                    const events = [
                        {
                            eventName: 'prop_client_codename',
                            eventValue: { value: 'captureredux' },
                        },
                        {
                            eventName: 'prop_client_version',
                            eventValue: { value: import.meta.env.VITE_VERSION },
                        },
                        {
                            eventName: 'prop_device_platform',
                            eventValue: { value: getOSInfo().os },
                        },
                        {
                            eventName: 'prop_device_os_version',
                            eventValue: { value: getOSInfo().version },
                        },
                        {
                            eventName: 'prop_device_browser',
                            eventValue: { value: getBrowserInfo().browser },
                        },
                        {
                            eventName: 'prop_device_browser_version',
                            eventValue: { value: getBrowserInfo().version },
                        },
                    ]
                    trackMultipleEventsInternal(events)
                    localStorageSet('propEventsSent', '1')
                }
            }

            public async componentDidMount() {
                if (
                    this.props.loginStatus !== LoginProgressStep.AUTHENTICATED
                ) {
                    await this.props.fetchAccountInfo()
                }
            }

            public render() {
                if (this.props.loginStatus === LoginProgressStep.FETCHING) {
                    return <LoadingPage />
                }
                if (
                    this.props.loginStatus === LoginProgressStep.NOT_STARTED ||
                    this.props.loginStatus === LoginProgressStep.FAILED
                ) {
                    return (
                        <LoginRequiredPage
                            text={
                                fromInsideApp
                                    ? _('login_again_for_security')
                                    : _('only_accessible_to_logged_in_users')
                            }
                            onRegister={this.doTryLogIn}
                            onSignIn={this.doTryLogIn}
                            goBack={this.goBackIfNotLoggedIn}
                            fromInsideApp={fromInsideApp}
                        />
                    )
                }
                if (sessionStorageGet('userCreated')) {
                    trackEvent('Login', 'UserCreated')
                    this.props.setIsNewSignup(true)
                    sessionStorageRemove('userCreated')
                }
                if (
                    this.props.loginStatus === LoginProgressStep.AUTHENTICATED
                ) {
                    this.sendPropertyEvents()
                    if (this.props.isTNNCustomer !== undefined) {
                        setDimension(
                            'dimension3',
                            this.props.isTNNCustomer
                                ? 'TNN_Customer'
                                : 'non-TNN_Customer',
                        )
                    }
                }

                const loggedOutPrompt = this.props.loginStatus ===
                    LoginProgressStep.UNAUTHENTICATED && (
                    <LoggedOutPromptOverlay
                        doSignIn={this.handleClickSignInInLoggedOutPrompt}
                    />
                )

                return (
                    <div style={{ height: '100%' }}>
                        <Inner {...this.props.innerProps} />
                        {loggedOutPrompt}
                    </div>
                )
            }
        }

        const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
            fetchAccountInfo: () => loadAccountInfoIfAvailable(dispatch),
            doLogIn: (gotoIfLoginFails: string, utmOverride?: string) =>
                requireAccountInfo(dispatch, gotoIfLoginFails, utmOverride),
            setIsNewSignup: (status: boolean) =>
                dispatch(SetIsNewSignup(status)),
        })

        // @ts-expect-error -- not sure how to fix this
        return connect<StateProps<P>, DispatchProps, P, State>(
            mapStateToProps,
            mapDispatchToProps,
        )(RequireLoggedIn)
    }

/**
 * Higher-Order-Component for decorating components so that they try fetch account info.
 */

export const fetchAccount = <P extends object>(
    Inner: React.ComponentType<P>,
): React.ComponentType<P> => {
    type DispatchProps = {
        fetchAccountInfo: () => Promise<void>
    }

    type Props = StateProps<P> & DispatchProps

    class FetchAccountComp extends React.Component<Props> {
        public async componentDidMount() {
            if (this.props.loginStatus !== LoginProgressStep.AUTHENTICATED) {
                await this.props.fetchAccountInfo()
            }
        }

        public render() {
            return <Inner {...this.props.innerProps} />
        }
    }

    const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
        fetchAccountInfo: () => loadAccountInfoIfAvailable(dispatch),
    })

    // @ts-expect-error -- not sure how to fix this
    return connect<StateProps<P>, DispatchProps, P, State>(
        mapStateToProps,
        mapDispatchToProps,
    )(FetchAccountComp)
}

// a hook variant of the above HOC
export function useFetchAccount() {
    const loginStatus = useSelector(getUserLoginStatus)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (loginStatus !== LoginProgressStep.AUTHENTICATED) {
            loadAccountInfoIfAvailable(dispatch)
        }
    }, [dispatch, loginStatus])

    return { loginStatus }
}
