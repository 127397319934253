import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ExpandIcon from '@capture/capture-components/src/icons/expand.svg?react'
import {
    ClearDownloaderState,
    MaximizeDownloaderModal,
} from '~/state/downloader/actions'
import {
    getDownloaderCount,
    getDownloaderModalState,
    getDownloaderSize,
} from '~/state/downloader/selectors'
import { _ } from '~/assets/localization/util'
import { isMobileMode } from '~/state/viewMode/selectors'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { colors, fontSize } from '~/assets/styleConstants'
import { IconButton } from '../Common/IconTextButton'
import { DownloadList } from '../Downloader/PaginatedDownloadModal'

const Wrapper = styled.div`
    position: fixed;
    bottom: 8px;
    left: 10px;
    width: ${(props: { mobile: boolean }) =>
        props.mobile ? '250px' : '304px'};
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 5px;
`

const StatusBoxWrapper = styled.div`
    background-color: white;
    box-shadow: 3px -3px 20px 2px rgba(0, 0, 0, 0.21);
    transition: height 0.3s ease-in-out;
    padding: 5px 5px 5px 5px;

    > div:first-of-type {
        justify-content: end;
    }

    p:last-of-type {
        color: ${colors.warningRed};
        text-align: center;
        text-decoration: underline;
        font-size: ${fontSize.small_12};
        :hover {
            cursor: pointer;
        }
    }
`

const TitleWrapper = styled.div`
    font-size: ${fontSize.medium_16};
    font-weight: bold;
    text-align: center;
    margin: 0px 0px 15px 0px;
`

const DownloadLinks = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 5px 5px 5px;
    justify-content: start;
    font-size: ${(props: { fontSize: string }) => props.fontSize};
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    > div {
        border-bottom: solid ${colors.captureGrey500} 0.3px;
        width: 100%;
        border-radius: 0;
        padding: 5px 10px 5px 10px;
        justify-content: start;
    }
`

const DownloadStatusPlacement = () => {
    const filesCount = useSelector(getDownloaderCount)
    const filesSize = useSelector(getDownloaderSize)?.toFixed(2)
    const modalStatus = useSelector(getDownloaderModalState)
    const isMobile = useSelector(isMobileMode)

    const dispatch = useDispatch()
    const dismissModal = () => dispatch(ClearDownloaderState())
    const maximizeModal = () => dispatch(MaximizeDownloaderModal())

    if (
        modalStatus !== 'min' ||
        filesCount === undefined ||
        filesSize === undefined
    ) {
        return null
    }

    return (
        <Wrapper mobile={isMobile}>
            <StatusBoxWrapper>
                <IconButton
                    onClick={maximizeModal}
                    icon={ExpandIcon}
                    iconSize={20}
                />
                <TitleWrapper>
                    {_('download_files')
                        .replace(
                            '%file_count%',
                            _('files__format').replace(
                                '%d',
                                filesCount.toString(),
                            ),
                        )
                        .replace(
                            '%file_count_size%',
                            bytesToSize(parseInt(filesSize)),
                        )}
                </TitleWrapper>
                {!isMobile && (
                    <DownloadLinks fontSize={fontSize.small_14}>
                        <DownloadList />
                    </DownloadLinks>
                )}

                {!isMobile && (
                    <p
                        onClick={dismissModal}
                        onKeyUp={dismissModal}
                        role="presentation">
                        {_('close_window')}
                    </p>
                )}
            </StatusBoxWrapper>
        </Wrapper>
    )
}

export default DownloadStatusPlacement
