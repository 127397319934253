import styled from 'styled-components'
import MailIcon from '@capture/capture-components/src/icons/email.svg?react'
import LinkIcon from '@capture/capture-components/src/icons/get-link.svg?react'
import SMSIcon from '@capture/capture-components/src/icons/sms.svg?react'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import type { SVGIcon } from '../Common/IconTextButton'

type Props = React.PropsWithChildren<{
    onClick?: () => void
    isDisabled?: boolean
}>
const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const IconText = styled.span`
    color: ${colors.captureGrey500};
    font-size: ${fontSize.small_12};
    margin-top: 4px;
`

const DisabledIcon = styled.div`
    opacity: 0.5;

    & > svg {
        display: block;
    }
`

type ShareByOptionProps = Props & {
    icon: SVGIcon
    text: string
    iconColor?: string
}
const ShareByOption = ({
    icon: OptionIcon,
    text,
    iconColor,
    isDisabled,
    onClick,
    children,
}: ShareByOptionProps) => {
    let icon = <OptionIcon width={48} height={48} color={iconColor} />
    if (isDisabled === true) {
        icon = <DisabledIcon>{icon}</DisabledIcon>
    }
    return (
        <IconWrapper
            onClick={onClick}
            onKeyUp={onClick}
            role="button"
            tabIndex={0}>
            {icon}
            <IconText>{text}</IconText>
            {children}
        </IconWrapper>
    )
}

export const ShareByMailButton: React.FunctionComponent<Props> = (props) => (
    <ShareByOption icon={MailIcon} text={_('email')} {...props} />
)
export const ShareBySMSButton: React.FunctionComponent<Props> = (props) => (
    <ShareByOption icon={SMSIcon} text={_('SMS')} {...props} />
)

export const GetLinkButton: React.FunctionComponent<Props> = (props) => (
    <ShareByOption icon={LinkIcon} text={_('get_link')} {...props} />
)
