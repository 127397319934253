import * as React from 'react'
import { connect } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { PRODUCT_NAME } from '~/config/constants'
import { deleteAlbum, unsubscribeFromAlbum } from '~/API/album'
import { deleteFile, removeConnectedDevice } from '~/API/job'
import {
    cancelStoragePlan,
    changeCurrentPlan,
    reactivateStoragePlan,
} from '~/API/storagePlan'
import { getMaxStorage, getUsedStorage } from '~/state/currentUser/selectors'
import type { ExtendedJobFile } from '~/state/files/reducer'
import { getFileByID } from '~/state/files/selectors'
import type { StripeStoragePlan } from '~/state/storagePlan/selectors'
import {
    getAvailableProducts,
    getCurrentStripeStoragePlan,
} from '~/state/storagePlan/selectors'
import { getTimelineJobID } from '~/state/timeline/selectors'
import {
    type PendingUserAction,
    UserActionAlertHandled,
    UserActionConfirmHandled,
    UserActionTypes,
} from '~/state/userActions/actions'
import {
    getUserActionAlert,
    getUserActionPendingConfirm,
} from '~/state/userActions/selectors'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { ConfirmPromptOverlay, OkPromptOverlay } from './DialoguePromptOverlay'
import { localizedDateStringShort } from './TimeStrings'

type StateProps = {
    getFile: (fileID: FileID) => ExtendedJobFile | undefined
    currentMaxStorage: number
    currentUsedStorage: number
    currentStoragePlan?: StripeStoragePlan
    userActionConfirm: PendingUserAction | undefined
    userActionAlert: PendingUserAction | undefined
    availablePlans: StripeProduct[]
    timelineID: JobID | undefined
}

type DispatchProps = {
    dispatch: Dispatch
}

type Props = StateProps & DispatchProps & WithRouterProps

type DialogContent = {
    text: (target: string) => string
    header?: string
    action?: (
        dispatch: Dispatch,
        target: string,
        navigate?: NavigateFunction,
    ) => void
}

const planPeriodStrings: Record<'monthly' | 'yearly', string> = {
    monthly: _('monthly'),
    yearly: _('yearly'),
}

class DialogComponent extends React.PureComponent<Props> {
    private alerts: DictionaryOf<DialogContent> = {
        [UserActionTypes.FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) => _('file_count_exceeds_download_limit'),
        },
        [UserActionTypes.SIZE_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) => _('size_exceeds_download_limit'),
        },
        [UserActionTypes.ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) =>
                _('album_file_count_exceeds_download_limit'),
        },
        [UserActionTypes.ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) => _('album_size_exceeds_download_limit'),
        },
        [UserActionTypes.PLAN_DOWNGRADE_CLICKED]: {
            text: (_target: string) => {
                const contextualPart = this.props.currentStoragePlan?.isCanceled
                    ? _('downgrade_canceled_plan_alert')
                    : _('downgrade_active_plan_alert')
                return `${_('downgrade_not_available')} ${contextualPart}`
            },
        },
        [UserActionTypes.INSUFFICIENT_PLAN_CLICKED]: {
            text: (_target: string) => {
                // target is total storage size after buying plan
                return _('insufficient_plan_explanation__format')
                    .replace(
                        '%max_storage%',
                        bytesToSize(parseInt(_target, 10)),
                    )
                    .replace(
                        '%used_space%',
                        bytesToSize(this.props.currentUsedStorage),
                    )
            },
            header: _('insufficient_plan_selected'),
        },
    }

    private confirms: DictionaryOf<DialogContent> = {
        [UserActionTypes.DELETE_ALBUM]: {
            text: () => _('delete_album_prompt_text'),
            action: deleteAlbum,
        },
        [UserActionTypes.UNSUBSCRIBE_ALBUM]: {
            text: () => _('unsubscribe_album_prompt_text'),
            action: unsubscribeFromAlbum,
        },
        [UserActionTypes.DELETE_FILE]: {
            text: (target: string) => {
                const file = this.props.getFile(target)
                if (file && file.jobID === this.props.timelineID) {
                    return _('delete_single_file_prompt')
                }
                return _('delete_album_file_prompt_text')
            },
            action: (dispatch: Dispatch, target) => {
                const file = this.props.getFile(target)
                if (file) {
                    deleteFile(dispatch, file.jobID, target)
                }
            },
        },
        [UserActionTypes.DELETE_DEVICE]: {
            text: () =>
                _('remove_device_prompt__format').replace('%s', PRODUCT_NAME),
            action: removeConnectedDevice,
        },
        [UserActionTypes.CANCEL_STORAGE_PLAN]: {
            text: () => {
                if (this.props.currentStoragePlan) {
                    const { validToDate, size } = this.props.currentStoragePlan

                    const expiredDate =
                        validToDate || localizedDateStringShort(new Date())
                    // TODO (CAPWEB-1693): Drop last sentence of cancel_storage_plan_prompt__format if unlimited
                    const storageAmount =
                        this.props.currentMaxStorage === Infinity
                            ? _('unlimited').toLocaleLowerCase()
                            : bytesToSize(
                                  this.props.currentMaxStorage -
                                      size * Math.pow(2, 30),
                              )

                    return _('cancel_storage_plan_prompt__format')
                        .replace('%storage_amount%', storageAmount)
                        .replace('%expired_date%', expiredDate)
                }
                return ''
            },
            action: cancelStoragePlan,
        },
        [UserActionTypes.REACTIVATE_STORAGE_PLAN]: {
            text: () => {
                if (this.props.currentStoragePlan?.validToDate) {
                    return _('reactivate_storage_plan_prompt__format').replace(
                        '%period%',
                        planPeriodStrings[this.props.currentStoragePlan.period],
                    )
                }
                return ''
            },
            action: reactivateStoragePlan,
        },
        [UserActionTypes.CHANGE_STORAGE_PLAN]: {
            text: (target: string) => {
                const targetSize = this.props.availablePlans.filter(
                    (f) => f.id === target,
                )[0].size
                if (this.props.currentStoragePlan && targetSize) {
                    if (targetSize === this.props.currentStoragePlan.size) {
                        return _('change_storage_plan_period_prompt')
                    }
                    // TODO: Get lowest denomination (bytes) from backend as we do with price?
                    return _('upgrade_storage_plan_size_prompt__format')
                        .replace('%new_size%', bytesToSize(targetSize))
                        .replace(
                            '%current_size%',
                            bytesToSize(this.props.currentStoragePlan.size),
                        )
                }

                return ''
            },
            action: changeCurrentPlan,
        },
    }

    private onUserActionConfirm = () => {
        if (this.props.userActionConfirm) {
            const { type, target } = this.props.userActionConfirm
            const action = this.confirms[type].action
            if (action) {
                action(
                    this.props.dispatch,
                    target,
                    type === UserActionTypes.DELETE_ALBUM
                        ? this.props.navigate
                        : undefined,
                )
            }
        }

        this.onConfirmPromptHandled()
    }

    private onConfirmPromptHandled = () => {
        this.props.dispatch(UserActionConfirmHandled())
    }

    private onErrorPromptHandled = () => {
        this.props.dispatch(UserActionAlertHandled())
    }

    public render() {
        if (this.props.userActionConfirm) {
            const { type, target } = this.props.userActionConfirm
            let confirmText, cancelText

            switch (type) {
                case UserActionTypes.DELETE_ALBUM:
                case UserActionTypes.DELETE_FILE:
                    confirmText = _('delete_')
                    break
                case UserActionTypes.CANCEL_STORAGE_PLAN:
                    confirmText = _('cancel_plan')
                    cancelText = _('keep_plan')
                    break
                case UserActionTypes.REACTIVATE_STORAGE_PLAN:
                    confirmText = _('reactivate')
                    break
                case UserActionTypes.UNSUBSCRIBE_ALBUM:
                    confirmText = _('unsubscribe')
                    break
                case UserActionTypes.DELETE_DEVICE:
                    confirmText = _('log_out')
                    break
            }
            return (
                <ConfirmPromptOverlay
                    onConfirm={this.onUserActionConfirm}
                    onCancel={this.onConfirmPromptHandled}
                    confirmText={confirmText}
                    cancelText={cancelText}>
                    {this.confirms[type].text(target)}
                </ConfirmPromptOverlay>
            )
        }

        if (
            this.props.userActionAlert &&
            this.alerts[this.props.userActionAlert.type]
        ) {
            const { type, target } = this.props.userActionAlert
            return (
                <OkPromptOverlay
                    onOK={this.onErrorPromptHandled}
                    header={this.alerts[type].header}>
                    {this.alerts[type].text(target)}
                </OkPromptOverlay>
            )
        }

        return null
    }
}

type UserActionDialogPropsStoreState = StateOfSelector<
    typeof getUserActionPendingConfirm
> &
    StateOfSelector<typeof getUserActionAlert> &
    StateOfSelector<typeof getFileByID> &
    StateOfSelector<typeof getMaxStorage> &
    StateOfSelector<typeof getUsedStorage> &
    StateOfSelector<typeof getCurrentStripeStoragePlan> &
    StateOfSelector<typeof getAvailableProducts> &
    StateOfSelector<typeof getTimelineJobID>
const mapStateToProps = (
    state: UserActionDialogPropsStoreState,
): StateProps => ({
    userActionConfirm: getUserActionPendingConfirm(state),
    userActionAlert: getUserActionAlert(state),
    getFile: (fileID: FileID) => getFileByID(state, fileID),
    currentMaxStorage: getMaxStorage(state),
    currentUsedStorage: getUsedStorage(state),
    currentStoragePlan: getCurrentStripeStoragePlan(state),
    availablePlans: getAvailableProducts(state),
    timelineID: getTimelineJobID(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({ dispatch })

export const UserActionDialogPlacement = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DialogComponent),
)
