import {
    cachedInArray,
    groupArray,
    tranformIntoCachedInArray,
} from '~/utilities/arrayUtils'
import { Day_asString, getFileDay } from '~/utilities/dateOperations'
import {
    Dictionary_filter,
    Dictionary_forEach,
    Dictionary_map,
} from '~/utilities/dictionaryUtils'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import type { FileDescription } from '../job/actions'
import {
    AllJobFilesWasFetched,
    FileRangeWasFetched,
    FileWasCopiedToJob,
    FileWasRemovedFromJob,
    FilesDeletionStarted,
    FilesRestorationSucceeded,
    FilesWereAddedToJob,
} from '../job/actions'
import { TimelineSectionReference__allDays } from '../timeline'
import { SectionsWereExpired } from '../timeline/actions'
import {
    FileCommentAborted,
    FileCommentError,
    FileCommentSubmitSuccessful,
    FileCommentSubmitted,
    FileDeletionFailed,
    FileDeletionStarted,
    FileDimensionsDiscovered,
    JobFilesWereExpired,
    ReactionChangesSubmitError,
    ReactionChangesSubmitSuccessfull,
    ReactionChangesSubmitted,
    VideoTranscodeError,
    VideoTranscodeQueued,
    VideoTranscodeReady,
    VideoTranscodeStarted,
} from './actions'

export enum CommentStatus {
    NOT_YET_SUBMITTED = 'NOT_YET_SUBMITTED', // Default state
    PENDING = 'PENDING', // Comment is sent to backend, waiting for response
    ERROR = 'ERROR', // Something went wrong when trying to save comment
}

export enum EncodingStatus {
    FETCHING = 'FETCHING', // Waiting for encoding status to be loaded
    PROCESSING = 'PROCESSING', // In Queue encoding state
    ENCODED = 'ENCODED', // Encoded
    ERROR = 'ERROR', // Encoding check failed
}

export type ExtendedJobFile = {
    jobID: JobID
    fileID: FileID
    addedBy: UserID
    duration?: number
    encodingStatus?: EncodingStatus
    checksum: string
    ctime?: number // Not present on all files
    mtime: number
    timestamp: number // timestamp when file was posted
    path: string
    size: number
    width?: number
    height?: number
    pendingComment: string
    pendingCommentStatus: CommentStatus
    isPendingServerDelete: boolean
    pendingReaction?: Reaction
    isDeleted: boolean
    group?: {
        type: FileGroupType
        id: string
        isMaster: boolean
    }
}

export type FilesState = {
    files: Record<JobID, Record<FileID, ExtendedJobFile>> // Files grouped by job
    _fileJobIDMap: Record<FileID, JobID> // Internal tracking: most actions are FileID-specific - TODO: Make obsolete
}

const initialState: FilesState = { files: {}, _fileJobIDMap: {} }

const hasFile = (state: FilesState, fileID: FileID, jobID?: JobID): boolean => {
    jobID = jobID || state._fileJobIDMap[fileID]
    return (
        jobID !== undefined &&
        state.files[jobID] !== undefined &&
        state.files[jobID][fileID] !== undefined
    )
}

function newStateWithElementChanges(
    state: FilesState,
    elementId: FileID,
    changes: Partial<ExtendedJobFile>,
): FilesState {
    if (!hasFile(state, elementId)) {
        return state
    }
    const jobID = state._fileJobIDMap[elementId]
    const element: ExtendedJobFile = {
        ...state.files[jobID][elementId],
        ...changes,
    }
    return {
        ...state,
        files: {
            ...state.files,
            [jobID]: {
                ...state.files[jobID],
                [elementId]: element,
            },
        },
    }
}

type FileChanges = Record<JobID, Record<FileID, Partial<ExtendedJobFile>>> // Changes keyed by jobID/fileID
const newStateWithMultipleChanges = (
    state: FilesState,
    changes: FileChanges,
): FilesState => {
    const files = Dictionary_map(state.files, (jobFiles, jobID) => {
        if (changes[jobID] === undefined) {
            return jobFiles
        }
        return Dictionary_map(jobFiles, (file: ExtendedJobFile, fileID) => {
            if (changes[jobID][fileID] === undefined) {
                return file
            }
            return { ...file, ...changes[jobID][fileID] }
        })
    })
    return { ...state, files }
}

const groupByJob = <T>(
    items: DictionaryOf<T>,
    groups: DictionaryOf<JobID>,
): Record<JobID, DictionaryOf<T>> => {
    const grouped: Record<JobID, DictionaryOf<T>> = {}
    Dictionary_forEach(items, (item, key) => {
        grouped[groups[key]] = grouped[groups[key]] || {}
        grouped[groups[key]][key] = item as T
    })
    return grouped
}

export const fileFromFileDescription = ({
    user_uuid,
    group_type,
    group_id,
    master,
    ...fileInfo
}: FileDescription): ExtendedJobFile => {
    const group =
        group_type !== undefined && group_id !== undefined
            ? {
                  type: group_type,
                  id: group_id,
                  isMaster: master === '1',
              }
            : undefined
    return {
        ...fileInfo,
        addedBy: user_uuid,
        pendingComment: '',
        pendingCommentStatus: CommentStatus.NOT_YET_SUBMITTED,
        isPendingServerDelete: false,
        isDeleted: false,
        group,
    }
}

// TODO: separate this list to single state
type FilePathList = Record<JobID, DictionaryOf<FileID>> // indexed by [jobID][path]
const filePathListFromFiles = ({ files }: FilesState): FilePathList =>
    Dictionary_map(files, (jobFiles) => {
        const byPath: DictionaryOf<FileID> = {}
        Dictionary_forEach(jobFiles, (file: ExtendedJobFile) => {
            byPath[file.path] = file.fileID
        })
        return byPath
    })

const stateWithNewFiles = (
    originState: FilesState,
    newFiles: ExtendedJobFile[],
): FilesState => {
    const originList = filePathListFromFiles(originState)
    const filesByJob = groupArray(newFiles, (f) => f.jobID)

    const files = { ...originState.files }
    const jobMap = { ...originState._fileJobIDMap }

    Dictionary_forEach(filesByJob, (jobFiles: ExtendedJobFile[], jobID) => {
        const filesByPath = originList[jobID] || {}
        const newJobFiles = { ...originState.files[jobID] }
        jobFiles.forEach((f) => {
            if (filesByPath[f.path] && filesByPath[f.path] !== f.fileID) {
                delete newJobFiles[filesByPath[f.path]]
            }
            newJobFiles[f.fileID] = { ...newJobFiles[f.fileID], ...f }
            jobMap[f.fileID] = f.jobID
        })
        files[jobID] = newJobFiles
    })

    return { ...originState, files, _fileJobIDMap: jobMap }
}

export function filesReducer(
    state: FilesState = initialState,
    action: Action,
): FilesState {
    if (isType(action, FilesWereAddedToJob)) {
        return stateWithNewFiles(
            state,
            action.payload.map(fileFromFileDescription),
        )
    }
    if (isType(action, FileWasCopiedToJob)) {
        const { to, from } = action.payload // TODO: make `from` be a FileReference
        if (hasFile(state, to.fileID, to.jobID) || !hasFile(state, from)) {
            return state
        }
        const newFile: ExtendedJobFile = {
            ...state.files[state._fileJobIDMap[from]][from],
            fileID: action.payload.to.fileID,
            jobID: action.payload.to.jobID,
        }
        return stateWithNewFiles(state, [newFile])
    }

    if (
        isType(action, FileRangeWasFetched) ||
        isType(action, AllJobFilesWasFetched)
    ) {
        return stateWithNewFiles(
            state,
            action.payload.files.map(fileFromFileDescription),
        )
    }

    if (isType(action, FileWasRemovedFromJob)) {
        return newStateWithElementChanges(state, action.payload.fileID, {
            isDeleted: true,
            isPendingServerDelete: false,
        })
    }

    if (isType(action, FilesRestorationSucceeded)) {
        const changes: DictionaryOf<{
            isDeleted: boolean
            isPendingServerDelete: boolean
        }> = {}
        action.payload.files.forEach((fileID) => {
            changes[fileID] = { isDeleted: false, isPendingServerDelete: false }
        })

        return newStateWithMultipleChanges(
            state,
            groupByJob(changes, state._fileJobIDMap),
        )
    }

    if (isType(action, FilesDeletionStarted)) {
        const changes: DictionaryOf<{ isPendingServerDelete: boolean }> = {}
        action.payload.files.forEach((fileID) => {
            changes[fileID] = { isPendingServerDelete: false }
        })

        return newStateWithMultipleChanges(
            state,
            groupByJob(changes, state._fileJobIDMap),
        )
    }

    if (isType(action, FileDeletionStarted)) {
        if (hasFile(state, action.payload)) {
            return newStateWithElementChanges(state, action.payload, {
                isPendingServerDelete: true,
            })
        }
    }

    if (isType(action, FileDeletionFailed)) {
        if (hasFile(state, action.payload)) {
            return newStateWithElementChanges(state, action.payload, {
                isPendingServerDelete: false,
            })
        }
    }

    if (isType(action, FileCommentSubmitted)) {
        return newStateWithElementChanges(state, action.payload.fileID, {
            pendingCommentStatus: CommentStatus.PENDING,
            pendingComment: action.payload.comment,
        })
    }
    if (isType(action, FileCommentSubmitSuccessful)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingComment: '',
            pendingCommentStatus: CommentStatus.NOT_YET_SUBMITTED,
        })
    }
    if (isType(action, FileCommentError)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingCommentStatus: CommentStatus.ERROR,
        })
    }
    if (isType(action, FileCommentAborted)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingCommentStatus: CommentStatus.NOT_YET_SUBMITTED,
        })
    }

    if (isType(action, VideoTranscodeStarted)) {
        return newStateWithElementChanges(state, action.payload, {
            encodingStatus: EncodingStatus.FETCHING,
        })
    }
    if (isType(action, VideoTranscodeQueued)) {
        return newStateWithElementChanges(state, action.payload, {
            encodingStatus: EncodingStatus.PROCESSING,
        })
    }
    if (isType(action, VideoTranscodeReady)) {
        return newStateWithElementChanges(state, action.payload, {
            encodingStatus: EncodingStatus.ENCODED,
        })
    }
    if (isType(action, VideoTranscodeError)) {
        return newStateWithElementChanges(state, action.payload, {
            encodingStatus: EncodingStatus.ERROR,
        })
    }

    if (isType(action, FileDimensionsDiscovered)) {
        const changes: Record<
            JobID,
            DictionaryOf<{ width: number; height: number }>
        > = {}
        action.payload.forEach(({ fileID, width, height }) => {
            const jobID = state._fileJobIDMap[fileID]
            changes[jobID] = changes[jobID] || {}
            changes[jobID][fileID] = { width, height }
        })
        return newStateWithMultipleChanges(state, changes)
    }

    if (isType(action, ReactionChangesSubmitted)) {
        return newStateWithElementChanges(state, action.payload.fileID, {
            pendingReaction: action.payload.reaction,
        })
    }

    if (isType(action, ReactionChangesSubmitSuccessfull)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingReaction: undefined,
        })
    }

    if (isType(action, ReactionChangesSubmitError)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingReaction: undefined,
        })
    }
    if (isType(action, JobFilesWereExpired)) {
        const isExpiredJob = cachedInArray(action.payload)
        return {
            ...state,
            files: Dictionary_filter(
                state.files,
                (_, jobID) => !isExpiredJob(jobID),
            ),
        }
    }

    if (isType(action, SectionsWereExpired)) {
        const expiredDays = action.payload.expiredSections.flatMap(
            TimelineSectionReference__allDays,
        )
        const isExpiredDay = tranformIntoCachedInArray(
            expiredDays,
            Day_asString,
        )
        return {
            ...state,
            files: Dictionary_map(state.files, (jobFiles, jobID) => {
                if (jobID === action.payload.jobID) {
                    return Dictionary_filter(
                        jobFiles,
                        (file: ExtendedJobFile) =>
                            !isExpiredDay(getFileDay(file)),
                    )
                }
                return jobFiles
            }),
        }
    }

    return state
}

export const filesReducerMapObj = {
    files: filesReducer,
}

export type StateWithFiles = StateOfReducerMapObj<typeof filesReducerMapObj>
