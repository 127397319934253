import * as React from 'react'
import styled from 'styled-components'
import MinimizeIcon from '@capture/capture-components/src/icons/minimize.svg?react'
import NoInternetIcon from '@capture/capture-components/src/icons/offline.svg?react'
import WarningIcon from '@capture/capture-components/src/icons/warning.svg?react'
import type { FileInformation } from '~/state/uploader/reducer'
import { colors, fontSize } from '~/assets/styleConstants'
import { UploadStatusBoxListEntry } from './UploadStatusBoxListEntry'

const UploadListContainer = styled.div`
    width: 100%;
    height: ${(props: { height: number }) => props.height}px;
    display: flex;
    flex-direction: column;
`
const TopElementContainer = styled.div`
    height: 54px;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.greyBorder};
    font-size: ${fontSize.small_14};
`
const ListContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`
const ListElement = styled.div`
    padding: 4px 0;
`
const TopElement = styled.div`
    display: flex;
`
const StatusText = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`
const WarningIconWrapper = styled.div`
    margin-right: 8px;
    height: 24px;
`
const MinimizeIconWrapper = styled.div`
    display: flex;
    cursor: pointer;
`
type Props = {
    filesInList: FileInformation[]
    availableHeight: number
    isOffline: boolean
    statusText: string
    displayWarningIcon: boolean
    doCollapse: () => any
}

type LocalState = {
    skippedItems: number
}

const listItemHeight = 48
const listElementsIncluded = 25

export class UploadStatusBoxList extends React.Component<Props, LocalState> {
    public state: LocalState = { skippedItems: 0 }

    private listDiv = React.createRef<HTMLDivElement>()
    private stopListScroll?: (e: WheelEvent) => any

    public componentDidMount() {
        if (this.listDiv.current) {
            this.stopListScroll = this.stopScroll(this.listDiv.current)
            this.listDiv.current.addEventListener('wheel', this.stopListScroll)
            this.listDiv.current.addEventListener('scroll', this.handleScroll)
        }
    }

    public componentWillUnmount() {
        if (this.listDiv.current) {
            if (this.stopListScroll) {
                this.listDiv.current.removeEventListener(
                    'wheel',
                    this.stopListScroll,
                )
            }
            this.listDiv.current.removeEventListener(
                'scroll',
                this.handleScroll,
            )
        }
    }

    private handleScroll = () => {
        if (this.listDiv.current) {
            const nowSkipping = Math.floor(
                Math.max(
                    0,
                    this.listDiv.current.scrollTop - 3 * listItemHeight,
                ) / listItemHeight,
            )
            if (nowSkipping !== this.state.skippedItems) {
                this.setState({ skippedItems: nowSkipping })
            }
        }
    }
    private stopScroll = (elem: HTMLElement) => (e: WheelEvent) => {
        const innerHeight = elem.children.item(0)?.clientHeight
        if (innerHeight == null) {
            e.preventDefault()
            return
        }

        const distanceToBottom =
            innerHeight - (elem.scrollTop + elem.clientHeight)

        if (e.deltaY !== undefined) {
            const scrollRange = innerHeight - elem.clientHeight
            elem.scrollTop = Math.max(
                0,
                Math.min(
                    scrollRange,
                    elem.scrollTop + e.deltaY * (e.detail !== 0 ? -1 : 1),
                ),
            )
            e.preventDefault()
        }
        // IE?
        else if (e.deltaY > 0 ? elem.scrollTop === 0 : distanceToBottom <= 1) {
            e.preventDefault()
        }
    }

    public render() {
        let warningIcon: React.ReactNode
        if (this.props.isOffline) {
            warningIcon = <NoInternetIcon color={colors.captureGrey800} />
        }
        if (this.props.displayWarningIcon) {
            warningIcon = <WarningIcon />
        }

        let filesInList = this.props.filesInList

        const skippedAfter = Math.max(
            0,
            filesInList.length - this.state.skippedItems - listElementsIncluded,
        )
        filesInList = filesInList.slice(
            this.state.skippedItems,
            this.state.skippedItems + listElementsIncluded,
        )

        const files = filesInList.map((file: FileInformation) => (
            <UploadStatusBoxListEntry
                file={file}
                isOffline={this.props.isOffline}
                key={file.id}
            />
        ))

        return (
            <UploadListContainer height={this.props.availableHeight}>
                <TopElementContainer>
                    <TopElement>
                        <StatusText>
                            <WarningIconWrapper>
                                {warningIcon}
                            </WarningIconWrapper>
                            <span>{this.props.statusText}</span>
                        </StatusText>
                        <MinimizeIconWrapper
                            onClick={this.props.doCollapse}
                            onKeyUp={this.props.doCollapse}
                            role="button"
                            tabIndex={0}>
                            <MinimizeIcon />
                        </MinimizeIconWrapper>
                    </TopElement>
                </TopElementContainer>
                <ListContainer ref={this.listDiv}>
                    <ListElement>
                        {this.state.skippedItems > 0 && (
                            <div
                                style={{
                                    height:
                                        this.state.skippedItems *
                                        listItemHeight,
                                }}
                            />
                        )}
                        {files}
                        {skippedAfter > 0 && (
                            <div
                                style={{
                                    height: skippedAfter * listItemHeight,
                                }}
                            />
                        )}
                    </ListElement>
                </ListContainer>
            </UploadListContainer>
        )
    }
}
