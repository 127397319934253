import { createSelector } from 'reselect'
import { RoutePath } from '~/routing/routePath'
import { b64ToUuid } from '~/utilities/uuid'
import type { StateWithTimeline } from '../timeline/reducers'
import type {
    JobInfo,
    JobInfoElement,
    JobInfoState,
    StateWithJobInfo,
    StoryJobInfo,
} from './reducer'
import { JobInfoStatus } from './reducer'

const getJobInfoState = (state: StateWithJobInfo): JobInfoState =>
    state.jobInfo || {}

export const getJobInfoElements = createSelector(
    getJobInfoState,
    (jobInfo): JobInfoElement[] => Object.keys(jobInfo).map((k) => jobInfo[k]),
)
type JobIDMatchMethod = (match: RegExpMatchArray) => JobID
const makeJobUUIDSearchMethod =
    (mountPoints: DictionaryOf<JobIDMatchMethod>) => (currentURL: string) => {
        return Object.keys(mountPoints).reduce<string | undefined>(
            (existing, url) => {
                const match = currentURL.match(new RegExp(url))
                return match ? mountPoints[url](match) : existing
            },
            undefined,
        )
    }
const CaptureWebJobMountPoints: DictionaryOf<JobIDMatchMethod> = {
    [RoutePath.getLoggedInAlbumPath('([^/]+)')]: (m) => b64ToUuid(m[1]),
    [RoutePath.getNotLoggedInAlbumPath('([^/]+)')]: (m) => b64ToUuid(m[1]),
    [RoutePath.getShareReceiverPath('([^/]+)')]: (m) => m[1], // TODO add b64ToUuid
    [RoutePath.getAlbumFileCommentsPath('([^/]+)', '.*')]: (m) =>
        b64ToUuid(m[1]),
    [RoutePath.getCreateAlbumLoginPath('([^/]+)')]: (m) => b64ToUuid(m[1]),
    [RoutePath.getAlbumCarouselPath('([^/]+)', '.*')]: (m) => b64ToUuid(m[1]),
    [RoutePath.getSelectForExternalEndPath('([^/]+)')]: (m) => b64ToUuid(m[1]),
    [RoutePath.getAlbumImageAsSharePath('([^/]+)', '.*')]: (m) =>
        b64ToUuid(m[1]),
}

export const getJobUUIDFromCaptureWebURL = makeJobUUIDSearchMethod(
    CaptureWebJobMountPoints,
)

export const getJobInfoElement = (
    state: StateWithJobInfo,
    jobID: JobID,
): JobInfoElement | undefined => {
    return getJobInfoState(state)[jobID]
}

export const getJobInformation = createSelector(
    getJobInfoElement,
    (jobInfoElement: JobInfoElement | undefined): JobInfo | undefined => {
        return jobInfoElement ? jobInfoElement.jobInfo : undefined
    },
)

export const getStoryJobInformation = createSelector(
    getJobInfoElement,
    (jobInfoElement: JobInfoElement | undefined): StoryJobInfo | undefined => {
        return jobInfoElement && jobInfoElement.jobInfo?.type === 'story'
            ? jobInfoElement.jobInfo
            : undefined
    },
)

export const getJobTitle = (
    state: StateWithJobInfo,
    jobID: JobID,
    defaultText: string,
): string => {
    const info = getJobInformation(state, jobID)
    return info && 'title' in info ? info.title : defaultText
}

export const getStatusOfJob = (
    state: StateWithJobInfo,
    jobID: JobID,
): JobInfoStatus => {
    const jobInfoElement = getJobInfoElement(state, jobID)
    return jobInfoElement ? jobInfoElement.status : JobInfoStatus.NOT_STARTED
}

export const getCreatorOfJob = (
    state: StateWithJobInfo,
    jobID: JobID,
): JobID | undefined => {
    const jobInfo = getJobInformation(state, jobID)
    return jobInfo ? jobInfo.owner : undefined
}

export const getProvidedPassword = (
    state: StateWithJobInfo,
    jobID: JobID,
): string | undefined => {
    const jobInfoElement = getJobInfoElement(state, jobID)
    return jobInfoElement ? jobInfoElement.providedPassword : undefined
}

export const isStoryJob = (
    state: StateWithJobInfo,
    jobID: JobID,
): boolean | undefined => {
    const jobInfo = getJobInformation(state, jobID)
    return jobInfo && jobInfo.type === 'story'
}
export const isTimelineJob = (
    state: StateWithTimeline,
    jobID: JobID,
): boolean => {
    return state.timeline.timelineJob === jobID
}
export const isShareJob = (
    state: StateWithJobInfo & StateWithTimeline,
    jobID: JobID,
): boolean | undefined => {
    const jobInfo = getJobInformation(state, jobID)
    // Shares and timeline-jobs have the same type.
    // If a timeline-job is not the timeline of the current user it must be a share
    return (
        jobInfo &&
        jobInfo.type === 'timeline' &&
        state.timeline.timelineJob !== jobID
    ) // TODO: use getTimelineJobID()
}

export const isSharedJob = createSelector(
    getJobInformation,
    (info: JobInfo | undefined): boolean => {
        if (info !== undefined && 'isShared' in info) {
            return info.isShared
        }

        return false
    },
)
