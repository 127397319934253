import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import { _ } from '~/assets/localization/util'
import { IconTextButton } from '~/components/Common/IconTextButton'
import { PageWrapper } from '~/components/Common/PageWrapper'
import { TwoAreasTopNavBar } from '~/components/Navigation/TwoAreasTopNavBar'
import { RoutePath } from '~/routing/routePath'

import { getServiceProvider } from '~/API/HostProvider'
import { trackEventInternal } from '~/analytics/eventTracking'
import { RippleLoaderOverlay } from '~/components/Common/RippleLoaderOverlay'
import { fontSize } from '~/assets/styleConstants'
import { Content } from './unsubscribe.styled'
import {
    FeedbackSaved,
    FeedbackUnsubscribed,
    UnsubscribeForm,
} from './SubscriptionFeedback'

enum Status {
    Entry = 'entry',
    Loading = 'loading',
    Error = 'error',
    Unsubscribed = 'unsubscribed',
    Saved = 'saved',
}

enum TrackingString {
    Unsubscribe = 'settings_unsubscribe_unsubscribe_me_tap',
    Stay = 'settings_unsubscribe_stay_on_the_list_tap',
    UnsubscribeSuccess = 'settings_unsubscribe_unsubscribe_success',
    UnsubscribeFail = 'settings_unsubscribe_unsubscribe_fail',
}

const emit = {
    error: (error: string) => {
        console.error(`Error on updating subscription status: ${error}`)
        trackEventInternal(TrackingString.UnsubscribeFail)
    },
    stayRequest: () => {
        trackEventInternal(TrackingString.Stay)
    },
    unsubscribeRequest: () => {
        trackEventInternal(TrackingString.Unsubscribe)
    },
    unsubscribeSucceeded: () => {
        trackEventInternal(TrackingString.UnsubscribeSuccess)
    },
}

const mutateOptionNoEmail = async (): Promise<Status> => {
    const service =
        await getServiceProvider().getAppServiceForLoggedInUserDefaults()

    const requestStatus = await service
        .updateDataProtectionConsentValues({
            stay_up_to_date__email: false,
        })
        .then((res) => {
            if (res?.ok) {
                emit.unsubscribeSucceeded()
                return Status.Unsubscribed
            } else {
                emit.error(`${res.status} - ${res.statusText}`)
                return Status.Error
            }
        })
        .catch((error) => {
            emit.error(error)
            return Status.Error
        })
    return requestStatus
}

export const useUnsubscribe = () => {
    const [status, setStatus] = useState<Status>(Status.Entry)

    const stayInList = useCallback(() => {
        emit.stayRequest()
        setStatus(Status.Saved)
    }, [])

    const doUnsubscribe = useCallback(async () => {
        setStatus(Status.Loading)
        emit.unsubscribeRequest()
        const newStatus = await mutateOptionNoEmail()
        setStatus(newStatus)
    }, [])

    return {
        status,
        doUnsubscribe,
        stayInList,
    }
}

const UnsubscribePage = () => {
    const navigate = useNavigate()
    const { status, stayInList, doUnsubscribe } = useUnsubscribe()

    const goBack = () => {
        navigate(RoutePath.Settings)
    }

    // on error, we throw to be catched on error boundary
    if (status === Status.Error) {
        throw new Error('Request to server failed')
    }

    return (
        <PageWrapper
            hideFooter
            navBar={
                <TwoAreasTopNavBar
                    left={
                        <IconTextButton
                            onClick={goBack}
                            icon={BackArrowIcon}
                            text={_('settings')}
                            fontSize={fontSize.small_14}
                        />
                    }
                />
            }>
            <Content data-cy={`unsubscribe-${status}`}>
                {status === Status.Entry && (
                    <UnsubscribeForm
                        unsubscribe={doUnsubscribe}
                        stayInList={stayInList}
                    />
                )}
                {status === Status.Loading && <RippleLoaderOverlay />}
                {status === Status.Unsubscribed && <FeedbackUnsubscribed />}
                {status === Status.Saved && <FeedbackSaved />}
            </Content>
        </PageWrapper>
    )
}

export default UnsubscribePage
