import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ShareLinkIcon from '@capture/capture-components/src/icons/get-link.svg?react'
import ShareAsAlbumIcon from '@capture/capture-components/src/icons/share-as-album.svg?react'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { createConfiguredAlbumWithFiles } from '~/API/album'
import { createShareWithFiles, publishJobByEmail } from '~/API/job'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import type { ConfigurableAlbumDetails } from '~/state/albumList/reducer'
import { AlbumLinkWasCopied } from '~/state/albumOptions/actions'
import { FilesWereShared } from '~/state/files/actions'
import type { CaptureFile } from '~/state/files/selectors'
import { isMobileDevice } from '~/utilities/device'
import { getAbsoluteURLOfPage } from '~/utilities/urlParsing'
import { Button } from '../Common/Button'
import { OptionsOverlay } from '../Common/Overlay'
import { RippleLoaderOverlay } from '../Common/RippleLoaderOverlay'
import { CopyShareLinkComponent } from '../Share/CopyLinkScreen'
import type { EmailSharingOptions } from '../Share/ShareToEmailForm'
import { ShareToEmailForm } from '../Share/ShareToEmailForm'
import { ShareByMailButton } from '../Share/ShareToOptionButtons'
import type { FileOptionContext } from './FilesOptionsPlacement'
import { SharedAlbumSettings } from './SharedAlbumSettings'

const ErrorWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    padding: 4px 4px 8px 4px;
    background-color: ${colors.captureMagenta};
    color: white;
`
const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0 20px;

    ${mediaQueries.mobile} {
        flex-direction: column;
    }
`
const GreyBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 210px;
    box-sizing: border-box;
    padding: 12px;
    border: ${(props: { isDisabled?: boolean }) =>
        props.isDisabled
            ? `2px solid ${colors.greyBorder}`
            : `2px solid ${colors.captureGrey800}`};
    border-radius: 3px;
    margin-right: 8px;
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    transition: 0.3s ease-in;

    ${(props) =>
        !props.isDisabled &&
        `&:hover {
        border-color: ${colors.captureBlue};
    }`}

    & > strong {
        color: ${(props) =>
            props.isDisabled ? colors.captureGrey500 : 'inherit'};
    }

    & > p {
        text-align: center;
        color: ${(props) =>
            props.isDisabled ? colors.captureGrey500 : 'inherit'};
        font-size: ${fontSize.small_14};
        margin: 8px 0;
    }

    ${mediaQueries.mobile} {
        width: 100%;
        margin: 4px 0;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${(props: { width?: number; height?: number }) =>
        props.height ? props.height + 'px' : 'auto'};
    width: ${({ width }) => (width ? width + 'px' : 'auto')};
    padding: 24px;
`

type OwnProps = {
    isMobile: boolean
    files: CaptureFile[]
    onCancel: () => void
    onConfirm: () => void
    context: FileOptionContext
    isLoggedIn?: boolean /* Default: true */
}

type DispatchProps = {
    shareToEmail: (
        options: EmailSharingOptions,
        shareID: string,
    ) => Promise<void>
    getShareInfo: () => Promise<{ id?: string; link: URLstring }>
    onLinkCopied: () => void
    doCreateAlbum: (albumDetails: ConfigurableAlbumDetails) => Promise<JobID>
}

type Props = OwnProps & DispatchProps

type ShareFilesOptionView =
    | 'options'
    | 'albumOptions'
    | 'externalOptions'
    | 'email'
type ShareFilesOptionsState = {
    optionView: ShareFilesOptionView
    isPending: boolean
    shareError?: string
    shareItem?: { link: string; id?: string; isAlbum?: boolean }
    optionsHeight?: number
    optionsWidth?: number
}

const defaultAlbumSettings = {
    title: _('my_new_shared_album'),
    allow_comments: true,
    allow_uploads: true,
    isShared: true,
}
class ShareFilesOptionsComp extends React.Component<
    Props,
    ShareFilesOptionsState
> {
    public state: ShareFilesOptionsState = {
        optionView: 'options',
        isPending: false,
        shareError: undefined,
        shareItem: undefined,
        optionsHeight: undefined,
    }

    private setShareError = (shareError: string) => {
        this.setState({ shareError })
        setTimeout(
            () =>
                this.setState((state) =>
                    state.shareError === shareError
                        ? { shareError: undefined }
                        : null,
                ),
            3000,
        )
    }

    private onAddToAlbumClick = () => {
        if (this.props.isLoggedIn === false) {
            this.setShareError(_('only_accessible_to_logged_in_users'))
        } else {
            this.setState({ optionView: 'albumOptions' })
        }
        trackEventInternal(
            `${
                this.props.context
                    ? this.props.context.toLocaleLowerCase() + '_'
                    : ''
            }share_as_album`,
            {
                count: this.props.files.length,
            },
        )
    }

    private onShareByEmailClick = () => {
        if (this.props.isLoggedIn === false) {
            this.setShareError(_('only_accessible_to_logged_in_users'))
        } else {
            this.setState({ optionView: 'email' })
        }
        trackEventInternal(
            `${
                this.props.context
                    ? this.props.context.toLocaleLowerCase() + '_'
                    : ''
            }share_as_link_via_email`,
            {
                count: this.props.files.length,
            },
        )
    }

    private updateStateWithShare = (shareMethod: Promise<void>) => {
        this.setState({ isPending: true })
        shareMethod
            .then(() => {
                this.setState({ isPending: false })
                this.props.onConfirm()
                trackEventInternal(
                    `${
                        this.props.context
                            ? this.props.context.toLocaleLowerCase() + '_'
                            : ''
                    }selection_share_finish`,
                    {
                        count: this.props.files.length,
                    },
                )
            })
            .catch(() => {
                this.setState({ isPending: false })
            })
    }

    private onGetShareLinkClick = () => {
        this.setState({ optionView: 'externalOptions', isPending: true })
        this.props
            .getShareInfo()
            .then(({ id, link }) =>
                this.setState({ shareItem: { id, link }, isPending: false }),
            )
            .catch(() => {
                this.setState({ optionView: 'options', isPending: false })
                this.setShareError(_('share_failed'))
            })
        trackEventInternal(
            `${
                this.props.context
                    ? this.props.context.toLocaleLowerCase() + '_'
                    : ''
            }share_as_link`,
            {
                count: this.props.files.length,
            },
        )
    }

    private onLinkCopied = () => {
        trackEvent(
            'ShareFiles',
            'shareClicked',
            'linkCopied',
            this.props.files.length,
        )
        this.props.onLinkCopied()
        this.props.onConfirm()
    }

    private handleConfirmShareEmail = (options: EmailSharingOptions) => {
        if (this.state.shareItem?.id) {
            this.updateStateWithShare(
                this.props.shareToEmail(options, this.state.shareItem.id),
            )
            trackEvent(
                'ShareFiles',
                'shareClicked',
                'sharedByEmail',
                this.props.files.length,
            )
        }
    }

    private handleAlbumSettingSaved = async (
        changes: Partial<ConfigurableAlbumDetails>,
    ) => {
        this.setState({ isPending: true })
        const albumID = await this.props.doCreateAlbum({
            ...defaultAlbumSettings,
            ...changes,
        })

        this.setState({
            shareItem: {
                id: albumID,
                link: getAbsoluteURLOfPage(Pages.AlbumNotLoggedIn(albumID)),
                isAlbum: true,
            },
            optionView: 'externalOptions',
            isPending: false,
        })
    }

    private shareToElem = React.createRef<HTMLDivElement>()
    private getContent = () => {
        const isLoggedIn = this.props.isLoggedIn !== false
        const error = this.state.shareError && (
            <ErrorWrapper>{this.state.shareError}</ErrorWrapper>
        )
        switch (this.state.optionView) {
            case 'options':
                return (
                    <div ref={this.shareToElem}>
                        {_('share_select_how_to')}
                        <OptionsWrapper>
                            <GreyBox
                                onClick={this.onAddToAlbumClick}
                                role="presentation">
                                <ShareAsAlbumIcon
                                    width={48}
                                    height={48}
                                    color={colors.defaultIconColor}
                                />
                                <strong>{_('share_as_album')}</strong>
                                <p>{_('share_album_explanation')}</p>
                            </GreyBox>
                            <GreyBox
                                onClick={this.onGetShareLinkClick}
                                onKeyUp={this.onGetShareLinkClick}
                                role="button"
                                tabIndex={0}>
                                <ShareLinkIcon width={48} height={48} />
                                <strong style={{ marginTop: 8 }}>
                                    {_('get_link')}
                                </strong>
                                <p>{_('share_get_link_explanation')}</p>
                            </GreyBox>
                        </OptionsWrapper>
                    </div>
                )
            case 'email':
                return (
                    <ShareToEmailForm
                        isMobile={this.props.isMobile}
                        onConfirm={this.handleConfirmShareEmail}
                    />
                )
            case 'albumOptions':
                return (
                    <SharedAlbumSettings
                        onSettingsSaved={this.handleAlbumSettingSaved}
                        defaultSettings={defaultAlbumSettings}
                    />
                )
            case 'externalOptions': {
                const shareOptions = [
                    <ShareByMailButton
                        key="shareByMail"
                        onClick={this.onShareByEmailClick}
                        isDisabled={!isLoggedIn}
                    />,
                ]

                return (
                    this.state.shareItem && (
                        <>
                            {this.state.shareItem.isAlbum &&
                                _('share_album_created_link')}
                            <CopyShareLinkComponent
                                isMobile={this.props.isMobile}
                                isMobileDevice={isMobileDevice.any()}
                                onConfirm={this.onLinkCopied}
                                link={this.state.shareItem.link}
                                otherOptions={shareOptions}
                                height={this.state.optionsHeight}
                                bottomElement={error}
                            />
                        </>
                    )
                )
            }
        }
    }

    public componentDidMount() {
        this.setState({
            optionsHeight: this.shareToElem.current
                ? this.shareToElem.current.clientHeight
                : undefined,
            optionsWidth: this.shareToElem.current
                ? this.shareToElem.current.clientWidth
                : undefined,
        })
    }

    public render() {
        return (
            <OptionsOverlay
                onClose={this.props.onCancel}
                cancelButton={Button(_('cancel'), this.props.onCancel)}>
                <ContentWrapper
                    width={this.state.optionsWidth}
                    height={this.state.optionsHeight}>
                    {this.getContent()}
                </ContentWrapper>
                {this.state.isPending && <RippleLoaderOverlay />}
            </OptionsOverlay>
        )
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    shareToEmail: async (options: EmailSharingOptions, shareID: string) => {
        if (ownProps.context) {
            trackEvent(
                ownProps.context,
                'SelectionSharedToEmail',
                undefined,
                ownProps.files.length,
            )
        }
        await publishJobByEmail(
            dispatch,
            shareID,
            options.recipients,
            options.subject,
            options.message,
        )
        dispatch(FilesWereShared())
    },
    getShareInfo: async () => {
        if (ownProps.isLoggedIn === false && ownProps.files.length === 1) {
            const file = ownProps.files[0]
            return {
                id: undefined,
                link: getAbsoluteURLOfPage(
                    Pages.AlbumImageAsShare(file.jobID, file.fileID),
                ),
            }
        }

        const shareID = await createShareWithFiles(dispatch, '', ownProps.files)
        if (shareID === undefined) {
            throw Error('create share failed')
        }

        return {
            id: shareID,
            link: getAbsoluteURLOfPage(Pages.Share(shareID)),
        }
    },
    onLinkCopied: () => {
        if (ownProps.context) {
            trackEvent(
                ownProps.context,
                'SelectionLinkCopied',
                undefined,
                ownProps.files.length,
            )
        }
        dispatch(AlbumLinkWasCopied())
    },
    doCreateAlbum: async (albumDetails: ConfigurableAlbumDetails) =>
        createConfiguredAlbumWithFiles(dispatch, albumDetails, ownProps.files),
})

export const ShareFilesOptions = connect(
    null,
    mapDispatchToProps,
)(ShareFilesOptionsComp)
