import { isTestEnv } from '~/config/constants'
import {
    localStorageGet,
    localStorageRemove,
    localStorageSet,
    sessionStorageGet,
    sessionStorageSet,
    webStorageClear,
} from '~/utilities/webStorage'

export type ServiceDict = {
    appHost: string
    thumbHost: string
    videoHost: string
    pollHost: string
    downloadHost: string
}

/**
 * The API Externals are elements that are considered app-constants, but needs to be loaded dynamically.
 * The values are stored in localStorage and may be updated by the fetchAccountInfo-method in ./login.ts
 * (as it is the method validating login-state and fetching these otherwise unchangeable values)
 */

// Essentially a method for doing the same things as auth.html (used when getting these values async)
export const setInitialAuthValues = (
    authToken: string,
    services: ServiceDict,
    longLivedSession = false,
) => {
    sessionStorageSet('authToken', authToken)
    sessionStorageSet('serviceDict', JSON.stringify(services))
    if (longLivedSession) {
        localStorageSet('authToken', authToken)
        localStorageSet('serviceDict', JSON.stringify(services))
    }
}
export const makeAuthValuesLongLived = () => {
    const token = sessionStorageGet('authToken')
    const dict = sessionStorageGet('serviceDict')
    if (token && dict) {
        localStorageSet('authToken', token)
        localStorageSet('serviceDict', dict)
    }
}
export const makeAuthValuesShortLived = () => {
    const authToken = sessionStorageGet('authToken')
    const serviceDict = sessionStorageGet('serviceDict')
    const authTokenLocal = localStorageGet('authToken')
    const serviceDictLocal = localStorageGet('serviceDict')
    if (!authToken && authTokenLocal) {
        sessionStorageSet('authToken', authTokenLocal)
    }
    if (!serviceDict && serviceDictLocal) {
        sessionStorageSet('serviceDict', serviceDictLocal)
    }
    localStorageRemove('authToken')
    localStorageRemove('serviceDict')
}

export const getAuthToken = (): string =>
    sessionStorageGet('authToken') || localStorageGet('authToken') || ''

export const getStoredServiceDict = (): ServiceDict | undefined => {
    const dictStr =
        sessionStorageGet('serviceDict') || localStorageGet('serviceDict')
    const dict = dictStr && JSON.parse(dictStr)
    if (
        dict &&
        dict.appHost &&
        dict.thumbHost &&
        dict.videoHost &&
        dict.pollHost &&
        dict.downloadHost
    ) {
        return dict
    }
}

export const clearLoginInfo = () => {
    webStorageClear('sessionStorage')
    webStorageClear('localStorage', [
        'showedEditorHint',
        'fastScrollerTooltip',
        'shiftSelectHint',
    ])
}

export enum LoginOption {
    Production = 'login.cptr.no',
    Testing = 'login-hotfix.cptr.no',
    Staging = 'login-staging.cptr.no',
    Client = 'login-client.univex.no',
    CI = 'login-ci.univex.no',
}

// The LOGIN_HOST is the start-point for all interaction with the app.
// It is used for user log in and the other hosts to be used by the API are provided from here
const resolveLoginHost = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const preferStaging = searchParams.has('isStaging')

    if (preferStaging) {
        return LoginOption.Staging
    }
    return isTestEnv ? LoginOption.Client : LoginOption.Production
}

export const LOGIN_HOST = resolveLoginHost()
