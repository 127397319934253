import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import DeleteIcon from '@capture/capture-components/src/icons/delete-forever.svg?react'
import ExportIcon from '@capture/capture-components/src/icons/export.svg?react'
import RestoreIcon from '@capture/capture-components/src/icons/restore.svg?react'
import type { Dispatch } from '~/state/common/actions'
import { IconTextButton } from '~/components/Common/IconTextButton'
import { _ } from '~/assets/localization/util'
import { loadTrashContent } from '~/API/job'
import { trackEvent } from '~/analytics/eventTracking'
import TrashIllustration from '~/assets/illustrations/trash-illustration.svg'
import { fontSize, layout, mediaQueries } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import {
    ConfirmableSelectionAction,
    FilesWereDeselected,
    SelectionActionInitiated,
} from '~/state/selectedFiles/actions'
import type { ImageGroup } from '~/state/timeline/selectors'
import { getTimelineJobID } from '~/state/timeline/selectors'
import { FetchMoreTrashTriggered } from '~/state/trash/actions'
import type { TrashViewFile } from '~/state/trash/selectors'
import {
    TrashGroupKey,
    getSelectedTrashFileIDs,
    getSelectedTrashFileInfos,
    getSelectedTrashFilesTotalSize,
    getTrashGroupWithSelection,
    getTrashViewFilesForJob,
    hasMoreTrashFilesToFetch,
    isLoadingTrash,
    shouldTrashBeFetched,
} from '~/state/trash/selectors'
import { getImageGroupStyle, isMobileMode } from '~/state/viewMode/selectors'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import type { FileInfoForPagination } from '~/utilities/download'
import { maybePaginatedDownloadDeletedFiles } from '~/API/download'
import { Button } from '../Common/Button'
import { DeletionNotice } from '../Common/DeletionNotice'
import { EmptyStatePage } from '../Common/EmptyStatePage'
import { PageWrapper } from '../Common/PageWrapper'
import { RippleLoader, RippleLoaderPage } from '../Common/RippleLoader'
import { SelectionActionDialogPlacement } from '../Common/SelectionActionDialogPlacement'
import { HeaderText } from '../Common/StandardPageElements'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'
import { ImageGroupList } from '../Timeline/ImageGroupList'
import {
    SelectionToolbarDesktop,
    SelectionToolbarMobile,
} from '../Timeline/SelectionToolBar'
import { FilesOptionsPlacement } from '../FilesOptions/FilesOptionsPlacement'

const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: ${layout.selectionToolbarHeight}px;

    ${mediaQueries.mobile} {
        padding-top: 32px;
    }
`

const Header = styled(HeaderText)`
    margin-top: 0;
    text-align: left;
    width: ${(props: { width: number }) => props.width}px;
`
const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
`

type StateProps = {
    isMobileMode: boolean
    files: TrashViewFile[]
    trashImageGroups: ImageGroup[]
    hasMoreToFetch: boolean
    selectedFileIDs: FileID[]
    selectedFileInfos: FileInfoForPagination[]
    selectedFilesTotalSize: number
    groupStyle: ImageGroupStyle
    isTrashLoading: boolean
    shouldTrashBeFetched: boolean
    timelineID?: JobID
}

type DispatchProps = {
    fetchTrash: () => void
    initiateDeleteFiles: () => void
    initiateRestoreFiles: () => void
    cancelSelectMode: (fileIDs: FileID[]) => void
    doFetchMoreTrashItems: () => void
    doExportFiles: (jobID: JobID, fileInfos: FileInfoForPagination[]) => void
}

type Props = StateProps & DispatchProps & WithRouterProps

class TrashPageComponent extends React.Component<Props> {
    contentElement = React.createRef<HTMLDivElement>()

    handleExport = () => {
        if (this.props.timelineID) {
            this.props.doExportFiles(
                this.props.timelineID,
                this.props.selectedFileInfos,
            )
        }
    }

    selectionButtons = [
        Button(_('export'), this.handleExport, {
            icon: ExportIcon,
            cyKey: 'trash__exportBtn',
        }),
        Button(_('restore'), this.props.initiateRestoreFiles, {
            icon: RestoreIcon,
            cyKey: 'trash__restoreBtn',
        }),
        Button(_('delete_'), this.props.initiateDeleteFiles, {
            icon: DeleteIcon,
        }),
    ]

    cancelSelecting = () => {
        this.props.cancelSelectMode(this.props.selectedFileIDs)
    }

    getTrashGroupHeader = (key: string) => {
        if (key === TrashGroupKey.DeletedSoon) {
            return _('permanently_deleted_soon')
        }
        if (key === TrashGroupKey.PermanetlyDeletedSoon) {
            return _('recently_deleted')
        }
    }

    getDeletionIndicator = (fileID: FileID, _i: number) => {
        const group = this.props.files.find((f) => f.id === fileID)
        if (group !== undefined) {
            return <DeletionNotice daysRemaining={group.daysRemaining} />
        }
    }

    private isSelectMode = () => this.props.selectedFileIDs.length > 0

    protected getContent = () => {
        if (
            (this.props.files.length === 0 && this.props.isTrashLoading) ||
            this.props.timelineID === undefined
        ) {
            return <RippleLoaderPage />
        }

        if (this.props.files.length === 0 && this.props.timelineID) {
            const header = _('trash_no_files')
            const subHeader = (
                <div>
                    <div>{_('trash_delete_info')}</div>
                    <div>{_('trash_restore_info')}</div>
                </div>
            )
            return (
                <EmptyStatePage
                    illustration={
                        <img src={TrashIllustration} alt="Empty trash" />
                    }
                    header={header}
                    subHeader={subHeader}
                />
            )
        }

        const Toolbar = this.props.isMobileMode
            ? SelectionToolbarMobile
            : SelectionToolbarDesktop
        const toolbarComp = this.isSelectMode() && (
            <Toolbar
                buttons={this.selectionButtons}
                selectedFiles={this.props.selectedFileIDs}
                selectedFilesSize={this.props.selectedFilesTotalSize}
                cancelSelectMode={this.cancelSelecting}
            />
        )
        return (
            <ContentWrapper ref={this.contentElement}>
                {!this.props.isMobileMode && (
                    <Header width={this.props.groupStyle.width}>
                        {_('deleted_items')}
                    </Header>
                )}
                <ImageGroupList
                    isInSelectMode={true}
                    imagesGrouped={this.props.trashImageGroups}
                    groupStyle={this.props.groupStyle}
                    transformHeader={this.getTrashGroupHeader}
                    getAdditionalBottomAreaElements={this.getDeletionIndicator}
                />
                {this.props.hasMoreToFetch && (
                    <LoaderWrapper>
                        <RippleLoader />
                    </LoaderWrapper>
                )}
                {toolbarComp}
                <SelectionActionDialogPlacement
                    jobID={this.props.timelineID}
                    selectionOverride={this.props.selectedFileIDs}
                />
                <FilesOptionsPlacement
                    jobID={this.props.timelineID}
                    context={'TrashPage'}
                    files={this.props.selectedFileIDs}
                />
            </ContentWrapper>
        )
    }

    public componentDidMount() {
        this.props.shouldTrashBeFetched && this.props.fetchTrash()
        window.addEventListener('scroll', this.isReachingBottomOfList)
    }
    public componentWillUnmount() {
        this.props.cancelSelectMode(this.props.selectedFileIDs)
        window.removeEventListener('scroll', this.isReachingBottomOfList)
    }

    isReachingBottomOfList = () => {
        if (
            !this.props.isTrashLoading &&
            this.contentElement.current &&
            this.contentElement.current.scrollHeight -
                window.innerHeight -
                window.pageYOffset <
                1000 &&
            this.props.hasMoreToFetch
        ) {
            trackEvent('Trash', 'fetchedMoreTrashItems')
            this.props.doFetchMoreTrashItems()
        }
    }

    goToSettings = () => this.props.navigate(Pages.Settings.url)

    public render() {
        const leftButton = (
            <IconTextButton
                onClick={this.goToSettings}
                text={_('settings')}
                icon={BackArrowIcon}
                fontSize={fontSize.small_14}
            />
        )

        let navBar: JSX.Element | null = <TwoAreasTopNavBar left={leftButton} />
        if (this.props.isMobileMode && this.isSelectMode()) {
            navBar = null
        }

        return <PageWrapper navBar={navBar}>{this.getContent()}</PageWrapper>
    }
}

type TrashPageStoreState = StateOfSelector<typeof getTimelineJobID> &
    StateOfSelector<typeof isMobileMode> &
    StateOfSelector<typeof getTrashViewFilesForJob> &
    StateOfSelector<typeof getTrashGroupWithSelection> &
    StateOfSelector<typeof getImageGroupStyle> &
    StateOfSelector<typeof hasMoreTrashFilesToFetch> &
    StateOfSelector<typeof isLoadingTrash> &
    StateOfSelector<typeof shouldTrashBeFetched>

const mapStateToProps = (state: TrashPageStoreState): StateProps => {
    const timelineID = getTimelineJobID(state)
    return {
        isMobileMode: isMobileMode(state),
        files: timelineID ? getTrashViewFilesForJob(state, timelineID) : [],
        trashImageGroups: getTrashGroupWithSelection(state, timelineID || ''),
        groupStyle: getImageGroupStyle(state),
        hasMoreToFetch: hasMoreTrashFilesToFetch(state),
        selectedFileIDs: getSelectedTrashFileIDs(state, timelineID || ''),
        selectedFileInfos: getSelectedTrashFileInfos(state, timelineID || ''),
        selectedFilesTotalSize: getSelectedTrashFilesTotalSize(
            state,
            timelineID || '',
        ),
        isTrashLoading: isLoadingTrash(state),
        shouldTrashBeFetched: shouldTrashBeFetched(state),
        timelineID,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchTrash: () => loadTrashContent(dispatch),
    initiateDeleteFiles: () =>
        dispatch(
            SelectionActionInitiated(ConfirmableSelectionAction.PERM_DELETE),
        ),
    initiateRestoreFiles: () =>
        dispatch(SelectionActionInitiated(ConfirmableSelectionAction.RESTORE)),
    cancelSelectMode: (fileIDs: FileID[]) =>
        dispatch(FilesWereDeselected(fileIDs)),
    doFetchMoreTrashItems: () => dispatch(FetchMoreTrashTriggered()),
    doExportFiles: (jobID: JobID, fileInfos: FileInfoForPagination[]) => {
        maybePaginatedDownloadDeletedFiles(dispatch, {
            jobID,
            fileInfos,
            context: 'TrashPage',
            zipFileName: _('deleted_items'),
        })
    },
})

export const TrashPage = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TrashPageComponent),
)

/**
 * TrashPage for use inside web-view from the mobile applications. No footer.
 **/
class TrashPageComponentForMobileApplication extends TrashPageComponent {
    public render() {
        const topNav = (
            <TwoAreasTopNavBar left={_('deleted_items')} hideUserAvi={true} />
        )

        return (
            <PageWrapper
                navBar={this.props.isMobileMode ? undefined : topNav}
                hideFooter={true}>
                {this.getContent()}
            </PageWrapper>
        )
    }
}

export const TrashPageForMobileApplication = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TrashPageComponentForMobileApplication),
)
