import * as React from 'react'
import styled from 'styled-components'
import DocIcon from '@capture/capture-components/src/icons/document.svg?react'
import { colors, fontSize } from '@capture/client/assets/styleConstants'
import type { ExtendedJobFile } from '@capture/client/state/files/reducer'
import DownloadIcon from '@capture/capture-components/src/icons/download.svg?react'

const DocumentsWrapper = styled.div`
    width: 100%;
    max-width: 392px;
    padding: 0 8px;
    box-sizing: border-box;
    margin: 22px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props: { centerElements: boolean }) =>
        props.centerElements ? 'center' : 'flex-start'};
`
const DocElementWrapper = styled.div`
    width: 192px;
    max-width: 50%;
    flex: 1 1 auto;
    padding: 2px;
    box-sizing: border-box;
`
const DocumentElement = styled.div`
    background-color: ${(props: { backgroundColor: string }) =>
        props.backgroundColor};
    border: 1px solid ${colors.captureBlue};
    border-radius: 3px;
    padding: 4px 16px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
        flex: 0 0 auto;
    }
`
const DocText = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: ${fontSize.small_12};
    flex: 1;
    margin: 0 4px;
    color: ${(props: { textColor: string }) => props.textColor};
`

type DocProps = {
    file: ExtendedJobFile
    doDownloadFile: (fileID: FileID, path: string) => void
}
type LocalState = {
    isHovering: boolean
}
class DocElement extends React.Component<DocProps, LocalState> {
    public state: LocalState = { isHovering: false }
    private handleMouseEnter = () => {
        this.setState({ isHovering: true })
    }
    private handleMouseLeave = () => {
        this.setState({ isHovering: false })
    }
    private handleClick = () => {
        this.props.doDownloadFile(this.props.file.fileID, this.props.file.path)
    }
    public render() {
        const color = this.state.isHovering ? '#FFF' : colors.captureGrey800
        const backgroundColor = this.state.isHovering
            ? colors.captureBlue
            : colors.captureBlue50

        return (
            <DocElementWrapper>
                <DocumentElement
                    onClick={this.handleClick}
                    onKeyUp={this.handleClick}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    backgroundColor={backgroundColor}
                    role="button"
                    tabIndex={0}>
                    <DocIcon color={color} />
                    <DocText textColor={color}>{this.props.file.path}</DocText>
                    <DownloadIcon width={24} height={24} color={color} />
                </DocumentElement>
            </DocElementWrapper>
        )
    }
}

type Props = {
    files: ExtendedJobFile[]
    onDownloadSingleFile: (fileID: FileID, path: string) => void
}
export const DocSection: React.FunctionComponent<Props> = (props) => {
    const docs = props.files.map((file) => (
        <DocElement
            key={file.fileID}
            file={file}
            doDownloadFile={props.onDownloadSingleFile}
        />
    ))

    return (
        <DocumentsWrapper centerElements={props.files.length === 1}>
            {docs}
        </DocumentsWrapper>
    )
}
