import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    FileUploadBackendSucceeded,
    FileUploadFailed,
    FileUploadProgress,
    FileUploadRetry,
    FileUploadStarted,
    FileUploadSucceeded,
    FileWasAcceptedToUploadQueue,
    FileWasAddedToUploadQueue,
    FileWasRejected,
    FileWasRemovedFromUploadQueue,
    UploadStatusListFiltered,
    UploaderPaused,
    UploaderResumed,
    UploaderStatusBoxCollapsed,
    UploaderStatusBoxDismissed,
    UploaderStatusBoxExpanded,
    UploaderStatusBoxShown,
    UploaderStopAborted,
    UploaderStopPrompted,
    UploaderStopped,
} from './actions'

export enum UploaderOnlineStatus {
    Online = 'Online',
    Retrying = 'Retrying',
    Offline = 'Offline',
}

export enum FileUploadStatus {
    Pending = 'Pending',
    Enqueued = 'Enqueued',
    Uploading = 'Uploading',
    BackendSucceeded = 'BackendSucceeded',
    Succeeded = 'Succeeded',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
}

export enum RejectReason {
    FileIsFolder = 'FileIsFolder',
    LocalFileUnavailable = 'LocalFileUnavailable',
    NoStorage = 'NoStorage',
    UnSupported = 'UnSupported',
    FileWasDeleted = 'FileWasDeleted',
    Unknown = 'Unknown',
}

export type FileInformation = {
    id: number
    targetJob: JobID
    targetFolder: string
    alsoTargetTimeline: boolean
    status: FileUploadStatus
    statusReason?: RejectReason
    uploadedPercent: number
    name: string
    size: number
    fileUUID?: FileID // ID of file from server when file is uploaded
    contentHash?: string
}

export type UploaderState = {
    isStatusBoxExpanded: boolean
    isStatusBoxVisible: boolean
    isPaused: boolean
    isPendingStop: boolean
    onlineStatus: UploaderOnlineStatus
    currentlyUploadingFiles: number[]
    filesByID: { [index: number]: FileInformation }
    isFiltered: boolean
}

const initialState: UploaderState = {
    isStatusBoxExpanded: false,
    isStatusBoxVisible: false,
    isPaused: false,
    isPendingStop: false,
    onlineStatus: UploaderOnlineStatus.Online,
    currentlyUploadingFiles: [],
    filesByID: {},
    isFiltered: false,
}

const stoppableStates: FileUploadStatus[] = [
    FileUploadStatus.Enqueued,
    FileUploadStatus.Uploading,
]

export function uploaderReducer(
    state: UploaderState = initialState,
    action: Action,
): UploaderState {
    if (isType(action, UploaderStatusBoxExpanded)) {
        return { ...state, isStatusBoxExpanded: true }
    }
    if (isType(action, UploaderStatusBoxCollapsed)) {
        return { ...state, isStatusBoxExpanded: false }
    }
    if (isType(action, UploaderStatusBoxShown)) {
        return { ...state, isStatusBoxVisible: true }
    }
    if (isType(action, UploaderStatusBoxDismissed)) {
        return { ...state, isStatusBoxVisible: false }
    }
    if (isType(action, UploaderPaused)) {
        return { ...state, isPaused: true }
    }
    if (isType(action, UploaderResumed)) {
        return { ...state, isPaused: false }
    }

    if (isType(action, UploaderStopPrompted)) {
        return { ...state, isPendingStop: true }
    }
    if (isType(action, UploaderStopAborted)) {
        return { ...state, isPendingStop: false }
    }

    if (isType(action, UploaderStopped)) {
        const files: { [index: number]: FileInformation } = {}
        Object.keys(state.filesByID)
            .map((id) => state.filesByID[parseInt(id, 10)])
            .forEach((file: FileInformation) => {
                if (
                    stoppableStates.indexOf(file.status) >= 0 &&
                    file.uploadedPercent < 1
                ) {
                    file = { ...file, status: FileUploadStatus.Cancelled }
                }
                files[file.id] = file
            })
        return {
            ...state,
            filesByID: files,
            isPendingStop: false,
            onlineStatus: UploaderOnlineStatus.Online,
        }
    }

    if (isType(action, FileWasAddedToUploadQueue)) {
        const doneStatuses = [
            FileUploadStatus.Succeeded,
            FileUploadStatus.Cancelled,
            FileUploadStatus.Rejected,
        ]
        const allIsDone = state.currentlyUploadingFiles.reduce(
            (soFar: boolean, id: number) =>
                soFar &&
                doneStatuses.indexOf(state.filesByID[id].status) !== -1,
            true,
        )
        const currentlyUploading: number[] = allIsDone
            ? []
            : state.currentlyUploadingFiles

        const newFile: FileInformation = {
            id: action.payload.id,
            targetJob: action.payload.targetJob,
            targetFolder: action.payload.targetFolder || '',
            alsoTargetTimeline: action.payload.alsoTargetTimeline === true,
            status: FileUploadStatus.Pending,
            uploadedPercent: 0,
            name: action.payload.name,
            size: action.payload.size,
        }

        return {
            ...state,
            filesByID: { ...state.filesByID, [newFile.id]: newFile },
            currentlyUploadingFiles: currentlyUploading.concat([newFile.id]),
            isFiltered: false,
        }
    }

    if (isType(action, FileWasAcceptedToUploadQueue)) {
        return _updatedFile(state, action.payload.fileID, {
            status: FileUploadStatus.Enqueued,
        })
    }

    if (isType(action, FileWasRemovedFromUploadQueue)) {
        if (
            stoppableStates.indexOf(
                state.filesByID[action.payload.fileID].status,
            ) >= 0
        ) {
            return _updatedFile(state, action.payload.fileID, {
                status: FileUploadStatus.Cancelled,
            })
        }
        return state
    }

    if (isType(action, FileUploadProgress)) {
        const { percentComplete, fileID } = action.payload
        const newState = _updatedFile(state, fileID, {
            uploadedPercent: percentComplete,
        })
        // When the uploader retries and the file makes progress, the uploader is once again online
        if (action.payload.percentComplete > 0) {
            newState.onlineStatus = UploaderOnlineStatus.Online
        }
        return newState
    }

    if (isType(action, FileUploadStarted)) {
        return _updatedFile(state, action.payload.fileID, {
            status: FileUploadStatus.Uploading,
        })
    }
    if (isType(action, FileUploadBackendSucceeded)) {
        return _updatedFile(state, action.payload.fileID, {
            status: FileUploadStatus.BackendSucceeded,
            fileUUID: action.payload.fileUUID,
        })
    }
    if (isType(action, FileUploadSucceeded)) {
        return _updatedFile(state, action.payload.fileID, {
            status: FileUploadStatus.Succeeded,
            fileUUID: action.payload.fileUUID,
        })
    }
    if (isType(action, FileWasRejected)) {
        if (action.payload.reason === RejectReason.NoStorage) {
            return _updatedFile(state, action.payload.fileID, {
                status: FileUploadStatus.Enqueued,
                uploadedPercent: 0,
            })
        }
        return _updatedFile(state, action.payload.fileID, {
            status: FileUploadStatus.Rejected,
            statusReason: action.payload.reason,
        })
    }
    if (isType(action, FileUploadFailed)) {
        // If the file-upload fails due to the upload being canceled, no action should be taken (as this is expected)
        if (
            state.filesByID[action.payload.fileID].status ===
            FileUploadStatus.Cancelled
        ) {
            return state
        }

        // When file upload fails it is due to network-issues:
        // Set the uploader to be in offline-mode and re-enqueue the file to have it retried when network returns
        return Object.assign(
            _updatedFile(state, action.payload.fileID, {
                status: FileUploadStatus.Enqueued,
                uploadedPercent: 0,
            }),
            { onlineStatus: UploaderOnlineStatus.Offline },
        )
    }

    if (isType(action, UploadStatusListFiltered)) {
        return { ...state, isFiltered: true }
    }

    if (isType(action, FileUploadRetry)) {
        if (state.onlineStatus === UploaderOnlineStatus.Offline) {
            return { ...state, onlineStatus: UploaderOnlineStatus.Retrying }
        }
        return state
    }

    return state
}

function _updatedFile(
    state: UploaderState,
    fileID: number,
    updatedValues: Partial<FileInformation>,
): UploaderState {
    const fileInfo = state.filesByID[fileID]
    const allEqual = Object.entries(updatedValues)
        .map(([key, value]) => {
            return fileInfo[key as keyof FileInformation] === value
        })
        .every((r: boolean) => r)
    if (allEqual) {
        return state
    }

    const file = { ...state.filesByID[fileID], ...updatedValues }
    return { ...state, filesByID: { ...state.filesByID, [fileID]: file } }
}

export const uploaderReducerMapObj = {
    uploader: uploaderReducer,
}

export type StateWithUploader = StateOfReducerMapObj<
    typeof uploaderReducerMapObj
>
