import * as Sentry from '@sentry/react'
import * as React from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { trackEvent } from '~/analytics/eventTracking'
import { SadFaceIcon } from '../Icons/SadFaceIcon'

const ErrorContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    & > h2 {
        margin-top: 0;
    }
`

type Props = {
    fallbackUI?: React.ReactNode
    children?: React.ReactNode
}

export class ErrorBoundary extends React.Component<
    Props,
    { hasError: boolean }
> {
    public state = { hasError: false }
    public static getDerivedStateFromError(_error: any) {
        return { hasError: true }
    }
    public componentDidCatch(error: any, _errorInfo: any) {
        Sentry.captureException(error, {
            level: 'error',
            tags: { captureScope: 'app_level' },
        })
        trackEvent(
            'Error',
            'AppErrorBoundaryInvoked',
            error.message || 'unspecified error',
        )
    }
    public render() {
        if (this.state.hasError) {
            return (
                this.props.fallbackUI || (
                    <ErrorContent>
                        <h2>{_('something_went_wrong')}</h2>
                        <SadFaceIcon size={48} />
                    </ErrorContent>
                )
            )
        }

        return this.props.children
    }
}
