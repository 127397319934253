import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import AvatarIcon from '@capture/capture-components/src/icons/avatar.svg?react'
import CloseIcon from '@capture/capture-components/src/icons/close.svg?react'
import { _ } from '~/assets/localization/util'
import { Avatar, AvatarWrapper } from '~/components/Common/Avatar'
import { IconButton } from '~/components/Common/IconTextButton'
import { OverlayModal } from '~/components/Common/OverlayModal'
import { ProvideNamePrompt } from '~/components/ProvideName/ProvideNamePrompt'
import type { CurrentUserInfo } from '~/state/currentUser/selectors'
import {
    getCurrentUserInfo,
    getMissingNameModalVisibility,
} from '~/state/currentUser/selectors'
import { HideMissingNameModal } from '~/state/currentUser/actions'
import { colors } from '~/assets/styleConstants'

const IconWrapper = styled.div`
    display: flex;
    justify-content: start;
`
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    max-width: 400px;
    padding: 25px;
    h2,
    p {
        text-align: center;
    }
`
const AvatarPlaceholder = styled(AvatarWrapper)`
    background-color: ${colors.captureGrey300};
    border: 2px solid rgba(${colors.captureGrey500rgb}, 0.1);
    align-items: center;
`

const HeaderElement = (props: { closeButtonAction: () => void }) => (
    <IconWrapper>
        <IconButton
            onClick={props.closeButtonAction}
            icon={CloseIcon}
            data-cy={'nameModal_close'}
        />
    </IconWrapper>
)

const ProfilePicture = (props: { userInfo?: CurrentUserInfo }) => {
    if (props.userInfo?.profilePicture) {
        return (
            <Avatar
                size={50}
                name={props.userInfo?.name}
                img={props.userInfo?.profilePicture}
            />
        )
    }
    return (
        <AvatarPlaceholder size={60}>
            <AvatarIcon width={40} height={40} color={'white'} />
        </AvatarPlaceholder>
    )
}

type Props = {
    text: string
    onDone?: () => void
    onClose?: () => void
}

export const MissingNameModal = (props: Props) => {
    const userInfo = useSelector(getCurrentUserInfo)
    const dispatch = useDispatch()
    const showModal = useSelector(getMissingNameModalVisibility)

    const closeButtonAction = () => {
        props.onClose?.()
        dispatch(HideMissingNameModal())
    }

    if (!showModal) {
        return null
    }

    return (
        <OverlayModal
            onClose={closeButtonAction}
            headerElements={
                <HeaderElement closeButtonAction={closeButtonAction} />
            }
            ignoreCloseOnClickOutside>
            <ContentWrapper>
                <ProfilePicture userInfo={userInfo} />
                <h2>{_('set_your_name')}</h2>
                <p>{props.text}</p>
                <ProvideNamePrompt onDone={props.onDone} />
            </ContentWrapper>
        </OverlayModal>
    )
}
