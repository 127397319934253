import DownloadIcon from '@capture/capture-components/src/icons/download.svg?react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { downloadDeletedFiles, downloadFiles } from '~/API/download'
import { _, getStringWithAmount } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import { Button } from '~/components/Common/Button'
import { PRODUCT_NAME } from '~/config/constants'
import {
    ClearDownloaderState,
    MinimizeDownloaderModal,
    SetClickedLinkStatus,
} from '~/state/downloader/actions'
import {
    getDownloaderCount,
    getDownloaderModalInfo,
    getDownloaderModalState,
    getDownloaderSize,
} from '~/state/downloader/selectors'
import { isMobileMode } from '~/state/viewMode/selectors'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { IconTextButton } from '../Common/IconTextButton'
import { OptionsOverlay } from '../Common/Overlay'

const Header = styled.div`
    font-size: ${fontSize.medium_20};
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
`

const SubHeader = styled.div`
    font-size: ${fontSize.medium_16};
    p {
        margin-top: 0;
        margin-bottom: 5px;
    }
`
const HeaderWrapper = styled.div`
    padding: 20px 50px 10px 50px;
    padding: ${(props: { isMobile: boolean }) =>
        props.isMobile ? '20px 10px 10px 10px' : '20px 50px 10px 50px'};
`

const DownloadLinks = styled.div`
    padding: ${(props: { fontSize: string; isMobile: boolean }) =>
        props.isMobile ? '10px 20px 30px 30px' : '10px 50px 30px 50px'};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: ${(props: { fontSize: string; isMobile: boolean }) =>
        props.fontSize};
`

export const DownloadList = () => {
    const dispatch = useDispatch()
    const downloadModalInfo = useSelector(getDownloaderModalInfo)

    if (downloadModalInfo === undefined) {
        return null
    }

    const { downloadType, jobID, pages } = downloadModalInfo

    return (
        <>
            {pages.map((page, i) => {
                const text = `${page.name}.zip - ${getStringWithAmount(
                    page.fileIDs.length,
                    _('file__format'),
                    _('files__format'),
                )} (${bytesToSize(page.totalSize)})`

                const downloadCallback = async () => {
                    if (downloadType === 'download_deleted') {
                        downloadDeletedFiles(dispatch, {
                            jobID,
                            fileIDs: page.fileIDs,
                            zipFileName: page.name,
                        })
                        dispatch(SetClickedLinkStatus(i))
                        return
                    }

                    downloadFiles(dispatch, {
                        type: downloadType,
                        jobID,
                        fileIDs: page.fileIDs,
                        zipFileName: page.name,
                        hasHEIC: page.hasHEIC,
                    })
                    dispatch(SetClickedLinkStatus(i))
                }

                return (
                    <IconTextButton
                        key={text}
                        onClick={downloadCallback}
                        text={text}
                        icon={DownloadIcon}
                        color={
                            page.clicked
                                ? colors.captureBlue900
                                : colors.captureBlue
                        }
                        hoverColor={colors.captureBlue900}
                    />
                )
            })}
        </>
    )
}

export const PaginatedDownloadModal: React.FC = () => {
    const filesCount = useSelector(getDownloaderCount)
    const filesSize = useSelector(getDownloaderSize)?.toFixed(2)
    const modalStatus = useSelector(getDownloaderModalState)
    const isMobile = useSelector(isMobileMode)
    const dispatch = useDispatch()
    const dismissModal = () => dispatch(ClearDownloaderState())
    const minimizeModal = () => dispatch(MinimizeDownloaderModal())

    if (
        modalStatus !== 'max' ||
        filesCount === undefined ||
        filesSize === undefined
    ) {
        return null
    }

    if (modalStatus === 'max') {
        const subHeaderText = _('download_paginated__format')
            .split(/(%\w+%)/)
            .map((t) => {
                if (t === '%file_count%') {
                    return (
                        <b key="file_count">
                            {_('files__format').replace(
                                '%d',
                                filesCount.toString(),
                            )}
                        </b>
                    )
                }

                if (t === '%file_count_size%') {
                    return (
                        <b key="file_count_size">{`(${bytesToSize(
                            parseInt(filesSize),
                        )})`}</b>
                    )
                }

                return t
            })
        return (
            <OptionsOverlay
                onClose={dismissModal}
                cancelButton={Button('', dismissModal)}
                minimizeButton={Button('', minimizeModal)}
                ignoreCloseOnClickOutside={true}
                header={
                    <HeaderWrapper isMobile={isMobile}>
                        <Header>{_('download_your_photos_and_videos')}</Header>
                        <SubHeader>
                            <p>{subHeaderText}</p>
                            <br />
                            {_('download_pagination_convenience').replace(
                                '%product_name%',
                                PRODUCT_NAME,
                            )}
                        </SubHeader>
                    </HeaderWrapper>
                }>
                <DownloadLinks
                    fontSize={fontSize.medium_16}
                    isMobile={isMobile}>
                    <DownloadList />
                </DownloadLinks>
            </OptionsOverlay>
        )
    }

    return null
}
