import { getElementIndex } from '~/utilities/arrayUtils'
import {
    AutoGeneratedAlbumFinished,
    DownloadAlbumFailed,
} from '../album/actions'
import {
    AlbumChangedToPrivate,
    AlbumLinkWasCopied,
} from '../albumOptions/actions'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { AlbumUnsubscriptionFailed } from '../currentUser/actions'
import {
    FilesCopiedToTimeline,
    FilesCopiedToTimelineFailed,
    FilesWereShared,
} from '../files/actions'
import {
    FileWasSetAsCoverPhoto,
    FilesCopiedToAlbum,
    FilesCopiedToAlbumFailed,
    FilesDeletionFailed,
    FilesDeletionSucceeded,
    FilesDownloadFailed,
    FilesRestorationFailed,
    FilesRestorationSucceeded,
    JobCopiedToTimeline,
    JobCopiedToTimelineFailed,
    JobDownloadFailed,
    SetFileAsCoverPhotoFailed,
    ShareCreationFailed,
    imageCopiedToClipboard,
    imageCopyToClipboardFailed,
} from '../job/actions'
import { JobDeletionFailed } from '../jobInfo/actions'
import {
    DeleteShareFailed,
    ShareCopiedToTimeline,
    ShareCopiedToTimelineFailed,
} from '../share/actions'
import {
    CreditCardDeleteFailed,
    CreditCardDeleted,
    CreditCardUpdateFailed,
    CreditCardUpdated,
    PlanCancelFailed,
    PlanCanceled,
    PlanChangeFailed,
    PlanChangeSucceeded,
    PlanReactivated,
    PlanReactivationFailed,
    PurchaseFailed,
    PurchaseSucceeded,
} from '../storagePlan/actions'
import { MaxSelectionCountReached } from '../timeline/actions'
import {
    TrashFilesDeletionFailed,
    TrashFilesDeletionSucceeded,
} from '../trash/actions'
import {
    ProfileNameChangeFailed,
    ProfilePictureChangeFailed,
} from '../users/actions'
import {
    LongRunningTaskFinished,
    LongRunningTaskStarted,
    StatusNotificationDismissed,
} from './actions'

type StatusNotificationBase<T extends string> = { id: number; type: T }
export type PendingStatusType =
    | 'filesAreBeingDeleted'
    | 'filesAreBeingRestored'
    | 'filesAreBeingCopied'
    | 'preparingDownload'
type PendingNotification = StatusNotificationBase<PendingStatusType>

export type StatusNotification =
    | (StatusNotificationBase<'albumCoverPhotoSet'> & { relatedFile: FileID })
    | (StatusNotificationBase<'setAlbumCoverPhotoFailed'> & {
          relatedJobID: JobID
          relatedFile: FileID
      })
    | (StatusNotificationBase<'filesDeleted'> & {
          relatedJob: JobID
          relatedFiles: FileID[]
      })
    | (StatusNotificationBase<'filesDeletionFailed'> & {
          relatedFiles: FileID[]
      } & { options?: { supressRetry?: boolean } })
    | (StatusNotificationBase<'trashFilesDeleted'> & { numFiles: number })
    | (StatusNotificationBase<'trashFilesDeletionFailed'> & {
          relatedFiles: FileID[]
      })
    | (StatusNotificationBase<'filesWereRestored'> & { numFiles: number })
    | (StatusNotificationBase<'filesRestorationFailed'> & {
          relatedJob: JobID
          relatedFiles: FileID[]
      })
    | (StatusNotificationBase<'autoCreatedAlbum'> & {
          relatedJobID: JobID
          relatedJobName: string
      })
    | (StatusNotificationBase<'deleteAlbumFailed'> & { relatedJobID: JobID })
    | (StatusNotificationBase<'unsubscribeAlbumFailed'> & {
          relatedJobID: JobID
      })
    | StatusNotificationBase<'jobCopiedToTimeline'>
    | StatusNotificationBase<'jobCopiedToTimelineFailed'>
    | (StatusNotificationBase<'filesCopiedToTimeline'> & {
          relatedFiles: FileID[]
      })
    | StatusNotificationBase<'filesCopiedToTimelineFailed'>
    | (StatusNotificationBase<'filesCopiedToAlbum'> & {
          relatedJob: JobID
          relatedFiles: FileID[]
          showAlbumLink: boolean
      })
    | (StatusNotificationBase<'filesCopiedToAlbumFailed'> & {
          relatedJob: JobID
          relatedFiles: FileID[]
      })
    | StatusNotificationBase<'shareCopiedToTimeline'>
    | StatusNotificationBase<'shareCopiedToTimelineFailed'>
    | StatusNotificationBase<'shareDeletionFailed'>
    | StatusNotificationBase<'shareCreationFailed'>
    | StatusNotificationBase<'filesWereShared'>
    | StatusNotificationBase<'filesDownloadFailed'>
    | StatusNotificationBase<'linkWasCopied'>
    | StatusNotificationBase<'purchaseSuccessful'>
    | StatusNotificationBase<'purchaseFailed'>
    | StatusNotificationBase<'creditCardUpdated'>
    | StatusNotificationBase<'creditCardUpdateFailed'>
    | StatusNotificationBase<'planCanceled'>
    | StatusNotificationBase<'planCancelFailed'>
    | StatusNotificationBase<'planChangeSucceeded'>
    | StatusNotificationBase<'planChangeFailed'>
    | StatusNotificationBase<'planReactivated'>
    | StatusNotificationBase<'planReactivationFailed'>
    | StatusNotificationBase<'changeProfilePictureFailed'>
    | StatusNotificationBase<'maxSelectionCountReached'>
    | StatusNotificationBase<'imageCopiedToClipboard'>
    | StatusNotificationBase<'imageCopyToClipboardFailed'>
    | StatusNotificationBase<'albumChangedToPrivate'>
    | StatusNotificationBase<'userNameChangeFailed'>
    | StatusNotificationBase<'deleteCardSuccess'>
    | StatusNotificationBase<'deleteCardFailed'>
    | PendingNotification

export type StatusNotificationState = {
    _nextID: number
    messages: StatusNotification[]
}

const initialState: StatusNotificationState = {
    _nextID: 0,
    messages: [],
}

const appendStatusNotification = (
    state: StatusNotificationState,
    msg: StatusNotification,
) => {
    return {
        ...state,
        _nextID: state._nextID + 1,
        messages: state.messages.concat(msg),
    }
}

const removePendingStatusNotificaton = (
    state: StatusNotificationState,
    pendingType: PendingStatusType,
) => {
    const pendingMsgIndex = getElementIndex(
        state.messages,
        (m) => m.type === pendingType,
    )
    return {
        ...state,
        messages: state.messages.filter((_m, i) => i !== pendingMsgIndex),
    }
}

export const statusNotificationsReducer = (
    state: StatusNotificationState = initialState,
    action: Action,
): StatusNotificationState => {
    if (isType(action, FileWasSetAsCoverPhoto)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'albumCoverPhotoSet',
            relatedFile: action.payload.fileID,
        })
    }
    if (isType(action, SetFileAsCoverPhotoFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'setAlbumCoverPhotoFailed',
            relatedJobID: action.payload.jobID,
            relatedFile: action.payload.fileID,
        })
    }

    if (isType(action, LongRunningTaskStarted)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: action.payload,
        })
    }

    if (isType(action, LongRunningTaskFinished)) {
        return removePendingStatusNotificaton(state, action.payload)
    }

    if (isType(action, FilesDeletionSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesDeleted',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
        })
    }
    if (isType(action, FilesDeletionFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesDeletionFailed',
            relatedFiles: action.payload.files,
            options: {
                supressRetry: action.payload.supressRetry,
            },
        })
    }

    if (isType(action, TrashFilesDeletionSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'trashFilesDeleted',
            numFiles: action.payload.length,
        })
    }
    if (isType(action, TrashFilesDeletionFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'trashFilesDeletionFailed',
            relatedFiles: action.payload,
        })
    }

    if (isType(action, StatusNotificationDismissed)) {
        return {
            ...state,
            messages: state.messages.filter((m) => m.id !== action.payload),
        }
    }

    if (isType(action, JobCopiedToTimeline)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'jobCopiedToTimeline',
        })
    }

    if (isType(action, JobCopiedToTimelineFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'jobCopiedToTimelineFailed',
        })
    }

    if (isType(action, FilesCopiedToTimeline)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToTimeline',
            relatedFiles: action.payload,
        })
    }

    if (isType(action, FilesCopiedToTimelineFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToTimelineFailed',
        })
    }

    if (isType(action, FilesCopiedToAlbum)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToAlbum',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
            showAlbumLink: action.payload.showAlbumLink === true,
        })
    }

    if (isType(action, FilesCopiedToAlbumFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToAlbumFailed',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
        })
    }
    if (isType(action, JobDeletionFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'deleteAlbumFailed',
            relatedJobID: action.payload,
        })
    }
    if (isType(action, AlbumUnsubscriptionFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'unsubscribeAlbumFailed',
            relatedJobID: action.payload,
        })
    }

    if (isType(action, ShareCopiedToTimeline)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'shareCopiedToTimeline',
        })
    }
    if (isType(action, ShareCopiedToTimelineFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'shareCopiedToTimelineFailed',
        })
    }

    if (isType(action, DeleteShareFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'shareDeletionFailed',
        })
    }

    if (isType(action, ShareCreationFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'shareCreationFailed',
        })
    }

    if (isType(action, FilesWereShared)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesWereShared',
        })
    }

    if (
        isType(action, FilesDownloadFailed) ||
        isType(action, DownloadAlbumFailed) ||
        isType(action, JobDownloadFailed)
    ) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesDownloadFailed',
        })
    }

    if (isType(action, FilesRestorationSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesWereRestored',
            numFiles: action.payload.files.length,
        })
    }

    if (isType(action, FilesRestorationFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesRestorationFailed',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
        })
    }
    if (isType(action, AlbumLinkWasCopied)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'linkWasCopied',
        })
    }
    if (isType(action, AutoGeneratedAlbumFinished)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'autoCreatedAlbum',
            relatedJobID: action.payload.jobID,
            relatedJobName: action.payload.name,
        })
    }
    if (isType(action, PurchaseSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'purchaseSuccessful',
        })
    }
    if (isType(action, PurchaseFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'purchaseFailed',
        })
    }
    if (isType(action, CreditCardUpdated)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'creditCardUpdated',
        })
    }
    if (isType(action, CreditCardUpdateFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'creditCardUpdateFailed',
        })
    }
    if (isType(action, CreditCardDeleted)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'deleteCardSuccess',
        })
    }
    if (isType(action, CreditCardDeleteFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'deleteCardFailed',
        })
    }
    if (isType(action, PlanCanceled)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planCanceled',
        })
    }
    if (isType(action, PlanCancelFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planCancelFailed',
        })
    }
    if (isType(action, PlanChangeSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planChangeSucceeded',
        })
    }
    if (isType(action, PlanChangeFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planChangeFailed',
        })
    }
    if (isType(action, PlanReactivated)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planReactivated',
        })
    }
    if (isType(action, PlanReactivationFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planReactivationFailed',
        })
    }
    if (isType(action, ProfilePictureChangeFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'changeProfilePictureFailed',
        })
    }
    if (isType(action, MaxSelectionCountReached)) {
        const alreadyExist = state.messages.some(
            (m) => m.type === 'maxSelectionCountReached',
        )
        if (!alreadyExist) {
            return appendStatusNotification(state, {
                id: state._nextID,
                type: 'maxSelectionCountReached',
            })
        }
    }
    if (isType(action, imageCopiedToClipboard)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'imageCopiedToClipboard',
        })
    }
    if (isType(action, imageCopyToClipboardFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'imageCopyToClipboardFailed',
        })
    }
    if (isType(action, AlbumChangedToPrivate)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'albumChangedToPrivate',
        })
    }
    if (isType(action, ProfileNameChangeFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'userNameChangeFailed',
        })
    }

    return state
}

export const statusNotificationsReducerMapObj = {
    statusNotifications: statusNotificationsReducer,
}

export type StateWithStatusNotifications = StateOfReducerMapObj<
    typeof statusNotificationsReducerMapObj
>
