import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import CheckIcon from '@capture/capture-components/src/icons/check.svg?react'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { createAlbumWithFiles } from '~/API/album'
import { copyFilesToAlbum, fetchListOfJobs } from '~/API/job'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { fontSize, mediaQueries } from '~/assets/styleConstants'
import type { AlbumListFilter } from '~/state/albumList/reducer'
import type { AlbumJobInfo } from '~/state/albumList/selectors'
import {
    getAlbumsCurrentUserCanAdd,
    privacyModeFilter,
} from '~/state/albumList/selectors'
import {
    getCurrentUserUUID,
    isAlbumListReady,
} from '~/state/currentUser/selectors'
import type { CaptureFile } from '~/state/files/selectors'
import { isSharedJob } from '~/state/jobInfo/selectors'
import { isMobileMode } from '~/state/viewMode/selectors'
import type { GridElementProps } from '~/utilities/gridElementSizeCalculator'
import {
    computeGridContainerWidth,
    computeMaxGridWidth,
} from '~/utilities/gridElementSizeCalculator'
import { AlbumSelectList } from '../Common/AlbumSelectList'
import { AnimatedEllipsis } from '../Common/AnimatedEllipsis'
import { Button } from '../Common/Button'
import { CenteredElementWrapper } from '../Common/LayoutComponents'
import { OptionsOverlay } from '../Common/Overlay'
import { RippleLoader } from '../Common/RippleLoader'
import type { FileOptionContext } from './FilesOptionsPlacement'

const albumElementDesktop = {
    elementWidth: 229,
    elementHeight: 114,
    elementSpaceAround: 2,
}
const albumElementMobile = {
    elementWidth: 137,
    elementHeight: 68,
    elementSpaceAround: 2,
}

const getGridSizingWidth = (elementStyle: GridElementProps) =>
    computeGridContainerWidth(
        468,
        computeMaxGridWidth(elementStyle, 2),
        elementStyle.elementWidth,
        elementStyle.elementSpaceAround,
    )

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: ${(props: { width: number }) => props.width}px;
    min-height: 250px;
    font-size: ${fontSize.large_22};

    ${mediaQueries.mobile} {
        font-size: ${fontSize.medium_18};
    }
`

const AlbumLoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${fontSize.medium_16};
`

type StateProps = {
    albums: AlbumJobInfo[]
    albumsLoaded: boolean
    isSharedAlbum: (albumID: JobID) => boolean
    currentUser?: UserID
    isMobile: boolean
}

type DispatchProps = {
    onFetchAlbums: (currentUser: UserID) => void
    doCopyFiles: (albumID: JobID) => Promise<void>
    doCreateAlbum: () => void
}

type OwnProps = {
    files: CaptureFile[]
    onConfirmNew: (albumID: JobID) => void
    onConfirmExisting: () => void
    cancel: () => void
    filter?: AlbumListFilter
    context: FileOptionContext
    jobID: JobID
}

type Props = StateProps & DispatchProps & OwnProps
type LocalState = { selectedAlbumID?: JobID }
class _AddToAlbumComponent extends React.Component<Props, LocalState> {
    public state: LocalState = {}

    public componentDidMount() {
        if (this.props.currentUser) {
            this.props.onFetchAlbums(this.props.currentUser)
        }
    }

    private onConfirm = () => {
        if (this.state.selectedAlbumID) {
            const isSharedAlbum = this.props.isSharedAlbum(
                this.state.selectedAlbumID,
            )
            this.props.doCopyFiles(this.state.selectedAlbumID).then(() => {
                if (this.props.context === 'TimelinePage') {
                    trackEvent(
                        'TimelinePage',
                        'CopyFilesToAlbum',
                        isSharedAlbum ? 'SharedAlbum' : 'PrivateAlbum',
                    )
                    trackEventInternal(
                        'timeline_selection_add_to_album_finish',
                        {
                            count: this.props.files.length,
                            shared_album: isSharedAlbum,
                        },
                    )
                } else if (this.props.context === 'CarouselView') {
                    trackEvent(
                        'CarouselView',
                        'CopyFilesToAlbum',
                        isSharedAlbum ? 'SharedAlbum' : 'PrivateAlbum',
                    )
                    trackEventInternal('carousel_add_to_album_finish', {
                        count: this.props.files.length,
                        shared_album: isSharedAlbum,
                    })
                } else if (this.props.context === 'AlbumPage') {
                    trackEvent(
                        'AlbumPage',
                        'CopyFilesToAlbum',
                        isSharedAlbum ? 'SharedAlbum' : 'PrivateAlbum',
                    )
                    trackEventInternal('carousel_add_to_album_finish', {
                        count: this.props.files.length,
                        shared_album: isSharedAlbum,
                    })
                }
            })
            this.props.onConfirmExisting()
        }
    }

    private setSelectedAlbum = (id: JobID) => {
        this.setState({
            ...this.state,
            selectedAlbumID: id,
        })
    }

    public render() {
        if (!this.props.albums) {
            return null
        }
        const albumElementStyle = this.props.isMobile
            ? albumElementMobile
            : albumElementDesktop
        const gridWidth = getGridSizingWidth(albumElementStyle)
        let content = (
            <AlbumSelectList
                albums={this.props.albums}
                onSelect={this.setSelectedAlbum}
                selectedID={this.state.selectedAlbumID}
                onCreateNew={this.props.doCreateAlbum}
                listElementStyle={albumElementStyle}
                listWidth={gridWidth}
                jobID={this.props.jobID}
            />
        )
        if (!this.props.albumsLoaded) {
            content = (
                <CenteredElementWrapper>
                    <AlbumLoadingWrapper>
                        <RippleLoader size={50} />
                        <AnimatedEllipsis text={_('fetching_albums')} />
                    </AlbumLoadingWrapper>
                </CenteredElementWrapper>
            )
        }

        return (
            <OptionsOverlay
                onClose={this.props.cancel}
                cancelButton={Button(_('cancel'), this.props.cancel)}
                confirmButton={Button(_('done'), this.onConfirm, {
                    icon: CheckIcon,
                    isDisabled: !this.state.selectedAlbumID,
                })}
                title={this.props.albumsLoaded ? _('album_select') : ''}>
                <ContentContainer width={gridWidth}>{content}</ContentContainer>
            </OptionsOverlay>
        )
    }
}

type AddToAlbumStoreState = StateOfSelector<typeof getAlbumsCurrentUserCanAdd> &
    StateOfSelector<typeof getCurrentUserUUID> &
    StateOfSelector<typeof isAlbumListReady> &
    StateOfSelector<typeof isSharedJob> &
    StateOfSelector<typeof isMobileMode>

const mapStateToProps = (
    state: AddToAlbumStoreState,
    ownProps: OwnProps,
): StateProps => {
    const availableAlbums = getAlbumsCurrentUserCanAdd(state)
    const albums = availableAlbums.filter(
        privacyModeFilter[ownProps.filter || 'all'],
    )

    return {
        albums,
        currentUser: getCurrentUserUUID(state),
        albumsLoaded: isAlbumListReady(state),
        isSharedAlbum: (albumID: JobID) => isSharedJob(state, albumID),
        isMobile: isMobileMode(state),
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    onFetchAlbums: (currentUser: UserID) =>
        fetchListOfJobs(dispatch, currentUser),
    doCopyFiles: (albumID: JobID) => {
        return copyFilesToAlbum(dispatch, albumID, ownProps.files)
    },
    doCreateAlbum: async () => {
        const albumID = await createAlbumWithFiles(
            dispatch,
            '__new_album__',
            ownProps.files,
        )
        ownProps.onConfirmNew(albumID)
    },
})

export const AddToAlbum = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_AddToAlbumComponent)
