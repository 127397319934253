import styled from 'styled-components'
import DocIcon from '@capture/capture-components/src/icons/document.svg?react'
import ThumbnailPlaceholder from '~/assets/svg/ThumbnailPlaceholder.svg'
import { FileTarget, getFileTargetFromName } from '~/utilities/fileTarget'
import { CenteredElementWrapper } from '../Common/LayoutComponents'

const ThumbWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const Image = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`
const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

type Props = {
    fileName: string
    thumbURL: string
}

export const Thumb = (props: Props) => {
    const fileTarget = getFileTargetFromName(props.fileName)
    if (fileTarget === FileTarget.Pictures) {
        return (
            <ThumbWrapper>
                <Image
                    style={{
                        backgroundImage: `url(${props.thumbURL}), url(${ThumbnailPlaceholder})`,
                    }}
                />
            </ThumbWrapper>
        )
    }

    if (fileTarget === FileTarget.Movies) {
        return (
            <ThumbWrapper>
                <Video src={props.thumbURL} />
            </ThumbWrapper>
        )
    }

    return (
        <ThumbWrapper>
            <CenteredElementWrapper>
                <DocIcon width={40} height={40} />
            </CenteredElementWrapper>
        </ThumbWrapper>
    )
}
