import {
    type PaymentIntent,
    type Stripe,
    type StripeError,
    loadStripe,
} from '@stripe/stripe-js'
import { STRIPE_API_TOKEN } from '~/config/constants'

export enum StripeCardErrorCodes {
    CardDeclined = 'generic_decline',
    CardExpired = 'expired_card',
    IncorrectCVC = 'incorrect_cvc',
    InsufficientFunds = 'insufficient_funds',
}

export enum PaymentStatus {
    RequiresPaymentMethod = 'requires_payment_method',
    RequiresConfirmation = 'requires_confirmation',
    RequiresAction = 'requires_action',
    Processing = 'processing',
    RequiresCapture = 'requires_capture',
    Canceled = 'canceled',
    Succeeded = 'succeeded',
}

export type HandleCardPaymentResponse = {
    paymentIntent?: PaymentIntent
    error?: StripeError
}

let stripeObject: Stripe | null = null

export const getStripeObject = async (apiToken: string): Promise<Stripe> => {
    if (stripeObject) {
        return stripeObject
    }

    stripeObject = await loadStripe(apiToken)
    if (stripeObject === null) {
        throw new Error('stripe failed to load')
    }
    return stripeObject
}

export const validateSCA = async (clientSecret: string, cardID: string) => {
    const stripe = await getStripeObject(STRIPE_API_TOKEN)
    const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: cardID },
    )
    if (error) {
        throw new Error(error.message)
    }
    return paymentIntent!
}
