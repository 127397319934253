import * as React from 'react'
import { _ } from '@capture/client/assets/localization/util'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
    Checkbox,
    Typography,
    Prompt,
    PromptTrigger,
    Accordion,
    PageCard,
    AccordionItem,
    PagePrimaryButton,
    PagePrimaryDestructiveButton,
} from '@capture/capture-components'
import { PRIVACY_STATEMENT_LINK } from '@capture/client/config/constants'
import { getServiceProvider } from '@capture/client/API/HostProvider'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import { fontSize } from '@capture/client/assets/styleConstants'
import { IconTextButton } from '@capture/client/components/Common/IconTextButton'
import { LoadingPage } from '@capture/client/components/Common/LoadingPage'
import { PageWrapper } from '@capture/client/components/Common/PageWrapper'
import { TwoAreasTopNavBar } from '@capture/client/components/Navigation/TwoAreasTopNavBar'
import { Settings, Takeout } from '@capture/client/routing/pages'
import { RecordUtil } from '@capture/client/utilities/dictionaryUtils'
import { ConsentToggle } from './ConsentToggle'
import { AccessDataPrompt, DeleteAccountPrompt } from './PrivacyPagePrompt'

// we need extra spacing in this section
const AccordionItemConsent = styled(AccordionItem)`
    gap: 1.5rem;

    > :last-child {
        margin-top: 0.5rem;
    }
`

const VerticalSpacer = styled.div.attrs({
    'aria-hidden': true,
    role: 'presentation',
})<{ $amount: number }>`
    padding-bottom: ${({ $amount }) => `${$amount}rem`};
`

const CheckboxList = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const withTSL = (s: string) =>
    s.replace('%company_name%', 'Telenor Software Lab AS')

const PrivacyStatementParagraph = () => {
    const [_s, beforeLink, linkText, afterLink] = withTSL(
        _('privacy_desc__processes_with_privacy_policy__format'),
    ).match('(.*)%link_start%(.*)%link_end%(.*)')!
    return (
        <Typography.Paragraph>
            {beforeLink}
            <Typography.Link href={PRIVACY_STATEMENT_LINK}>
                {linkText}
            </Typography.Link>
            {afterLink}
        </Typography.Paragraph>
    )
}

type TConsentVals = Record<DataProtectionConsentKey, boolean>
export type ConsentValue = {
    val: boolean
    isSaving: boolean
    toggle: () => unknown
}

const useConsentValues = (): Record<
    DataProtectionConsentKey,
    ConsentValue
> | null => {
    const [state, setState] = React.useState<TConsentVals | null>(null)
    const [serverState, setServerState] = React.useState<TConsentVals | null>(
        null,
    )

    React.useEffect(() => {
        getServiceProvider()
            .getAppServiceForLoggedInUserDefaults()
            .then((service) => service.getDataProtectionConsentValues())
            .then(({ data }) => {
                setState(data)
                setServerState(data)
            })
    }, [setState, setServerState])

    return React.useMemo(() => {
        if (state === null || serverState === null) {
            return null
        }

        const save = async ([key, value]: [
            DataProtectionConsentKey,
            boolean,
        ]) => {
            setState((prevState) => prevState && { ...prevState, [key]: value })
            try {
                const service =
                    await getServiceProvider().getAppServiceForLoggedInUserDefaults()
                await service.updateDataProtectionConsentValues({
                    [key]: value,
                })
                setServerState(
                    (prevState) => prevState && { ...prevState, [key]: value },
                )
            } catch (e) {
                console.error('when updateDataProtectionConsentValues:', e)
                setState(state)
            }
        }

        return RecordUtil.from(state)
            .map((val, key) => ({
                val,
                isSaving: val !== serverState[key],
                toggle: () => save([key, !val]),
            }))
            .getValue()
    }, [state, serverState, setState, setServerState])
}

type PrivacyPageProps = {
    insideMobileApp: boolean
}

export const PrivacyPage = ({ insideMobileApp }: PrivacyPageProps) => {
    const consentValues = useConsentValues()
    const navigate = useNavigate()

    const navigateToSettings = () => navigate(Settings.url)
    const navigateToTakeout = () => navigate(Takeout.url)

    if (consentValues === null) {
        return <LoadingPage />
    }
    const isUserConsentMigrated = consentValues?.face_grouping

    const backButton =
        insideMobileApp === true ? null : (
            <IconTextButton
                onClick={navigateToSettings}
                text={_('settings')}
                icon={BackArrowIcon}
                fontSize={fontSize.small_14}
            />
        )

    const navBar = <TwoAreasTopNavBar left={backButton} />

    return (
        <PageWrapper navBar={navBar} hideFooter={insideMobileApp === true}>
            <PageCard>
                <Accordion type="multiple">
                    <AccordionItem title={_('stay_up_to_date')}>
                        <Typography.Paragraph>
                            {_('privacy_desc__let_you_know_about_updates')}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            {withTSL(_('can_contact_me_via__format'))}
                        </Typography.Paragraph>
                        <CheckboxList>
                            <Checkbox
                                checked={
                                    consentValues.stay_up_to_date__email.val
                                }
                                onClick={
                                    consentValues.stay_up_to_date__email.toggle
                                }
                                disabled={
                                    consentValues.stay_up_to_date__email
                                        .isSaving
                                }>
                                {_('email')}
                            </Checkbox>
                            <Checkbox
                                checked={
                                    consentValues
                                        .stay_up_to_date__push_notification.val
                                }
                                onClick={
                                    consentValues
                                        .stay_up_to_date__push_notification
                                        .toggle
                                }
                                disabled={
                                    consentValues
                                        .stay_up_to_date__push_notification
                                        .isSaving
                                }>
                                {_('push_notification')}
                            </Checkbox>
                            <Checkbox
                                checked={consentValues.stay_up_to_date__sms.val}
                                onClick={
                                    consentValues.stay_up_to_date__sms.toggle
                                }
                                disabled={
                                    consentValues.stay_up_to_date__sms.isSaving
                                }>
                                {_('SMS')}
                            </Checkbox>
                        </CheckboxList>
                    </AccordionItem>

                    <AccordionItemConsent title={_('consents')}>
                        {isUserConsentMigrated ? (
                            <>
                                <ConsentToggle
                                    title={_('face_grouping')}
                                    description={withTSL(
                                        _(
                                            'privacy_desc__can_process_face_details__format',
                                        ),
                                    )}
                                    item={consentValues.face_grouping}
                                />
                                <ConsentToggle
                                    title={_(
                                        'advanced_filtering_and_categorization',
                                    )}
                                    description={withTSL(
                                        _(
                                            'privacy_desc__can_process_images_advanced_filtering__format',
                                        ),
                                    )}
                                    item={
                                        consentValues.advanced_filtering_and_categorization
                                    }
                                />
                            </>
                        ) : (
                            <ConsentToggle
                                title={_('facial_recognition')}
                                description={withTSL(
                                    _(
                                        'privacy_desc__can_process_images_categorisation__format',
                                    ),
                                )}
                                item={consentValues.facial_recognition}
                            />
                        )}

                        <ConsentToggle
                            title={_('help_us_improve')}
                            description={withTSL(
                                _(
                                    'privacy_desc__can_process_data_for_improvement__format',
                                ),
                            )}
                            item={consentValues.help_us_improve}
                        />
                    </AccordionItemConsent>

                    <AccordionItem title={_('access_your_data')}>
                        <PrivacyStatementParagraph />
                        <Typography.Paragraph>
                            {withTSL(
                                _(
                                    'privacy_desc__download_personal_information__format',
                                ),
                            )}
                        </Typography.Paragraph>
                        <Prompt>
                            <PromptTrigger asChild>
                                <PagePrimaryButton>
                                    {_('privacy_request_a_copy_of_data')}
                                </PagePrimaryButton>
                            </PromptTrigger>
                            <AccessDataPrompt />
                        </Prompt>

                        <VerticalSpacer $amount={1} />

                        <Typography.Paragraph>
                            {_('privacy_download_all')}
                        </Typography.Paragraph>
                        <PagePrimaryButton onPress={navigateToTakeout}>
                            {_('privacy_download_all_button')}
                        </PagePrimaryButton>
                    </AccordionItem>

                    <AccordionItem
                        title={_('delete_your_account')}
                        data-cy="deleteCaptureAccountExpandable">
                        <Typography.Paragraph>
                            {withTSL(
                                _('you_can_request_delete_account__format'),
                            )}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            <Typography.Bold>
                                {_(
                                    'delete_account_includes_irreversible_deletion',
                                )}
                            </Typography.Bold>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            {_('it_may_take_up_to_days__format').replace(
                                '%d',
                                '30',
                            )}
                        </Typography.Paragraph>
                        <Prompt>
                            <PromptTrigger asChild>
                                <PagePrimaryDestructiveButton data-cy="requestAccountDeletionBtn">
                                    {_('request_deletion')}
                                </PagePrimaryDestructiveButton>
                            </PromptTrigger>
                            <DeleteAccountPrompt />
                        </Prompt>
                    </AccordionItem>

                    {insideMobileApp === true && <VerticalSpacer $amount={2} />}
                </Accordion>
            </PageCard>
        </PageWrapper>
    )
}

export const PrivacyPageForMobileApplication = () => {
    return <PrivacyPage insideMobileApp />
}
