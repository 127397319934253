import * as React from 'react'

import type { Token } from '@stripe/stripe-js'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
} from '~/components/Common/IconTextButton'
import { FormRow } from '~/components/Form/FormField'
import type { CreditCardFieldMethods } from '~/components/Stripe/StripeCreditCardForm'
import { makeStripeCreditCardField } from '~/components/Stripe/StripeCreditCardForm'
import { STRIPE_API_TOKEN } from '~/config/constants'
import { isEmail } from '~/utilities/inputValidation'

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top: 16px;
    gap: 24px;
    justify-content: center;

    > div {
        width: 150px;
    }

    ${mediaQueries.mobile} {
        gap: 6px;

        > div {
            width: 122px;
        }
    }
`

const FormRowWrapper = styled.div`
    margin: 8px 0 8px;
    border: 1px solid ${colors.captureGrey400};
`

const EmailInfo = styled.span`
    display: block;
    font-size: ${fontSize.small_14};
    color: ${colors.captureGrey700};
    margin: 5px 0 15px;
    font-style: italic;
`

type Props = {
    userEmail?: string
    onPaymentDetailsAccepted: (stripeToken: Token, email: string) => void
    onCancel: () => void
}

type ComponentState = {
    email: { value: string; isValid?: boolean }
}

const CreditCardField = makeStripeCreditCardField(STRIPE_API_TOKEN)

export class BuyStorageForm extends React.Component<Props, ComponentState> {
    private credRef = React.createRef<CreditCardFieldMethods>()
    public state: ComponentState = {
        email: this.props.userEmail
            ? {
                  value: this.props.userEmail,
                  isValid: isEmail(this.props.userEmail),
              }
            : { value: '' },
    }
    private handlePayButtonClicked = async () => {
        if (this.state.email.isValid === undefined) {
            this.setState({ email: { value: '', isValid: false } })
            return
        }
        if (this.credRef.current && this.state.email.isValid) {
            const { token } = await this.credRef.current.getToken(
                this.state.email.value,
            )
            if (token) {
                this.props.onPaymentDetailsAccepted(
                    token,
                    this.state.email.value,
                )
            }
        }
    }
    private updateEmail = (newValue: string) =>
        this.setState({
            email: {
                value: newValue,
                isValid: isEmail(newValue),
            },
        })
    public render() {
        return (
            <form>
                <FormRowWrapper>
                    <FormRow
                        header={_('email')}
                        onChange={this.updateEmail}
                        showError={this.state.email.isValid === false}
                        name="email"
                        autoComplete="email"
                        preFilledValue={this.props.userEmail}
                    />
                </FormRowWrapper>
                <EmailInfo>{_('email_request_explanation')}</EmailInfo>
                <CreditCardField
                    ref={this.credRef}
                    key="buyStorageForm__CreditCardField"
                />
                <ButtonWrapper>
                    <BorderedTextButton
                        onClick={this.props.onCancel}
                        text={_('cancel')}
                        fontSize={fontSize.medium_16}
                        color={colors.captureBlue}
                    />
                    <FilledTextButton
                        text={_('confirm_payment')}
                        fontSize={fontSize.medium_16}
                        fillColor={colors.captureBlue}
                        onClick={this.handlePayButtonClicked}
                    />
                </ButtonWrapper>
            </form>
        )
    }
}
