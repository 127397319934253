import { createSelector } from 'reselect'
import { localizedDateStringShort } from '~/components/Common/TimeStrings'
import { cachedInArray, groupArray } from '~/utilities/arrayUtils'
import { getMaxStorage, getUsedStorage } from '../currentUser/selectors'
import type { StateWithStoragePlan } from './reducer'

const _getAvailableProducts = (state: StateWithStoragePlan) =>
    state.storagePlan.availableProducts

// TODO get bytes sizes from backend
export const getAvailableProducts = createSelector(
    _getAvailableProducts,
    (products) =>
        products.map((p) => ({
            ...p,
            size: p.size * Math.pow(2, 30),
        })),
)

export const getAvailableTrialPeriod = (state: StateWithStoragePlan) =>
    state.storagePlan.availableTrial

export const getCurrentUserGrants = (state: StateWithStoragePlan) =>
    state.storagePlan.userGrants.map((g) => ({
        ...g,
        size: g.size * Math.pow(2, 30),
    }))
export const isUpdatingStoragePlan = (state: StateWithStoragePlan) =>
    state.storagePlan.isUpdatingStoragePlan

export const getCreditCardInfo = (state: StateWithStoragePlan) =>
    state.storagePlan.paymentInfo

export const getPriceString = (price: number, currency: string) => {
    const normalisedCurrency = (price / 100).toFixed(price % 100 === 0 ? 0 : 2)
    return `${currency.toUpperCase()} ${normalisedCurrency}`
}

export type StoragePlan = {
    size: number
    isCanceled: boolean
    planActionLink?: string
    validToDate?: string
}

export type StripeStoragePlan = StripeProduct &
    StoragePlan & {
        priceString: string
    }

export const getCurrentStripeStoragePlan = createSelector(
    getAvailableProducts,
    getCurrentUserGrants,
    (products, grants): StripeStoragePlan | undefined => {
        const isAvailable = cachedInArray(products.map((p) => p.id))
        const currentGrant = grants.find(
            (g): g is StripeUserGrant =>
                g.source === 'capture stripe' &&
                isAvailable(g.stripe_product_id),
        )
        if (currentGrant) {
            const { _links, renews_at, expires_at, stripe_product_id } =
                currentGrant
            const currentProduct = products.filter(
                (p) => p.id === stripe_product_id,
            )[0]
            const validToDate = renews_at || expires_at
            return {
                ...currentProduct,
                isCanceled: expires_at !== undefined,
                priceString: getPriceString(
                    currentProduct.price,
                    currentProduct.currency,
                ),
                planActionLink: _links.cancellation || _links.reactivation,
                validToDate: validToDate
                    ? localizedDateStringShort(new Date(validToDate * 1000))
                    : undefined,
            }
        }
    },
)

export const getCaptureIncludedStoragePlan = createSelector(
    getCurrentUserGrants,
    (grants): StoragePlan | undefined => {
        const captureGrant = grants.find((g) => g.source === 'capture')

        if (captureGrant) {
            return {
                size: captureGrant.size,
                isCanceled: false,
            }
        }
    },
)

const unlimitedStorageThreshold = 1000 * Math.pow(2, 30) // 1000 GB
export const getAggregatedUserGrants = createSelector(
    getCurrentUserGrants,
    (grants): UserGrant[] => {
        const shouldAggregate = ({ source }: UserGrant) =>
            source === 'customer service' ||
            source === 'other' ||
            source === 'capture'
        const definiteGrants = grants.filter(
            (g) =>
                !(
                    (
                        shouldAggregate(g) ||
                        (g.source === 'b2b' &&
                            g.size >= unlimitedStorageThreshold)
                    ) // unlimited storage will be handled separately
                ),
        )

        const aggregateGrants = grants.filter(shouldAggregate)
        const aggregateGrantGroups = groupArray(
            aggregateGrants,
            (g) => g.source,
        )
        const aggregatedGrants = Object.keys(aggregateGrantGroups).map(
            (group) => ({
                ...aggregateGrantGroups[group][0],
                size: aggregateGrantGroups[group].reduce(
                    (s, g) => s + g.size,
                    0,
                ),
            }),
        )

        return aggregatedGrants.concat(definiteGrants)
    },
)

export type UserGrantSource = 'capture stripe' | OtherPaymentSource
export type SourceGroup = {
    sourceName: UserGrantSource
    size: number
    price?: number
    currency?: string
    period?: string
    isCanceled?: boolean
    priceString?: string
}
export const getStorageSources = createSelector(
    getAggregatedUserGrants,
    getCurrentStripeStoragePlan,
    (userGrant, stripeStoragePlan): SourceGroup[] => {
        return userGrant.map((g) => {
            if (g.source === 'capture stripe' && stripeStoragePlan) {
                return {
                    sourceName: g.source,
                    size: g.size,
                    price: stripeStoragePlan.price,
                    currency: stripeStoragePlan.currency,
                    period: stripeStoragePlan.period,
                    isCanceled: stripeStoragePlan.isCanceled,
                    priceString: getPriceString(
                        stripeStoragePlan.price,
                        stripeStoragePlan.currency,
                    ),
                }
            }

            return {
                sourceName: g.source,
                size: g.size,
            }
        })
    },
)

export enum UnavailableReason {
    PLAN_IS_DOWNGRADE = 'PLAN_IS_DOWNGRADE',
    INSUFFICIENT_STORAGE_PLAN = 'INSUFFICIENT_STORAGE_PLAN',
}
export type CaptureStripeProduct = StripeProduct & {
    unavailableReason?: UnavailableReason
    priceString: string
}

export type PlanGroup = {
    size: number
    monthly: CaptureStripeProduct
    yearly: CaptureStripeProduct
}

const getUnavailableReason = (
    usedSpace: number,
    maxSpace: number,
    consideredPlan: StripeProduct,
    currPlan?: StripeStoragePlan,
) => {
    const sizeGained = consideredPlan.size - (currPlan ? currPlan.size : 0)
    const isPeriodDowngrade =
        currPlan &&
        currPlan.period === 'yearly' &&
        consideredPlan.period === 'monthly'

    if (isPeriodDowngrade) {
        return UnavailableReason.PLAN_IS_DOWNGRADE
    }

    if (usedSpace > maxSpace + sizeGained) {
        return UnavailableReason.INSUFFICIENT_STORAGE_PLAN
    }
}

export const getStoragePlanGroups = createSelector(
    getAvailableProducts,
    getCurrentStripeStoragePlan,
    getUsedStorage,
    getMaxStorage,
    (products, currPlan, usedSpace, maxSpace) => {
        const monthlyList: Map<PlanGroup['size'], PlanGroup['monthly']> =
            new Map()
        const yearlyList: Map<PlanGroup['size'], PlanGroup['yearly']> =
            new Map()
        const sizeList: Set<PlanGroup['size']> = new Set()

        products.forEach((p) => {
            if (!sizeList.has(p.size)) {
                sizeList.add(p.size)
            }
            const unavailableReason = getUnavailableReason(
                usedSpace,
                maxSpace,
                p,
                currPlan,
            )

            switch (p.period) {
                case 'monthly':
                    monthlyList.set(p.size, {
                        ...p,
                        unavailableReason,
                        priceString: getPriceString(p.price, p.currency),
                    })
                    break
                case 'yearly':
                    yearlyList.set(p.size, {
                        ...p,
                        unavailableReason,
                        priceString: getPriceString(p.price, p.currency),
                    })
                    break
            }
        })

        const groups: PlanGroup[] = []
        sizeList.forEach((size) => {
            const monthly = monthlyList.get(size)
            const yearly = yearlyList.get(size)
            if (monthly && yearly) {
                groups.push({
                    size,
                    monthly,
                    yearly,
                })
            }
        })

        return groups.sort((a, b) => b.size - a.size)
    },
)
